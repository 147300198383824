import { useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';
import TestTimelineLog from './TestTimelineLog';
import { useFirestoreRealtimeData } from '../../../../../../hooks';
import Input from './Input';
import ConfigHint from './ConfigHint';
import Output from './Output';
import { MotionDiv } from '../../../../../../components/atoms';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';

const Playground = () => {
	const { remainingTasks } = useContext(GenflowPlanContext);
	const [testRunning, setTestRunning] = useState(false);
	const [staticData, setStaticData] = useState([]);
	const [activeRequestId, setActiveRequestId] = useState();

	const handleActivate = (requestId) => {
		setActiveRequestId(requestId);
		setTestRunning(true);
	};

	return (
		<Flex vertical gap='middle'>
			<MotionDiv animationIndex={0}>
				<Input onActivate={handleActivate} testRunning={testRunning} />
			</MotionDiv>
			{remainingTasks?.length > 0 && (
				<MotionDiv animationIndex={1}>
					<ConfigHint />
				</MotionDiv>
			)}
			{
				(testRunning && activeRequestId) ? (
					<MotionDiv animationIndex={2}>
						<TestTimelineLogRealtime
							requestId={activeRequestId}
							// scrollContainerRef={scrollContainerRef}
							onFinish={(v) => {
								setStaticData(v);
								setTestRunning(false);
							// setAllowAnimate(false);
							}}
						/>
					</MotionDiv>
				) : activeRequestId ? (
					<>
						<TestTimelineLog data={staticData} allowAnimate={false} />
						<MotionDiv animationIndex={3}>
							<Output data={staticData?.requestLogs?.[0]?.output || {}} />
						</MotionDiv>
					</>
				) : null
			}
		</Flex>
	);
};

const TestTimelineLogRealtime = ({ requestId, scrollContainerRef, onFinish }) => {
	const nodeRequestLogs = useFirestoreRealtimeData({
		collectionName: 'nodeRequestLogs',
		conditions: [['requestId', '==', requestId]],
		orderKey: 'createdAt',
	});
	const requestLogs = useFirestoreRealtimeData({
		collectionName: 'requestLogs',
		conditions: [['_id', '==', requestId]],
	});

	const data = {
		nodeRequestLogs,
		requestLogs,
	};

	useEffect(() => {
		if (requestLogs?.length > 0) {
			onFinish(data);
		}
	}, [requestLogs]);

	return (
		<TestTimelineLog
			data={data}
			scrollContainerRef={scrollContainerRef}
		/>
	);
};

export default Playground;
