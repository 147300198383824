import { ModelCascader } from '../../../../../atoms';
import { useContext, useEffect, useState } from 'react';
import PromptbookContext from '../../../contexts/Context';
import deepEquivalence from '../../../../../../utils/deepEquivalence';

const selectProps = {
	size: 'large',
	variant: 'filled',
	style: { width: '100%' },
};

const ModelSelect = () => {
	const { data, loading, editPromptbook } = useContext(PromptbookContext);
	const [localModelValue, setLocalModelValue] = useState();

	useEffect(() => {
		if (!loading && data?.model && !deepEquivalence(data?.model, localModelValue)) {
			setLocalModelValue(data?.model);
		}
	}, [data?.model]);

	const handleChange = (value) => {
		setLocalModelValue(value);
		editPromptbook('model', value, 'json');
	};

	return (
		<ModelCascader
			{...selectProps}
			loading={loading}
			value={localModelValue}
			onChange={handleChange}
		/>
	);
};

export default ModelSelect;
