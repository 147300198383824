import { useMemo } from 'react';
import GlobalAuthContext from './GlobalAuthContext';
import { useAuthData } from '../hooks';

const GlobalAuthProvider = ({ children }) => {
	// eslint-disable-next-line no-undef
	const storageEndpoint = `${process.env.REACT_APP_BASE_API}/storage`;
	const { data: storageData, loading: storageLoading, refresh: storageRefresh } = useAuthData(storageEndpoint + '?limit=100');

	const storageContextValues = useMemo(() => ({
		storageList: storageData?.data?.rows || [],
		storageLoading,
		storageRefresh,
		txtList: storageData?.data?.rows?.filter(row => row.metadata?.mimeType?.startsWith('text/')),
		csvList: storageData?.data?.rows?.filter(row => row.metadata?.mimeType === 'text/csv'),
		imageList: storageData?.data?.rows?.filter(row => row.metadata?.mimeType?.startsWith('image/')),
	}), [storageData]);

	const modelEndpoint = 'https://backend.lang.teammate.as/platforms';
	const { data: modelData, loading: modelLoading, refresh: modelRefresh } = useAuthData(modelEndpoint);
	const { data: chatModelData, loading: chatModelLoading, refresh: chatModelRefresh } = useAuthData(modelEndpoint + '?type=chat');
	const { data: embeddingModelData, loading: embeddingModelLoading, refresh: embeddingModelRefresh } = useAuthData(modelEndpoint + '?type=embedding');
	
	const modelContextValues = useMemo(() => ({
		modelList: modelData,
		modelLoading,
		modelRefresh,
		chatModelList: chatModelData,
		chatModelLoading,
		chatModelRefresh,
		embeddingModelList: embeddingModelData,
		embeddingModelLoading,
		embeddingModelRefresh
	}));
	
	const contextValues = {
		...storageContextValues,
		...modelContextValues,
	};

	return (
		<GlobalAuthContext.Provider value={contextValues}>
			{children}
		</GlobalAuthContext.Provider>
	);
};

export default GlobalAuthProvider;
