import { useContext, useEffect, useMemo, useState } from 'react';
import { Flex, Tabs } from 'antd';
import { CodeOutlined, PlusOutlined } from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';
import { Loading } from '../../../../../../components/atoms';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import {
	CustomMetricGenerationButton,
	GenerationModal,
	InpageEditor,
	MetricCardCustom,
	MetricCardFormat,
	MetricCardGeneral,
	MetricCardGenerating
} from './components';
import { useAuthRequest } from '../../../../../../hooks';
import { AnimatePresence } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import './index.css';

const Metrics = (props) => {
	const {
		data,
		loading,
		targetPlanId,
		targetPlanData
	} = useContext(GenflowOptimizeContext);
	const outputList = targetPlanData?.metadata?.output || [];

	const items = outputList?.length > 0
		? outputList.filter(output => output.variable?.startsWith('step.'))
			.map((output) => {
				const moduleCount= loading ? '-'
					: data?.metadata?.evaluationModules?.[output.key] ? Object.keys(data?.metadata?.evaluationModules?.[output.key])?.length || 0
						: 0;
				return {
					key: output.key,
					label: (
						<Flex gap='small'>
							<CodeOutlined style={{ margin: 0 }} />
							<span>{output.key?.length > 12 ? output.key?.slice(0, 10) + '...' : output.key}</span>
							<TagCount count={moduleCount} />
						</Flex>
					),
					children: <MetricsSelectionContent outputKey={output.key} {...props} />
				};
			})
		: [];

	return (
		<>
			{loading ? (
				<Loading />
			) : outputList?.length === 0 ? (
				<Flex vertical gap='small'>
					<span>You need to have at least one output schema.</span>
				</Flex>
			) : (
				<Tabs
					items={items}
					tabPosition='left'
					tabBarExtraContent={{
						left: <TabBarTitle />
					}}
					tabBarStyle={{
						width: 175,
						alignItems: 'flex-start'
					}}
					style={{
						minHeight: 'calc(100vh - 150px)',
						background: '#FFFFFF',
						borderRadius: '12px',
					}}
					animated
				/>
			)}
		</>
	);
};

const TabBarTitle = () => {
	const styles = {
		padding: '15px 20px',
		fontWeight: 600,
	};

	return (
		<div style={styles}>
            Output Schema
		</div>
	);
};

const TagCount = ({ count }) => (
	<div className='tag-count-container'>
		{count}
	</div>
);

const MetricsSelectionContent = (props) => {
	const {
		genflowId,
		optimizationId,
		data,
		loading,
		customMetricsList,
		customMetricsRefresh,
		metricGenerationWatchingList,
		setMetricGenerationWatchingList,
		isBeforeEvaluationInvoked
	} = useContext(GenflowOptimizeContext);
	const { outputKey } = props;
	const [generationOpen, setGenerationOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (metricGenerationWatchingList?.length > 0) {
			const interval = setInterval(() => {
				customMetricsRefresh();
			}, 3000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [metricGenerationWatchingList]);

	useEffect(() => {
		if (metricGenerationWatchingList?.length > 0) {
			// Step 1: Create a map to count occurrences of each invocationId in customMetricsList
			const invocationCountMap = customMetricsList?.reduce((acc, customMetric) => {
				if (customMetric.metadata?.invocationId) {
					acc[customMetric.metadata.invocationId] = (acc[customMetric.metadata.invocationId] || 0) + 1;
				}
				return acc;
			}, {}) || {};

			// Step 2: Filter metricGenerationWatchingList based on the count in the map and requestCount
			const newMetricsGenerationWatchingList = metricGenerationWatchingList.filter(payload => {
				const requiredCount = payload.requestCount;
				const actualCount = invocationCountMap[payload.invocationId] || 0;
				return actualCount < requiredCount;
			});

			// Step 3: Update the state if there are changes
			if (newMetricsGenerationWatchingList.length !== metricGenerationWatchingList.length) {
				setMetricGenerationWatchingList(newMetricsGenerationWatchingList);
			}
		}
	}, [customMetricsList, metricGenerationWatchingList]);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/metrics`;
	}, [genflowId, optimizationId]);

	const [creationLoading, setCreationLoading] = useState(false);
	const { makeRequest } = useAuthRequest();
	const handleCreateNewCustomMetric = async () => {
		setCreationLoading(true);
		const response = await makeRequest(baseEndpoint, 'POST');
		await customMetricsRefresh();

		if (response?.message === 'OK' && response?.result?.metric_id) {
			setSearchParams({ ...searchParams, metricId: response?.result?.metric_id });
		}
		setCreationLoading(false);
	};

	const shouldSuggestAiGenerate = customMetricsList?.length === 0;

	return (
		<Flex gap='large' vertical style={{ padding: '12px 24px 24px 0' }}>
			<span style={{ fontSize: 20 }}>Choose metrics for the Output Schema <b>{outputKey}</b>.</span>
			{loading
				? <Loading />
				: (
					<Flex gap='middle' vertical>
						<MetricsGroupContainer title='Custom metrics' id='custom'>
							<Flex gap='small' style={{ width: '100%' }}>
								<CustomMetricGenerationButton
									shouldPulse={shouldSuggestAiGenerate}
									title='Let AI generate custom metrics'
									icon={<BsStars style={{ fontSize: 32, color: isBeforeEvaluationInvoked ? 'var(--primary-color)' : 'var(--disabled-color)' }} />}
									onClick={() => setGenerationOpen(true)}
								/>
								<CustomMetricGenerationButton
									title='Create custom metric manually'
									icon={<PlusOutlined style={{ fontSize: 32, color: isBeforeEvaluationInvoked ? 'var(--primary-color)' : 'var(--disabled-color)' }} />}
									onClick={handleCreateNewCustomMetric}
									loading={creationLoading}
								/>
								{/*<CustomMetricGenerationButton*/}
								{/*	title='Import from other optimizations'*/}
								{/*	icon={<BiDuplicate style={{ fontSize: 32, color: isBeforeEvaluationInvoked ? 'var(--primary-color)' : 'var(--disabled-color)' }} />}*/}
								{/*/>*/}
							</Flex>
							{metricGenerationWatchingList?.map(item => (
								<MetricCardGenerating key={item.invocationId} {...item} />
							))}
							<AnimatePresence>
								{customMetricsList?.map(item => (
									<MetricCardCustom
										key={item.metric_id}
										outputKey={outputKey}
										metricId={item.metric_id}
										{...item}
									/>
								))}
							</AnimatePresence>
						</MetricsGroupContainer>
						<MetricsGroupContainer title='Basics' id='basic'>
							<MetricCardGeneral
								title='System Performance'
								description='Evaluate latency, cost and token usage.'
								isFree
								metricsList={[
									{ label: 'Latency(ms)', type: 'float' },
									{ label: 'Total cost (USD)', type: 'float' },
									{ label: 'Input cost (USD)', type: 'float' },
									{ label: 'Output cost (USD)', type: 'float' },
									{ label: 'Total token length', type: 'int' },
									{ label: 'Input token length', type: 'int' },
									{ label: 'Output token length', type: 'int' },
								]}
								outputKey={outputKey}
								moduleKey='system_log'
							/>
							<MetricCardFormat outputKey={outputKey} />
							<MetricCardGeneral
								title='Moderation score'
								description='Evaluate harassment, hate, self-harm, sexual and violence using OpenAI Moderation.'
								isFree
								metricsList={[
									{ label: 'Harassment', type: 'float' },
									{ label: 'Harassment/Threatening', type: 'float' },
									{ label: 'Hate', type: 'float' },
									{ label: 'Hate/Threatening', type: 'float' },
									{ label: 'Self-harm', type: 'float' },
									{ label: 'Self-harm/Instructions', type: 'float' },
									{ label: 'Self-harm/Intent', type: 'float' },
									{ label: 'Sexual', type: 'float' },
									{ label: 'Sexual/Minors', type: 'float' },
									{ label: 'Violence', type: 'float' },
									{ label: 'Violence/Graphic', type: 'float' },
								]}
								outputKey={outputKey}
								moduleKey='openai_moderation'
								comingSoon
							/>
						</MetricsGroupContainer>
						<MetricsGroupContainer title='Common LLM-as-a-judge Evaluators' id='llm-as-a-judge'>
							<MetricCardGeneral
								title='YMYL'
								description='Evaluate whether the output contains YMYL (Your-Money-Your-Life) topic.'
								metricsList={[
									{ label: 'Contains YMYL topic', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='ymyl'
							/>
							<MetricCardGeneral
								title='Bias'
								description='Evaluate whether the output is biased,'
								metricsList={[
									{ label: 'Is biased', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='bias'
							/>
							<MetricCardGeneral
								title='Privacy'
								description='Evaluate whether the output has private information.'
								metricsList={[
									{ label: 'Has privacy info', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='privacy'
							/>
							<MetricCardGeneral
								title='Toxicity'
								description='Evaluate whether the output is toxic.'
								metricsList={[
									{ label: 'Is toxic', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='toxicity'
							/>
							<MetricCardGeneral
								title='Intellectual Property'
								description='Evaluate whether the output has intellectual property information.'
								metricsList={[
									{ label: 'Has IP info', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='intellectual_property'
							/>
						</MetricsGroupContainer>
						<MetricsGroupContainer title='Knowledge (RAG) Evaluations' id='knowledge-evaluations'>
							<MetricCardGeneral
								title='Relevancy between output and knowledge'
								description='Evaluate whether the output is relevant to the defined knowledge.'
								metricsList={[
									{ label: 'Is relevant', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='rag' // TODO: separate key_name against module for relevancy between input, output and knowledge
								comingSoon
							/>
							<MetricCardGeneral
								title='Relevancy between input, output and knowledge'
								description='Evaluate whether the input, output and knowledge are relevant to each other.'
								metricsList={[
									{ label: 'Is relevant', type: 'yes_no' },
								]}
								outputKey={outputKey}
								moduleKey='rag' // TODO: separate key_name against module for relevancy between output and knowledge
								comingSoon
							/>
						</MetricsGroupContainer>
						<InpageEditor
							title='Let AI generate custom metrics'
							open={generationOpen}
							onClose={() => setGenerationOpen(false)}
						>
							<GenerationModal onFinish={() => setGenerationOpen(false)} />
						</InpageEditor>
					</Flex>
				)}
		</Flex>
	);
};

const MetricsGroupContainer = (props) => {
	const { title, children } = props;
	return (
		<Flex gap='middle' justify='flex-start' vertical>
			<span style={{ fontSize: 24 }}>{title}</span>
			<Flex wrap='wrap' gap='small'>
				{children}
			</Flex>
		</Flex>
	);
};

export default Metrics;
