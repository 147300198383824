import dayjs, { locale, extend } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ja';

extend(relativeTime);

const RelativeTime = ({ datetime }) => {
	const { i18n } = useTranslation();
	locale(i18n.language);

	return dayjs().to(dayjs(datetime));
};

export default RelativeTime;
