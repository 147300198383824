import { TipsCard } from '../../../../../components/atoms';
import { Flex } from 'antd';

const Tips = () => {
	return (
		<Flex vertical gap='large'>
			<TipsCard title='How to choose the right model?' animationIndex={0}>
				Start with larger models and gradually scale down. Begin by testing your prompts in the Playground with a more powerful model. Once you&apos;re satisfied with the results, try smaller models to optimize for efficiency. 
				<br /><br />
				Alternatively, you can create multiple Prompt Books (using the Duplicate feature) and set different models for each. Run the same inputs across these variations to compare how results differ. This technique can help you find the optimal balance between performance and resource usage.
			</TipsCard>
			<TipsCard title='What should I write in Knowledge Book queries?' animationIndex={1}>
				Unlike Prompt Book, it&apos;s better to use keywords or short phrases in Knowledge Book queries, similar to search queries. This approach removes unnecessary noise and improves matching accuracy.
				<br /><br />
				In other words, it is better to use the Prompt Book in previous steps to design an effective querying strategy. By creating a prompt that generates appropriate search terms for the Knowledge Book, you can enhance the overall performance of your generation flow.
			</TipsCard>
			<TipsCard title='How to improve my generation flow continuously?' animationIndex={2}>
				<p>
					Prompting techniques are still an evolving field of research. To stay updated and improve your generation flows, explore these common dichotomies in AI research:
				</p>
				<ul>
					<li>RAG vs. ICL (In-Context Learning)</li>
					<li>Prompt Chaining vs. Stepwise Prompting</li>
					<li>Exemplar-based vs. Rule-based approaches</li>
				</ul>
				<p>
					In addition to these, you can also search for other terms and concepts to gain new insights. Actively incorporate the knowledge you gain into your generation flows to continuously enhance their performance and capabilities.
				</p>
			</TipsCard>
		</Flex>
	);
};

export default Tips;
