import { useContext } from 'react';
import { Button, Upload } from 'antd';
import { FileAddOutlined, InboxOutlined } from '@ant-design/icons';
import GlobalContext from '../../../context/GlobalContext';
import GlobalAuthContext from '../../../context/GlobalAuthContext';

const { Dragger } = Upload;

const StorageUpload = (props) => {
	const { type = 'button', onUpload = () => {}, disabled, allowedFormat = [] } = props;
	const { workspaceId, userId, pushMessage } = useContext(GlobalContext);
	const { storageRefresh } = useContext(GlobalAuthContext);
    
	const uploadProps = {
		// eslint-disable-next-line no-undef
		action: `${process.env.REACT_APP_BASE_API}/storage`,
		multiple: true,
		headers: {
			'Workspace-ID': workspaceId,
			'User-ID': userId,
		},
		onChange: async (info) => {
			if (info.file.status === 'done') {
				await storageRefresh();

				pushMessage(`"${info.file.name}" uploaded successfully`, 'success');

				const newObjectId = info.file.response?.result?.object_id;
				onUpload(newObjectId);
			} else if (info.file.status === 'error') {
				pushMessage(`"${info.file.name}" upload failed`, 'error');
			}
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 3,
			format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
		},
		disabled,
	};

	return type === 'button' ? (
		<Upload {...uploadProps}>
			<Button
				type='primary'
				size='large'
				icon={<FileAddOutlined />}
			>
                Upload file
			</Button>
		</Upload>
	) : type === 'drag' ? (
		<Dragger {...uploadProps}>
			<p className="ant-upload-drag-icon">
				<InboxOutlined/>
			</p>
			<p className="ant-upload-text">Click or drag file to this area to upload</p>
			{allowedFormat?.length > 0 && (
				<p className="ant-upload-text">Only {allowedFormat.join(', ')} is supported</p>
			)}
		</Dragger>
	) : null;
};

export default StorageUpload;
