import { createContext } from 'react';

const GlobalContext = createContext({
	showBanner: false,
	setShowBanner: () => {},
	workspaceId: null,
	workspace: {},
	workspaceLoading: true,
	workspaceRefresh: () => {},
	setWorkspaceId: () => {},
	userId: null,
	setUserId: () => {},
	user: {},
	userLoading: true,
	userRefresh: () => {},
	jwt: null,
	setJwt: () => {},
	refreshJwt: async () => {},
	tokenManager: {},
	setTokenManager: () => {},
	redirectToAuth: () => {},
	displayLanguage: 'en',
	setDisplayLanguage: () => {},
	pushMessage: (content, type) => {},
	uniqueClientId: 'no-client',
	collapsed: false,
	setCollapsed: () => {}
});

export default GlobalContext;
