import { Flex } from 'antd';
import { FormWithRequest } from '../../../../components/molecules';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../context/GlobalContext';
import { useContext, useEffect, useState } from 'react';
import { MotionDiv } from '../../../../components/atoms';
import { CardContainer } from '../../components';

const Config = () => {
	const { t } = useTranslation();

	// verbose approach
	const { workspaceId, user, userRefresh } = useContext(GlobalContext);
	const [workspaceName, setWorkspaceName] = useState('');

	useEffect(() => {
		const newName = user?.workspaces?.find(({ _id }) => _id === workspaceId)?.name;
		setWorkspaceName(newName);
	}, [user, workspaceId]);

	const formConfig = {
		name: 'workspace-config',
		labelCol: {
			span: 6,
		},
		wrapperCol: {
			span: 18,
		},
		labelWrap: true,
		colon: false,
		layout: 'horizontal',
		preserve: false,
		initialValues: {
			name: workspaceName
		}
	};

	const formItems = [
		{
			label: t('form.label.workspace-name'),
			name: 'name',
			rules: [
				{
					required: true,
					message: t('form.rules.workspace-name.required'),
				}
			],
			item: {
				type: 'input',
				placeholder: t('form.placeholder.workspace-name'),
				variant: 'filled',
				size: 'large',
			},
		},
	];

	return (
		<CardContainer
			title='Workspace settings'
		>
			<Flex vertical justify='center' style={{ width: '100%' }}>
				<div style={{ maxWidth: '600px' }}>
					<FormWithRequest
						key={workspaceName}
						formConfig={formConfig}
						formItems={formItems}
						submitButtonConfig={{
							type: 'primary',
							block: true,
						}}
						requestEndpoint='https://backend.lang.teammate.as/workspaces'
						requestMethod='PUT'
						onSuccess={() => {
							userRefresh();
						}}
					/>
				</div>
			</Flex>
		</CardContainer>
	);
};

export default Config;
