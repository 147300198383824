import Context from './Context';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../../../../../../context/GlobalContext';
import GenflowOptimizeContext from '../../../../../../../context/genflowOptimize/Context';
import { useChannel } from 'ably/react';
import { useAuthData } from '../../../../../../../hooks';

const Provider = ({ children }) => {
	const isInitialLoad = useRef(true);
	const { uniqueClientId } = useContext(GlobalContext);
	const { genflowId, optimizationId } = useParams();
	const { targetOperationList, targetOperationLoading, data } = useContext(GenflowOptimizeContext);
	const [current, setCurrent] = useState();
	const [hasError, setHasError] = useState(false);
	const [isWorking, setIsWorking] = useState(false);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}`;
	}, [genflowId, optimizationId]);

	const { data: logsData, loading: logsLoading, refresh: logsRefresh } = useAuthData(`${baseEndpoint}/simulations/tasks/results?limit=300`);

	const aggregatedResultsEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/evaluations/results/aggregated`;
	}, [optimizationId]);
	const { data: aggregatedResultsData, loading: aggregatedResultsLoading, refresh: aggregatedResultsRefresh } = useAuthData(aggregatedResultsEndpoint);

	const [messages, updateMessages] = useState([]);
	useChannel(uniqueClientId || 'no-client', (message) => {
		if (message.name === `evaluations/${optimizationId}`) {
			updateMessages((prev) => [...prev, message]);
		}
	});

	useEffect(() => {
		/// TODO we have to support other plans as well....
		if (isInitialLoad.current && !targetOperationLoading) {
			const formattedHistoricalMessages = targetOperationList?.map(item => ({
				data: item
			})) || [];
			updateMessages((prev) => [...prev, ...formattedHistoricalMessages]);
			isInitialLoad.current = false;
		}
	}, [targetOperationList, targetOperationLoading]);

	const contextValues = useMemo(() => ({
		isWorking,
		messages,
		hasError,
		logsList: logsData?.data?.rows || [],
		logsLoading,
		logsRefresh,
		aggregatedResultsList: aggregatedResultsData?.data?.rows || [],
		aggregatedResultsLoading,
		aggregatedResultsRefresh,
	}), [isWorking, messages, hasError, logsData, aggregatedResultsData]);

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default Provider;
