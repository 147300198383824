import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Flex, Button, Tag } from 'antd';
import Icon, { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ListPageTemplate } from '../../../../components/templates';
import AdminContext from '../../contexts/Context';
import { Editors, RelativeTime } from '../../../../components/atoms';
import { TbLayoutDashboard, TbSettingsAutomation } from 'react-icons/tb';
import { FiTool } from 'react-icons/fi';
import { IoSettingsSharp } from 'react-icons/io5';

const defaultIconStyles = {
	color: 'var(--teammately-icon-gray)',
};

const GenflowListPage = () => {
	const navigate = useNavigate();
	const [buttonsLoading, setButtonsLoading] = useState(false);
	const { genflowList, genflowLoading, createGenflow } = useContext(AdminContext);

	const columns = [
		{
			title: 'Title',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
			width: '40%',
		},
		{
			title: 'Last Update',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
		},
		{
			title: 'Collaborators',
			dataIndex: 'collaborators',
			key: 'collaborators',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		},
	];

	// todo ブロックにする
	const displayData = genflowList?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.map((row) => {
			const { genflow_id, name, updated_at, updated_by, metadata } = row;
			const basePath = `/genflows/${genflow_id}`;

			return {
				key: genflow_id,
				label: (
					<Flex gap='small' align='center'>
						{metadata?.productionPlanId && (
							<Tooltip placement="topLeft" title={'Click to see detail'}>
								<Tag
									bordered={false}
									color='var(--teammately-orange)'
									onClick={() => navigate(`/genflows/${genflow_id}/develop/${metadata?.productionPlanId}`)}
									style={{ fontWeight: 600 }}
								>
									PROD
								</Tag>
							</Tooltip>
						)}
						<Tooltip placement="topLeft" title={name || 'No title'}>
							<a onClick={() => navigate(`/genflows/${genflow_id}`)}>
								{name || 'No title'}
							</a>
						</Tooltip>
					</Flex>
				),
				updatedAt: <RelativeTime datetime={updated_at} />,
				collaborators: <Editors editors={[updated_by]} />,
				productionPlan: (
					<Tooltip placement="topLeft" title={metadata?.productionPlanId || 'No production plan'} color='var(--tooltip-background)'>
						{metadata?.productionPlanId ? (
							<a onClick={() => navigate(`/genflows/${genflow_id}/develop/${metadata?.productionPlanId}`)}>
								{metadata?.productionPlanId}
							</a>
						) : '-'}
					</Tooltip>
				),
				action: (
					<Flex gap='small'>
						<Tooltip title='Overview' color='var(--tooltip-background)'>
							<Button shape='circle' type='text' icon={<Icon component={TbLayoutDashboard} />} style={defaultIconStyles} onClick={() => navigate(basePath)} />
						</Tooltip>
						<Tooltip title='Develop' color='var(--tooltip-background)'>
							<Button shape='circle' type='text' icon={<Icon component={FiTool} />} style={defaultIconStyles} onClick={() => navigate(basePath + '/develop')} />
						</Tooltip>
						<Tooltip title='Optimize' color='var(--tooltip-background)'>
							<Button shape='circle' type='text' icon={<Icon component={IoSettingsSharp} />} style={defaultIconStyles} onClick={() => navigate(basePath + '/optimize')} />
						</Tooltip>
					</Flex>
				),
			};
		});

	const buttons = [
		{
			label: 'Create generation flow',
			type: 'primary',
			icon: <PlusOutlined />,
			onClick: () => onCreateClick(),
		}
	];

	const onCreateClick = async () => {
		setButtonsLoading(true);
		await createGenflow();
		setButtonsLoading(false);
	};

	return (
		<div style={{ backgroundColor: 'var(--default-background)', minHeight: 'calc(100vh - 50px)' }}>
			<ListPageTemplate
				title='Generation Flow List'
				description='Welcome to Generation Flow List. Here, you can manage and organize all your AI generation flow projects. Create new flows and edit existing ones'
				buttons={buttons}
				buttonsLoading={buttonsLoading}
				columns={columns}
				data={displayData}
				loading={genflowLoading}
			/>
		</div>
	);
};

export default GenflowListPage;
