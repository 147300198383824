import { Flex } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';

const CustomMetricGenerationButton = ({ shouldPulse, title, icon, loading, onClick }) => {
	const { isBeforeEvaluationInvoked } = useContext(GenflowOptimizeContext);

	return (
		<div
			className={`metric-card metric-card-button ${shouldPulse ? 'pulse' : ''}`}
			onClick={isBeforeEvaluationInvoked ? onClick : () => {}}
			style={{
				width: 'calc(33.33% - 6px)',
				...(!isBeforeEvaluationInvoked ? { cursor: 'not-allowed', backgroundColor: 'var(--disabled-background)' } : {})
			}}
		>
			<Flex vertical gap='small' align='center' style={{ padding: '12px 0' }}>
				{loading ? <LoadingOutlined style={{ fontSize: 32, color: isBeforeEvaluationInvoked ? 'var(--primary-color)' : 'var(--disabled-color)' }} /> : icon}
				<span style={{ fontSize: 20, color: isBeforeEvaluationInvoked ? 'var(--primary-color)' : 'var(--disabled-color)' }}>{title}</span>
			</Flex>
		</div>
	);
};

export default CustomMetricGenerationButton;
