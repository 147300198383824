import { Flex, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import GlobalContext from '../../../context/GlobalContext';
import './index.css';

const CopyableInput = ({ value, messageOnCopy = 'Successfully copied' }) => {
	const { pushMessage } = useContext(GlobalContext);
	const [hovered, setHovered] = useState(false);

	return (
		<Tooltip color='var(--tooltip-background)' title='Click to copy'>
			<div
				className='input-readonly-copyable'
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				onClick={async () => {
					await navigator.clipboard.writeText(value);
					pushMessage(messageOnCopy, 'success');
				}}
			>
				<Flex justify='space-between' align='center'>
					<span>{value}</span>
					{hovered && <CopyOutlined/>}
				</Flex>
			</div>
		</Tooltip>
	);
};

export default CopyableInput;
