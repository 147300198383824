import { useContext } from 'react';
import { Flex, Table, Tag } from 'antd';
import GenflowOptimizeContext from '../../../../../../../../../context/genflowOptimize/Context';
import './index.css';
import ExperimentDetailContext from '../../../contexts/Context';
import GenflowDetailContext from '../../../../../../../../../context/genflowDetail/Context';
import { Ellipsis, Empty, Loading } from '../../../../../../../../../components/atoms';

const AggregatedResults = () => {
	const { plansList } = useContext(GenflowDetailContext);
	const { data, customMetricsList } = useContext(GenflowOptimizeContext);
	const comparisonPlans = [ data?.metadata?.targetPlanId, ...(data?.metadata?.comparisonPlans || []) ] || [];

	const { aggregatedResultsList, aggregatedResultsLoading } = useContext(ExperimentDetailContext);
	const moduleKeys = [...new Set(aggregatedResultsList?.map(item => item.module_key))];
	const metricKeys = [...new Set(aggregatedResultsList?.map(item => item.metric_key))];
	const aggMethods = [...new Set(aggregatedResultsList?.map(item => item.agg_method))];

	const columns = [
		{
			key: 'module_key',
			title: 'Module',
			dataIndex: 'module_key',
			filters: moduleKeys?.map(key => ({ value: key, text: key })),
			onFilter: (value, record) => record.module_key?.indexOf(value) === 0,
			sorter: (a, b) => a.module_key?.localeCompare(b.module_key),
			width: 150,
			fixed: true,
		},
		{
			key: 'metric_key',
			title: 'Metric',
			dataIndex: 'metric_key',
			filters: metricKeys?.map(key => ({ value: key, text: key })),
			onFilter: (value, record) => record.metric_key?.indexOf(value) === 0,
			sorter: (a, b) => a.metric_key?.localeCompare(b.metric_key),
			width: 150,
			fixed: true,
		},
		{
			key: 'agg_method',
			title: 'Agg. Method',
			dataIndex: 'agg_method',
			filters: aggMethods?.map(key => ({ value: key, text: key })),
			onFilter: (value, record) => record.agg_method?.indexOf(value) === 0,
			sorter: (a, b) => a.agg_method?.localeCompare(b.agg_method),
			width: 150,
			fixed: true,
		},
		...((comparisonPlans && comparisonPlans?.length > 0) ? comparisonPlans?.map((planId) => ({
			key: planId,
			title: (
				<Flex gap='small' align='center'>
					{planId === data?.metadata?.targetPlanId ? (
						<Tag bordered={false} color='magenta-inverse'>TARGET</Tag>
					) : (
						<Tag bordered={false} color='cyan-inverse'>ALT</Tag>
					)}
					<Ellipsis
						threshold={100}
						name={plansList?.find(plan => plan.genflow_plan_id === planId)?.name || 'Untitled plan'}
					/>
				</Flex>
			),
			dataIndex: ['scores', planId],
			align: 'right',
			render: (text) => text ? Number(text).toFixed(2).toLocaleString() : '-'
		})) || [] : [])
	];

	const displayData = aggregatedResultsList?.map((item) => {
		const customMetricData = item.metric_key?.startsWith('custom/') ? customMetricsList?.find(metricItem => metricItem.metric_id === item.metric_key?.replace('custom/', '')) : null;
		return {
			...item,
			module_key: item.metric_key?.startsWith('custom/') ? 'Custom' : item.module_key,
			metric_key: item.metric_key?.startsWith('custom/') ? customMetricData?.name : item.metric_key,
		};
	});

	return (displayData && displayData?.length > 0) ? (
		<div style={{ width: 'calc(100vw - 200px - 275px - 70px)' }}>
			<Table
				columns={columns}
				dataSource={displayData || []}
				loading={aggregatedResultsLoading}
				scroll={{ x: comparisonPlans?.length * 500 }}
				pagination={false}
			/>
		</div>
	) : (
		<div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			{aggregatedResultsLoading ? <Loading/> : <Empty type='waiting' description='Waiting for data'/>}
		</div>
	);
};

export default AggregatedResults;
