import { Dropdown } from '../../../../../atoms';
import { Flex } from 'antd';
import Icon, { QuestionCircleOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { HiOutlineBookOpen } from 'react-icons/hi';

const methodDoesNotExist = () => {
	throw new Error('Method not implemented');
};

const DropdownSupport = () => {
	const menuItems = [
		{
			label: (
				<a href='https://docs.teammately.ai' target='_blank' rel="noreferrer">
					<Flex gap='small' align='center' justify='center'>
						<HiOutlineBookOpen style={{ fontSize: 18 }} />
						Documentation
					</Flex>
				</a>
			),
			key: 'documentation',
		},
		// {
		// 	label: (
		// 		<a href='https://link.teammate.as/lang-support-contact-form' target='_blank' rel="noreferrer">
		// 			<Flex gap='small'>
		// 				<UserOutlined style={{ fontSize: 18 }} />
		// 				Contact Team
		// 			</Flex>
		// 		</a>
		// 	),
		// 	key: 'contact',
		// },
		// {
		// 	label: (
		// 		<a href='https://link.teammate.as/lang-support-contact-form' target='_blank' rel="noreferrer">
		// 			<Flex gap='small'>
		// 				<SolutionOutlined style={{ fontSize: 18 }} />
		// 				Feedback
		// 			</Flex>
		// 		</a>
		// 	),
		// 	key: 'feedback',
		// },
	];

	return (
		<Dropdown
			name={(
				<Flex gap={4} align='center' justify='center'>
					<QuestionCircleOutlined />
					Help
				</Flex>
			)}
			items={menuItems}
			type='button-text'
		/>
	);
};

export default DropdownSupport;
