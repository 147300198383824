import Container from './Container';
import Provider from './contexts/Provider';

const ReportDetail = () => {
	return (
		<Provider>
			<Container />
		</Provider>
	);
};

export default ReportDetail;
