import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Flex } from 'antd';
import Icon, { KeyOutlined } from '@ant-design/icons';
import { LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu';
import { IoIosRocket } from 'react-icons/io';
import { MdOutlineSpaceDashboard, MdOutlineWorkspacePremium } from 'react-icons/md';
import { FaRegListAlt, FaUserCog } from 'react-icons/fa';
import { TiCloudStorage } from 'react-icons/ti';
import { IoSettingsSharp } from 'react-icons/io5';
import { TbPlugConnected } from 'react-icons/tb';
import { BsFillPeopleFill } from 'react-icons/bs';

const defaultHeight = 'calc(100vh - 50px)';
const defaultIconColor = 'var(--teammately-icon-gray)';
const iconStyle = {
	fontSize: 20,
	color: defaultIconColor,
};

const defaultOpenKeys = ['/settings'];

const LeftNavigation = ({ collapsed = false, onCollapse = () => {}, collapsedWidth }) => {
	const location = useLocation();
	const localPathArr = location.pathname.split('/');
	const currentKey = localPathArr.join('/') || 'root';
	const navigate = useNavigate();

	function getItem({ key, icon, customIcon, children, type, disabled, label, style }) {
		return {
			key,
			icon: customIcon || <Icon style={{
				...iconStyle,
				color: currentKey === key ? 'var(--primary-color)' : defaultIconColor
			}} component={icon} />,
			children,
			label,
			type,
			disabled,
			// style: !collapsed
			// 	? { paddingLeft: 12, paddingRight: 12, ...style }
			// 	: { padding: '3px 11px', ...style },
		};
	}

	const items = [
		getItem({
			key: '/get-started',
			customIcon: <Icon component={IoIosRocket} style={{ ...iconStyle, fontSize: 24, marginLeft: -2, marginRight: -2 }} />,
			label: 'Get started'
		}),
		getItem({
			key: '/',
			customIcon: <Icon component={MdOutlineSpaceDashboard} style={{ ...iconStyle, fontSize: 26, marginLeft: -4, marginRight: -2 }} />,
			label: 'Dashboard'
		}),
		getItem({
			key: '/genflows',
			customIcon: <Icon component={FaRegListAlt} style={{ ...iconStyle }} />,
			label: 'Generation flow'
		}),
		getItem({
			key: '/settings',
			icon: IoSettingsSharp,
			label: 'Settings',
			children: [
				getItem({
					key: '/platform-connections',
					icon: TbPlugConnected,
					label: 'Platform'
				}),
				getItem({
					key: '/storage',
					customIcon: <Icon component={TiCloudStorage} style={{ ...iconStyle, fontSize: 22, marginLeft: -2, marginRight: -1 }} />,
					label: 'Storage'
				}),
				getItem({
					key: '/settings/user',
					icon: FaUserCog,
					label: 'User'
				}),
				getItem({
					key: '/settings/workspace/config',
					icon: MdOutlineWorkspacePremium,
					label: 'Workspace'
				}),
				getItem({
					key: '/settings/workspace/members',
					icon: BsFillPeopleFill,
					label: 'Members'
				}),
				getItem({
					key: '/api-keys',
					customIcon: <KeyOutlined style={{ ...iconStyle }} />,
					label: 'API Keys'
				})
			]
		}),
	];

	return (
		<Layout.Sider
			collapsible
			collapsed={collapsed}
			collapsedWidth={collapsedWidth}
			onCollapse={() => onCollapse()}
			trigger={null}
			style={{
				background: '#FFFFFF',
				overflow: 'scroll',
				height: defaultHeight,
				position: 'fixed',
				boxShadow: '6px 0 10px -5px rgba(0, 0, 0, 0.05)',
				zIndex: 20
			}}
		>
			<Flex vertical justify='space-between' style={{ height: defaultHeight }}>
				<Menu
					style={{ fontWeight: 500 }}
					theme='light'
					mode='inline'
					items={items}
					selectedKeys={currentKey}
					defaultOpenKeys={defaultOpenKeys}
					onClick={(v) => navigate(v.key)}
				/>
				<Menu
					theme="light"
					mode="inline"
					items={[
						getItem({
							customIcon: collapsed ? (
								<LuArrowRightToLine style={{
									...iconStyle,
									color: '#555555',
									fontSize: 24,
									marginLeft: -5,
								}} />
							) : (
								<LuArrowLeftToLine style={{
									...iconStyle,
									color: '#555555',
									fontSize: 24,
									marginLeft: -2,
									marginRight: -2
								}} />
							),
							label: collapsed ? 'Expand' : 'Collapse'
						})
					]}
					selectedKeys={null}
					onClick={() => onCollapse()}
				/>
			</Flex>
		</Layout.Sider>
	);
};

export default LeftNavigation;
