import { Flex, Row, Col } from 'antd';
import { GenerationFlows, GetStarted, Resource } from './cards';
import { useContext } from 'react';
import AdminContext from '../../contexts/Context';
import './index.css';

const defaultCardGap = 18;

const Home = () => {
	const { genflowList, genflowLoading } = useContext(AdminContext);

	return (
		<>
			<Flex vertical gap={defaultCardGap}>
				<GetStarted animationIndex={0} />
				<Row gutter={defaultCardGap}>
					<Col span={17}>
						<Flex vertical gap={defaultCardGap}>
							<Flex gap='middle'>
								<Resource
									title='Concept'
									// description='Teammately is an AI agent that builds custom Language Models, empowering developers to discover the best combination of foundation model, prompt, knowledge and more.'
									imgSrc='/images/header-concept.png'
									linkText='Detail'
									href='https://docs.teammately.ai'
									animationIndex={1}
								/>
								<Resource
									title='Documentation'
									// description='Comprehensive guides to help you optimize Teammately in your projects. From quick starts to advanced topics, find everything you need to leverage our AI capabilities.'
									imgSrc='/images/header-documentation.png'
									linkText='Detail'
									href='https://docs.teammately.ai'
									animationIndex={2}
								/>
								<Resource
									title='Blog'
									// description='Stay updated with the latest in AI and Teammately developments. Our blog features insights, best practices, and real-world applications to inspire your next AI-powered innovation.'
									imgSrc='/images/header-blog.png'
									linkText='Detail'
									href='https://blogs.teammately.ai'
									animationIndex={3}
									isComingSoon
								/>
							</Flex>
							<GenerationFlows animationIndex={4} receiveAttention={genflowList?.length === 0 && !genflowLoading} />
						</Flex>
					</Col>
					{/*<Col span={8}>*/}
					{/*	<Flex vertical gap={defaultCardGap}>*/}
					{/*		<CardContainer*/}
					{/*			title='AI Works Done'*/}
					{/*			animationIndex={5}*/}
					{/*		>*/}
					{/*			<Empty type='waiting' description='Waiting for your requests to AI' />*/}
					{/*		</CardContainer>*/}
					{/*	</Flex>*/}
					{/*</Col>*/}
				</Row>
			</Flex>
		</>
	);
};

export default Home;
