import Context from './Context';
import { useContext, useMemo } from 'react';
import { useAuthData, useAuthRequest } from '../../../hooks';
import GlobalContext from '../../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';

const Provider = ({ children }) => {
	const { pushMessage, user, userLoading, userRefresh, workspace: workspaceData, workspaceLoading, workspaceRefresh } = useContext(GlobalContext);
	const navigate = useNavigate();
	const { makeRequest } = useAuthRequest();

	const genflowEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows`;
	}, []);
	const { data: genflowData, loading: genflowLoading, refresh: genflowRefresh } = useAuthData(genflowEndpoint + '?limit=100');

	const genflowValues = useMemo(() => ({
		genflowList: genflowData?.data?.rows || [],
		genflowLoading,
		genflowRefresh
	}), [genflowData]);

	const createGenflow = async () => {
		const response = await makeRequest(genflowEndpoint, 'POST');
		await genflowRefresh();

		if (response.message === 'OK' && response.result?.['genflow_id']) {
			const genflowId = response.result?.['genflow_id'];
			navigate(`/genflows/${genflowId}`);
		} else {
			pushMessage('Creation failed', 'error');
		}

		return response;
	};

	const workspaceEndpoint = 'https://backend.lang.teammate.as/workspaces';
	// const { data: workspaceData, loading: workspaceLoading, refresh: workspaceRefresh } = useAuthData(workspaceEndpoint);

	const handleWorkspaceRequest = async (method, payload) => {
		const response = await makeRequest(workspaceEndpoint, method, payload);
		if (response.success) {
			await workspaceRefresh();
		} else {
			console.error('Error:', response);
		}
		return response;
	};

	const handleWorkspaceMemberRequest = async (method, userId, payload) => {
		const response = await makeRequest(workspaceEndpoint + '/members' + (userId ? `/${userId}` : ''), method, payload);
		if (response.success) {
			await workspaceRefresh();
		} else {
			console.error('Error:', response);
		}
		return response;
	};

	const handleWorkspaceInvitationRequest = async (method, invitationCode, payload) => {
		const response = await makeRequest(workspaceEndpoint + '/members/invitations' + (invitationCode ? `/${invitationCode}` : ''), method, payload);
		if (response.success) {
			await workspaceRefresh();
		} else {
			console.error('Error:', response);
		}
		return response;
	};

	const workspaceValues = useMemo(() => ({
		workspaceData,
		workspaceLoading,
		workspaceRefresh
	}), [workspaceData]);

	const userValues = useMemo(() => ({
		userData: user,
		userLoading,
		userRefresh
	}), [user]);

	const getStartedTasksEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/onboard`;
	}, []);
	const { data: getStartedTasksData, loading: getStartedTasksLoading, refresh: getStartedTasksRefresh } = useAuthData(getStartedTasksEndpoint);

	const getStartedTasksValues = useMemo(() => ({
		getStartedTasksList: getStartedTasksData?.data?.rows || [],
		getStartedTasksLoading,
		getStartedTasksRefresh
	}), [getStartedTasksData]);

	const markGetStartedTaskDone = async (taskId) => {
		const response = await makeRequest(getStartedTasksEndpoint + '/' + taskId, 'POST');
		await genflowRefresh();

		if (response.message === 'OK') {
			await getStartedTasksRefresh();
		} else {
			pushMessage('Operation failed', 'error');
		}

		return response;
	};

	const contextValues = {
		...genflowValues,
		createGenflow,
		...workspaceValues,
		handleWorkspaceRequest,
		handleWorkspaceMemberRequest,
		handleWorkspaceInvitationRequest,
		...userValues,
		...getStartedTasksValues,
		markGetStartedTaskDone
	};

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default Provider;
