import { useState } from 'react';
import { Flex, Tag } from 'antd';
import { AbsoluteTime } from '../../../../../../../../components/atoms';
import { motion } from 'framer-motion';

const MetricCardGenerating = ({ invocationId, requestTimestamp, requestCount }) => {
	const [toggle, setToggle] = useState(false);

	return (
		<Flex
			vertical
			gap='middle'
			style={{
				width: 'calc(33.33% - 6px)'
			}}
			className={'metric-card-generating'}
		>
			<Flex vertical gap='small'>
				<Flex gap='small' align='center'>
					<span style={{ fontSize: 18 }}>
						Generating metrics...
					</span>
				</Flex>
				<Flex align='center'>
					<Tag bordered={false}>Requesting generation count</Tag>
					<span>{requestCount}</span>
				</Flex>
				<Flex align='center'>
					<Tag bordered={false}>Started at</Tag>
					<AbsoluteTime datetime={requestTimestamp}/>
				</Flex>
			</Flex>
			<motion.div
				initial={{ x: toggle ? 0 : 100 }}
				animate={{ x: toggle ? 100 : 0 }}
				transition={{
					x: {
						type: 'tween',
						stiffness: 300,
						duration: 2,
					}
				}}
				onAnimationComplete={() => setToggle(!toggle)}
			>
				<div style={{ width: 100, overflow: 'hidden' }}>
					<img
						src='/images/bike.gif'
						alt='Generating'
						style={{ width: '100%', height: 'auto', ...(toggle ? { transform: 'scaleX(-1)' } : {}) }}
					/>
				</div>
			</motion.div>
		</Flex>
	);
};

export default MetricCardGenerating;
