import { useContext, useEffect, useRef } from 'react';
import { Flex, Table, Tag, Skeleton, Tooltip } from 'antd';
import ExperimentDetailContext from '../../contexts/Context';
import GenflowDetailContext from '../../../../../../../../context/genflowDetail/Context';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import { Ellipsis, Empty, Loading } from '../../../../../../../../components/atoms';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';
import Generating from './Generating';
const { Column, ColumnGroup } = Table;

const Logs = () => {
	const isInitialLoad = useRef(true);
	const intervalRef = useRef(null);
	const { plansList } = useContext(GenflowDetailContext);
	const { data, userInputSetList, userInputSetLoading, userInputSetRefresh, customMetricsList } = useContext(GenflowOptimizeContext);
	const comparisonPlans = [ data?.metadata?.targetPlanId, ...(data?.metadata?.comparisonPlans?.sort() || []) ] || [];

	const { logsList, logsLoading, logsRefresh } = useContext(ExperimentDetailContext);

	const displayData = logsList?.map((item) => {
		const row = {
			input: <ContentList data={item.input} />
		};

		item.output_list && Object.keys(item.output_list)?.forEach(outputKey => {
			row[outputKey] = item.output_list[outputKey]?.output ? (
				<Flex vertical gap='small'>
					<ContentList
						data={item.output_list[outputKey]?.output}
						loading={logsLoading}
					/>
					<Flex gap='small' wrap style={{ padding: 10, border: '1px solid #DDDDDD', borderRadius: 12 }}>
						{Object.keys(item.output_list[outputKey]?.scores || {})?.map(metricKey => {
							const isCustom = metricKey?.startsWith('custom/');
							const thisCustomMetricData = isCustom ? customMetricsList?.find(metric => metric.metric_id === metricKey?.replace('custom/', '')) : {};

							return (
								<Flex wrap={false} key={metricKey}>
									<Tag bordered={false} color='blue'>
										<Flex gap={3} align='center'>
											<span>{isCustom ? thisCustomMetricData?.name : metricKey}</span>
											{isCustom && (
												<Tooltip color='var(--tooltip-background)' title={thisCustomMetricData?.instruction_text}>
													<QuestionCircleOutlined />
												</Tooltip>
											)}
										</Flex>
									</Tag>
									<Flex key={metricKey} wrap={false} gap={3}>
										<span>{item.output_list[outputKey]?.scores?.[metricKey]?.value}</span>
										{item.output_list[outputKey]?.scores?.[metricKey]?.extra_data?.reason && (
											<Tooltip
												title={(
													<Flex vertical style={{ padding: 10 }}>
														<div><Tag style={{ fontWeight: 600 }} color='geekblue-inverse'>REASONS BY AI</Tag></div>
														<span style={{ color: '#111111' }}>{item.output_list[outputKey]?.scores?.[metricKey]?.extra_data?.reason}</span>
													</Flex>
												)}
												color='var(--primary-color-background)'
												placement='bottom'
											>
												<Icon component={BsStars} />
											</Tooltip>
										)}
									</Flex>
								</Flex>
							);
						})}
					</Flex>
				</Flex>
			) : (
				<Generating />
			);
		});

		return row;
	});

	useEffect(() => {
		if (
			(userInputSetList.length > logsList.length && userInputSetList.length > 0)
			|| logsList?.some(item => item.output_list && Object.keys(item.output_list).length < comparisonPlans?.length)
		) {
			intervalRef.current = setInterval(() => {
				logsRefresh();
			}, 1000);
		} else {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [userInputSetList.length, logsList.length]);

	return (displayData && displayData?.length > 0) ? (
		<div style={{ width: 'calc(100vw - 200px - 275px - 70px)' }}>
			<Table
				dataSource={displayData || []}
				loading={userInputSetLoading || logsLoading}
				scroll={{ x: comparisonPlans?.length * 500 }}
				rowHoverable={false}
				size='small'
			>
				<Column
					title='INPUT'
					dataIndex='input'
					fixed
					width={300}
					onCell={() => {
						return {
							style: {
								verticalAlign: 'top'
							}
						};
					}}
				/>
				<ColumnGroup title='OUTPUT'>
					{(comparisonPlans && comparisonPlans?.length > 0) && comparisonPlans.map((planId) => {
						const isTargetPlan = planId === data?.metadata?.targetPlanId;
						return (
							<Column
								key={planId}
								title={(
									<Flex gap='small' align='center'>
										{isTargetPlan ? (
											<Tag bordered={false} color='magenta-inverse'>TARGET</Tag>
										) : (
											<Tag bordered={false} color='cyan-inverse'>ALT</Tag>
										)}
										<Ellipsis
											threshold={100}
											name={plansList?.find(plan => plan.genflow_plan_id === planId)?.name || 'Untitled plan'}
										/>
									</Flex>
								)}
								dataIndex={planId}
								width={500}
								onCell={() => {
									return {
										style: {
											verticalAlign: 'top'
										}
									};
								}}
							/>
						);
					})}
				</ColumnGroup>
			</Table>
		</div>
	) : (
		<div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			{logsLoading ? <Loading /> : <Empty type='waiting' description='Waiting for logs'/>}
		</div>
	);
};

const ContentList = ({ data = {}, loading = false }) => {
	return loading ? (
		<Skeleton.Input active />
	) : (
		<Flex gap='small' vertical>
			{Object.keys(data)?.length > 0 ? Object.keys(data)?.map(key => (
				<Flex key={key}>
					<div>
						<Tag bordered={false}>
							{key}
						</Tag>
					</div>
					<span>{data?.[key]}</span>
				</Flex>
			)) : null}
		</Flex>
	);
};

export default Logs;
