import Context from './Context';
import { useAuthData, useAuthRequest } from '../../hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const GenflowPlanProvider = ({ children }) => {
	const { genflowId, planId } = useParams();
	const isInitialLoad = useRef(true);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${planId}`;
	}, [planId]);

	const compiledPlanEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans-compiled/${planId}`;
	}, [planId]);

	const messageEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/messages/genflow-plans/${planId}`;
	}, [planId]);

	// base data
	const { data, loading, refresh } = useAuthData(baseEndpoint, !!planId);
	useEffect(() => {
		if (isInitialLoad.current && !loading) {
			isInitialLoad.current = false;
		}
	}, [loading]);

	// compiled plan data
	const { data: compiledPlanData, loading: compiledPlanLoading, refresh: compiledPlanRefresh } = useAuthData(compiledPlanEndpoint, !!planId);
	const [stepNameMap, setStepNameMap] = useState({});
	const [remainingTasks, setRemainingTasks] = useState([]);
	useEffect(() => {
		if (compiledPlanData?.data?.rows?.[0] && !compiledPlanLoading) {
			const newStepNameMap = {};
			compiledPlanData?.data?.rows?.[0]?.json?.steps?.forEach(step => {
				newStepNameMap[step.id] = step.name;
			});
			setStepNameMap(newStepNameMap);
			setRemainingTasks(remainingTasksChecker(compiledPlanData?.data?.rows?.[0]?.json));
		}
	}, [compiledPlanData?.data?.rows?.[0], compiledPlanLoading]);

	// message data
	const { data: messagesData, loading: messagesLoading, refresh: messagesRefresh } = useAuthData(messageEndpoint, !!planId);

	// eslint-disable-next-line no-undef
	const storageEndpoint = `${process.env.REACT_APP_BASE_API}/storage`;
	const { data: storageData, loading: storageLoading, refresh: storageRefresh } = useAuthData(storageEndpoint);

	const { makeRequest } = useAuthRequest();
	const operatePlan = async (payload, method, path = '') => {
		const response = await makeRequest(baseEndpoint + path, method, payload);
		await Promise.all([refresh(), compiledPlanRefresh()]);
		return response;
	};

	const addPromptbook = async () => {
		const response = await makeRequest(baseEndpoint + '/promptbooks', 'POST');
		await refresh();
		return response;
	};

	const addKnowledgebook = async () => {
		const response = await makeRequest(baseEndpoint + '/knowledgebooks', 'POST');
		await refresh();
		return response;
	};

	const [currentEditingStep, setCurrentEditingStep] = useState({ type: null, id: null });
	const [currentEditingStepData, setCurrentEditingStepData] = useState({});

	useEffect(() => {
		if (!currentEditingStep.id) {
			setCurrentEditingStepData({});
		}
	}, [currentEditingStep]);

	const contextValues = useMemo(() => ({
		genflowId,
		planId,
		data: data?.data?.rows?.[0] || {},
		loading: loading && isInitialLoad.current,
		refresh,
		compiledPlanData: compiledPlanData?.data?.rows?.[0] || {},
		compiledPlanLoading: compiledPlanLoading && isInitialLoad.current,
		compiledPlanRefresh,
		stepNameMap,
		remainingTasks,
		messagesData: messagesData?.data?.rows || [],
		messagesLoading,
		messagesRefresh,
		storageList: storageData?.data?.rows || [],
		storageLoading,
		storageRefresh,
		operatePlan,
		addPromptbook,
		addKnowledgebook,
		currentEditingStep,
		setCurrentEditingStep,
		currentEditingStepData,
		setCurrentEditingStepData,
	}), [planId, data, compiledPlanData, stepNameMap, messagesData, currentEditingStep, currentEditingStepData]);

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

const remainingTasksChecker = (data) => {
	const remainingTasks = [];

	// Check input
	if (!data?.input_schema || data?.input_schema?.length === 0) {
		remainingTasks.push({
			area: 'input_schema',
			message: 'At least one input schema is necessary.'
		});
	}

	// Check steps
	data?.steps?.forEach(step => {
		if (!step?.prompt_text && step?.type === 'promptbook') {
			remainingTasks.push({
				area: 'step',
				message: 'Write your prompt text for the step: ' + (step?.name || `Untitled promptbook (ID: ${step?.id})`)
			});
		}

		if (step?.type === 'promptbook' && !step?.model || step?.type === 'knowledgebook' && !step?.config?.model) {
			remainingTasks.push({
				area: 'step',
				message: `Model is missing for the ${step?.type} "${(step?.name || `Untitled (ID: ${step?.id})`)}"`
			});
		}

		if (step?.type === 'knowledgebook' && !step?.index_id) {
			remainingTasks.push({
				area: 'step',
				message: `Knowledgebook "${(step?.name || `Untitled (ID: ${step?.id})`)}" is not indexed for search.`
			});
		}
	});

	// Check output
	if (!data?.output_schema || data?.output_schema?.length === 0) {
		remainingTasks.push({
			area: 'output_schema',
			message: 'At least one output schema is necessary.'
		});
	} else if (data?.output_schema?.some(output => !output.key)) {
		remainingTasks.push({
			area: 'output_schema',
			message: 'Every output schema needs to have a unique keyname.'
		});
	} else if (new Set(data?.output_schema).size !== data?.output_schema?.length) {
		remainingTasks.push({
			area: 'output_schema',
			message: 'Some key name is duplicated.'
		});
	}
	data?.output_schema?.forEach(output => {
		if (!output?.variable) {
			remainingTasks.push({
				area: 'output_schema',
				message: 'Variable setting is necessary for the key: ' + output?.key
			});
		}
	});

	return remainingTasks;
};

export default GenflowPlanProvider;