import { useState, useEffect } from 'react';
import { Progress } from 'antd';

const LoadingProgress = ({ duration, shouldAnimateProgress, isComplete, ...restProps }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (shouldAnimateProgress) {
			const endProgress = 99;
			const updateInterval = 500; // Update every 500ms
			let elapsed = 0;
			let totalUpdates = Math.floor(duration / updateInterval); // Total number of updates that will occur
			// console.log(totalUpdates, duration, updateInterval);
			let averageIncrement = endProgress / (totalUpdates * 10); // Average increment per update to approximately reach 99% at the end

			const timer = setInterval(() => {
				if (isComplete || progress >= endProgress) {
					clearInterval(timer);
					if (isComplete) {
						setProgress(100);
					}
					return;
				}

				elapsed += updateInterval;
				const randomVariance = (Math.random() * 0.4 - 0.2) * averageIncrement; // Random variance of ±20% of the average increment
				setProgress(prevProgress => {
					const newProgress = Math.min(endProgress, prevProgress + averageIncrement + randomVariance);
					return newProgress;
				});

			}, updateInterval);

			return () => clearInterval(timer);
		}
	}, [isComplete, duration, progress]);

	return (
		<Progress
			{...restProps}
			percent={shouldAnimateProgress ? Math.floor(progress) : 99}
		/>
	);
};

export default LoadingProgress;
