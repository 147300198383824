import { useContext, useState } from 'react';
import { useOutlet } from 'react-router-dom';
import { Layout, theme } from 'antd';
import SideMenu from './SideMenu';
import GlobalContext from '../../../context/GlobalContext';

const { Content } = Layout;

const collapsedWidth = 50;

const GenflowLayout = () => {
	const outlet = useOutlet();
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const { collapsed, setCollapsed } = useContext(GlobalContext);

	return (
		<Layout hasSider>
			<SideMenu collapsed={collapsed} setCollapsed={setCollapsed} collapsedWidth={collapsedWidth} />
			<Layout
				className="site-layout"
				style={{
					marginLeft: collapsed ? collapsedWidth : 200,
					transition: 'margin-left 0.25s',
					background: colorBgContainer,
				}}
			>
				<Content
					className='Page'
					style={{
						overflow: 'initial'
					}}
				>
					{outlet}
				</Content>
			</Layout>
		</Layout>
	);
};

export default GenflowLayout;
