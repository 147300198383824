import { motion } from 'framer-motion';

const MotionDiv = (props) => {
	const {
		animationIndex = 0,
		children,
		...restProps
	} = props;

	const defaultVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -20 }
	};
	const delay = animationIndex * 0.1;
    
	return (
		<motion.div
			initial='hidden'
			animate='visible'
			variants={defaultVariants}
			transition={{ duration: 0.3, ease: 'easeInOut', delay }}
			{...restProps}
		>
			{children}
		</motion.div>
	);
};

export default MotionDiv;
