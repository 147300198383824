import { useState } from 'react';
import { Modal } from 'antd';
import Icon, { FileSearchOutlined } from '@ant-design/icons';
import { Apologies, SelectCard } from '../../../../../../../../components/atoms';
import { GrDocumentCsv } from 'react-icons/gr';
import { IoDuplicateOutline } from 'react-icons/io5';
import { BsStars } from 'react-icons/bs';
import AIGenerate from './AIGenerate';
import Upload from './Upload';
import FromLog from './FromLog';
import CopyOthers from './CopyOthers';

const items = [
	{
		key: 'ai',
		title: 'Let AI generate',
		description: 'Let Teammately AI to generate Input Dataset.',
		icon: <Icon component={BsStars} style={{ fontSize: 36 }} />,
		isAi: true
	},
	{
		key: 'upload',
		title: 'Upload from csv file',
		description: 'If you already have input in csv, you can upload.',
		icon: <Icon component={GrDocumentCsv} style={{ fontSize: 36 }} />,
	},
	{
		key: 'log',
		title: 'From production log',
		description: 'You can use the real user input from the production app already deployed.',
		icon: <FileSearchOutlined style={{ fontSize: 36 }} />,
		disabled: true,
	},
	{
		key: 'copy',
		title: 'Copy from other optimization',
		description: 'You can copy user input set from your existing optimization projects.',
		icon: <Icon component={IoDuplicateOutline} style={{ fontSize: 36 }} />,
		disabled: true,
	},
];

const CreationModal = ({ open, onClose }) => {
	const [current, setCurrent] = useState('initial');

	const handleClose = () => {
		onClose();
		setCurrent('initial');
	};

	return (
		<Modal
			open={open}
			title='Add Input Dataset'
			footer={[]}
			onCancel={handleClose}
			width={800}
		>
			{(current === 'initial' || current === null) ? (
				<Initial setCurrent={setCurrent} />
			) : current === 'ai' ? (
				<AIGenerate setCurrent={setCurrent} onFinish={handleClose} />
			) : current === 'upload' ? (
				<Upload setCurrent={setCurrent} onFinish={handleClose} />
			) : current === 'log' ? (
				<FromLog setCurrent={setCurrent} onFinish={handleClose} />
			) : current === 'copy' ? (
				<CopyOthers setCurrent={setCurrent} onFinish={handleClose} />
			) : (
				<Apologies note='Unknown error, please close and try again' />
			)}
		</Modal>
	);
};

const Initial = ({ setCurrent }) => (
	<div
		style={{
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			gap: '10px'
		}}
	>
		{items.map((item) => (
			<div key={item.key} style={{ flex: '1 0 calc(50% - 10px)' }}>
				<SelectCard
					title={item.title}
					description={item.description}
					icon={item.icon}
					onSelect={() => setCurrent(item.key)}
					isAi={item.isAi}
					disabled={item.disabled}
				/>
			</div>
		))}
	</div>
);

export default CreationModal;
