import { useState, useEffect } from 'react';
import { Flex, Input } from 'antd';

// todo: initialValue
const InputName = (props) => {
	const { onChange, placeholder } = props;
	const [values, setValues] = useState({});

	useEffect(() => {
		onChange(values);
	}, [values]);

	return (
		<Flex gap="small">
			<Input
				onChange={(e) => setValues({ ...values, firstname: e.target.value })}
				placeholder={placeholder?.firstname}
			/>
			<Input
				onChange={(e) => setValues({ ...values, lastname: e.target.value })}
				placeholder={placeholder?.lastname}
			/>
		</Flex>
	);
};

export default InputName;
