import { useLocation } from 'react-router-dom';
import {
	ApiKeys,
	GenflowList,
	GetStarted,
	Home,
	Members, PlatformConnections,
	StorageList,
	UserSettings,
	WorkspaceConfig
} from './screens';
import Provider from './contexts/Provider';
import LeftNavigation from './LeftNavigation';
import { Layout } from 'antd';
import { useState } from 'react';
import './index.css';

const collapsedWidth = 50;

const App = () => {
	const location = useLocation();
	const pathname = location.pathname;

	const [ collapsed, setCollapsed ] = useState(false);

	return (
		<Provider>
			<Layout hasSider>
				<LeftNavigation
					collapsed={collapsed}
					onCollapse={() => setCollapsed(!collapsed)}
					collapsedWidth={collapsedWidth}
				/>
				<Layout
					className='site-layout'
					style={{
						marginLeft: collapsed ? collapsedWidth : 200,
						transition: 'margin-left 0.25s',
						background: '#FFFFFF',
					}}
				>
					<div className='top-container-body'>
						{pathname === '/' ? (
							<Home />
						) : pathname === '/get-started' ? (
							<GetStarted />
						) : pathname === '/genflows' ? (
							<GenflowList />
						) : pathname === '/storage' ? (
							<StorageList />
						) : pathname === '/platform-connections' ? (
							<PlatformConnections />
						) : pathname === '/settings/user' ? (
							<UserSettings />
						) : pathname === '/settings/workspace/config' ? (
							<WorkspaceConfig />
						) : pathname === '/settings/workspace/members' ? (
							<Members />
						) : pathname === '/api-keys' ? (
							<ApiKeys />
						) : null}
					</div>
				</Layout>
			</Layout>
		</Provider>
	);
};

export default App;
