import { createContext } from 'react';

const Context = createContext({
	isWorking: false,
	messages: [],
	hasError: false,
	logsList: [],
	logsLoading: true,
	logsRefresh: () => {},
	aggregatedResultsList: [],
	aggregatedResultsLoading: true,
	aggregatedResultsRefresh: () => {}
});

export default Context;
