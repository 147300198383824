import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from '@firebase/auth';
import { firebaseAuth } from '../../config/firebase';
import storeToken from './storeToken';
import createNewUser from './createNewUser';

const handleEmail = async (type, options) => {
	try {
		const { username, password, ...restOptions } = options;
		const userCredential = type === 'login'
			? await signInWithEmailAndPassword(firebaseAuth, username, password)
			: type === 'signup'
				? await createUserWithEmailAndPassword(firebaseAuth, username, password)
				: null;

		const { user } = userCredential;
		const { stsTokenManager, accessToken = '', uid } = user;
		if (!accessToken) {
			throw new Error('Access token not found');
		}

		// storeToken(accessToken);

		let redirectPath;

		if (type === 'signup') {
			const response = await createNewUser(accessToken, { ...restOptions, email: username });
			if (!response) {
				throw new Error('Error creating new user');
			}

			redirectPath = '/';
		}

		return {
			success: true,
			redirect: redirectPath,
			userId: uid,
			token: accessToken,
			tokenManager: stsTokenManager
		};
	} catch (error) {
		const errorCode = error.code;
		const errorMessage = error.message;

		return {
			success: false,
			message: errorMessage,
			code: errorCode?.split('/')[1] || 'unknown'
		};
	}
};

export default handleEmail;
