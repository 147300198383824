import Context from './Context';
import GenflowPlanContext from '../../../../context/genflowPlan/Context';
import { useContext, useMemo } from 'react';
import { useAuthData, useAuthRequest } from '../../../../hooks';
import { useParams } from 'react-router-dom';

const Provider = ({ id, children }) => {
	const { genflowId, planId } = useParams();
	const { data: planData, operatePlan } = useContext(GenflowPlanContext);
	const steps = planData?.metadata?.steps;

	// eslint-disable-next-line no-undef
	const baseEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${planId}/knowledgebooks/${id}`;
	const { data, loading, refresh } = useAuthData(baseEndpoint);

	const { makeRequest } = useAuthRequest();
	const editKnowledgebook = async (key, value, type) => {
		const payload = { key, value, ...(type ? { type } : {}) };
		const response = await makeRequest(baseEndpoint, 'PATCH', payload);
		await refresh();

		return response;
	};

	const copyKnowledgebook = async (position) => {
		const response = await makeRequest(baseEndpoint + '/copy', 'POST');

		if (response?.message === 'OK') {
			const newKnowledgebookId = response?.result?.['knowledgebook_id'];
			const newItem = { type: 'knowledgebook', id: newKnowledgebookId };
			let newSteps = [...(steps || [])];
			newSteps.splice(position, 0, newItem);
			operatePlan({ key: 'metadata.steps', value: newSteps, type: 'json' }, 'PATCH');
		}

		return response;
	};

	const deleteKnowledgebook = async () => {
		const response = await makeRequest(baseEndpoint, 'DELETE');
		if (response?.message === 'OK') {
			let newSteps = steps.filter((item) => item.id !== id);
			operatePlan({ key: 'metadata.steps', value: newSteps, type: 'json' }, 'PATCH');
		}
		return response;
	};

	// eslint-disable-next-line no-undef
	const indexEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${planId}/knowledgebooks-indexing/${id}`;
	const indexKnowledgebook = async (method = 'POST', payload) => {
		const response = await makeRequest(indexEndpoint, method, payload);
		await refresh();
		return response;
	};

	const isIndexed = !!data?.data?.rows?.[0]?.index_id;

	const contextValues = useMemo(() => ({
		genflowId,
		planId,
		knowledgebookId: id,
		data: data?.data?.rows?.[0] || {},
		loading,
		refresh,
		editKnowledgebook,
		copyKnowledgebook,
		deleteKnowledgebook,
		indexKnowledgebook,
		isIndexed
	}), [genflowId, planId, id, data]);

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default Provider;
