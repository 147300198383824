import { useContext,  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Popconfirm, Tag, Tooltip, Modal } from 'antd';
import Icon, {
	CloseOutlined,
	DeleteOutlined,
	EditOutlined, ExclamationCircleFilled,
	PlusOutlined,
	SaveOutlined
} from '@ant-design/icons';
import { IoDuplicateOutline } from 'react-icons/io5';
import { ListPageTemplate } from '../../../../components/templates';
import { Editors, Empty, RelativeTime, UserTableCell } from '../../../../components/atoms';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import GlobalContext from '../../../../context/GlobalContext';

const { confirm } = Modal;

const TITLE = 'Plan List';
const DESCRIPTION = 'Plan is one of the trial or experiment of generation flow composed of prompts, knowledges, foundation models, etc. Create a variety of plans by yourself and AI, simulate them automatically and choose the best.';
const COLUMNS = [
	{
		title: 'Plan name',
		dataIndex: 'label',
		key: 'label',
		ellipsis: true,
		width: '50%',
	},
	{
		title: 'Created by',
		dataIndex: 'createdBy',
		key: 'createdBy',
	},
	{
		title: 'Editors',
		dataIndex: 'editors',
		key: 'editors',
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
	}
];

const PlanListPage = (props) => {
	const { handleCreate } = props;
	const navigate = useNavigate();
	const { pushMessage } = useContext(GlobalContext);
	const { genflowData, plansList, plansLoading, operateGenflow } = useContext(GenflowDetailContext);
	const [productionUpdateMode, setProductionUpdateMode] = useState();
	const [productionCandidatePlanId, setProductionCandidatePlanId] = useState();
	const [loadingButtons, setLoadingButtons] = useState([]);

	const currentProductionPlanId = genflowData?.metadata?.productionPlanId;

	const [productionPlanUpdating, setProductionPlanUpdating] = useState(false);
	const handleProductionPlanUpdate = async () => {
		if (productionCandidatePlanId && currentProductionPlanId !== productionCandidatePlanId) {
			setProductionPlanUpdating(true);

			const payload = {
				key: 'metadata.productionPlanId',
				value: productionCandidatePlanId
			};

			await operateGenflow(payload, 'PATCH');

			pushMessage('Production plan updated', 'success');
			setProductionUpdateMode(false);
			setProductionPlanUpdating(false);
		}
	};

	const buttons = [
		{
			id: 'create-plan',
			label: 'Create new plan',
			type: 'primary',
			icon: <PlusOutlined />,
			disabled: productionUpdateMode,
			onClick: async () => {
				setLoadingButtons([...loadingButtons, 'create-plan']);
				await handleCreate();
				setLoadingButtons(loadingButtons.filter(b => b !== 'create-plan'));
			},
		},
		{
			label: productionUpdateMode ? 'Selecting the production plan' : 'Switch plan for production',
			type: 'primary',
			icon: <EditOutlined />,
			disabled: !plansList || plansList?.length === 0 || productionUpdateMode,
			onClick: () => {
				setProductionUpdateMode(true);
			},
		},
	];

	const rightButtons = [
		{
			label: 'Cancel',
			icon: <CloseOutlined />,
			disabled: productionPlanUpdating,
			onClick: () => {
				setProductionUpdateMode(false);
			},
		},
		{
			label: 'Update production plan',
			type: 'primary',
			icon: <SaveOutlined />,
			danger: true,
			loading: productionPlanUpdating,
			onClick: () => {
				confirm({
					title: 'Do you push the selected plan for production?',
					icon: <ExclamationCircleFilled />,
					content: (
						<Flex vertical gap='middle'>
							<span>All the API requests to this generation flow endpoint will use the selected plan for generation.</span>
							<Flex vertical gap='small' style={{ border: '2px solid var(--teammately-orange)', padding: 12, borderRadius: 8 }}>
								<Tag bordered={false} color='var(--teammately-orange)' style={{ fontWeight: 600 }}>Selected plan</Tag>
								{plansList?.find(plan => plan.genflow_plan_id === productionCandidatePlanId)?.name}
							</Flex>
						</Flex>
					),
					okText: 'Push',
					okButtonProps: {
						danger: true,
						icon: <SaveOutlined />,
						loading: productionPlanUpdating,
					},
					onOk() {
						handleProductionPlanUpdate();
					},
					cancelButtonProps: {
						disabled: productionPlanUpdating
					}
				});

			},
		},
	];

	const displayData = plansList?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.map((row) => {
			const { genflow_id, genflow_plan_id, name, created_by, updated_at, editors, metadata } = row;

			return {
				key: genflow_plan_id,
				updatedAt: <RelativeTime datetime={updated_at} />,
				createdBy: <UserTableCell userId={created_by} />,
				editors: <Editors editors={editors} loading={plansLoading} />,
				label: (
					<Flex>
						{currentProductionPlanId === genflow_plan_id && (
							<Tag bordered={false} color='var(--teammately-orange)' style={{ fontWeight: 600 }}>PROD</Tag>
						)}
						<Tooltip
							placement='topLeft'
							title={name || 'No title'}
							color='var(--tooltip-background)'
						>
							<a onClick={() => navigate(`/genflows/${genflow_id}/develop/${genflow_plan_id}`)}>
								{name || 'No title'}
							</a>
						</Tooltip>
					</Flex>
				),
				action: (
					<Flex gap='small' align='center'>
						<DuplicateButton id={genflow_plan_id} disabled={productionUpdateMode} />
						<DeleteButton id={genflow_plan_id} disabled={productionUpdateMode || currentProductionPlanId === genflow_plan_id} />
					</Flex>
				)
			};
		});

	const ComponentOnEmpty = () => (
		<Flex vertical gap='small'>
			<Empty type='building' description='Start creating a plan first!' />
			<Button
				type='primary'
				size='large'
				icon={<PlusOutlined />}
				loading={loadingButtons?.includes('create-plan')}
				disabled={productionUpdateMode}
				onClick={async () => {
					setLoadingButtons([...loadingButtons, 'create-plan']);
					await handleCreate();
					setLoadingButtons(loadingButtons.filter(b => b !== 'create-plan'));
				}}
			>
				Create new plan
			</Button>
		</Flex>
	);

	return (
		<div style={{ paddingTop: 12 }}>
			<ListPageTemplate
				title={TITLE}
				description={DESCRIPTION}
				buttons={buttons}
				rightButtons={productionUpdateMode ? rightButtons : []}
				loadingButtons={loadingButtons}
				componentOnEmpty={<ComponentOnEmpty />}
				columns={COLUMNS}
				data={displayData}
				loading={plansLoading && !plansList}
				tableConfig={{
					rowSelection: productionUpdateMode ? {
						columnTitle: 'Prod',
						columnWidth: 50,
						type: 'radio',
						selectedRowKeys: [productionCandidatePlanId],
						onChange: (keys) => {
							setProductionCandidatePlanId(keys[0]);
						},
					} : null,
				}}
			/>
		</div>
	);
};

const DuplicateButton = ({ id, disabled }) => {
	const navigate = useNavigate();
	const { pushMessage } = useContext(GlobalContext);
	const { genflowId, operatePlan } = useContext(GenflowDetailContext);
	const [loading, setLoading] = useState(false);

	const handleDuplicate = async() => {
		setLoading(true);

		const response = await operatePlan(null, 'POST', `/${id}/copy`);
		if (response.message === 'OK' && response.result?.['genflow_plan_id']) {
			const genflowPlanId = response.result?.['genflow_plan_id'];
			navigate(`/genflows/${genflowId}/develop/${genflowPlanId}`);
		} else {
			pushMessage('Creation failed', 'error');
		}

		setLoading(false);
		return response;
	};

	return (
		<Tooltip title='Duplicate' color='var(--tooltip-background)'>
			<Button
				type='text'
				shape='circle'
				icon={<Icon component={IoDuplicateOutline} />}
				loading={loading}
				disabled={disabled}
				onClick={handleDuplicate}
			/>
		</Tooltip>
	);
};

const DeleteButton = ({ id, disabled }) => {
	const { pushMessage } = useContext(GlobalContext);
	const { operatePlan } = useContext(GenflowDetailContext);
	const [loading, setLoading] = useState(false);

	const handleDelete = async() => {
		setLoading(true);

		const response = await operatePlan(null, 'DELETE', `/${id}`);
		if (response.message === 'OK') {
			pushMessage('Successfully deleted', 'success');
		} else {
			pushMessage('Creation failed', 'error');
		}

		setLoading(false);
		return response;
	};

	return (
		<Popconfirm
			title='Delete plan'
			description='Are you sure to delete this plan?'
			onConfirm={handleDelete}
			okText='Delete'
			cancelText='Cancel'
		>
			<Tooltip title='Delete' color='var(--tooltip-background)'>
				<Button
					type='text'
					shape='circle'
					icon={<DeleteOutlined />}
					danger
					loading={loading}
					disabled={disabled}
				/>
			</Tooltip>
		</Popconfirm>
	);
};

export default PlanListPage;
