import { useContext, useEffect, useState } from 'react';
import KnowledgebookContext from '../../../contexts/Context';
import GlobalAuthContext from '../../../../../../context/GlobalAuthContext';
import { Flex, Select } from 'antd';
import { Empty, StorageUpload } from '../../../../../atoms';

const Object = () => {
	const { txtList, storageList, storageLoading, storageRefresh } = useContext(GlobalAuthContext);
	const { data, loading, editKnowledgebook, isIndexed } = useContext(KnowledgebookContext);
	const [localValue, setLocalValue] = useState();

	useEffect(() => {
		if (data?.type !== localValue) {
			setLocalValue(data?.metadata?.objects);
		}
	}, [data]);

	const handleChange = (value) => {
		setLocalValue(value);
		editKnowledgebook('metadata.objects', value, 'json');
	};

	// todo this endpoint only returns 100 objects. We need to support dynamic loading.
	const objectOptions = txtList?.map((item) => ({ value: item.object_id, label: item.name }));

	return (
		<Flex vertical gap='small'>
			<Select
				size='large'
				variant='filled'
				placeholder='Choose .txt files for knowledge'
				mode='multiple'
				loading={storageLoading}
				options={objectOptions}
				allowClear
				value={localValue}
				onChange={handleChange}
				disabled={isIndexed}
				notFoundContent={<Empty type='waiting' description='Upload your files!' />}
			/>
			{!isIndexed && (
				<StorageUpload
					type='drag'
					onUpload={() => storageRefresh()}
					allowedFormat={['txt']}
				/>
			)}
		</Flex>
	);
};

export default Object;
