import { Form, Input } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const RequestPasswordResetForm = () => {
	return (
		<>
			<Form.Item
				name="username"
				rules={[
					{
						required: true,
						message: 'Please enter email address',
					},
				]}
			>
				<Input
					size='large'
					placeholder='Email address'
					prefix={<MailOutlined className={'prefixIcon'} />}
				/>
			</Form.Item>
		</>
	);
};

export default RequestPasswordResetForm;
