const isFalsyValue = value => [false, null, undefined].includes(value);

const deepEquivalence = (obj1, obj2) => {
	if (!obj1 || !obj2) {
		return false;
	}

	const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

	for (let key of allKeys) {
		const val1 = obj1[key];
		const val2 = obj2[key];

		if ((!(key in obj1) && !isFalsyValue(val2)) || (!(key in obj2) && !isFalsyValue(val1))) {
			return false;
		}

		const isFalsyEquivalence = [val1, val2].every(isFalsyValue);

		if (isFalsyEquivalence) {
			continue;
		}

		if (typeof val1 === 'object' && typeof val2 === 'object' && val1 !== null && val2 !== null) {
			if (!deepEquivalence(val1, val2)) {
				return false;
			}
			continue;
		}

		if (val1 !== val2) {
			return false;
		}
	}

	return true;
};

export default deepEquivalence;
