import { Flex } from 'antd';
import './index.css';

const SelectCard = (props) => {
	const { title, description, icon, onSelect, isAi = false, disabled = false } = props;

	return (
		<div className={(isAi ? ' select-card-ai' : 'select-card') + (disabled ? '-disabled' : '')} onClick={onSelect}>
			<Flex gap='middle' align='center' style={{ padding: '10px 20px' }}>
				{icon && icon}
				<Flex vertical>
					<span style={{ fontWeight: 'bold', fontSize: '20px' }}>
						{title}
					</span>
					{description}
				</Flex>
			</Flex>
		</div>
	);
};

export default SelectCard;
