import { Button, ConfigProvider, Flex } from 'antd';
import { ImageContainer, MotionDiv, TypingAnimation } from '../../components/atoms';
import { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const bodyStyle = {
	position: 'relative', width: '100vw', height: '100vh'
};

const items = [
	{
		component: (
			<Flex gap={100} align='center'>
				<MotionDiv>
					<ImageContainer width={200} src='/images/smiling.gif' alt='Welcome'/>
				</MotionDiv>
				<div style={{ width: 800 }}>
					<Flex vertical gap='large'>
						<TypingAnimation
							key='welcome'
							sequence={[
								'',
								500,
								'Welcome to Teammately!',
							]}
							wrapper='span'
							speed={75}
							cursor={false}
							style={{ fontSize: 32, fontWeight: 600, display: 'inline-block' }}
						/>
						<TypingAnimation
							key='initializing'
							sequence={[
								'',
								2000,
								'>> ai ~ %  your teammately account is being set up...\n|=======================......|',
								1000,
								'>> ai ~ %  your teammately account is being set up...\n|=======================......|\n>> ai ~ %  Teammately is a platform where you let AI build AI.',
								1000,
								'>> ai ~ %  your teammately account is being set up...\n|=======================......|\n>> ai ~ %  Teammately is a platform where you let AI build AI.\n>> ai ~ %  we are redefining how you build AI. let me tell the story behind ->>',
							]}
							wrapper='span'
							speed={85}
							style={{ fontSize: 20, lineHeight: '32px', color: '#666666' }}
						/>
					</Flex>
				</div>
			</Flex>
		)
	},
	{
		component: (
			<Flex gap={100} align='center'>
				<ImageContainer width={200} src='/images/bike.gif' alt='Riding'/>
				<div style={{ width: 800 }}>
					<Flex vertical gap='large'>
						<TypingAnimation
							key='deep-tuning'
							sequence={[
								'>> ai ~ %  It is very challenging to build an LLM app that works as you intended. Very hard, so as us...',
								1000,
								'>> ai ~ %  It is very challenging to build an LLM app that works as you intended. Very hard, so as us...\n>> ai ~ %  That is why we build a "Deep Tuning" platform - where LLM automatically iterates your AI / LLM architecture.',
							]}
							wrapper='span'
							speed={85}
							style={{ fontSize: 20, lineHeight: '32px', color: '#666666' }}
						/>
					</Flex>
				</div>
			</Flex>
		)
	},
	{
		component: (
			<Flex gap={100} align='center'>
				<ImageContainer width={175} src='/images/evaluating.gif' alt='Evaluatinging'/>
				<div style={{ width: 800 }}>
					<Flex vertical gap='large'>
						<TypingAnimation
							key='evaluation'
							sequence={[
								'>> ai ~ %  Our LLM builds, our LLM evaluates your LLM app output, examine and analyze the statistical results, then propose a new one.',
							]}
							wrapper='span'
							speed={85}
							style={{ fontSize: 20, lineHeight: '32px', color: '#666666' }}
						/>
					</Flex>
				</div>
			</Flex>
		)
	}
];

const Story = () => {
	const [current, setCurrent] = useState(0);
	const [navigating, setNavigating] = useState(false); // New state to manage navigation
	const navigate = useNavigate(); // Hook for navigation

	const handleNavigation = () => {
		setNavigating(true);
		setTimeout(() => {
			navigate('/');
		}, 500);
	};

	const slideVariants = {
		initial: {
			y: 30,
			opacity: 0,
			scale: 1
		},
		animate: {
			y: 0,
			opacity: 1,
			scale: 1,
			transition: {
				type: 'spring',
				stiffness: 300,
				damping: 30
			}
		},
		exit: {
			y: -30,
			opacity: 0,
			scale: current === 2 ? 3 : 1,
			transition: {
				type: 'spring',
				stiffness: 300,
				damping: 30
			}
		}
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimaryBg: '#EAEDFA',
					colorPrimary: '#3857D3',
					colorInfo: '#3857D3',
					colorLink: '#3857D3',
					colorInfoText: '#3857D3',
					fontSizeHeading2: '24px',
					lineHeightHeading2: '30px'
				}
			}}
		>
			<div style={bodyStyle}>
				<div
					className='first-view-animated-background'
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				/>
				<AnimatePresence>
					{navigating && (
						<motion.div
							key='background'
							variants={{
								initial: {
									opacity: 0,
								},
								animate: {
									opacity: 1,
								},
							}}
							initial="initial"
							animate="animate"
						>
							<div
								style={{
									backgroundColor: '#FFFFFF',
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									zIndex: -5
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
				<div
					style={{
						position: 'relative',
						zIndex: 1,
						overflow: 'hidden',
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Flex vertical gap={30}>
						<AnimatePresence>
							{!navigating && (
								<motion.div
									key={current}
									variants={slideVariants}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									{items[current]?.component}
								</motion.div>
							)}
						</AnimatePresence>
						<Flex justify='center'>
							<Button
								shape={current < 2 ? 'circle' : null}
								type='primary'
								size='large'
								icon={<RightOutlined/>}
								onClick={() => current < 2 ? setCurrent(current + 1) : handleNavigation()}
							>
								{current === 2 && 'Start'}
							</Button>
						</Flex>
					</Flex>
				</div>
			</div>
		</ConfigProvider>
	);
};

export default Story;
