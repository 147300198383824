const jsonExtractor = (inputData) => {
	if (typeof inputData === 'object' && inputData !== null) {
		return inputData;
	}

	try {
		return JSON.parse(inputData);
	} catch (error) {
		try {
			const jsonStart = inputData.indexOf('{');
			const jsonEnd = inputData.lastIndexOf('}') + 1;
			const jsonString = inputData.substring(jsonStart, jsonEnd);

			return JSON.parse(jsonString);
		} catch (error) {
			return {};
		}
	}
};

export default jsonExtractor;
