import { useContext } from 'react';
import { Button, ConfigProvider, Flex, Tag, Typography } from 'antd';
import GlobalContext from '../../../../../context/GlobalContext';
import { LeftOutlined, LockOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './index.css';

const Header = (props) => {
	const { title, description, prevDestination, nextDestination, isLocked } = props;
	const { collapsed } = useContext(GlobalContext);
	const navigate = useNavigate();

	return (
		<div
			className='sub-header-container'
			style={{
				left: 260 + (collapsed ? 50 : 200),
				width: `calc(100vw - ${collapsed ? '50' : '200'}px - 275px + 10px)`,
				transition: 'width 0.25s, left 0.25s'
			}}
		>
			<Flex justify='space-between' align='center'>
				<Flex gap='middle' align='center' style={{ height: 30, padding: '0 16px' }}>
					{isLocked && (
						<LockOutlined style={{ fontSize: 20 }} />
					)}
					{title && (
						<Typography.Title level={3} style={{ margin: 0, fontSize: 20 }}>{title}</Typography.Title>
					)}
					{description && (
						<Typography.Paragraph style={{ margin: 0 }}>{description}</Typography.Paragraph>
					)}
					{isLocked && (
						<Tag bordered={false} color='volcano-inverse'>LOCKED</Tag>
					)}
				</Flex>
				<ConfigProvider
					theme={{
						token: {
							colorText: 'var(--primary-color)',
						},
						components: {
							Button: {
								textHoverBg: 'var(--primary-color-background)',
							}
						}
					}}
				>
					<Flex align='flex-end' gap={5}>
						{(prevDestination || nextDestination) && (
							<>
								<Button
									type='text'
									icon={<LeftOutlined />}
									iconPosition='start'
									disabled={!prevDestination}
									onClick={() => navigate(prevDestination)}
								>
									Prev
								</Button>
								<Button
									type='primary'
									icon={<RightOutlined />}
									iconPosition='end'
									disabled={!nextDestination}
									onClick={() => navigate(nextDestination)}
								>
									Next
								</Button>
							</>
						)}
					</Flex>
				</ConfigProvider>
			</Flex>
		</div>
	);
};

export default Header;
