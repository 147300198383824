import { useContext, useMemo } from 'react';
import { Flex, Tag, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthData } from '../../../../../../../../../hooks';
import GenflowOptimizeContext from '../../../../../../../../../context/genflowOptimize/Context';
import GenflowDetailContext from '../../../../../../../../../context/genflowDetail/Context';
import { Column, Pie } from '../../../../../../../../../components/molecules/charts';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Loading } from '../../../../../../../../../components/atoms';
import './index.css';

const specialModuleKeys = ['system_log', 'openai_moderation'];

const scorecardMap = {
	latency: {
		title: 'Latency (ms)',
		description: 'Measures the latency of generation',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	total_cost: {
		title: 'Total Cost (USD)',
		description: 'xxx',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	text_input_cost: {
		title: 'Text Input Cost (USD)',
		description: 'xxx',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	text_output_cost: {
		title: 'Text Input Cost (USD)',
		description: 'xxx',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	text_total_token_length: {
		title: 'Total token length',
		description: 'Text token only',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	text_input_token_length: {
		title: 'Input token length',
		description: 'Text token only',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	text_output_token_length: {
		title: 'Output token length',
		description: 'Text token only',
		tag: 'System log',
		type: 'column',
		showAggregation: true
	},
	intellectual_property: {
		title: 'Intellectual property',
		description: 'xxx',
		tag: 'Common LM-as-a-judge',
		type: 'yes_no'
	},
	privacy: {
		title: 'Privacy',
		description: 'xxx',
		tag: 'Common LM-as-a-judge',
		type: 'yes_no'
	},
	bias: {
		title: 'Bias',
		description: 'xxx',
		tag: 'Common LM-as-a-judge',
		type: 'yes_no'
	},
	ymyl: {
		title: 'YMYL',
		description: 'xxx',
		tag: 'Common LM-as-a-judge',
		type: 'yes_no'
	},
	toxicity: {
		title: 'Toxicity',
		description: 'xxx',
		tag: 'Common LM-as-a-judge',
		type: 'yes_no'
	},
};

const Scores = () => {
	const { data } = useContext(GenflowOptimizeContext);
	const outputKeys = data?.metadata?.evaluationModules ? Object.keys(data?.metadata?.evaluationModules) : [];
	const hasGeneralMetrics = outputKeys.some(outputKey => Object.keys(data?.metadata?.evaluationModules[outputKey])?.some(moduleKey => specialModuleKeys.includes(moduleKey)));

	return (
		<Flex vertical gap='large'>
			{outputKeys?.map((key, index) => (
				<ScoresGroupContainer
					key={key}
					title={(
						<Flex gap='small'>
							<span>Output:</span>
							<span style={{ fontWeight: 600, backgroundColor: 'var(--primary-color-background)', padding: '0 5px', borderRadius: 8 }}>
								{key}
							</span>
						</Flex>
					)}
				>
					{Object.keys(data?.metadata?.evaluationModules?.[key])?.filter(moduleKey => !specialModuleKeys.includes(moduleKey))
						.map((moduleKey, index) => (
							<ScoreCard
								key={moduleKey}
								id={moduleKey}
								dimensionPrefix='Rate: '
							/>
						))}
				</ScoresGroupContainer>
			))}
			{hasGeneralMetrics && (
				<ScoresGroupContainer title='General'>
					<ScoreCard
						id='latency'
						category='System log'
						description='Measures the latency of generation'
					/>
					<ScoreCard
						id='total_cost'
						category='System log'
						description='xxx'
					/>
					{/*<ScoreCard*/}
					{/*	id='text_input_cost'*/}
					{/*	showAggregation*/}
					{/*	category='System log'*/}
					{/*	description='xxx'*/}
					{/*/>*/}
					{/*<ScoreCard*/}
					{/*	id='text_output_cost'*/}
					{/*	showAggregation*/}
					{/*	category='System log'*/}
					{/*	description='xxx'*/}
					{/*/>*/}
					{/*<ScoreCard*/}
					{/*	id='text_total_token_length'*/}
					{/*	showAggregation*/}
					{/*	category='System log'*/}
					{/*	description='xxx'*/}
					{/*/>*/}
					{/*<ScoreCard*/}
					{/*	id='text_input_token_length'*/}
					{/*	showAggregation*/}
					{/*	category='System log'*/}
					{/*	description='xxx'*/}
					{/*/>*/}
					<ScoreCard
						id='text_output_token_length'
						category='System log'
						description='xxx'
					/>
				</ScoresGroupContainer>
			)}
		</Flex>
	);
};

const ScoresGroupContainer = (props) => {
	const { title, children } = props;
	return (
		<Flex gap='middle' justify='flex-start' vertical>
			<span style={{ fontSize: 24 }}>{title}</span>
			<Flex wrap='wrap' gap='small'>
				{children}
			</Flex>
		</Flex>
	);
};

const ScoreCard = ({ id, dimensionPrefix }) => {
	const { planId } = useParams();
	const { plansList } = useContext(GenflowDetailContext);
	const { data, genflowId, optimizationId, customMetricsList } = useContext(GenflowOptimizeContext);
	const comparisonPlans = [ data?.metadata?.targetPlanId, ...(data?.metadata?.comparisonPlans || []) ] || [];
	const breakdownMap = {};
	comparisonPlans.forEach(id => {
		breakdownMap[id] = plansList?.find(plan => plan.genflow_plan_id === id)?.name || 'Untitled plan';
	});

	const isCustom = id?.startsWith('custom/');
	const thisCustomMetricData = isCustom ? customMetricsList?.find(metric => metric.metric_id === id?.replace('custom/', '')) : {};
	const urlSafeId = id?.replace('/', '.');

	const chartEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/scores/chart/${urlSafeId}`;
	}, [optimizationId, planId]);
	const { data: chartData, loading: chartLoading } = useAuthData(chartEndpoint);

	const someLoading = chartLoading;

	return (
		<Flex gap='small' vertical style={{ height: 375 }} className='score-card-multiple'>
			<Flex gap='small'>
				<span style={{ fontSize: 20, fontWeight: 600 }}>{isCustom ? thisCustomMetricData?.name : scorecardMap[id]?.title}</span>
				{(scorecardMap[id]?.description || isCustom) && (
					<Tooltip color='var(--tooltip-background)' title={isCustom ? thisCustomMetricData?.instruction_text : scorecardMap[id]?.description}>
						<QuestionCircleOutlined />
					</Tooltip>
				)}
			</Flex>
			{scorecardMap[id]?.tag && (
				<div>
					<Tag bordered={false} color='blue'>{scorecardMap[id]?.tag}</Tag>
				</div>
			)}
			{isCustom && (
				<div>
					<Tag bordered={false} color='blue'>Custom metric</Tag>
				</div>
			)}
			{!someLoading ? (
				<div style={{ height: '290px' }}>
					{((isCustom && thisCustomMetricData?.type === 'FIVE_GRADE') || scorecardMap[id]?.type === 'column' || scorecardMap[id]?.type === 'five_grade') && (
						<Column
							data={chartData?.data?.rows?.map(row => ({ ...row, dimension: dimensionPrefix ? dimensionPrefix + row.dimension : row.dimension }))}
							keyMap={{
								breakdown: breakdownMap
							}}
						/>
					)}
					{((isCustom && thisCustomMetricData?.type === 'YES_NO') || scorecardMap[id]?.type === 'yes_no') && (
						<Pie
							data={chartData?.data?.rows}
							keyMap={{
								'0': 'NO',
								'1': 'YES',
							}}
						/>
					)}
				</div>
			) : (
				<Loading />
			)}
		</Flex>
	);
};

export default Scores;
