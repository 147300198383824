import { createContext } from 'react';

const GenflowDetailContext = createContext({
	genflowId: null,
	localPathArr: [],
	genflowData: {},
	genflowLoading: true,
	genflowRefresh: () => {},
	productionPlanData: {},
	productionPlanLoading: true,
	productionPlanRefresh: () => {},
	operateGenflow: () => {},
	plansList: {},
	plansLoading: true,
	plansRefresh: () => {},
	operatePlan: () => {},
	optimizationsData: {},
	optimizationsLoading: true,
	optimizationsRefresh: () => {},
	createPlan: () => {},
	createOptimize: () => {},
	createOptimizeWithInitialization: () => {},
});

export default GenflowDetailContext;
