import { Progress as AntProgress } from 'antd';

const conicColors = {
	'0%': '#87d068',
	'50%': '#ffe58f',
	'100%': '#ffccc7',
};

const Progress = (props) => {
	return (
		<AntProgress type='line' showInfo={false} strokeColor={conicColors} {...props} />
	);
};

export default Progress;
