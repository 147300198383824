import { Button, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ReceiveAttentionButton = ({ onClick, loading, buttonText, buttonIcon = <PlusOutlined />, shoulderText = 'Create' }) => {
	return (
		<div
			style={{
				minHeight: 500,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Flex vertical gap={30}>
				<Flex vertical align='center' style={{ padding: '100px 0' }}>
					{shoulderText && (
						<span className='top-description'>{`\\\\ ${shoulderText} //`}</span>
					)}
					<img src='/images/smiling.gif' alt='Click me please' width={102} height={85}/>
					<Button
						type='primary'
						size='large'
						icon={buttonIcon}
						onClick={onClick}
						loading={loading}
					>
						{buttonText}
					</Button>
				</Flex>
			</Flex>
		</div>
	);
};

export default ReceiveAttentionButton;
