import { useContext, useEffect } from 'react';
import { Flex } from 'antd';
import Provider from './contexts/Provider';
import { Header, Editor, Status } from './components';
import Context from './contexts/Context';
import GenflowPlanContext from '../../../context/genflowPlan/Context';
import './index.css';

const Knowledgebook = ({ id }) => {
	return (
		<Provider id={id}>
			<ContextSyncHandler id={id} />
			<Flex vertical gap='middle'>
				<Header />
				<Editor />
				<Status />
			</Flex>
		</Provider>
	);
};

const ContextSyncHandler = ({ id }) => {
	const { currentEditingStep, setCurrentEditingStepData } = useContext(GenflowPlanContext);
	const { data, loading } = useContext(Context);

	useEffect(() => {
		if (currentEditingStep.id === id && !loading) {
			setCurrentEditingStepData(data);
		}
	}, [id, currentEditingStep, data]);

	return null;
};

export default Knowledgebook;
