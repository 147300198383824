import { useContext, useEffect, useRef } from 'react';
import { Flex, Table, Tag, Skeleton, Tooltip } from 'antd';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import ExperimentDetailContext from '../../contexts/Context';
import { useParams } from 'react-router-dom';
import { Empty, Loading } from '../../../../../../../../components/atoms';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';

const Logs = () => {
	const { planId } = useParams();
	const intervalRef = useRef(null);
	const { userInputSetList, userInputSetLoading, status,  customMetricsList } = useContext(GenflowOptimizeContext);

	const { isWorking, logsList, logsLoading, logsRefresh } = useContext(ExperimentDetailContext);
	const thisPlanLogsList = logsList?.filter(record => record.plan_id === planId) || [];

	const metricKeys = [...new Set(logsList?.flatMap(item => Object.keys(item.output_list[planId]?.scores || {})) || [])];

	const COLUMNS = [
		{
			key: 'input',
			title: 'Input',
			dataIndex: 'input',
			fixed: true,
			render: (text) => (
				<div style={{ width: 200 }}>{text}</div>
			),
			onCell: () => {
				return {
					style: {
						verticalAlign: 'top'
					}
				};
			}
		},
		{
			key: 'output',
			title: 'Simulation result',
			dataIndex: 'output',
			fixed: true,
			render: (text) => (
				<div style={{ width: 350 }}>{text}</div>
			),
			onCell: () => {
				return {
					style: {
						verticalAlign: 'top'
					}
				};
			}
		},
		...((metricKeys && metricKeys?.length > 0)
			? metricKeys.sort((a, b) => a - b).map((key) => {
				const isCustom = key?.startsWith('custom/');
				const thisCustomMetricData = isCustom ? customMetricsList?.find(metric => metric.metric_id === key?.replace('custom/', '')) : {};

				return {
					key: key,
					title: isCustom ? (
						<Flex gap='small'>
							{thisCustomMetricData?.name}
							<Tooltip color='var(--tooltip-background)' title={thisCustomMetricData?.instruction_text}>
								<QuestionCircleOutlined />
							</Tooltip>
						</Flex>
					) : key,
					dataIndex: key,
					sorter: (a, b) => {
						return a[key + '--sort'] - b[key + '--sort'];
					},
					onCell: () => {
						return {
							style: {
								verticalAlign: 'top'
							}
						};
					}
				};
			})
			: []
		)
	];

	const displayData = logsList?.map((item) => {
		const itemsForSort = {};
		const scoreItems = {};
		item.output_list[planId]?.scores && Object.keys(item.output_list[planId]?.scores)?.forEach(key => {
			const scoreData = item.output_list[planId]?.scores?.[key];
			itemsForSort[key + '--sort'] = scoreData?.value;
			scoreItems[key] = (
				<Flex gap='small'>
					<span>{scoreData?.value}</span>
					{scoreData?.extra_data?.reason && (
						<Tooltip
							title={(
								<Flex vertical style={{ padding: 10 }}>
									<div><Tag style={{ fontWeight: 600 }} color='geekblue-inverse'>REASONS BY AI</Tag></div>
									<span style={{ color: '#111111' }}>{scoreData?.extra_data?.reason}</span>
								</Flex>
							)}
							color='var(--primary-color-background)'
							placement='bottom'
						>
							<Icon component={BsStars} />
						</Tooltip>
					)}
				</Flex>
			);
		});

		return {
			...itemsForSort,
			...scoreItems,
			input: <ContentList data={item.input} />,
			output: (
				<Flex vertical gap='small'>
					<ContentList
						data={item.output_list[planId]?.output}
						loading={logsLoading}
					/>
				</Flex>
			)
		};
	});

	useEffect(() => {
		if (isWorking && userInputSetList.length > thisPlanLogsList.length && userInputSetList.length > 0) {
			intervalRef.current = setInterval(() => {
				logsRefresh();
			}, 3000);
		} else {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [userInputSetList.length, logsList.length, status, isWorking]);

	return (displayData && displayData?.length > 0) ? (
		<div style={{ width: 'calc(100vw - 200px - 275px - 70px)' }}>
			<Table
				columns={COLUMNS}
				dataSource={displayData || []}
				loading={userInputSetLoading || logsLoading}
				scroll={{ x: true }}
				size='small'
				rowHoverable={false}
			/>
		</div>
	) : (
		<div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			{logsLoading ? <Loading /> : <Empty type='waiting' description='Waiting for logs'/>}
		</div>
	);
};

const ContentList = ({ data = {}, loading = false }) => {
	return loading ? (
		<Skeleton.Input active/>
	) : (
		<Flex gap='small' vertical>
			{Object.keys(data)?.map(key => (
				<Flex key={key}>
					<div>
						<Tag bordered={false}>
							{key}
						</Tag>
					</div>
					<span>{data?.[key]}</span>
				</Flex>
			))}
		</Flex>
	);
};

export default Logs;
