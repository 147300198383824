import { Flex, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const ItemContainer = (props) => {
	const { title, description, extra, children } = props;
	return (
		<Flex vertical gap='small' justify='space-between'>
			{description ? (
				<Flex justify='space-between' align='flex-end'>
					<Flex gap='small' align='center'>
						<b>{title}</b>
						<Tooltip title={description}>
							<QuestionCircleOutlined style={{ cursor: 'pointer' }} />
						</Tooltip>
					</Flex>
					{extra && extra}
				</Flex>
			) : (
				<Flex justify='space-between' align='flex-end'>
					<b>{title}</b>
					{extra && extra}
				</Flex>
			)}
			{children}
		</Flex>
	);
};

export default ItemContainer;
