import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import axios from 'axios';
import GlobalContext from '../../context/GlobalContext';

function hashUrl(input) {
	let hash = 5381;
	for (let i = 0; i < input.length; i++) {
		const char = input.charCodeAt(i);
		hash = (hash << 5) + hash + char;
	}
	return hash >>> 0;
}

const useAuthData = (url, allowLoad = true) => {
	const { workspaceId, user, userLoading, tokenManager, jwt, refreshJwt, pushMessage } = useContext(GlobalContext);
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [retryCount, setRetryCount] = useState(0);
	const maxRetries = 1;

	const userId = user?.uid;

	const headers = useMemo(() => ({
		Authorization: `Bearer ${tokenManager?.accessToken || sessionStorage.getItem('accessToken') || ''}`,
		'Workspace-ID': workspaceId,
		'User-ID': userId
	}), [tokenManager?.accessToken, sessionStorage.getItem('accessToken'), workspaceId, userId]);

	const fetchData = useCallback(async () => {
		// const cacheKey = hashUrl(url);
		// const cachedData = sessionStorage.getItem(cacheKey);
		// if (cachedData) {
		// 	setData(JSON.parse(cachedData));
		// 	setLoading(false);
		// 	return;
		// }

		if (allowLoad && (tokenManager?.accessToken || sessionStorage.getItem('accessToken')) && userId && workspaceId) {
			setLoading(true);
			setError(false);
			try {
				const response = await axios.get(url, { headers });
				if (response?.status === 200) {
					// sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
					setData(response.data);
				} else if (response?.status === 401 && retryCount < maxRetries) {
					setRetryCount(prev => prev + 1);
					await refreshJwt();
					fetchData();
				}
			} catch (error) {
				console.error('Fetch Error:', error);
				setError(true);
			}
			setLoading(false);
		}
	}, [url, headers, allowLoad, retryCount, maxRetries, refreshJwt]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const refresh = useCallback(() => {
		// const cacheKey = hashUrl(url);
		// sessionStorage.removeItem(cacheKey);
		fetchData();
	}, [fetchData]);

	return { data, loading: loading || userLoading, error, refresh };
};

export default useAuthData;
