import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton, Flex, Tag, Typography, Menu, ConfigProvider, Tooltip } from 'antd';
import Icon, {
	CheckCircleFilled,
	FileSearchOutlined,
	HomeOutlined, LineChartOutlined,
	PartitionOutlined,
	FileDoneOutlined, ArrowUpOutlined, ArrowRightOutlined
} from '@ant-design/icons';
import { Bs1Square, Bs2Square, Bs3Square, Bs4Square, Bs5Square, Bs6Square, Bs7Square } from 'react-icons/bs';
import { Editors, Ellipsis } from '../../../../../components/atoms';
import GenflowOptimizeContext from '../../../../../context/genflowOptimize/Context';
import './index.css';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';

const iconStyles = { fontSize: 20 };
const numberedIconsMap = {
	1: Bs1Square,
	2: Bs2Square,
	3: Bs3Square,
	4: Bs4Square,
	5: Bs5Square,
	6: Bs6Square,
	7: Bs7Square,
};

// const statusDirectoryMap = {
// 	INITIALIZATION: 'initialize',
// 	CONFIGURATION: 'input',
// 	TARGET_PLAN_EVALUATION: `experiments/${targetPlanId}`,
// 	ALT_PLANS_GENERATED: 'recommendations',
// 	ALT_PLANS_EVALUATIONS: 'experiments/comparison',
// 	ALT_PLANS_EVALUATIONS_COMPLETED: 'experiments/comparison/scores',
// 	COMPLETED: 'experiments/comparison/scores',
// 	FINAL_REPORT_GENERATED: 'conclusions'
// };

const LeftNavigation = () => {
	const navigate = useNavigate();
	const { pageKey = 'top', planId = '', tabKey } = useParams();
	const {
		genflowId,
		optimizationId,
		data,
		targetPlanId,
		status,
		isInitializationCompleted,
		isBeforeEvaluationInvoked,
		isBeforeAltEvaluationInvoked,
		isAllCompleted,
		userInputSetList
	} = useContext(GenflowOptimizeContext);

	const showSuggestionToGoMetrics = pageKey === 'input'
		&& isInitializationCompleted
		&& isBeforeEvaluationInvoked
		&& userInputSetList?.filter(item => item.is_approved === true)?.length === data?.metadata?.targetUserInputCount
		&& (!data?.metadata?.evaluationModules || Object.keys(data?.metadata?.evaluationModules)?.length === 0);

	return (
		<div className='optimize-left-navigation-container'>
			<ConfigProvider
				theme={{
					components: {
						Menu: {
							activeBarBorderWidth: 0
						},
					},
				}}
			>
				<Flex vertical gap='middle'>
					<MenuContainer title='About'>
						<Metadata />
					</MenuContainer>
					<MenuContainer title='Steps'>
						<Menu
							style={{ fontWeight: 500 }}
							theme='light'
							items={[
								{
									key: 'initialize',
									label: <MenuItem title='Initialize' index={1} completed={isInitializationCompleted} />,
								},
								{
									key: 'input',
									label: <MenuItem title='Create Input Dataset' index={2} completed={status && !isBeforeEvaluationInvoked} />,
								},
								{
									key: 'metrics',
									label: (
										<Tooltip title={showSuggestionToGoMetrics ? 'You finished preparing input dataset? Go create eval metrics' : null} placement='bottom' color='var(--teammately-orange)' open={showSuggestionToGoMetrics}>
											<MenuItem title='Create Eval Metrics' index={3} completed={status && !isBeforeEvaluationInvoked} />
										</Tooltip>
									)
								},
								{
									key: targetPlanId ? `experiments/${targetPlanId}` : 'experiments',
									label: <MenuItem title='Evaluate Target Plan' index={4} completed={status && !isBeforeAltEvaluationInvoked} />
								},
								{
									key: 'recommendations',
									label: <MenuItem title='Review Alternative Plans' index={5} completed={status && !isBeforeAltEvaluationInvoked} />
								},
								{
									key: 'experiments/comparison',
									label: <MenuItem title='Evaluate Alternative Plans' index={6} completed={isAllCompleted} />
								},
								{
									key: 'conclusions',
									label: <MenuItem title='Optimize Conclusions' index={7} completed={isAllCompleted} />
								},
							]}
							selectedKeys={[planId ? [pageKey, planId].join('/') : pageKey]}
							onClick={(v) => navigate(`/genflows/${genflowId}/optimize/${optimizationId}/${v.keyPath?.[0]}`)}
						/>
					</MenuContainer>
					<MenuContainer title='Results'>
						<Menu
							style={{ fontWeight: 500 }}
							theme='light'
							items={[
								{
									key: 'experiments',
									label: <MenuItem title='Overview' icon={<HomeOutlined style={iconStyles} />} />,
									// label: <MenuItem title='Progress Dashboard' icon={<HourglassOutlined style={iconStyles} />} />,
								},
								{
									key: 'recommendations',
									label: <MenuItem title='Recommended Plans' icon={<PartitionOutlined style={iconStyles} />} />,
								},
								{
									key: 'experiments/comparison/logs',
									label: <MenuItem title='Logs' icon={<FileSearchOutlined style={iconStyles} />} />
								},
								{
									key: 'experiments/comparison/scores',
									label: <MenuItem title='Scores' icon={<LineChartOutlined style={iconStyles} />} />
								},
								{
									key: 'conclusions',
									label: <MenuItem title='Final Report' icon={<FileDoneOutlined style={iconStyles} />} />,
								},
							]}
							selectedKeys={[planId === 'comparison' ? [pageKey, planId, tabKey].join('/') : planId ? [pageKey, planId].join('/') : pageKey]}
							onClick={(v) => {
								navigate(`/genflows/${genflowId}/optimize/${optimizationId}/${v.keyPath?.[0]}`);
							}}
						/>
					</MenuContainer>
				</Flex>
			</ConfigProvider>
		</div>
	);
};

const MenuContainer = ({ title, children }) => {
	return (
		<Flex vertical gap='small' style={{ marginRight: 10 }}>
			<span style={{ fontSize: 14, fontWeight: 600, color: '#666666' }}>{title?.toUpperCase()}</span>
			{children}
		</Flex>
	);
};

const MenuItem = ({ title, index, icon, completed, suggestionTooltip }) => {
	return (
		<Tooltip title={title} color='var(--tooltip-background)' placement='right'>
			<Flex gap='small' align='center' justify='space-between'>
				<Flex gap='small' align='center'>
					{icon ? icon
						: index ? (
							<Flex align='center' style={{ width: 20 }}>
								<Icon component={numberedIconsMap[index]} style={iconStyles} />
							</Flex>
						) : null
					}
					<Ellipsis name={title} threshold={completed ? 22 : 100} />
				</Flex>
				{completed && (
					<CheckCircleFilled style={{ fontSize: 16, color: 'var(--primary-color)' }} />
				)}
			</Flex>
		</Tooltip>
	);
};

const Metadata = () => {
	const isInitialLoad = useRef(true);
	const { genflowId } = useContext(GenflowDetailContext);
	const { targetPlanId, targetPlanData, targetPlanLoading, data, status, operateOptimize } = useContext(GenflowOptimizeContext);
	const [localName, setLocalName] = useState();
	const [planHovered, setPlanHovered] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isInitialLoad.current && !targetPlanLoading && targetPlanData) {
			isInitialLoad.current = false;
		}
	}, [targetPlanData]);

	useEffect(() => {
		if (data?.name) {
			setLocalName(data?.name);
		}
	}, [data?.name]);

	const handleEdit = (value) => {
		setLocalName(value);
		operateOptimize({ key: 'name', value }, 'PATCH');
	};
	
	return (
		<Flex vertical gap='middle' style={{ borderRadius: 10, backgroundColor: '#F9F9F9', padding: 10, marginRight: 5 }}>
			<MetadataItem title='Optimize title'>
				<Typography.Text
					editable={{
						onChange: handleEdit
					}}
				>
					{localName || 'Untitled optimize'}
				</Typography.Text>
			</MetadataItem>
			{(status && status !== 'INITIALIZATION') && (
				<MetadataItem title='Target plan'>
					{isInitialLoad.current ? (
						<Skeleton.Input active size='small' />
					) : (
						<Tag
							color='volcano'
							onClick={() => navigate(`/genflows/${genflowId}/develop/${targetPlanId}`)}
							onMouseEnter={() => setPlanHovered(true)}
							onMouseLeave={() => setPlanHovered(false)}
							style={{ cursor: 'pointer' }}
						>
							<Flex justify='space-between'>
								<Ellipsis name={targetPlanData?.name || '(Untitled plan)'} threshold={planHovered ? 25 : 35} />
								{planHovered && <ArrowRightOutlined />}
							</Flex>
						</Tag>
					)}
				</MetadataItem>
			)}
			<MetadataItem title='Collaborators'>
				<Editors editors={data?.editors || [data?.updated_by]} />
			</MetadataItem>
		</Flex>
	);
};

const MetadataItem = ({ title, children }) => {
	return (
		<Flex vertical gap='small'>
			<span style={{ fontSize: 12, fontWeight: 600, color: '#666666' }}>{title?.toUpperCase()}</span>
			{children}
		</Flex>
	);
};

export default LeftNavigation;
