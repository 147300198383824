import { useState, useContext } from 'react';
import { Button, Flex, Form, Input, message, Popconfirm, Table, Typography, Tag, Modal, Popover, Radio, Space } from 'antd';
import { AddModal } from '../../../../components/molecules';
import { DownOutlined, UpOutlined, UserAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../context/GlobalContext';
import AdminContext from '../../contexts/Context';
import { CardContainer } from '../../components';

const Members = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { userRole, pushMessage } = useContext(GlobalContext);
	const { workspaceData, workspaceLoading, handleWorkspaceMemberRequest, handleWorkspaceInvitationRequest } = useContext(AdminContext);
	const userIsAuthorized = !workspaceLoading && ['owner', 'admin'].includes(userRole);

	let columns = [
		{
			title: 'User Name',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
		}
	];

	if (userIsAuthorized) {
		columns.push(
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
			}
		);
	}

	const EditRoleButton = ({ member }) => {
		const [open, setOpen] = useState(false);
		const [value, setValue] = useState(member.role);

		const updateAdminStatus = async () => {
			pushMessage('Updating role...');
			const response = await handleWorkspaceMemberRequest(
				'PUT',
				member.userId,
				{ role: value }
			);

			if (response?.success) {
				pushMessage('Role updated', 'success');
			} else {
				pushMessage('Updated failed', 'error');
			}
		};

		return (
			<Popover
				open={open}
				onOpenChange={(v) => setOpen(v)}
				trigger='click'
				placement={'bottomLeft'}
				title={t('button.change-role')}
				content={(
					<Flex vertical gap='middle'>
						<Radio.Group defaultValue={value} onChange={(e) => setValue(e?.target?.value)}>
							<Flex vertical gap='small'>
								<Radio value='admin'>{t('member-role-admin')}</Radio>
								<Radio value='member'>{t('member-role-member')}</Radio>
								<Radio value='viewer'>{t('member-role-viewer')}</Radio>
							</Flex>
						</Radio.Group>
						<Flex gap='small'>
							<Button size='small' onClick={() => setOpen(false)}>{t('cancel')}</Button>
							<Button size='small' type='primary' onClick={updateAdminStatus}>{t('button.save')}</Button>
						</Flex>
					</Flex>
				)}
			>
				<Typography.Link>
					{t('button.change-role')} {open ? <UpOutlined /> : <DownOutlined />}
				</Typography.Link>
			</Popover>
		);
	};

	const ActionComponent = ({ member }) => (
		<Flex gap='middle'>
			{(member.role !== 'owner' && member.status !== 'invited') && (
				<>
					<EditRoleButton member={member} />
					<Popconfirm
						title={t('member-remove-popconfirm-title')}
						okText={t('member-remove-popconfirm-ok')}
						okButtonProps={{ danger: true }}
						onConfirm={() => {
							handleWorkspaceMemberRequest('DELETE', member.userId);
						}}
						cancelText={t('cancel')}
					>
						<Typography.Link type='danger'>
							{t('remove-user-button')}
						</Typography.Link>
					</Popconfirm>
				</>
			)}
			{member.status === 'invited' && (
				<>
					<Popconfirm
						title={t('member-invitation-revoke-popconfirm-title')}
						okText={t('member-invitation-revoke-popconfirm-ok')}
						okButtonProps={{ danger: true }}
						onConfirm={() => {
							handleWorkspaceInvitationRequest('DELETE', member.invitationCode);
						}}
						cancelText={t('cancel')}
					>
						<Typography.Link type='danger'>
							{t('revoke-invitation-button')}
						</Typography.Link>
					</Popconfirm>
					<Typography.Link onClick={() => informInvitationLink(member.invitationCode)}>
						{t('invitation-link-button')}
					</Typography.Link>
				</>
			)}
		</Flex>
	);

	const dataSource = workspaceData?.members?.map((member) => ({
		...member,
		key: member.userId || member.invitationCode,
		userName: member.user_name || '(Not set)',
		status: <Tag color='success' bordered={false}>Active</Tag>,
		// status: member.status === 'active'
		// 	? <Tag color='success' bordered={false}>{t('member-status-active')}</Tag>
		// 	: member.status === 'invited'
		// 		? <Tag color='warning' bordered={false}>{t('member-status-invited')}</Tag>
		// 		: member.status === 'declined'
		// 			? <Tag color='error' bordered={false}>{t('member-status-declined')}</Tag>
		// 			: member.expiredAt < new Date()
		// 				? <Tag color='error' bordered={false}>{t('member-status-expired')}</Tag>
		// 				: <Tag color='error' bordered={false}>{t('member-status-unknown')}</Tag>,
		role: <Tag color='green' bordered={false}>Owner</Tag>,
		// role: member.role === 'owner'
		// 	? <Tag color='green' bordered={false}>{t('member-role-owner')}</Tag>
		// 	: member.role === 'admin'
		// 		? <Tag color='cyan' bordered={false}>{t('member-role-admin')}</Tag>
		// 		: member.role === 'member'
		// 			? <Tag color='blue' bordered={false}>{t('member-role-member')}</Tag>
		// 			: <Tag bordered={false}>{t('member-role-viewer')}</Tag>,
		action: userIsAuthorized ? <ActionComponent member={member} /> : null,
	}));

	const informInvitationLink = (invitationCode) => {
		Modal.success({
			title: t('invite-member-modal-success-title'),
			content: (
				<Flex vertical style={{ paddingBottom: 10 }}>
					<Typography.Paragraph>
						{t('invite-member-modal-success-description')}
					</Typography.Paragraph>
					<Typography.Text keyboard copyable style={{ fontSize: 16 }}>
						{`https://console.lang.teammate.as/auth/join-workspace?invitationCode=${invitationCode}`}
					</Typography.Text>
				</Flex>
			),
			onOk() {},
			width: 600,
			maskClosable: true,
		});
	};

	return (
		<>
			<CardContainer
				title='Workspace members'
			>
				<Flex vertical gap='middle'>
					<Flex gap='small' align='center'>
						<Button type='primary' size='large' icon={<UserAddOutlined />} onClick={() => setOpen(true)}>
						Invite human teammate
						</Button>
					</Flex>
					<Table
						loading={workspaceLoading}
						columns={columns}
						dataSource={dataSource}
						rowKey={(record) => record.userId}
					/>
				</Flex>
			</CardContainer>
			<AddModal
				open={open}
				title={t('invite-member-button')}
				onSubmit={async (values) => {
					const response = await handleWorkspaceMemberRequest('POST', null, values);
					if (response?.success) {
						setOpen(false);
						informInvitationLink(response?.invitationCode);
						pushMessage(`An invitation mail is sent to ${values.email} . Tell them to check their inbox.`, 'success');
					} else if (response?.reason?.startsWith('The requested email ')) {
						pushMessage(response?.reason, 'error');
					} else {
						pushMessage('Error inviting user', 'error');
					}
				}}
				onCancel={() => setOpen(false)}
				okText={t('invite-member-ok')}
				loading={workspaceLoading}
			>
				<Form.Item
					label={t('email-address')}
					name="email"
					rules={[
						{
							required: true,
							message: 'Please input an email address',
						},
						{
							type: 'email',
							message: 'Please input a valid email address',
						},
					]}
				>
					<Input
						size='large'
						variant='filled'
						allowClear
						style={{
							width: '100%',
						}}
						placeholder={t('email-address')}
					/>
				</Form.Item>
			</AddModal>
		</>
	);
};

export default Members;
