import { useContext } from 'react';
import { Skeleton, Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import GlobalContext from '../../../context/GlobalContext';

const DEFAULT_ICON_SRC = '/images/avatar-circle-color-orange.png';

const Editors = ({ editors, loading }) => {
	const { workspace, workspaceLoading } = useContext(GlobalContext);
	const members = workspace?.members;

	return (loading || workspaceLoading) ? (
		<Skeleton.Avatar active />
	) : (
		<Avatar.Group
			max={{
				count: 2,
				style: {
					color: 'var(--primary-color)',
					backgroundColor: 'var(--primary-color-background)',
				},
			}}
		>
			{(editors && editors?.length > 0) && editors?.map((editor, index) => {
				const isAi = editor === 'ai';
				const editorMetadata = members?.find(member => member.user_id === editor);

				return (
					<Tooltip
						key={editor}
						title={isAi ? 'AI Generated' : editorMetadata?.user_name}
						placement='top'
						color='var(--tooltip-background)'
					>
						<Avatar
							src={isAi ? '/images/ai.png' : (editorMetadata?.avatar_url || DEFAULT_ICON_SRC)}
							icon={<UserOutlined/>}
						/>
					</Tooltip>
				);
			})}
		</Avatar.Group>
	);
};

export default Editors;
