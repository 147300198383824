import { Flex, Tag, Tooltip } from 'antd';
import Icon, { CheckOutlined, CloseOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { MotionDiv, AbsoluteTime } from '../../../../../../../components/atoms';
import '../index.css';
import GenflowOptimizeContext from '../../../../../../../context/genflowOptimize/Context';
import { BsStars } from 'react-icons/bs';
import { GrDocumentCsv } from 'react-icons/gr';

const Card = (props) => {
	const { animationIndex, optimization_dataset_record_id, created_at, input, source_key, is_approved, disabled } = props;
	const { operateDatasetApproval } = useContext(GenflowOptimizeContext);
	const [localIsApproved, setLocalIsApproved] = useState(is_approved);
	const [isRecent, setIsRecent] = useState(false);

	const handleApproval = (v) => {
		if (!disabled) {
			operateDatasetApproval([optimization_dataset_record_id], v);
			setLocalIsApproved(v);
		}
	};

	useEffect(() => {
		if (is_approved !== localIsApproved) {
			setLocalIsApproved(is_approved);
		}
	}, [is_approved]);

	useEffect(() => {
		if (created_at) {
			const createdAtDate = new Date(created_at).getTime();
			const now = Date.now();
			const diffInSeconds = (now - createdAtDate) / 1000;

			setIsRecent(diffInSeconds <= 15);
			
			const timer = setTimeout(() => {
				setIsRecent(false);
			}, (10 - diffInSeconds) * 1000);

			return () => clearTimeout(timer);
		}
	}, [created_at]);

	const sourceDisplayMap = {
		generations: {
			icon: <Icon component={BsStars} />,
			message: 'AI Generated'
		},
		log: {
			icon: <Icon component={GrDocumentCsv} />,
			message: 'Upload'
		},
		upload: {
			icon: <UploadOutlined />,
			message: 'Upload'
		},
		manual: {
			icon: <EditOutlined />,
			message: 'Manual'
		}
	};

	return (
		<MotionDiv
			key={optimization_dataset_record_id}
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3, ease: 'easeInOut', delay: animationIndex * 0.03 }}
		>
			<div
				className={`user-input-card${disabled ? '-disabled' : ''}${localIsApproved === false ? ' user-input-card-disapproved' : ''}${isRecent ? ' user-input-card-recent' : ''}`}
				onClick={() => {
					if (!disabled) {
						setLocalIsApproved(!localIsApproved);
					}
				}}
			>
				<Flex gap='middle' align='center'>
					<CardSwitch value={localIsApproved} onChange={handleApproval} disabled={disabled} />
					<Flex vertical gap='small' style={{ width: '100%' }}>
						<Flex align='center' justify='flex-start'>
							<Tag color='blue' bordered={false} icon={sourceDisplayMap[source_key]?.icon}>
								{sourceDisplayMap[source_key]?.message}
							</Tag>
							<span style={{ color: '#999999', fontSize: 12 }}>{created_at && <AbsoluteTime datetime={created_at} />}</span>
						</Flex>
						{Object.keys(input)?.map((key) => (
							<Flex key={key} align='center'>
								<Tag style={{ fontWeight: 600 }}>{key}</Tag>
								<span className='user-input-label'>
									{input[key]}
								</span>
							</Flex>
						))}
					</Flex>
				</Flex>
			</div>
		</MotionDiv>
	);
};

const CardSwitch = ({ value, onChange, disabled }) => {
	return (
		<Tooltip title={disabled ? '[LOCKED] You cannot modify after evaluation.' : null} color='var(--tooltip-background)' >
			<Flex gap='small'>
				<div
					className={`input-button ${value === false ? 'input-button-false-selected' : 'input-button-unselected'}`}
					onClick={(e) => {
						e.stopPropagation();
						onChange(value === false ? null : false);
					}}
				>
					<CloseOutlined className={`input-button-icon ${value === false ? 'input-button-icon-selected' : 'input-button-icon-unselected'}`} />
				</div>
				<div
					className={`input-button ${value === true ? 'input-button-true-selected' : 'input-button-unselected'}`}
					onClick={(e) => {
						e.stopPropagation();
						onChange(value === true ? null : true);
					}}
				>
					<CheckOutlined className={`input-button-icon ${value === true ? 'input-button-icon-selected' : 'input-button-icon-unselected'}`} />
				</div>
			</Flex>
		</Tooltip>
	);
};

export default Card;
