import { countries } from 'country-data';

export default countries.all
	.filter(({ status }) => status === 'assigned')
	.map(({ name, alpha2 }) => {
		if (alpha2 === 'HK') {
			return { label: 'China (Hong Kong SAR)', value: alpha2 };
		} else if (alpha2 === 'MO') {
			return { label: 'China (Macao SAR)', value: alpha2 };
		} else if (alpha2 === 'TW') {
			return { label: 'China (Taiwan Province)', value: alpha2 };
		}
		return { label: name, value: alpha2 };
	})
	.sort((a, b) => a.label?.localeCompare(b.label));
