import { Button, Cascader, Divider, Flex, Skeleton, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import GlobalAuthContext from '../../../context/GlobalAuthContext';

const ModelCascader = (props) => {
	const {
		types = ['chat'],
		onChange = () => {},
		value,
		...restProps
	} = props;

	const { t } = useTranslation();
	const [cascaderVisible, setCascaderVisible] = useState(false);
	const { chatModelList, chatModelLoading } = useContext(GlobalAuthContext);
	const { embeddingModelList, embeddingModelLoading } = useContext(GlobalAuthContext);
	const [localValue, setLocalValue] = useState([]);

	const modelList = useMemo(() => {
		if (types.includes('chat')) {
			return chatModelList;
		} else if (types.includes('embedding')) {
			return embeddingModelList;
		}
	}, [types, chatModelList, embeddingModelList]);

	const modelLoading = useMemo(() => {
		if (types.includes('chat')) {
			return chatModelLoading;
		} else if (types.includes('embedding')) {
			return embeddingModelLoading;
		}
	}, [types, chatModelLoading, embeddingModelLoading]);

	const options = modelList?.map((platform) => ({
		label: platform?._id === 'system-infer' ? 'Test Inference by Teammately' : `${t('platform.' + platform?.metaPlatformId)} (${platform?.platformConnectionName})`,
		value: platform._id,
		children: platform.metaProviders?.map((metaProvider) => ({
			label: t(`provider.${metaProvider?._id?.split('/')[1]}`),
			value: metaProvider._id,
			children: metaProvider.metaModels.map((metaModel) => ({
				label: metaModel.name,
				value: metaModel._id,
			})),
		})),
	}))
		.filter((platform) => {
			return platform?.children?.some((metaProvider) => {
				return metaProvider?.children?.length > 0;
			});
		});

	const valueFormatted = (value && value?.platformId) ? [
		value?.platformId,
		value?.metaProviderId,
		value?.metaModelId,
	] : [];

	useEffect(() => {
		if (value && value?.platformId) {
			setLocalValue([
				value?.platformId,
				value?.metaProviderId,
				value?.metaModelId,
			]);
		} else {
			setLocalValue([]);
		}
	}, [value]);

	const handleChange = (v) => {
		setLocalValue(v);
		
		const formattedValues = {
			platformId: v?.[0],
			metaPlatformId: v?.[1]?.split('/')?.[0],
			metaProviderId: v?.[1],
			metaModelId: v?.[2],
		};
		onChange(formattedValues);
	};

	return modelLoading && valueFormatted?.length === 3 ? (
		<Skeleton.Input size='large' style={{ width: '100%' }} active />
	) : (
		<>
			<Cascader
				{...restProps}
				placeholder='Choose model'
				open={cascaderVisible}
				onDropdownVisibleChange={(visible) => setCascaderVisible(visible)}
				options={options}
				value={localValue}
				onChange={handleChange}
				allowClear
				showSearch
				dropdownRender={(menu) => (
					<>
						<div style={{ margin: 15 }}>
							<Typography.Text type='secondary'>
								{t('title.model-cascader')}
							</Typography.Text>
						</div>
						<Divider
							style={{
								margin: '8px 0',
							}}
						/>
						{menu}
						<Divider
							style={{
								margin: '8px 0',
							}}
						/>
						<div
							style={{
								padding: 8,
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<Flex gap='small' justify='center' align='center' vertical>
								To use another model, create a new platform connection.
								<Flex gap='small' justify='center' align='center'>
									<Button
										type='primary'
										size='small'
										icon={<PlusOutlined />}
										onClick={() => window.location.href = '/platform-connections?openModal=true'}
									>
										Add platform connection
									</Button>
								</Flex>
							</Flex>
						</div>
					</>
				)}
			/>
		</>
	);
};

export default ModelCascader;
