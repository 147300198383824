import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Dropdown, Avatar, Typography, Skeleton } from 'antd';
import GlobalContext from '../../../../../../context/GlobalContext';

const DropdownWithAvatar = () => {
	const [opening, setOpening] = useState(false);
	const { user, redirectToAuth } = useContext(GlobalContext);
	const navigate = useNavigate();

	const handleLogout = () => {
		redirectToAuth();
	};

	const menuItems = [
		{
			label: (
				<Flex vertical gap='small' style={{ padding: '5px 0' }} onClick={() => navigate('/settings/user')}>
					<Flex gap='middle' align='center' justify='start' >
						<UserAvatar />
						<Typography.Text strong>
							{user?.name}
						</Typography.Text>
					</Flex>
				</Flex>
			),
			key: 'user-avatar',
		},
		{
			type: 'divider',
		},
		{
			label: (
				<a onClick={() => navigate('/settings/user')}>
					Personal settings
				</a>
			),
			key: 'personal',
		},
		{
			label: (
				<a onClick={() => navigate('/settings/workspace/config')}>
					Workspace settings
				</a>
			),
			key: 'workspace',
		},
		{
			type: 'divider',
		},
		{
			label: (
				<a onClick={() => handleLogout()}>
					Sign out
				</a>
			),
			key: 'sign-out',
			danger: true,
		},
	];

	return (
		<Dropdown
			menu={{
				items: menuItems,
			}}
			placement="bottomRight"
			trigger="hover"
			onOpenChange={(open) => setOpening(open)}
		>
			<a onClick={(e) => e.preventDefault()}>
				<div style={{
					padding: '4px',
					backgroundColor: opening ? '#ededed' : 'transparent',
					borderRadius: '50%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					transition: 'background-color 0.2s ease-in-out',
				}}>
					<UserAvatar />
				</div>
			</a>
		</Dropdown>
	);
};

const DEFAULT_ICON_SRC = '/images/avatar-circle-color-orange.png';
const UserAvatar = () => {
	const { user, userLoading, workspace, workspaceLoading } = useContext(GlobalContext);
	const userId = user.uid;
	const workspaceMembers = workspace?.members;
	const [avatarSrc, setAvatarSrc] = useState(null);
	const isInitialLoad = useRef(true);

	useEffect(() => {
		if (!userLoading && userId && !workspaceLoading && workspaceMembers && workspaceMembers?.length > 0 && isInitialLoad.current) {
			const userMetadata = workspaceMembers?.find(m => m.user_id === userId);
			const userCustomSrc = userMetadata?.['avatar_url'];
			if (userCustomSrc) {
				setAvatarSrc(userCustomSrc);
			} else {
				setAvatarSrc(DEFAULT_ICON_SRC);
			}
			isInitialLoad.current = false;
		}
	}, [userId, workspaceMembers]);

	return <Avatar size='large' src={avatarSrc || DEFAULT_ICON_SRC} />;
};

export default DropdownWithAvatar;
