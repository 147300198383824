import { useContext, useEffect, useState } from 'react';
import { useChannel } from 'ably/react';
import GlobalContext from '../../../../../context/GlobalContext';
import KnowledgebookContext from '../../contexts/Context';
import { Alert, ConfigProvider, Empty, Flex, Timeline } from 'antd';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ImageContainer } from '../../../../atoms';
import './index.css';
import GenflowPlanContext from '../../../../../context/genflowPlan/Context';

const eventMessageMap = {
	INITIALIZATION: 'Process Started',
	CHUNKING: {
		success: 'Chunking Completed',
		error: 'Chunking Failed'
	},
	EMBEDDING: {
		success: 'Embedding Completed',
		error: 'Embedding Failed'
	},
	DATABASE_INSERTION: 'Database Ready',
	COMPLETION: 'Process Completed',
	PROCESS_CLOSED: 'Process Closed'
};

const Status = () => {
	const { uniqueClientId } = useContext(GlobalContext);
	const { compiledPlanRefresh } = useContext(GenflowPlanContext);
	const { knowledgebookId } = useContext(KnowledgebookContext);

	const [messages, updateMessages] = useState([]);
	useChannel(uniqueClientId || 'no-client', (message) => {
		if (knowledgebookId && message.name === `knowledge_indexing:${knowledgebookId}`) {
			updateMessages((prev) => message.data?.event === 'INITIALIZATION' ? [message] : [...prev, message]);
		}

		if (message.data?.event === 'COMPLETION') {
			compiledPlanRefresh();
		}
	});

	return (
		<Flex vertical gap='middle' style={{ border: '1px solid lightgray', padding: 10, borderRadius: '10px' }}>
			<span style={{ fontWeight: 600 }}>Indexing progress</span>
			{messages.length > 0 ? (
				<ConfigProvider
					theme={{
						components: {
							Timeline: {
								itemPaddingBottom: 0
							}
						}
					}}
				>
					<Timeline
						pending={!messages.some(message => message.data?.event === 'COMPLETION') && !messages.some(message => message.data?.type === 'ERROR')}
						items={messages?.map((message, index) => ({
							...(message.data?.event === 'COMPLETION' ? { dot: <CheckCircleOutlined/> } : {}),
							color: message.data?.type === 'SUCCESS' ? 'green' : message.data?.type === 'ERROR' ? 'red' : 'gray',
							children: (
								<motion.div
									key={message.id}
									initial={{ opacity: 0, x: 30 }}
									animate={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.3 }}
									style={{ paddingBottom: index === messages.length - 1 ? 0 : 10 }}
								>
									<Flex gap='small' align='center'>
										<span>
											{eventMessageMap[message.data?.event]?.[message.data?.type?.toLowerCase()] || eventMessageMap[message.data?.event] || ''}
										</span>
										<span style={{ fontSize: 12, color: '#555555' }}>
											{dayjs(message.timestamp).format('YYYY-MM-DD HH:mm:ss')}
										</span>
									</Flex>
								</motion.div>
							)
						}))}
					/>
				</ConfigProvider>
			)
				:
				<Empty />
			}
			{messages.some(message => message.data?.type === 'ERROR') && (
				<motion.div
					key='error'
					initial={{ opacity: 0, x: 30 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 0.3 }}
				>
					<Alert
						message='Error'
						description='Error happened while indexing. Please try again later, or contact team.'
						type='error'
						showIcon
						icon={<ImageContainer src='/images/sorry.png' alt='Sorry' width={150} />}
						closable
					/>
				</motion.div>
			)}
		</Flex>
	);
};

export default Status;