import Presentation from './Presentation';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthData, useAuthRequest } from '../../hooks';

const Container = (props) => {
	const { genflowId } = useParams();

	const location = useLocation();
	const pathArr = location.pathname.split('/');
	const currentLocalDirectory = pathArr[3] || 'root';

	// eslint-disable-next-line no-undef
	const endpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}`;
	const { data, loading, refresh } = useAuthData(endpoint);
	
	const { makeRequest } = useAuthRequest();
	const [ requestLoading, setRequestLoading ] = useState(false);

	const operateGenflow = async (payload, method) => {
		setRequestLoading(true);
		const response = await makeRequest(endpoint, method, payload);
		await refresh();
		setRequestLoading(false);

		return response;
	};

	return <Presentation
		{...props}
		currentLocalDirectory={currentLocalDirectory}
		data={{
			genflow: data?.data?.rows?.[0]
		}}
		loading={{
			genflow: loading,
			requests: requestLoading,
		}}
		refresh={{
			genflow: refresh
		}}
		operate={{
			genflow: operateGenflow
		}}
	/>;
};

export default Container;
