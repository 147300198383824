import { MotionDiv } from '../../../../../components/atoms';

const Modal = ({ isVisible, children }) => {
	if (!isVisible) return null;

	return (
		<div style={{
			position: 'absolute',
			top: '0',
			left: '260px',
			right: '0',
			bottom: '0',
			height: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			backdropFilter: 'blur(1px)',
			// display: 'flex',
			// alignItems: 'center',
			// justifyContent: 'center'
		}}>
			<MotionDiv>
				<div style={{
					position: 'relative',
					width: '75%',
					padding: '20px',
					margin: '20% auto',
					background: 'white',
					borderRadius: '10px',
					boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
				}}>
					{children}
				</div>
			</MotionDiv>
		</div>
	);
};

export default Modal;
