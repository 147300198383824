import { createContext } from 'react';

const GenflowOptimizeContext = createContext({
	genflowId: null,
	optimizationId: null,
	optimizeData: {},
	optimizeLoading: true,
	refresh: () => {},
	status: null,
	isInitializationCompleted: false,
	isBeforeEvaluationInvoked: true,
	isBeforeAltEvaluationInvoked: true,
	isAllCompleted: false,
	hasReachedMaxModuleCount: false,
	targetPlanId: null,
	targetPlanData: {},
	targetPlanLoading: true,
	targetCompiledPlanData: {},
	targetCompiledPlanLoading: true,
	userInputSetList: [],
	userInputSetLoading: true,
	setUserInputSetSort: () => {},
	setUserInputSetFilter: () => {},
	userInputSetRefresh: () => {},
	userInputSetGenerationsList: [],
	userInputSetGenerationsLoading: true,
	userInputSetGenerationsRefresh: () => {},
	userInputSetGenerationsStatusList: [],
	userInputSetGenerationsStatusLoading: true,
	userInputSetGenerationsStatusRefresh: () => {},
	targetOperationList: [],
	targetOperationLoading: true,
	targetOperationRefresh: () => {},
	plansList: [],
	plansLoading: true,
	plansRefresh: () => {},
	customMetricsList: [],
	customMetricsLoading: true,
	customMetricsRefresh: () => {},
	metricsData: [],
	metricsLoading: true,
	operateOptimize: () => {},
	updateStatus: () => {},
	operateDatasetManualAdd: () => {},
	operateDatasetApproval: () => {},
	convertRecommendationToPlan: () => {},
	invokeEvaluation: () => {},
	metricGenerationWatchingList: [],
	setMetricGenerationWatchingList: () => {}
});

export default GenflowOptimizeContext;
