import GenflowListProvider from '../../../context/genflowList/Provider';
import GenflowDetailProvider from '../../../context/genflowDetail/Provider';
import Container from './Container';

const GenflowDetail = () => {
	return (
		<GenflowListProvider>
			<GenflowDetailProvider>
				<Container/>
			</GenflowDetailProvider>
		</GenflowListProvider>
	);
};

export default GenflowDetail;
