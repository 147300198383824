import { useContext } from 'react';
import { Alert, Flex } from 'antd';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';

const ConfigHint = () => {
	const { remainingTasks } = useContext(GenflowPlanContext);

	return (
		<Alert
			message='Hints for development'
			type='error'
			description={(
				<Flex gap='small' vertical>
					<span style={{ fontWeight: 600 }}>You have {remainingTasks?.length} remaining tasks to proceed.</span>
					<ul className='config-hint-list'>
						{remainingTasks?.map((task, index) => {
							return (
								<li key={index}>
									{task?.message}
								</li>
							);
						})}
					</ul>
				</Flex>
			)}
		/>
	);
};

export default ConfigHint;
