import { Flex } from 'antd';
import { MotionDiv } from '../../../../components/atoms';
import './index.css';

const CardContainer = ({ children, title, description, type = 'white', extra, animationIndex, style }) => {
	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex vertical gap='middle' className={`top-card-container top-card-background-${type}`} style={style}>
				<Flex align='center' justify='space-between'>
					<Flex gap='large' align='center' justify='flex-start'>
						<h2 className='top-card-title'>{title}</h2>
						{description && <span className='top-card-description'>{description}</span>}
					</Flex>
					{extra}
				</Flex>
				{children}
			</Flex>
		</MotionDiv>
	);
};

export default CardContainer;
