import { useOutlet, ScrollRestoration } from 'react-router-dom';
import { ConfigProvider, Layout, theme } from 'antd';
import Header from './Header';

const DefaultLayout = () => {
	const outlet = useOutlet();
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimaryBg: '#EAEDFA',
					colorPrimary: '#3857D3',
					colorInfo: '#3857D3',
					colorLink: '#3857D3',
					colorInfoText: '#3857D3',
					fontSizeHeading2: '24px',
					lineHeightHeading2: '30px'
				},
				components: {
					Table: {
						rowHoverBg: '#EAEDFA',
					}
				}
			}}
		>
			<Layout
				style={{
					paddingTop: 50,
					background: colorBgContainer,
				}}
			>
				<Header />
				{outlet}
			</Layout>
			<ScrollRestoration />
		</ConfigProvider>
	);
};

export default DefaultLayout;
