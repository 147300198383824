import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InpageEditor } from '../index';
import CustomMetricProvider from './context/Provider';
import CustomMetricCard	from './CustomMetricCard';
import CustomMetricEditor from './CustomMetricEditor';

const MetricCardCustom = (props) => {
	const { outputKey, metricId } = props;
	const [open, setOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('metricId') === metricId) {
			setOpen(true);
		}
	}, [searchParams.get('metricId')]);

	const handleOpen = () => {
		setOpen(true);
		setSearchParams({ ...searchParams, metricId });
	};

	const handleClose = () => {
		setOpen(false);
		const newSearchParams = {};
		Object.keys(searchParams)?.forEach(key => {
			if (key !== 'metricId') {
				newSearchParams[key] = searchParams[key];
			}
		});
		setSearchParams(newSearchParams);
	};

	return (
		<CustomMetricProvider outputKey={outputKey} metricId={metricId}>
			<CustomMetricCard
				{...props}
				handleOpen={handleOpen}
			/>
			<InpageEditor
				title='Edit custom LM-as-a-judge metric'
				open={open}
				onClose={handleClose}
			>
				<CustomMetricEditor />
			</InpageEditor>
		</CustomMetricProvider>
	);
};

export default MetricCardCustom;
