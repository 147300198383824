import { Button, Flex, Input, Modal, Popconfirm, Select } from 'antd';
import { useContext, useState } from 'react';
import PromptbookContext from '../../../contexts/Context';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';
import { useAuthRequest } from '../../../../../../hooks';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';
import { CodeEditor, Loading } from '../../../../../atoms';
// import jsonExtractor from '../../../../../../utils/jsonExtractor';

const formatOptions = [
	{ value: 'Markdown-like format (i.e. ## Instruction\\n)', label: 'Markdown-like format (i.e. ## Instruction\\n)' },
	{ value: 'XML-like format (i.e. <instruction>text</instruction>)', label: 'XML-like format (i.e. <instruction>text</instruction>)' },
	{ value: 'Llama-like format (i.e. <|begin_of_text|>text<|end_of_text|>)', label: 'Llama-like format (i.e. <|begin_of_text|>text<|end_of_text|>)' },
	{ value: 'Python-like format', label: 'Python-like format' },
	{ value: 'Simple text', label: 'Simple text' },
];

const GenerationAssistant = () => {
	const { compiledPlanData } = useContext(GenflowPlanContext);
	const { refresh, promptbookId, isOpenGenerationAssistant, setOpenGenerationAssistant, editPromptbook } = useContext(PromptbookContext);
	const [localValues, setLocalValues] = useState({ instructionText: '', formatPreference: null });
	const [candidatesLoading, setCandidatesLoading] = useState(false);
	const [candidateInDraft, setCandidateInDraft] = useState();
	const [importLoading, setImportLoading] = useState(false);

	const handleChange = (key, value) => {
		setLocalValues({ ...localValues, [key]: value });
	};

	const { makeRequest } = useAuthRequest();
	// eslint-disable-next-line no-undef
	const testGenflowEndpoint = `${process.env.REACT_APP_LANG_API}/run/s3tdOVDPS1WPmKWF9Ce9Ng/qFEqSRGZSsK3KTEmxwj7Ng`;
	const handleGeneration = async () => {
		setCandidatesLoading(true);

		const stepsSoFar = [];
		let hasReachedThisStep = false;
		let localCount = 0;
        
		while (!hasReachedThisStep && localCount < compiledPlanData?.json?.steps?.length) {
			const currentStep = compiledPlanData.json.steps[localCount];
			if (currentStep.id === promptbookId) {
				hasReachedThisStep = true;
			} else {
				stepsSoFar.push(currentStep);
				localCount++;
			}
		}

		const payload = {
			input: {
				...localValues,
				inputSchema: compiledPlanData?.json?.input_schema?.length > 0 ? JSON.stringify(compiledPlanData?.json?.input_schema?.map(schema => schema.key)) : '',
				historicalSteps: JSON.stringify(stepsSoFar?.map(({ id, name, prompt_text, type }) => ({
					id,
					title: name,
					type,
					...(type === 'promptbook' ? { prompt_text } : {} )
				}))),
			}
		};

		const response = await makeRequest(testGenflowEndpoint, 'POST', payload);
		if (response?.success) {
			const candidateRaw = response?.data?.output?.candidate;
			const candidate = candidateRaw ? candidateRaw.replace(/\[\[(.*?)\]\]/g, '{{$1}}') : '';
			setCandidateInDraft(candidate);
		}

		setCandidatesLoading(false);
	};

	const handleImportText = async () => {
		setImportLoading(true);
		await editPromptbook('promptText', candidateInDraft);
		await refresh();
		setOpenGenerationAssistant(false);
		setImportLoading(false);
	};

	return (
		<Modal
			open={isOpenGenerationAssistant}
			onCancel={() => setOpenGenerationAssistant(false)}
			centered
			footer={[]}
			title='Prompt text generation assistant'
			width={750}
		>
			<Flex vertical gap='middle'>
				<span>In this modal, you can let AI generate prompt texts on behalf given your creative objective to AI models. It also helps you find the right model to get started.</span>
				<Flex vertical gap='small'>
					<span style={{ fontWeight: 600 }}>What is your goal in this generation step?</span>
					<Input.TextArea
						variant='filled'
						placeholder='e.g. "Legal translation from Chinese to English. We need output in JSON format."'
						rows={4}
						disabled={candidatesLoading || importLoading}
						value={localValues?.instructionText}
						onChange={(v) => handleChange('instructionText', v.target.value)}
					/>
				</Flex>
				<Flex vertical gap='small'>
					<span style={{ fontWeight: 600 }}>(Optional) Any preference in prompt format?</span>
					<Select
						size='large'
						variant='filled'
						placeholder='Select prompt text format if you have any preference'
						allowClear
						disabled={candidatesLoading || importLoading}
						options={formatOptions}
						value={localValues?.formatPreference}
						onChange={(v) => handleChange('formatPreference', v)}
					/>
				</Flex>
				<Button
					block
					type={candidateInDraft ? 'default' : 'primary'}
					size='large'
					loading={candidatesLoading || importLoading}
					onClick={handleGeneration}
					icon={<Icon component={BsStars}/>}
				>
					{candidateInDraft ? 'Refresh prompt candidates' : 'Start generating prompt candidates'}
				</Button>
				{candidatesLoading ? (
					<Loading />
				) : !!candidateInDraft && (
					<Flex
						vertical
						gap='small'
						className='ai-generated-content-container'
					>
						<span style={{ fontWeight: 600, fontSize: 18 }}>Generated prompt text</span>
						<CodeEditor
							height='150px'
							language='markdown'
							defaultValue={candidateInDraft}
							options={{
								selectOnLineNumbers: true,
								wordWrap: 'on',
								wrappingIndent: 'indent',
								readOnly: true,
							}}
						/>
						<Popconfirm
							title='Use the suggested prompt text?'
							description='Your current prompt in this promptbook would be overwritten.'
							onConfirm={handleImportText}
							okText='Import'
							cancelText='Cancel'
						>
							<Button
								type='primary'
								size='large'
								loading={candidatesLoading || importLoading}
								icon={<DownloadOutlined />}
							>
								Use this prompt text
							</Button>
						</Popconfirm>
					</Flex>
				)}
			</Flex>
		</Modal>
	);
};

export default GenerationAssistant;
