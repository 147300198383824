import Icon from '@ant-design/icons';
import { ReactComponent as LogoSvg } from './icon-silhouette.svg';

const IconLogoSilhouette = (props) => {
	return (
		<Icon component={LogoSvg} {...props} />
	);
};

export default IconLogoSilhouette;
