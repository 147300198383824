import { Button, Result } from 'antd';
import { Develop, DevelopList, Observe, OptimizeList, Optimize, Overview } from './screens';
import { useLocation } from 'react-router-dom';
import './index.css';

const GenflowDetail = (props) => {
	const { data, loading, refresh, operate } = props;
	const location = useLocation();
	const pathArr = location.pathname.split('/')?.slice(3);

	return pathArr[0] === 'optimize' && pathArr?.length >= 2 ? (
		<Optimize />
	) : (
		<div className='container-body'>
			{pathArr?.length === 0 ? (
				<Overview
					data={data?.genflow}
					loading={loading?.genflow}
					operate={operate?.genflow}
				/>
			) : (pathArr[0] === 'optimize' && pathArr?.length === 1) ? (
				<OptimizeList/>
			) : (pathArr[0] === 'develop' && pathArr?.length === 1) ? (
				<DevelopList/>
			) : (pathArr[0] === 'develop' && pathArr?.length === 2) ? (
				<Develop/>
			) : (pathArr[0] === 'observe') ? (
				<Observe/>
			) : (
				<Result
					status='404'
					title='404'
					subTitle='Sorry, the page you visited does not exist.'
					extra={<Button type='primary' size='large'>Back Home</Button>}
				/>
			)}
		</div>
	);
};

export default GenflowDetail;
