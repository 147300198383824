import { useContext, useRef } from 'react';
import KnowledgebookContext from '../../../contexts/Context';
import { CodeEditor } from '../../../../../atoms';
import { Skeleton } from 'antd';

const defaultValue = '{{textInput}}';

const QueryTextEditor = ({ type }) => {
	const { data, loading, editKnowledgebook } = useContext(KnowledgebookContext);
	const debounceTimer = useRef();

	const debouncedOnChange = useRef((text) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			editKnowledgebook('queryText', text);
		}, 750);
	}).current;

	const handleChange = (text) => {
		debouncedOnChange(text);
	};

	return loading ? (
		<Skeleton active />
	) : (
		<CodeEditor
			height='50px'
			language='markdown'
			defaultValue={data?.['query_text'] || defaultValue}
			onChange={handleChange}
		/>
	);
};

export default QueryTextEditor;
