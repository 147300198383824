/**
 * Adjusts the order of elements in an array based on specified direction without altering the original array.
 * Creates a new array where the element at `currentIndex` is swapped with its adjacent element in the specified `direction`.
 *
 * @param {Array} arr - The original array whose elements are to be adjusted.
 * @param {number} currentIndex - The index of the element to swap.
 * @param {string} direction - The direction to swap ('up' for previous or 'down' for next).
 * @returns {Array} - A new array with adjusted elements, or the original array if no swap was done.
 */
const adjustArrayOrder = (arr, currentIndex, direction) => {
	if (currentIndex < 0 || currentIndex >= arr.length - 1) {
		console.error('Invalid current index.');
		return arr.slice();
	}

	let targetIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1;

	if (targetIndex < 0 || targetIndex >= arr.length) {
		console.error('Target index out of bounds.');
		return arr.slice();
	}

	let newArr = arr.slice();
	let temp = newArr[currentIndex];
	newArr[currentIndex] = newArr[targetIndex];
	newArr[targetIndex] = temp;

	return newArr;
};

export default adjustArrayOrder;
