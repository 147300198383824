import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Flex, Tag, Tooltip } from 'antd';
import {
	CloseCircleOutlined,
	EditOutlined,
	FileSearchOutlined,
	LineChartOutlined,
	PlayCircleOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Working } from './components';
import { Logs, Scores } from './screens';
import Modal from '../../Modal';
import { motion, AnimatePresence } from 'framer-motion';
import { Loading, TipsCard } from '../../../../../../components/atoms';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import ExperimentDetailContext from './contexts/Context';
import './index.css';

const allowedTabKeys = ['logs', 'scores'];

const ExperimentDetail = () => {
	const isInitialLoad = useRef(true);
	const { planId, tabKey } = useParams();
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const [invocationLoading, setInvocationLoading] = useState(false);
	const { data, userInputSetList, invokeEvaluation, status, customMetricsList, updateStatus } = useContext(GenflowOptimizeContext);
	const { statusLoading } = useContext(ExperimentDetailContext);
	const [invocationErrorMessage, setInvocationErrorMessage] = useState(null);

	useEffect(() => {
		if (!tabKey || !allowedTabKeys.includes(tabKey)) {
			navigate(`./${planId}/logs`);
		}
	}, [tabKey]);

	const direction = allowedTabKeys.indexOf(tabKey);

	const handleInvocation = async () => {
		setInvocationLoading(true);
		const response = await invokeEvaluation(planId);
		if (response.succeed) {
			await updateStatus('TARGET_PLAN_EVALUATION');
			setModalOpen(false);
		} else {
			setInvocationErrorMessage(response.message);
		}
		setInvocationLoading(false);
	};

	useEffect(() => {
		if (isInitialLoad.current && status) {
			setModalOpen(status === 'CONFIGURATION');
			isInitialLoad.current = false;
		}
	}, [status]);

	return (
		<Flex vertical gap='middle'>
			{status === 'TARGET_PLAN_EVALUATION' && (
				<Working />
			)}
			<Flex vertical>
				{statusLoading ? <Loading /> : (
					<>
						<Tabs
							activeKey={tabKey}
							onSelect={(v) => navigate(`./${planId}/${v}`)}
							items={[
								{
									key: 'logs',
									component: (
										<TabItemWrapper
											title='Logs'
											icon={<FileSearchOutlined style={{ fontSize: 20 }} />}
										/>
									)
								},
								{
									key: 'scores',
									component: (
										<TabItemWrapper
											title='Scores'
											icon={<LineChartOutlined style={{ fontSize: 20 }} />}
										/>
									)
								}
							]}
						/>
						<div className='experiment-detail-container'>
							<AnimatePresence initial={false} custom={direction}>
								{tabKey === 'logs' && (
									<TabContentWrapper tabKey='logs' direction={direction}>
										<Logs />
									</TabContentWrapper>
								)}
								{tabKey === 'scores' && (
									<TabContentWrapper tabKey='scores' direction={direction}>
										<Scores />
									</TabContentWrapper>
								)}
							</AnimatePresence>
						</div>
						<Modal isVisible={modalOpen}>
							<Flex vertical gap='middle'>
								<span style={{ fontSize: 24, fontWeight: 600 }}>Have you finished configurations?</span>
								<Flex gap='middle'>
									<div style={{ padding: 10, borderRadius: 12, border: '1px solid #DDDDDD', width: '30%' }}>
										<Flex vertical gap='middle' justify='space-between' style={{ height: '100%' }}>
											<Flex vertical gap='small' align='center'>
												<span style={{ fontWeight: 600, fontSize: 20 }}>
												INPUT DATASET
												</span>
												<span style={{ fontSize: 18 }}>
													{userInputSetList?.filter(item => item.is_approved === true)?.length} / {data?.metadata?.targetUserInputCount} patterns
												</span>
												{userInputSetList?.filter(item => item.is_approved === true)?.length < data?.metadata?.targetUserInputCount && (
													<Tag bordered={false} color='red-inverse' icon={<CloseCircleOutlined />}>NOT ENOUGH</Tag>
												)}
												{userInputSetList?.filter(item => item.is_approved === true)?.length > data?.metadata?.targetUserInputCount && (
													<Tag bordered={false} color='red-inverse' icon={<CloseCircleOutlined />}>TOO MUCH</Tag>
												)}
											</Flex>
											<Button size='small' icon={<EditOutlined />} block onClick={() => navigate('../input')} disabled={invocationLoading}>
												Back to edit
											</Button>
										</Flex>
									</div>
									<div style={{ padding: 10, borderRadius: 12, border: '1px solid #DDDDDD', width: '70%' }}>
										<Flex vertical gap='middle' justify='space-between' style={{ height: '100%' }}>
											<Flex vertical gap='small' align='center'>
												<span style={{ fontWeight: 600, fontSize: 20 }}>
													EVALUATION METRICS
												</span>
												<Flex vertical gap='small' align='flex-start' style={{ width: '100%' }}>
													{data?.metadata?.evaluationModules && Object.keys(data?.metadata?.evaluationModules)?.map((outputKey) => {
														return (
															<Flex key={outputKey}>
																<Tag bordered={false}>{outputKey}</Tag>
																<Flex gap='small' wrap>
																	{data?.metadata?.evaluationModules?.[outputKey] && Object.keys(data?.metadata?.evaluationModules?.[outputKey])?.map((metricKey) => {
																		const isCustom = metricKey.startsWith('custom/');
																		const thisCustomMetricData = isCustom ? customMetricsList?.find(metric => metric.metric_id === metricKey?.replace('custom/', '')) : {};

																		return (
																			<Tag
																				key={metricKey}
																				bordered={false}
																				color='blue'
																				style={{ margin: 0 }}
																			>
																				{isCustom ? (
																					<Flex gap='small'>
																						{thisCustomMetricData?.name || 'Untitled metric'}
																						<Tooltip color='var(--tooltip-background)' title={thisCustomMetricData?.instruction_text || '(No instruction provided)'}>
																							<QuestionCircleOutlined />
																						</Tooltip>
																					</Flex>
																				) : metricKey}
																			</Tag>
																		);
																	})}
																</Flex>
															</Flex>
														);
													})}
												</Flex>
												{(!data?.metadata?.evaluationModules || Object.keys(data?.metadata?.evaluationModules)?.length === 0) && (
													<Tag bordered={false} color='red-inverse' icon={<CloseCircleOutlined />}>NEED AT LEAST ONE METRIC</Tag>
												)}
											</Flex>
											<Button size='small' icon={<EditOutlined />} block onClick={() => navigate('../metrics')} disabled={invocationLoading}>
												Back to edit
											</Button>
										</Flex>
									</div>
								</Flex>
								{/*<TipsCard title='What happens if we start evaluation?'>*/}
								{/*	<span>*/}
								{/*		When you start the evaluation:*/}
								{/*		<ul>*/}
								{/*			<li>Your Plan will run against your approved input dataset.</li>*/}
								{/*			<li>Outputs will be evaluated based on your defined metrics.</li>*/}
								{/*			<li>Results will gradually appear in the &apos;Logs&apos; and &apos;Scores&apos; tabs.</li>*/}
								{/*			<li>AI will analyze these results and make suggestions for alternative Plans.</li>*/}
								{/*		</ul>*/}
								{/*		Note: Depending on your dataset size and number of metrics, this process may take 10 minutes or more. Feel free to take a break - grab a coffee, stretch, or take a quick walk. Cockatiel will handle the evaluation for you.*/}
								{/*	</span>*/}
								{/*</TipsCard>*/}
								<Button
									type='primary'
									size='large'
									icon={<PlayCircleOutlined/>}
									block
									onClick={handleInvocation}
									loading={invocationLoading}
									disabled={(userInputSetList?.filter(item => item.is_approved === true)?.length !== data?.metadata?.targetUserInputCount
										|| !data?.metadata?.evaluationModules
										|| Object.keys(data?.metadata?.evaluationModules)?.length === 0
									)}
								>
									Start Evaluation
								</Button>
								{invocationErrorMessage && (
									<Alert message={invocationErrorMessage} type='error' showIcon closable onClose={() => setInvocationErrorMessage()} />
								)}
							</Flex>
						</Modal>
					</>
				)}
			</Flex>
		</Flex>
	);
};

const TabItemWrapper = ({ title, icon }) => {
	return (
		<Flex gap='small' style={{ height: 50 }} align='center'>
			{icon && icon}
			<span style={{ fontSize: 20 }}>{title}</span>
		</Flex>
	);
};

const TabContentWrapper = ({ tabKey, direction, children }) => {
	const tabVariants = {
		initial: (direction) => ({
			x: direction > 0 ? 1000 : -1000,
			opacity: 0
		}),
		animate: {
			x: 0,
			opacity: 1,
			transition: { type: 'spring', stiffness: 300, damping: 30 }
		},
		exit: (direction) => ({
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
			transition: { duration: 0.2 }
		})
	};

	return (
		<motion.div
			key={tabKey}
			variants={tabVariants}
			initial='initial'
			animate='animate'
			exit='exit'
			custom={direction}
			style={{ padding: '0 10px' }}
		>
			{children}
		</motion.div>
	);
};


export default ExperimentDetail;
