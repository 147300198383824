import { Column as AntColumn } from '@ant-design/plots';
import { Apologies } from '../../../atoms';

const colorPalette = ['#81D6D8', '#FFA093', '#FFC93D', '#C0DEA2', '#B5ACFF', '#FF914D', '#94BDFF', '#FF94BD', '#98C683', '#D09AE8'];

const Column = ({
	data = [],
	keyMap,
	height,
	group = true,
}) => {

	// Depending on the optimize phase, the "data" variable has breakdown or not
	// This affects the configuration of the chart (using legend or not)
	const hasBreakdown = data?.some(item => 'breakdown' in item);

	// get unique breakdown values from data, which will be used for legend title
	const breakdowns = hasBreakdown ? [...new Set(data?.map(item => item.breakdown))] : [];

	const config = {
		data,
		xField: 'dimension',
		yField: 'value',
		// set seriesField and groupField when breakdown exists
		...(hasBreakdown && {
			seriesField: 'breakdown',
			groupField: 'breakdown',
		}),
		// set isGroup and isStack based on group and hasBreakdown
		isGroup: group && hasBreakdown,
		isStack: !group && hasBreakdown,
		height,
		label: {
			position: 'middle',
			layout: [
				{ type: 'interval-adjust-position' },
				{ type: 'interval-hide-overlap' },
				{ type: 'adjust-color' },
			],
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: true,
			},
		},
		meta: {
			dimension: {
				formatter: (item) => keyMap?.dimension?.[item] || item,
			},
			value: {
				formatter: (item) => Number(item)?.toLocaleString(),
			},
			...(hasBreakdown && {
				breakdown: {
					formatter: (item) => keyMap?.breakdown?.[item] || item,
				},
			}),
		},
		slider: {
			start: 0,
			end: 1,
			foregroundStyle: {
				fill: '#55ABBA',
			},
		},
		columnStyle: hasBreakdown ? 
			({ breakdown }) => {
				const breakdownIndex = breakdowns?.indexOf(breakdown);
				const colorIndex = breakdownIndex % colorPalette.length;
				return { fill: colorPalette[colorIndex] };
			} :
			{ fill: colorPalette[0] },
		dodgePadding: 2,
		// set legend when breakdown exists
		...(hasBreakdown && {
			legend: {
				position: 'top-left',
				itemName: {
					formatter: (text, item, index) => {
						return keyMap?.breakdown?.[text] || text;
					},
					style: {
						fill: '#000000',  // legend text color: black
						fontSize: 12,
					},
				},
				marker: (text, index) => {
					const colorIndex = breakdowns?.indexOf(text) % colorPalette.length;
					return {
						style: {
							fill: colorPalette?.[colorIndex],
						},
					};
				},
			},
		}),
		// hide legend when breakdown does not exist
		...(hasBreakdown ? {} : { legend: false }),
		color: colorPalette,
		interactions: [
			{
				type: 'element-highlight-by-color',
			},
			{
				type: 'element-link',
			},
		],
	};

	return data?.length > 0 ? (
		<AntColumn {...config} />
	) : (
		<div style={{
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
			<Apologies note='No data was found...'/>
		</div>
	);
};

export default Column;

// // sample data
// let data = [
// 	{ dimension: '0-999', value: 0, breakdown: 'Group A' },
// 	{ dimension: '0-999', value: 1, breakdown: 'Group B' },
// 	{ dimension: '0-999', value: 2, breakdown: 'Group C' },
// 	{ dimension: '0-999', value: 3, breakdown: 'Group D' },
// 	{ dimension: '0-999', value: 4, breakdown: 'Group E' },
// 	{ dimension: '0-999', value: 500, breakdown: 'Group F' },
// 	{ dimension: '0-999', value: 6, breakdown: 'Group G' },
// 	{ dimension: '0-999', value: 731, breakdown: 'Group H' },
// 	{ dimension: '0-999', value: 8, breakdown: 'Group I' },
// 	{ dimension: '0-999', value: 91, breakdown: 'Group J' },
// 	{ dimension: '0-999', value: 10, breakdown: 'Group K' },
// 	{ dimension: '0-999', value: 11, breakdown: 'Group L' },
// 	{ dimension: '1000-1999', value: 3, breakdown: 'Group A' },
// 	{ dimension: '1000-1999', value: 2, breakdown: 'Group B' },
// 	{ dimension: '1000-1999', value: 4, breakdown: 'Group C' },
// 	{ dimension: '1000-1999', value: 913, breakdown: 'Group J' },
// 	{ dimension: '1000-1999', value: 10, breakdown: 'Group K' },
// 	{ dimension: '2000-2999', value: 2, breakdown: 'Group A' },
// 	{ dimension: '2000-2999', value: 134, breakdown: 'Group B' },
// 	{ dimension: '3000-3999', value: 2, breakdown: 'Group A' },
// 	{ dimension: '3000-3999', value: 3, breakdown: 'Group B' },
// 	{ dimension: '4000-4999', value: 50, breakdown: 'Group A' },
// 	{ dimension: '4000-4999', value: 45, breakdown: 'Group B' },
// 	{ dimension: '5000-5999', value: 212, breakdown: 'Group A' },
// 	{ dimension: '5000-5999', value: 3, breakdown: 'Group B' },
// 	{ dimension: '6000-6999', value: 111, breakdown: 'Group A' },
// 	{ dimension: '6000-6999', value: 2, breakdown: 'Group B' },
// 	{ dimension: '7000-7999', value: 11, breakdown: 'Group A' },
// 	{ dimension: '7000-7999', value: 999, breakdown: 'Group B' },
// 	{ dimension: '8000-8999', value: 0, breakdown: 'Group A' },
// 	{ dimension: '8000-8999', value: 1, breakdown: 'Group B' },
// 	{ dimension: '9000-9999', value: 0, breakdown: 'Group A' },
// 	{ dimension: '9000-9999', value: 1, breakdown: 'Group B' },
// 	{ dimension: '10000+', value: 20, breakdown: 'Group A' },
// 	{ dimension: '10000+', value: 25, breakdown: 'Group B' },
// 	{ dimension: '10000+', value: 30, breakdown: 'Group C' },
// ];

