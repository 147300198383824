import { useRef } from 'react';
import Editor from '@monaco-editor/react';
import './index.css';

function CodeEditor(props) {
	const {
		onChange = () => {},
		onStartEditing = () => {},
		onFinishEditing = () => {},
		stepNameMap = {},
		options,
		...restProps
	} = props;
	const editorRef = useRef(null);
	const decorationsRef = useRef([]);

	function handleEditorDidMount(editor, monaco) {
		editorRef.current = editor;
		updateDecorations(editor.getValue(), monaco);

		editor.onDidFocusEditorWidget(() => {
			onStartEditing();
		});

		editor.onDidBlurEditorWidget(() => {
			onFinishEditing();
		});

		// // Listen to changes in the editor's value
		editor.onDidChangeModelContent(() => {
			const value = editor.getValue();
			onChange(value);
			updateDecorations(value, monaco);
		});

		editor.onDidChangeCursorPosition(() => {
			const position = editor.getPosition();
			const value = editor.getValue();
			const placeholders = findAllPlaceholderTextPositions(value);
			const isInPlaceholder = placeholders.some(placeholder => {
				const { startLine, startColumn, endLine, endColumn } = placeholder;
				return position.lineNumber >= startLine
					&& position.lineNumber <= endLine
					&& position.column >= startColumn + 1
					&& position.column <= endColumn - 2;
			});
			//
			// if (isInPlaceholder) {
			// 	console.log('in placeholder');
			// } else {
			// 	console.log('out of placeholder');
			// }
		});
	}

	function updateDecorations(value, monaco) {
		const editor = editorRef.current;
		if (!editor) return;

		const placeholders = findAllPlaceholderTextPositions(value);

		const newDecorations = placeholders.map(placeholder => {
			const { startLine, startColumn, endLine, endColumn } = placeholder;
			const keyName = placeholder.placeholder?.match(/\{\{(.+?)\}\}/)?.[1];
			const value = Object.keys(stepNameMap)?.includes(keyName)
				? `generation result of **${stepNameMap[keyName]}**`
				: `input of **${keyName}**`;

			return {
				range: new monaco.Range(startLine, startColumn, endLine, endColumn + 1),
				options: {
					inlineClassName: 'editor-inline-placeholder',
					hoverMessage: [{ value: `Placeholder for the ${value}` }]
				}
			};
		});

		decorationsRef.current = editor.deltaDecorations(decorationsRef.current, newDecorations);
	}

	return (
		<Editor
			className='code-editor'
			height='400px'
			options={{
				minimap: { enabled: false },
				scrollBeyondLastLine: false,
				overviewRulerBorder: false,
				scrollbar: {
					vertical: 'hidden',
					horizontal: 'hidden'
				},
				lineNumbersMinChars: 3,
				...options
			}}
			onMount={handleEditorDidMount}
			// onMount={setEditorInstance}
			{...restProps}
		/>
	);
}

function findAllPlaceholderTextPositions(text) {
	let results = [];
	const lines = text.split('\n');
	const regex = /{{\w*}}/g;

	lines.forEach((line, lineNumber) => {
		let match;
		while ((match = regex.exec(line)) !== null) {
			const matchedText = match[0];
			const startIndex = match.index;

			const startLine = lineNumber + 1;
			const startColumn = startIndex + 1;
			const endLine = startLine;
			const endColumn = startColumn + matchedText.length - 1;

			results.push({
				placeholder: matchedText,
				startLine,
				startColumn,
				endLine,
				endColumn
			});
		}
	});

	return results;
}

export default CodeEditor;