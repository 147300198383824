import { useNavigate, useSearchParams } from 'react-router-dom';

const useNavigateWithParams = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return (path) => {
		const currentParams = {};
		searchParams.forEach((value, key) => {
			currentParams[key] = value;
		});

		const searchString = new URLSearchParams(currentParams).toString();
		navigate(`${path}?${searchString}`);
	};
};

export default useNavigateWithParams;
