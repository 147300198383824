import { useEffect, useState } from 'react';
import { Spin, Form, Input, Select, Radio } from 'antd';
import { AddModal } from '../../molecules';
import { useData, useAuthRequest } from '../../../hooks';
import { useTranslation } from 'react-i18next';

const AddPlatformForm = ({ open, onSubmit, onCancel, loading, platformId, initialValues }) => {
	const { t } = useTranslation();
	const [currentPlatform, setCurrentPlatform] = useState();

	const platformEndpoint = 'https://backend.lang.teammate.as/meta/platforms';
	const { data: platformsData, loading: platformsLoading } = useData(platformEndpoint);
	const options = platformsData?.filter(({ _id }) => _id !== 'teammate')
		.map(({ _id }) => ({
			label: t('platform.' + _id),
			value: _id
		}));

	const { makeRequest } = useAuthRequest();
	const handleOk = async (values) => {
		let endpoint = 'https://backend.lang.teammate.as/platforms';

		if (platformId) {
			endpoint = `https://backend.lang.teammate.as/platforms/${platformId}`;
		}

		const response = await makeRequest(endpoint, platformId ? 'PUT' : 'POST', values);
		if (response.success) {
			onSubmit();
		}

		return response;
	};

	const handleCancel = () => {
		onCancel();
	};

	useEffect(() => {
		setCurrentPlatform(initialValues?.metaPlatformId);
	}, [platformId]);

	return (
		<AddModal
			key={platformId}
			open={open}
			title={platformId ? t('title.modal.edit-platform-connection') : t('title.modal.create-new-platform-connection')}
			onCancel={handleCancel}
			onSubmit={handleOk}
			initialValues={initialValues}
		>
			<Spin spinning={platformsLoading}>
				<Form.Item
					label={t('form.label.connection-name')}
					name="platformConnectionName"
					rules={[
						{
							required: true,
							message: t('form.rules.connection-name.required'),
						},
					]}
				>
					<Input
						size='large'
						variant='filled'
						placeholder={t('form.placeholder.connection-name')}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.platform')}
					name="metaPlatformId"
					rules={[
						{
							required: true,
							message: t('form.rules.platform.required'),
						},
					]}
				>
					<Select
						size='large'
						variant='filled'
						placeholder={t('form.placeholder.platform')}
						options={options}
						onChange={(value) => setCurrentPlatform(value)}
					/>
				</Form.Item>
				{/^(openai|gcp|stability|cohere|anthropic)$/.test(currentPlatform) && (
					<Form.Item
						label={t('form.label.platform-api-key')}
						name="apiKey"
						rules={[
							{
								required: true,
								message: t('form.rules.platform-api-key.required'),
							},
						]}
					>
						<Input.Password
							size='large'
							variant='filled'
							placeholder={t('api-key')}
						/>
					</Form.Item>
				)}
				{currentPlatform === 'aws' && (
					<>
						<Form.Item
							label={t('form.label.auth-type')}
							name="authType"
						>
							<Radio.Group
								size='large'
								defaultValue='iamUser'
								options={[
									{ label: t('form.label.auth-by-iam-user'), value: 'iamUser' },
								]}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.platform-access-key')}
							name="accessKey"
							rules={[
								{
									required: true,
									message: t('form.rules.platform-access-key.required'),
								},
							]}
						>
							<Input
								size='large'
								variant='filled'
								placeholder={t('access-key')}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.platform-secret-access-key')}
							name="secretAccessKey"
							rules={[
								{
									required: true,
									message: t('form.rules.platform-secret-access-key.required'),
								},
							]}
						>
							<Input.Password
								size='large'
								variant='filled'
								placeholder={t('secret-access-key')}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.region')}
							name="region"
							rules={[
								{
									required: true,
									message: t('form.rules.region.required'),
								},
							]}
						>
							<Input
								size='large'
								variant='filled'
								placeholder={t('region')}
							/>
						</Form.Item>
					</>
				)}
			</Spin>
		</AddModal>
	);
};

export default AddPlatformForm;