import { Button, Flex, Menu, Tooltip } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import './index.css';

const iconStyle = { marginLeft: -2, fontSize: 24, color: '#555555' };

const AssistantModule = ({ items, currentKey, setCurrentKey = () => {} }) => {
	const isVisible = Boolean(currentKey);
	const currentItem = items.find(item => item.key === currentKey) || items[0];

	const handleClose = () => {
		setCurrentKey(null);
	};

	return (
		<div className='assistant-module ignore-bookcontainer-outside-clicks'>
			<Modal isVisible={isVisible} onClose={handleClose} title={currentItem.title}>
				{currentItem.content}
			</Modal>
			<RightSideMenu items={items} currentKey={currentKey} setCurrentKey={setCurrentKey} />
		</div>
	);
};

const Modal = ({ isVisible, onClose, title, children }) => {
	const modalVariants = {
		hidden: { x: '100%' },
		visible: { x: 0, transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 } },
		exit: { x: '100%', transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 } },
		initial: { x: '100%' }
	};

	return (
		<AnimatePresence className='ignore-bookcontainer-outside-clicks'>
			{isVisible && (
				<motion.div
					className='assistant-module-modal ignore-bookcontainer-outside-clicks'
					variants={modalVariants}
					animate="visible"
					exit="exit"
				>
					<Flex
						className='ignore-bookcontainer-outside-clicks'
						gap='small'
						justify='space-between'
						align='center'
						style={{ borderBottom: '1px #DDDDDD solid', padding: '5px 5px 5px 10px' }}
					>
						<span className='ignore-bookcontainer-outside-clicks' style={{ fontWeight: 600 }}>{title}</span>
						<Flex className='ignore-bookcontainer-outside-clicks' gap='small' justify='flex-end' align='center' style={{ paddingRight: '50px' }}>
							<Button size='small' type='text' icon={<MinusOutlined/>} onClick={onClose}/>
						</Flex>
					</Flex>
					<div className="assistant-module-content ignore-bookcontainer-outside-clicks" style={{ padding: '10px', paddingRight: '60px' }}>
						{children}
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

const RightSideMenu = ({ items, currentKey, setCurrentKey }) => {
	const menuItems = items.map(item => ({
		key: item.key,
		icon: (
			<Tooltip
				title={item.title}
				placement='left'
				color='var(--tooltip-background)'
				className='ignore-bookcontainer-outside-clicks'
			>
				<item.icon
					style={{ ...iconStyle, color: currentKey === item.key ? 'var(--primary-color)' : '#555555' }}
					className='ignore-bookcontainer-outside-clicks'
				/>
			</Tooltip>
		),
		label: item.title,
		style: { paddingLeft: 10, paddingRight: 12 }
	}));

	return (
		<div className='assistant-module-navigation ignore-bookcontainer-outside-clicks'>
			<Menu
				theme='light'
				mode='inline'
				items={menuItems}
				selectedKeys={[currentKey]}
				onClick={({ key }) => setCurrentKey(key === currentKey ? null : key)}
				className='ignore-bookcontainer-outside-clicks'
			/>
		</div>
	);
};

export default AssistantModule;
