import { createContext } from 'react';

const GenflowPlanContext = createContext({
	genflowId: null,
	planId: null,
	data: {},
	loading: true,
	refresh: () => {},
	compiledPlanData: {},
	compiledPlanLoading: true,
	compiledPlanRefresh: () => {},
	stepNameMap: {},
	messagesData: [],
	messagesLoading: true,
	messagesRefresh: () => {},
	storageList: [],
	storageLoading: true,
	storageRefresh: () => {},
	operatePlan: () => {},
	addPromptbook: () => {},
	addKnowledgebook: () => {},
	currentEditingStep: {},
	setCurrentEditingStep: () => {},
	currentEditingStepData: {},
	setCurrentEditingStepData: () => {}
});

export default GenflowPlanContext;
