import { useContext, useEffect, useRef, useState } from 'react';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';
import { Button, Flex, Input, Select, Skeleton, Tag, Tooltip, Typography } from 'antd';
import BookContainer from '../BookContainer';
import { BookItemContainer } from '../../../../../../components/atoms';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import generateUuid from '../../../../../../utils/generateUuid';

const StartEndStep = ({ type, onChange = () => {}, animationIndex }) => {
	const { data, loading, compiledPlanData, stepNameMap } = useContext(GenflowPlanContext);
	const [localValue, setLocalValue] = useState();
	const isInitialLoad = useRef(true);
	const debounceTimer = useRef();

	const shouldPulse = type === 'output' && data?.metadata?.steps?.length > 0 && (!data?.metadata?.output || data?.metadata?.output?.length === 0);

	useEffect(() => {
		if (isInitialLoad.current && !loading && data?.metadata?.[type]) {
			setLocalValue(data?.metadata?.[type]?.map(item => ({ ...item, tempId: generateUuid() })));
			isInitialLoad.current = false;
		}
	}, [data, loading]);

	const handleOnChangeInteraction = (newValue) => {
		const updateValue = newValue?.map(({ tempId, ...restItem }) => restItem);
		onChange(updateValue);
	};

	const debouncedOnChange = useRef((newValue) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			handleOnChangeInteraction(newValue);
		}, 750);
	}).current;

	const handleChange = (index, dimension, v) => {
		const newValue = [...localValue];
		const formattedV = v?.replace(/[^a-zA-Z0-9\-_.]/g, '');
		if (newValue[index][dimension] !== formattedV) {
			newValue[index] = { ...newValue[index], [dimension]: formattedV };
			setLocalValue(newValue);
			debouncedOnChange(newValue);
		}
	};

	const handleAdd = () => {
		const generateUniqueKey = (baseKey, existingKeys) => {
			const numbers = existingKeys
				.filter(key => key.startsWith(baseKey))
				.map(key => parseInt(key.slice(baseKey.length), 10))
				.filter(Number.isInteger);
			const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;
			return `${baseKey}${maxNumber + 1}`;
		};

		const existingKeys = localValue?.map(item => item.key) || [];
		const baseKey = 'param';
		const uniqueKey = generateUniqueKey(baseKey, existingKeys);
		const newValue = [...(localValue || []), { key: uniqueKey, type: 'text', tempId: generateUuid() }];

		setLocalValue(newValue);
		handleOnChangeInteraction(newValue);
	};

	const handleDelete = (index) => {
		const newValue = localValue?.filter((item, i) => i !== index);
		setLocalValue(newValue);
		handleOnChangeInteraction(newValue);
	};

	const typeOptions = [
		{ value: 'text', label: 'Text' },
		{ value: 'image', label: 'Image', disabled: true },
	];

	const [variableOptions, setVariableOptions] = useState([]);
	useEffect(() => {
		let inputOptions = [], stepOptions = [];

		if (data?.metadata?.input && data?.metadata?.input.length > 0) {
			inputOptions = data?.metadata?.input?.filter(item => item.key)?.map(item => (
				{
					value: `input.${item.key}`,
					label: <Flex align='center'><Tag color='blue'>START</Tag>{item.key}</Flex>
				}
			));
		}

		if (data?.metadata?.steps && data?.metadata?.steps?.length > 0) {
			stepOptions = data?.metadata?.steps?.map(item => (
				{
					value: `step.${item.id}`,
					label: (
						<Flex align='center'>
							<Tag color='orange'>
								{item.type?.replace(/book$/g, '').toUpperCase()}
							</Tag>
							<Typography.Text ellipsis>
								{stepNameMap[item.id] || `(Untitled ${item.type})`}
							</Typography.Text>
						</Flex>
					)
				}
			));
		}

		setVariableOptions([...inputOptions, ...stepOptions]);
	}, [data]);

	return (
		<BookContainer animationIndex={animationIndex}>
			<Flex vertical gap='middle' style={{ overflow: 'hidden' }}>
				<Typography.Title
					level={3}
					style={{ margin: 0, fontSize: '20px', height: '24px' }}
				>
					{type === 'input' ? 'Start' : type === 'output' ? 'End' : 'Unknown'}
				</Typography.Title>
				<BookItemContainer
					title={'Define ' + (type === 'input' ? 'input' : type === 'output' ? 'output' : 'unknown') + ' schema'}
				>
					{loading ? <Skeleton.Input block active size='large' /> : (
						<Flex vertical gap='small'>
							<span style={{ fontSize: 12, color: '#555555' }}>
								{type === 'input'
									? 'Define input schema with its key names here. You can receive dynamic values on API call using the predefined key names, and use them within your prompt texts and queries.'
									: type === 'output'
										? 'Define output schema here. You can format the response schema including results from each promptbook / knowledgebook or input values.' : ''
								}
							</span>
							<Button
								className={shouldPulse ? 'pulse' : null}
								type='dashed'
								icon={<PlusOutlined />}
								onClick={handleAdd}
							>
								Add variable
							</Button>
							{(localValue && localValue?.length > 0) ? (
								<AnimatePresence mode='popLayout'>
									{localValue?.map((item, i) => (
										<motion.div
											key={item?.tempId}
											layout
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
										>
											<Flex align='center' style={{ width: '100%' }}>
												{type === 'input' && (
													<StartItem
														item={item}
														index={i}
														type={item?.type}
														name={item?.key}
														options={typeOptions}
														onChange={handleChange}
													/>
												)}
												{type === 'output' && (
													<EndItem
														index={i}
														name={item?.key}
														variable={item?.variable}
														options={variableOptions}
														onChange={handleChange}
													/>
												)}
												<Button
													type='text'
													shape='circle'
													icon={<DeleteOutlined />}
													onClick={() => handleDelete(i)}
												/>
											</Flex>
										</motion.div>
									))}
								</AnimatePresence>
							) : (
								<Flex justify='center' align='center' style={{ height: 100, backgroundColor: 'rgba(255, 0, 0, 0.05)', border: 'red 1px dashed', borderRadius: '10px' }}>
									<span style={{ fontSize: '18px', color: 'red' }}>You have to have at least one {type} parameter</span>
								</Flex>
							)}
						</Flex>
					)}
				</BookItemContainer>
			</Flex>
		</BookContainer>
	);
};

const StartItem = ({ type, name, options, index, onChange }) => {
	const [localName, setLocalName] = useState(name);

	const { compiledPlanData } = useContext(GenflowPlanContext);
	const promptTextCombined = compiledPlanData?.json?.steps?.map(step => step.prompt_text)?.join('\n---\n') || '';
	const isNameUsed = localName && promptTextCombined?.includes(localName);

	const handleChange = (e) => {
		const newValue = e.target.value;
		setLocalName(newValue);
		onChange(index, 'key', e.target.value);
	};

	return (
		<Flex gap='small' align='center' style={{ width: '100%' }}>
			<Flex gap='small' align='center'>
				Type
				<Select
					variant='filled'
					options={options}
					value={type}
					style={{ width: '150px' }}
					onChange={(v) => onChange(index, 'type', v)}
				/>
			</Flex>
			<Flex gap='small' align='center' style={{ width: '100%' }}>
				Key
				<Input
					variant='filled'
					value={localName}
					onChange={handleChange}
					placeholder='Input your preferred key name for API'
					status={!localName ? 'error' : null}
				/>
			</Flex>
			<Tooltip
				title={`This variable is ${!isNameUsed ? ' never ' : ''}used`}
				color='var(--tooltip-background)'
			>
				<Tag
					color={isNameUsed ? 'green' : 'red'}
					style={{ width: 130 }}
					bordered={false}
					icon={isNameUsed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				>
					{isNameUsed ? 'Used' : localName ? 'Unused' : 'Invalid'}
				</Tag>
			</Tooltip>
		</Flex>
	);
};

const EndItem = ({ index, name, variable, options, onChange }) => {
	const [localName, setLocalName] = useState(name);
	const handleChange = (e) => {
		const newValue = e.target.value;
		setLocalName(newValue);
		onChange(index, 'key', e.target.value);
	};

	return (
		<Flex gap='small' align='center' style={{ width: '100%' }}>
			<Flex gap='small' align='center' style={{ width: '100%' }}>
				Keyname
				<Input
					variant='filled'
					value={localName}
					onChange={handleChange}
					placeholder='Input your preferred key name for API'
					status={!localName ? 'error' : null}
				/>
			</Flex>
			<Flex gap='small' align='center'>
				Variable
				<Select
					variant='filled'
					placeholder='Select variable of output'
					status={!variable ? 'error' : null}
					options={options}
					value={variable}
					style={{ width: '350px' }}
					onChange={(v) => onChange(index, 'variable', v)}
					popupMatchSelectWidth={false}
				/>
			</Flex>
		</Flex>
	);
};

export default StartEndStep;
