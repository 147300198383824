import { useContext, useEffect, useState } from 'react';
import { Table, Flex, Tooltip, Button } from 'antd';
import { Ellipsis, Loading, ReceiveAttentionButton, RelativeTime } from '../../../../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';

const Plans = () => {
	const { genflowData, plansList, plansLoading } = useContext(GenflowDetailContext);
	const [ localData, setLocalData ] = useState(genflowData);
	const navigate = useNavigate();
	const { createPlan } = useContext(GenflowDetailContext);

	const shouldReceiveAttention = !plansLoading && (!plansList || plansList?.length === 0);

	useEffect(() => {
		if (genflowData && localData !== genflowData) {
			setLocalData(genflowData);
		}
	}, [genflowData]);

	const columns = [
		{
			title: 'Plan Name',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
			width: '75%',
		},
		{
			title: 'Last Update',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
		},
	];

	const displayData = plansList?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.slice(0, 10)
		.map((row) => {
			const { genflow_plan_id, name, status, created_at, updated_at, updated_by, metadata } = row;

			return {
				key: genflow_plan_id,
				updatedAt: <RelativeTime datetime={updated_at} />,
				label: (
					<Tooltip placement="topLeft" title={name || 'Untitled Plan'}>
						<a onClick={() => navigate(`./develop/${genflow_plan_id}`)}>
							<Ellipsis name={name || 'Untitled Plan'} threshold={60} />
						</a>
					</Tooltip>
				),
			};
		});

	const [createLoading, setCreateLoading] = useState(false);

	const onCreateClick = async () => {
		setCreateLoading(true);
		await createPlan();
		setCreateLoading(false);
	};

	return (
		<Flex vertical gap='small'>
			<Flex gap='small' align='center' justify='space-between'>
				<Button loading={createLoading} size='large' type='primary' icon={<PlusOutlined />} onClick={onCreateClick}>
                    Create new plan
				</Button>
				<Button size='large' type='text' onClick={() => navigate('./develop')}>
					Show all
				</Button>
			</Flex>
			{plansLoading ? (
				<Loading />
			) : shouldReceiveAttention ? (
				<ReceiveAttentionButton
					onClick={onCreateClick}
					loading={createLoading}
					shoulderText='START HERE'
					buttonText='Create new plan'
				/>
			) : (
				<Table
					pagination={false}
					columns={columns}
					dataSource={displayData}
				/>
			)}
		</Flex>
	);
};

export default Plans;
