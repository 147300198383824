import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Flex } from 'antd';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import {
	ExperimentList,
	ExperimentDetail,
	FinalReports,
	Initialize,
	InputDataset,
	Metrics,
	Recommendations,
	MultiExperimentDetail
} from './screens';
import GenflowOptimizeContext from '../../../../context/genflowOptimize/Context';
import { Loading } from '../../../../components/atoms';
import './index.css';

const whitelistedPageKey = [
	'initialize',
	'input',
	'metrics',
	'experiments',
	'recommendations',
	'conclusions'
];

const NavigateToParent = ({ path = '' }) => {
	const { genflowId, optimizationId } = useContext(GenflowOptimizeContext);
	return (
		<Navigate to={`/genflows/${genflowId}/optimize/${optimizationId}${path}`} />
	);
};

const Optimizations = () => {
	const isInitialLoad = useRef(true);
	const { pageKey, planId } = useParams();
	const {
		genflowId,
		optimizationId,
		data,
		loading,
		targetPlanId,
		operateOptimize,
		status,
		isInitializationCompleted,
		isBeforeEvaluationInvoked,
		isBeforeAltEvaluationInvoked
	} = useContext(GenflowOptimizeContext);
	const navigate = useNavigate();

	const basePath = useMemo(() => {
		return `/genflows/${genflowId}/optimize/${optimizationId}`;
	}, [genflowId, optimizationId]);

	const statusDirectoryMap = {
		INITIALIZATION: 'initialize',
		CONFIGURATION: 'input',
		TARGET_PLAN_EVALUATION: `experiments/${targetPlanId}`,
		ALT_PLANS_GENERATED: 'recommendations',
		ALT_PLANS_EVALUATIONS: 'experiments/comparison',
		ALT_PLANS_EVALUATIONS_COMPLETED: 'experiments/comparison/scores',
		COMPLETED: 'experiments/comparison/scores',
		FINAL_REPORT_GENERATED: 'conclusions'
	};

	const [localStatus, setLocalStatus] = useState();
	useEffect(() => {
		if (isInitialLoad.current) {
			navigate(`${basePath}/${statusDirectoryMap[status] || 'initialize'}`);
		}

		if (status) {
			setLocalStatus(status);
			isInitialLoad.current = false;
		}

		// Auto redirect to target page upon event, except for recommendation as it has different logic
		if (localStatus && status !== localStatus && status !== 'ALT_PLANS_GENERATED') {
			setLocalStatus(status);
			navigate(`${basePath}/${statusDirectoryMap[status]}`);
		}
	}, [status]);

	const steps = {
		initialize: {
			key: 'initialize',
			title: 'Initialize',
			description: 'Select a plan to start evaluation and optimization',
			content: <Initialize />,
			nextDestination: '../input',
			isLocked: isInitializationCompleted
		},
		input: {
			key: 'input',
			title: 'Input Dataset',
			description: 'Create input dataset for experiment.',
			content: <InputDataset />,
			prevDestination: '../initialize',
			nextDestination: '../metrics',
			isLocked: status && !isBeforeEvaluationInvoked
		},
		metrics: {
			key: 'metrics',
			title: 'Metrics',
			description: 'Select metrics to use in evaluation for each output schema.',
			content: <Metrics />,
			prevDestination: '../input',
			nextDestination: targetPlanId ? `../experiments/${targetPlanId}` : null,
			isLocked: status && !isBeforeEvaluationInvoked
		},
		experiments: {
			key: 'experiments',
			title: 'Experiments',
			content: planId === 'comparison' ? <MultiExperimentDetail /> : planId ? <ExperimentDetail /> : <ExperimentList />,
			prevDestination: planId === 'comparison' ? '../recommendations'
				: planId === targetPlanId ? '../metrics' : planId ? '../experiments' : null,
			nextDestination: planId === 'comparison' ? '../conclusions'
				: planId === targetPlanId ? '../recommendations' : null
		},
		recommendations: {
			key: 'recommendations',
			title: 'Recommendations',
			content: <Recommendations />,
			prevDestination: targetPlanId ? `../experiments/${targetPlanId}` : null,
			nextDestination: '../experiments/comparison',
			isLocked: status && !isBeforeAltEvaluationInvoked
		},
		conclusions: {
			key: 'conclusions',
			title: 'Conclusions',
			content: <FinalReports />,
			prevDestination: '../experiments/comparison',
		},
	};

	const headerHeight = steps[pageKey]?.title ? 40 : 0;
	const bodyStyle = {
		paddingTop: headerHeight + 24
	};

	return (
		<Flex>
			<LeftNavigation />
			<Header
				loading={loading}
				label={data?.name}
				labelOnNull='Untitled optimization'
				onChange={(value) => {
					operateOptimize({ key: 'name', value }, 'PATCH');
				}}
				title={steps[pageKey]?.title}
				description={steps[pageKey]?.description}
				prevDestination={steps[pageKey]?.prevDestination}
				nextDestination={steps[pageKey]?.nextDestination}
				isLocked={steps[pageKey]?.isLocked}
			/>
			<div className='optimize-container-body'>
				{/*{(loading || !status) ? (*/}
				{/*	<Loading />*/}
				{/*) : (*/}
				<>
					<div style={bodyStyle}>
						<Flex vertical gap='small'>
							{steps[pageKey]?.content || <NavigateToParent />}
						</Flex>
					</div>
				</>
				{/*)}*/}
			</div>
		</Flex>
	);
};

export default Optimizations;
