import { useContext } from 'react';
import { Collapse, Flex } from 'antd';
import { BookItemContainer } from '../../../../atoms';
import GenerationAssistantModal from './GenerationAssistantModal';
import ModelSelect from './ModelSelect';
import PromptTextEditor from './PromptTextEditor';
// import OutputFormatSelect from './OutputFormatSelect';
import SliderConfig from './SliderConfig';
import Icon from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';
import PromptbookContext from '../../contexts/Context';

const Editor = ({ stepNameMap }) => {
	const { setOpenGenerationAssistant } = useContext(PromptbookContext);

	const mainItems = [
		{
			key: 'model',
			title: 'Model',
			component: <ModelSelect />,
		},
		// {
		// 	key: 'system',
		// 	title: 'System Prompt',
		// 	component: (
		// 		<QueryTextEditor type='system' />
		// 	),
		// },
		{
			key: 'text-prompt',
			title: 'Text Prompt',
			component: (
				<PromptTextEditor type='prompt' stepNameMap={stepNameMap} />
			),
			extra: (
				<Flex gap='small' align='center' justify='flex-end'>
					<button className='text-button-ai' onClick={() => setOpenGenerationAssistant(true)}>
						<Flex gap={3} align='center'>
							<Icon component={BsStars} />
							<span>Let AI Write Prompts</span>
						</Flex>
					</button>
				</Flex>
			)
		},
	// {
	// 	key: 'output-format',
	// 	title: 'Output Format',
	// 	component: <OutputFormatSelect {...selectProps} />,
		// }
	];

	const collapsedItems = [
		{
			key: 'temperature',
			title: 'Temperature',
			description: 'Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.',
			component: <SliderConfig type='temperature' />,
		},
		{
			key: 'maxTokens',
			title: 'Max Tokens',
			description: 'The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for standard English text)',
			component: <SliderConfig type='maxTokens' />,
		},
		{
			key: 'topP',
			title: 'Top P',
			description: 'Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered.',
			component: <SliderConfig type='topP' />,
		},
		{
			key: 'frequencyPenalty',
			title: 'Frequency Penalty',
			description: 'How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model\'s likelihood to repeat the same line verbatim.',
			component: <SliderConfig type='frequencyPenalty' />,
		},
		{
			key: 'presencePenalty',
			title: 'Presence Penalty',
			description: 'How much to penalize new tokens based on whether they appear in the text so far. Increases the model\'s likelihood to talk about new topics.',
			component: <SliderConfig type='presencePenalty' />,
		},
	];

	return (
		<>
			<Flex vertical gap='middle'>
				{mainItems.map((item) => (
					<BookItemContainer key={item.key} title={item.title} description={item.description} extra={item.extra}>
						{item.component}
					</BookItemContainer>
				))}
			</Flex>
			<Collapse ghost items={[
				{
					key: 'item',
					label: 'Advanced configs',
					children: (
						<Flex vertical gap='middle'>
							{collapsedItems.map((item) => (
								<BookItemContainer key={item.key} title={item.title} description={item.description} extra={item.extra}>
									{item.component}
								</BookItemContainer>
							))}
						</Flex>
					)
				}
			]} />
			<GenerationAssistantModal />
		</>
	);
};

export default Editor;
