import { Flex, Form, Input } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigateWithParams } from '../../../../hooks';

const LoginForm = () => {
	const navigate = useNavigateWithParams();

	return (
		<>
			<Form.Item
				name="username"
				rules={[
					{
						required: true,
						message: 'Please enter email address',
					},
				]}
			>
				<Input
					size='large'
					placeholder='Email address'
					prefix={<MailOutlined className={'prefixIcon'} />}
				/>
			</Form.Item>
			<Form.Item
				name="password"
				rules={[
					{
						required: true,
						message: 'Please enter password',
					},
				]}
			>
				<Input.Password
					size='large'
					placeholder='Password'
					prefix={<LockOutlined className={'prefixIcon'} />}
				/>
			</Form.Item>
			<div style={{ display: 'flex', justifyContent: 'right', width: '100%', marginTop: 5, marginBottom: 5 }}>
				<Flex gap='small'>
					<a onClick={() => navigate('/auth/reset-password')}>
						Forgot password
					</a>
				</Flex>
			</div>
		</>
	);
};

export default LoginForm;
