import { MotionDiv } from '../../../../../../../components/atoms';
import '../index.css';

const Box = (props) => {
	const { animationIndex, children } = props;

	return (
		<MotionDiv animationIndex={animationIndex}>
			<div className='optimize-top-box'>
				{children}
			</div>
		</MotionDiv>
	);
};

export default Box;
