import Context from './Context';
import { useAuthData, useAuthRequest } from '../../hooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../GlobalContext';

const MAX_MODULE_COUNT = 3;

const GenflowOptimizeProvider = ({ children }) => {
	const { pushMessage } = useContext(GlobalContext);
	const { genflowId, optimizationId } = useParams();
	const isInitialLoad = useRef(true);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}`;
	}, [genflowId, optimizationId]);

	// Base data
	const { data, loading, refresh } = useAuthData(baseEndpoint, !!optimizationId);
	const parsedMetadata = data?.data?.rows?.[0]?.metadata || {};
	const currentEvaluationModuleCount = parsedMetadata?.evaluationModules ? Object.values(parsedMetadata?.evaluationModules).reduce((acc, val) => acc + Object.keys(val).length, 0) : 0;
	const hasReachedMaxModuleCount = currentEvaluationModuleCount >= MAX_MODULE_COUNT;

	const status = data?.data?.rows?.[0]?.status;
	const isInitializationCompleted = status && status !== 'INITIALIZATION';
	const isBeforeEvaluationInvoked = ['INITIALIZATION', 'CONFIGURATION'].includes(status);
	const isBeforeAltEvaluationInvoked = ['INITIALIZATION', 'CONFIGURATION', 'TARGET_PLAN_EVALUATION', 'ALT_PLANS_GENERATED'].includes(status);
	const isAllCompleted = status === 'FINAL_REPORT_GENERATED';

	// Target plan data
	const targetPlanId = data?.data?.rows?.[0]?.metadata?.targetPlanId;
	const targetPlanUrl = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${targetPlanId}`;
	const { data: targetPlanData, loading: targetPlanLoading } = useAuthData(targetPlanUrl, !!targetPlanId && !loading);

	useEffect(() => {
		if (isInitialLoad.current && !loading) {
			isInitialLoad.current = false;
		}
	}, [loading, data, targetPlanData]);

	const targetCompiledPlanUrl = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans-compiled/${targetPlanId}`;
	const { data: targetCompiledPlanData, loading: targetCompiledPlanLoading } = useAuthData(targetCompiledPlanUrl, !!targetPlanId && !loading);

	// User input set data
	const [userInputSetSort, setUserInputSetSort] = useState({ key: 'created_at', direction: 'desc' });
	const [userInputSetFilter, setUserInputSetFilter] = useState('all');
	const userInputSetUrl = baseEndpoint + '/datasets?limit=1000&order_by=' + userInputSetSort?.key + '&order_direction=' + userInputSetSort?.direction
		+ (userInputSetFilter !== 'all' ? ('&filters=source_key,=,' + userInputSetFilter) : '');
	const {
		data: userInputSetData,
		loading: userInputSetLoading,
		refresh: userInputSetRefresh
	} = useAuthData(userInputSetUrl, !!optimizationId);

	// User input set generation data
	const {
		data: userInputSetGenerationsData,
		loading: userInputSetGenerationsLoading,
		refresh: userInputSetGenerationsRefresh
	} = useAuthData(baseEndpoint + '/datasets/generations?limit=100', !!optimizationId);

	// User input set generation status data
	const {
		data: userInputSetGenerationsStatusData,
		loading: userInputSetGenerationsStatusLoading,
		refresh: userInputSetGenerationsStatusRefresh
	} = useAuthData(baseEndpoint + '/datasets/generations-status', !!optimizationId);

	// Plans status data
	const {
		data: plansList,
		loading: plansLoading,
		refresh: plansRefresh
	} = useAuthData(baseEndpoint + '/plans', !!optimizationId);

	// Operation data
	// eslint-disable-next-line no-undef
	const operationEndpoint = `${process.env.REACT_APP_BASE_API}/operations`;
	const {
		data: targetOperationData,
		loading: targetOperationLoading,
		refresh: targetOperationRefresh
	} = useAuthData(`${operationEndpoint}?namespace=evaluations/${optimizationId}/${targetPlanId}`, !!optimizationId && !!targetPlanId);

	// Custom metrics data
	const {
		data: customMetricsData,
		loading: customMetricsLoading,
		refresh: customMetricsRefresh
	} = useAuthData(baseEndpoint + '/metrics', !!optimizationId);

	// eslint-disable-next-line no-undef
	const metricsEndpoint = `${process.env.REACT_APP_BASE_API}/static/metrics`;
	const {
		data: metricsData,
		loading: metricsLoading
	} = useAuthData(metricsEndpoint);

	const { makeRequest } = useAuthRequest();
	const operateOptimize = async (payload, method, path = '') => {
		const response = await makeRequest(baseEndpoint + path, method, payload);
		await refresh();
		return response;
	};

	const updateStatus = async (value) => {
		const statusPayload = {
			key: 'status',
			value,
		};

		await operateOptimize(statusPayload, 'PATCH');
	};

	const operateDatasetManualAdd = async (datasets = [], sourceKey) => {
		const response = await makeRequest(
			baseEndpoint + '/datasets/manual-add',
			'POST',
			{ datasets, sourceKey }
		);
		await userInputSetRefresh();

		return response;
	};

	const operateDatasetApproval = async (recordIds, shouldApprove) => {
		const operationType = shouldApprove === true ? 'approve' : shouldApprove === false ? 'disapprove' :  'abstain';
		await makeRequest(
			baseEndpoint + '/datasets/approvals/' + operationType,
			'POST',
			{ recordIds }
		);
		await userInputSetRefresh();
	};

	// eslint-disable-next-line no-undef
	const planConversionEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/recommendations-to-plans`;
	const convertRecommendationToPlan = async (recommendationId) => {
		return await makeRequest(
			planConversionEndpoint + '/' + recommendationId,
			'POST',
			{ originalPlanId: targetPlanId }
		);
	};

	// eslint-disable-next-line no-undef
	const invokeEvaluation = async (planId) => {
		if (!planId) {
			return { succeed: false, message: 'Unknown plan, unable to proceed evaluation.' };
		} else if (userInputSetData?.data?.rows?.filter(item => item.is_approved === true)?.length !== data?.data?.rows?.[0]?.metadata?.targetUserInputCount) {
			return { succeed: false, message: 'Number of user input set does not match the target count' };
		} else if (!data?.data?.rows?.[0]?.metadata?.evaluationModules) {
			return { succeed: false, message: 'You need at least one evaluation metric to run experiments' };
		} else {
			// eslint-disable-next-line no-undef
			const invocationEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/plans/${planId}/simulations/invoke`;

			const response = await makeRequest(invocationEndpoint, 'POST');

			if (response?.message === 'OK') {
				return { succeed: true };
			} else {
				return { succeed: false, message: 'Error in initiating evaluation. Please try again later.' };
			}
		}
	};

	const [cachedTargetPlanValues, setCachedTargetPlanValues] = useState();
	useEffect(() => {
		const firstRow = targetPlanData?.data?.rows?.[0];
		if (firstRow != null) {
			setCachedTargetPlanValues({
				targetPlanData: firstRow,
			});
		}
	}, [targetPlanId, targetPlanData?.data?.rows?.[0], targetPlanLoading]);
	const targetPlanValues = cachedTargetPlanValues;

	const [metricGenerationWatchingList, setMetricGenerationWatchingList] = useState([]);

	const contextValues = useMemo(() => ({
		genflowId,
		optimizationId,
		data: data?.data?.rows?.[0] || {},
		loading: loading && targetPlanLoading && isInitialLoad.current,
		refresh,
		status: status || '',
		isInitializationCompleted,
		isBeforeEvaluationInvoked,
		isBeforeAltEvaluationInvoked,
		isAllCompleted,
		hasReachedMaxModuleCount,
		targetPlanId,
		targetPlanLoading,
		targetCompiledPlanData: targetCompiledPlanData?.data?.rows?.[0] || {},
		targetCompiledPlanLoading,
		userInputSetList: userInputSetData?.data?.rows || [],
		userInputSetLoading,
		userInputSetRefresh,
		setUserInputSetSort,
		setUserInputSetFilter,
		userInputSetGenerationsList: userInputSetGenerationsData?.data?.rows || [],
		userInputSetGenerationsLoading,
		userInputSetGenerationsRefresh,
		userInputSetGenerationsStatusList: userInputSetGenerationsStatusData?.data?.rows || [],
		userInputSetGenerationsStatusLoading,
		userInputSetGenerationsStatusRefresh,
		targetOperationList: targetOperationData?.data?.rows || [],
		targetOperationLoading,
		targetOperationRefresh,
		plansList: plansList?.data?.rows || [],
		plansLoading,
		plansRefresh,
		customMetricsList: customMetricsData?.data?.rows || [],
		customMetricsLoading,
		customMetricsRefresh,
		metricsData: metricsData?.data?.rows || [],
		metricsLoading,
		operateOptimize,
		updateStatus,
		operateDatasetManualAdd,
		operateDatasetApproval,
		convertRecommendationToPlan,
		invokeEvaluation,
		metricGenerationWatchingList,
		setMetricGenerationWatchingList
	}), [optimizationId, data, targetPlanId, targetPlanLoading, userInputSetData, userInputSetGenerationsData, userInputSetGenerationsStatusData, targetOperationData, plansList, customMetricsData, metricsData, metricGenerationWatchingList]);

	return (
		<Context.Provider value={{ ...targetPlanValues, ...contextValues }}>
			{children}
		</Context.Provider>
	);
};

export default GenflowOptimizeProvider;
