import { useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import GlobalContext from '../../context/GlobalContext';

const useAuthRequest = () => {
	const { workspaceId, user, loading: userLoading, tokenManager, jwt, refreshJwt, pushMessage } = useContext(GlobalContext);
	const jwtRef = useRef(tokenManager?.accessToken || sessionStorage.getItem('accessToken'));
	const maxRetries = 2;
	const jwtWaitTimeout = 2000;

	useEffect(() => {
		jwtRef.current = tokenManager?.accessToken || sessionStorage.getItem('accessToken'); // Update the ref when jwt changes
	}, [tokenManager?.accessToken || sessionStorage.getItem('accessToken')]);

	const waitForJwt = () => {
		return new Promise((resolve, reject) => {
			const checkJwt = () => {
				if (jwtRef.current) {
					resolve(jwtRef.current);
				} else if (!userLoading) {
					reject(new Error('User is not authenticated'));
				} else {
					setTimeout(checkJwt, jwtWaitTimeout);
				}
			};
			checkJwt();
		});
	};

	const makeRequest = async (endpoint, method, payload, retryCount = 0) => {
		try {
			const token = await waitForJwt();
			const userId = user?.uid;

			const headers = {
				Authorization: `Bearer ${token}`,
				'Workspace-ID': workspaceId,
				'User-ID': userId
			};

			let body = {
				method,
				url: endpoint,
				headers,
			};

			if (payload) {
				body.data = payload;
			}

			const response = await axios(body);

			if (response?.status !== 200 && response?.status !== 201) {
				return { success: false, reason: response.message };
			} else {
				return response.data;
			}
		} catch (e) {
			if (e.response?.status === 403) {
				pushMessage('No permission', 'error');
				return { success: false, reason: 'Forbidden' };
			} else if (retryCount < maxRetries) {
				return makeRequest(endpoint, method, payload, retryCount + 1);
			} else {
				console.error(e.response?.data?.error);
				pushMessage(`Network error: ${e.response?.data?.error}`, 'error');
				return { success: false, reason: e.message };
			}
		}
	};

	return { makeRequest };
};

export default useAuthRequest;
