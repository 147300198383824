import { useContext, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { MotionDiv } from '../../../../../../components/atoms';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';

const buttonProps = {
	block: true,
	icon: <PlusOutlined />,
	size: 'large',
	type: 'dashed',
	style: {
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
	}
};

const AddStep = ({ animationIndex, ...restProps }) => {
	const { data, loading } = useContext(GenflowPlanContext);

	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex gap='middle'>
				<LoadableButton
					type='PROMPTBOOK'
					shouldPulse={!loading && data?.metadata?.steps?.length === 0}
					{...restProps}
				>
					Prompt Book
					<Tooltip
						title={(
							<Flex vertical gap='small'>
								<span>Write prompt text, choose model and define the output format in the prompt book.</span>
								<span>You can add multiple promptbooks to run as a chain for generations like Chain of Thought.</span>
							</Flex>
						)}
						color='var(--tooltip-background)'
					>
						<QuestionCircleOutlined />
					</Tooltip>
				</LoadableButton>
				<LoadableButton
					type='KNOWLEDGEBOOK'
					{...restProps}
				>
					Knowledge Book
					<Tooltip
						title={(
							<Flex vertical gap='small'>
								<span>Upload your data to create knowledge base for a grounded generation in knowledgebook.</span>
								<span>You can use this for generations like RAG (Retrieval-Augmented Generation).</span>
							</Flex>
						)}
						color='var(--tooltip-background)'
					>
						<QuestionCircleOutlined />
					</Tooltip>
				</LoadableButton>
			</Flex>
		</MotionDiv>
	);
};

const LoadableButton = ({ type, position, onAdd = () => {}, shouldPulse = false, children }) => {
	const [loading, setLoading] = useState(false);

	const handleAdd = async () => {
		setLoading(true);
		await onAdd(position, type);

		setLoading(false);
	};

	return (
		<Button
			className={shouldPulse ? 'pulse' : ''}
			loading={loading}
			onClick={handleAdd}
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export default AddStep;
