import { useContext, useState } from 'react';
import { useAuthRequest } from '../../../../../../hooks';
import { Button, Flex, Form, Input, Tooltip } from 'antd';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';
import { PlayCircleFilled } from '@ant-design/icons';
import './index.css';

const InputComponent = ({ testRunning, onActivate }) => {
	const { genflowId, planId, data, loading, remainingTasks } = useContext(GenflowPlanContext);
	const inputList = data?.metadata?.input || [];

	// eslint-disable-next-line no-undef
	const endpoint = `${process.env.REACT_APP_LANG_API}/run/playground/${genflowId}/${planId}`;
	const { makeRequest } = useAuthRequest();
	const handleSubmit = async (values) => {
		const response = await makeRequest(endpoint, 'POST', { input: values });
		if (response?.success) {
			onActivate(response?.data?.requestId);
		}

		return response;
	};
	
	return (
		<Flex vertical gap='small'>
			<Flex justify='space-between' align='center' gap='small'>
				<span className='playground-component-title'>Input</span>
			</Flex>
			<Form
				name='playground-input'
				colon={false}
				onFinish={handleSubmit}
			>
				{inputList?.map((item) => (
					<Form.Item
						key={item?.key}
						className='playground-input-component'
						name={item?.key}
						label={item?.key}
						labelCol={{ span: 5 }}
						style={{ margin: 0 }}
					>
						<Input.TextArea variant='filled' />
					</Form.Item>
				))}
				<Form.Item
					style={{ margin: 0, marginTop: 10 }}
				>
					<Tooltip
						title={(!loading && data?.metadata?.steps?.length === 0) ? 'You need to have at least one step to run test.' : remainingTasks?.length > 0 ? 'Fix errors before running a test.' : ''}
						color='var(--tooltip-background)'
					>
						<Button
							type='primary'
							htmlType='submit'
							block
							icon={<PlayCircleFilled />}
							size='large'
							loading={testRunning}
							disabled={!loading && (data?.metadata?.steps?.length === 0 || remainingTasks?.length > 0)}
						>
							Run Test
						</Button>
					</Tooltip>
				</Form.Item>
			</Form>
		</Flex>
	);
};

export default InputComponent;
