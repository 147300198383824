import { useContext, useEffect, useRef } from 'react';
import { Dropdown, Button, Flex } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import { Loading, MotionDiv } from '../../../../components/atoms';
import { Endpoint, Optimizations, Plans, Summary } from './components';
import './index.css';

const defaultComponentStyle = {
	padding: '24px 0',
	width: '100%',
};

const bodyStyle = {
	backgroundColor: 'var(--default-background)',
	minHeight: 'calc(100vh - 120px)',
};

const Overview = (props) => {
	const isInitialLoad = useRef(true);
	const { genflowData, genflowLoading } = useContext(GenflowDetailContext);
	const { data, loading, operate } = props;

	useEffect(() => {
		if (!genflowLoading && genflowData) {
			isInitialLoad.current = false;
		}
	}, [genflowLoading]);

	const showLoading = genflowLoading && isInitialLoad.current;

	return (
		<div style={bodyStyle}>
			<div
				style={defaultComponentStyle}
			>
				<ProCard
					gutter={16}
					ghost
				>
					<ProCard
						colSpan={12}
						wrap
						ghost
					>
						<Flex gap={16} vertical>
							<OverviewCard
								title='Summary'
								loading={showLoading}
								animationIndex={0}
							>
								<Summary
									data={data}
									operate={operate}
								/>
							</OverviewCard>
							<OverviewCard
								title='Develop'
								loading={showLoading}
								animationIndex={1}
							>
								<Plans
									data={data}
									operate={operate}
								/>
							</OverviewCard>
						</Flex>
					</ProCard>
					<ProCard
						colSpan={12}
						wrap
						ghost
					>
						<Flex gap={16} vertical>
							<OverviewCard
								title='Endpoint'
								loading={showLoading}
								animationIndex={2}
							>
								<Endpoint />
							</OverviewCard>
							<OverviewCard
								title='Optimize'
								loading={showLoading}
								animationIndex={2}
							>
								<Optimizations
									data={data}
									operate={operate}
								/>
							</OverviewCard>
						</Flex>
					</ProCard>
				</ProCard>
			</div>
		</div>
	);
};

const OverviewCard = (props) => {
	const { title, loading, animationIndex, children } = props;
	
	const defaultCardStyle = {
		width: '100%',
		height: 150
	};

	const defaultTitleStyle = {
		fontSize: 22
	};

	return (
		<MotionDiv animationIndex={animationIndex}>
			<ProCard
				title={<b style={defaultTitleStyle}>{title}</b>}
				className='on-hover-shadow'
				collapsible
			>
				{loading
					? <Loading />
					: children
				}
			</ProCard>
		</MotionDiv>
	);
};

const MenuButton = () => {
	return (
		<Dropdown
			menu={{
				items: [
					{
						label: <a href="https://www.antgroup.com">1st menu item</a>,
						key: '0',
					},
					{
						label: <a href="https://www.aliyun.com">2nd menu item</a>,
						key: '1',
					},
					{
						type: 'divider',
					},
					{
						label: '3rd menu item',
						key: '3',
						danger: true
					},
				]
			}}
			trigger={['click']}
			placement='bottomRight'
			arrow
		>
			<Button shape='circle' size='middle' type='text' icon={<EllipsisOutlined />} />
		</Dropdown>
	);
};

export default Overview;
