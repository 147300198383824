import { TypeAnimation } from 'react-type-animation';

const TypingAnimation = (props) => {
	return (
		<div style={{ whiteSpace: 'pre-wrap' }}>
			<TypeAnimation
				{...props}
			/>
		</div>
	);
};

export default TypingAnimation;
