import { Button, Flex } from 'antd';
import { IconLogoSilhouette, MotionDiv } from '../../../../../../components/atoms';
import { TbExternalLink } from 'react-icons/tb';
import './index.css';

const Resource = ({ animationIndex, title, description, imgSrc, href, linkText, isComingSoon }) => {
	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex vertical className='top-resource-container top-card-background-white'>
				<img className='top-resource-image' src={imgSrc} alt={title} />
				<div style={{ padding: 12 }}>
					<Flex vertical gap='middle'>
						<Flex vertical gap='small'>
							<h3 className='top-resource-title'>{title}</h3>
							{description && <span className='top-resource-description'>{description}</span>}
						</Flex>
						{isComingSoon ? (
							<Button type='primary' size='large' disabled icon={<IconLogoSilhouette />}>
								Coming soon!
							</Button>
						) : (linkText && href) && (
							<Button type='primary' size='large' ghost icon={<TbExternalLink />} href={href} target='_blank'>
								{linkText}
							</Button>
						)}
					</Flex>
				</div>
			</Flex>
		</MotionDiv>
	);
};

export default Resource;