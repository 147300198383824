import { createContext } from 'react';

const CustomMetricContext = createContext({
	data: {},
	loading: true,
	refresh: () => {},
	operateCustomMetric: () => {},
	duplicateMetric: () => {},
	deleteMetric: () => {},
});

export default CustomMetricContext;
