import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthData, useAuthRequest } from '../../../../../../hooks';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';
import { Button, Empty, Flex, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import './index.css';
import deepEquivalence from '../../../../../../utils/deepEquivalence';

// const CHUNK_ENDPOINT = 'https://chunk.cockatiel.ai/chunk';
// const CHUNK_ENDPOINT = 'https://chunking-api.vercel.app/chunk';
// eslint-disable-next-line no-undef
const CHUNK_ENDPOINT = `${process.env.REACT_APP_CHUNK_API}/chunk`;

const ChunkPlayground = () => {
	const { currentEditingStepData, storageList } = useContext(GenflowPlanContext);
	const [loading, setLoading] = useState(true);
	const [chunkData, setChunkData] = useState({});

	const { makeRequest } = useAuthRequest();

	const firstObjectId = currentEditingStepData?.metadata?.objects?.[0] || '';
	const firstObjectDetail = storageList?.find(item => item.object_id === firstObjectId);
	const chunkSettings = currentEditingStepData?.metadata?.chunk;
	const [localChunkSetting, setLocalChunkSetting] = useState({});

	// eslint-disable-next-line no-undef
	const endpoint = `${process.env.REACT_APP_BASE_API}/storage/${firstObjectId}/signedUrl`;
	const { data: objectData } = useAuthData(endpoint, !!firstObjectId);
	const signedUrl = objectData?.url || '';

	const handleChunk = async () => {
		if (
			signedUrl
			&& dayjs(objectData?.expires).isAfter(dayjs())
			&& chunkSettings
			&& Object.keys(chunkSettings).length > 0
			&& !deepEquivalence(chunkSettings, localChunkSetting)
		) {
			setLoading(true);
			setLocalChunkSetting(chunkSettings);
			const payload = {
				signed_url: signedUrl,
				chunk_settings: {
					...chunkSettings,
					splitter_type: 'recursive_character'
				},
				preview_end: 10
			};

			const response = await makeRequest(CHUNK_ENDPOINT, 'POST', payload);

			if (response) {
				setChunkData(response);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		handleChunk();
	}, [firstObjectId, objectData, chunkSettings]);

	return (
		<Spin spinning={loading} className='ignore-bookcontainer-outside-clicks'>
			<Flex vertical gap='small' style={{ minHeight: 'calc(100vh - 165px)' }} className='ignore-bookcontainer-outside-clicks'>
				<span className='chunk-object-title ignore-bookcontainer-outside-clicks'>{firstObjectDetail?.name}</span>
				{chunkData?.chunks?.length > 0
					? chunkData?.chunks?.map((text, index) => <ChunkCard key={index} text={text} />)
					: firstObjectId
						? <Empty description='Loading...' />
						: <Empty description='Select files for search first' />
				}
				{(chunkData && chunkData?.total_chunks > 0) && (
					<Flex align='center' style={{ marginTop: '10px' }} className='ignore-bookcontainer-outside-clicks'>
						<Tag color='green' bordered={false} className='ignore-bookcontainer-outside-clicks'>Total chunks</Tag>
						<span style={{ fontSize: 16 }} className='ignore-bookcontainer-outside-clicks'>{chunkData?.total_chunks?.toLocaleString()} chunks</span>
					</Flex>
				)}
			</Flex>
		</Spin>
	);
};

const ChunkCard = ({ text }) => {
	return (
		<div className='chunk-card on-hover-shadow ignore-bookcontainer-outside-clicks'>
			<span className='chunk-text ignore-bookcontainer-outside-clicks'>
				{text}
			</span>
		</div>
	);
};

export default ChunkPlayground;
