import { Flex, Tag } from 'antd';
import { AbsoluteTime, LoadingProgress } from '../../../../../../../components/atoms';
import { motion } from 'framer-motion';
import { useState } from 'react';

const CardGenerating = (props) => {
	const { invoked_at, metadata, shouldAnimateProgress, isCompleted } = props;
	const [toggle, setToggle] = useState(false);

	return (
		<Flex gap='middle' align='center' justify='space-between' className='user-input-card card-generating card-generating-background'>
			<Flex vertical gap='large'>
				<Flex gap='small' align='center'>
					<span className='card-generating-title'>
						Generating user input...
					</span>
				</Flex>
				<Flex align='center'>
					<Tag bordered={false}>Requesting generation count</Tag>
					<span>{metadata?.generationCount || 0}</span>
				</Flex>
				<Flex align='center'>
					<Tag bordered={false}>Started at</Tag>
					<AbsoluteTime datetime={invoked_at}/>
				</Flex>
			</Flex>
			<Flex gap='large' align='flex-end' justify='flex-end' style={{ width: '100%' }}>
				<div style={{ width: '100%' }}>
					<motion.div
						initial={{ x: toggle ? 0 : 40 }}
						animate={{ x: toggle ? 400 : 0 }}
						transition={{
							x: {
								type: 'tween',
								stiffness: 300,
								duration: 5,
							}
						}}
						onAnimationComplete={() => setToggle(!toggle)}
					>
						<div style={{ width: 120, overflow: 'hidden' }}>
							<img
								src='/images/bike.gif'
								alt='Generating'
								style={{ width: '100%', height: 'auto', ...(toggle ? { transform: 'scaleX(-1)' } : {}) }}
							/>
						</div>
					</motion.div>
				</div>
				<LoadingProgress
					strokeColor={{
						'0%': '#87d068',
						'50%': '#ffe58f',
						'100%': '#ffccc7',
					}}
					duration={metadata?.generationCount * 1000}
					shouldAnimateProgress={shouldAnimateProgress}
					isComplete={isCompleted}
					type='circle'
				/>
			</Flex>
		</Flex>
	);
};

export default CardGenerating;
