import { useContext, useRef } from 'react';
import PromptbookContext from '../../../contexts/Context';
import { SliderWithInputNumber } from '../../../../../atoms';

const propMap = {
	temperature: {
		min: 0,
		max: 1,
		step: 0.01,
		dataType: 'float'
	},
	maxTokens: {
		min: 0,
		max: 168000,
		step: 1,
		valueFormatter: (value) => value.toLocaleString(),
		dataType: 'integer'
	},
	topP: {
		min: 0,
		max: 1,
		step: 0.01,
		dataType: 'float'
	},
	frequencyPenalty: {
		min: 0,
		max: 2,
		step: 0.01,
		dataType: 'float'
	},
	presencePenalty: {
		min: 0,
		max: 2,
		step: 0.01,
		dataType: 'float'
	},
};

const SliderConfig = ({ type }) => {
	const { data, loading, editPromptbook } = useContext(PromptbookContext);
	const { dataType = 'string', ...restProps } = propMap[type];

	const debounceTimer = useRef();

	const debouncedOnChange = useRef((value) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			editPromptbook(`metadata.${type}`, value, dataType);
		}, 500);
	}).current;
    
	const handleChange = (value) => {
		if (Object.keys(propMap).includes(type)) {
			debouncedOnChange(value);
		}
	};

	return (
		<SliderWithInputNumber
			{...restProps}
			value={data?.metadata?.[type]}
			onChange={handleChange}
		/>
	);
};

export default SliderConfig;
