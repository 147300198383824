import { AuthModal } from '../../components/organisms';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const AuthScreen = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (!['login', 'signup', 'reset-password', 'new-password'].includes(id)) {
			navigate('/auth/login');
		}
	}, [id]);

	return <AuthModal type={id} />;
};

export default AuthScreen;
