import Context from './Context';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../../../../../../context/GlobalContext';
import GenflowOptimizeContext from '../../../../../../../context/genflowOptimize/Context';
import { useChannel } from 'ably/react';
import { useAuthData } from '../../../../../../../hooks';

const Provider = ({ children }) => {
	const isInitialLoad = useRef(true);
	const { uniqueClientId } = useContext(GlobalContext);
	const { genflowId, optimizationId, planId } = useParams();
	const { status, targetPlanId, targetOperationList, targetOperationLoading } = useContext(GenflowOptimizeContext);
	const [current, setCurrent] = useState();
	const [hasError, setHasError] = useState(false);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}`;
	}, [genflowId, optimizationId]);

	const { data: logsData, loading: logsLoading, refresh: logsRefresh } = useAuthData(`${baseEndpoint}/simulations/tasks/results?limit=300&planIds=${planId}`);

	const { data: eventsData, loading: eventsLoading, refresh: eventsRefresh } = useAuthData(`${process.env.REACT_APP_BASE_API}/events?namespace=evaluations.${optimizationId}.${planId}`);

	const [messages, updateMessages] = useState([]);
	useChannel(uniqueClientId || 'no-client', (message) => {
		if (message.name === `evaluations/${optimizationId}/${planId}`) {
			updateMessages((prev) => [...prev, message]);
		}
	});

	useEffect(() => {
		/// TODO we have to support other plans as well....
		if (isInitialLoad.current && !targetOperationLoading) {
			const formattedHistoricalMessages = targetOperationList?.map(item => ({
				data: item
			})) || [];
			updateMessages((prev) => [...prev, ...formattedHistoricalMessages]);
			isInitialLoad.current = false;
		}
	}, [targetOperationList, targetOperationLoading]);

	const isWorking = useMemo(() => {
		if (messages?.some(message => message.data?.event === 'COMPLETION')) {
			return false;
		} else {
			return planId === targetPlanId ? status === 'TARGET_PLAN_EVALUATION' : status === 'ALT_PLANS_EVALUATIONS';
		}
	}, [targetPlanId, status, messages]);

	const contextValues = useMemo(() => ({
		isWorking,
		messages,
		hasError,
		logsList: logsData?.data?.rows || [],
		logsLoading,
		logsRefresh,
	}), [isWorking, messages, hasError, current, logsData]);

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default Provider;
