import { Typography } from 'antd';

const { Paragraph } = Typography;

const Documentation = () => {
	return (
		<Typography>
			<Paragraph>
				Explore our documentation to make the most of our tool and create efficient, effective generation flows for your AI applications.
			</Paragraph>
			<Paragraph>
				Please visit the following links:
				<ul>
					<li><a href="https://docs.teammately.ai/develop">Documentation</a> - Here you can find comprehensive guides and references to help you develop your generation flows.</li>
					<li><a href="https://docs.teammately.ai/getting-started/develop">Getting Started with Development</a> - Learn the basics of developing with our tool in step by step.</li>
					<li><a href="https://docs.teammately.ai/get-started">Getting Started Tutorial</a> - Follow this guide to get started with our tool in a structured manner.</li>
				</ul>
			</Paragraph>
		</Typography>
	);
};

export default Documentation;
