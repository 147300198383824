import { useContext, useState } from 'react';
import { filesize } from 'filesize';
import { Spin, Button, Flex, Popconfirm } from 'antd';
import {
	DeleteOutlined,
	DownloadOutlined,
	FileImageOutlined,
	FileOutlined,
	FilePdfOutlined,
	FileTextOutlined
} from '@ant-design/icons';
import { ListPageTemplate } from '../../../../components/templates';
import { RelativeTime, StorageUpload, UserTableCell } from '../../../../components/atoms';
import GlobalContext from '../../../../context/GlobalContext';
import { useAuthRequest } from '../../../../hooks';

const bodyStyle = {
	backgroundColor: 'var(--default-background)'
};

const StoragePage = ({ data, loading, refresh }) => {
	const { pushMessage } = useContext(GlobalContext);
	
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			width: 300,
		},
		{
			title: 'Uploaded at',
			dataIndex: 'uploadedAt',
			key: 'uploadedAt',
		},
		{
			title: 'Uploaded by',
			dataIndex: 'uploadedBy',
			key: 'uploadedBy',
		},
		{
			title: 'File size',
			dataIndex: 'fileSize',
			key: 'fileSize',
		},
		{
			title: 'Action',
			dataIndex: 'actions',
			key: 'actions',
		},
	];

	const { makeRequest } = useAuthRequest();

	const [downloads, setDownloads] = useState({});
	const handleDownload = async (objectId, filename) => {
		setDownloads(prev => ({ ...prev, [objectId]: true }));
		// eslint-disable-next-line no-undef
		const endpoint = `${process.env.REACT_APP_BASE_API}/storage/${objectId}/signedUrl`;
		const response = await makeRequest(endpoint, 'GET');
		if (response?.message === 'OK' && response?.url) {
			const url = response.url;
			const fetchResponse = await fetch(url);
			const blob = await fetchResponse.blob();
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			pushMessage('Failed to fetch data', 'error');
		}
		setDownloads(prev => ({ ...prev, [objectId]: false }));
	};

	const [deletes, setDeletes] = useState({});
	const handleDelete = async (objectId) => {
		setDeletes(prev => ({ ...prev, [objectId]: true }));
		// eslint-disable-next-line no-undef
		const endpoint = `${process.env.REACT_APP_BASE_API}/storage/${objectId}`;
		const response = await makeRequest(endpoint, 'DELETE');
		if (response?.message === 'OK') {
			pushMessage('A file deleted successfully', 'success');
		} else {
			pushMessage('Failed to delete, please try again later', 'error');
		}

		await refresh();
		setDeletes(prev => ({ ...prev, [objectId]: false }));
	};

	const displayData = data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.map((row) => {
			const { object_id, name, created_at, created_by, metadata } = row;

			return {
				key: object_id,
				name: (
					<Flex gap='small' align='center'>
						<FileIcon
							mimeType={metadata?.mimeType}
							objectId={object_id}
							style={{ fontSize: '24px' }}
						/>
						{name || 'No title'}
					</Flex>
				),
				uploadedAt: <RelativeTime datetime={created_at} />,
				uploadedBy: <UserTableCell userId={created_by} />,
				fileSize: metadata?.size ? filesize(metadata?.size, { standard: 'jedec' }) : '-',
				actions: (
					<Flex gap='small' align='center'>
						<Button
							type='text'
							shape='circle'
							icon={<DownloadOutlined />}
							onClick={() => handleDownload(object_id, name)}
							loading={downloads[object_id] || false}
						/>
						<Popconfirm
							title="Delete file"
							description="Are you sure to delete this file?"
							onConfirm={() => handleDelete(object_id)}
							okText="Delete"
							cancelText="Cancel"
						>
							<Button
								type='text'
								shape='circle'
								danger
								icon={<DeleteOutlined />}
								loading={deletes[object_id] || false}
							/>
						</Popconfirm>
					</Flex>
				)
			};
		});

	const buttons = [
		{
			isCustom: true,
			component: <StorageUpload type='button' onUpload={() => refresh()} />
		}
	];

	return (
		<div style={bodyStyle}>
			<Spin spinning={loading}>
				<ListPageTemplate
					title='Storage'
					buttons={buttons}
					buttonsLoading={loading}
					columns={columns}
					data={displayData}
					loading={loading.genflows}
				/>
			</Spin>
		</div>
	);
};

const FileIcon = ({ mimeType = '', objectId, ...restProps }) => {
	if (mimeType === 'application/pdf') {
		return <FilePdfOutlined {...restProps} />;
	} else if (mimeType === 'text/plain') {
		return <FileTextOutlined {...restProps} />;
	} else if (mimeType.startsWith('image/')) {
		return <FileImageOutlined {...restProps} />;
	} else {
		return <FileOutlined {...restProps} />;
	}
};

export default StoragePage;
