import { useState } from 'react';
import { Flex, Segmented, Table } from 'antd';
import { BarsOutlined, CodeOutlined } from '@ant-design/icons';
import { CodeEditor } from '../../../../../../components/atoms';

const Output = ({ data }) => {
	const items = Object.keys(data)?.map(item => ({ key: item, value: data[item] }));
	const [displayMode, setDisplayMode] = useState('table');

	const tableColumns = [
		{
			title: 'Key',
			dataIndex: 'key',
			key: 'key',
		},
		{
			title: 'Value',
			dataIndex: 'value',
			key: 'value',
		}
	];

	return (
		<Flex vertical gap='small'>
			<Flex justify='space-between' align='center' gap='small'>
				<span className='playground-component-title'>Output</span>
				<Segmented
					options={[
						{
							label: 'Table',
							value: 'table',
							icon: <BarsOutlined />,
						},
						{
							label: 'JSON',
							value: 'json',
							icon: <CodeOutlined />,
						},
					]}
					onChange={(v) => setDisplayMode(v)}
				/>
			</Flex>
			{displayMode === 'table' && (
				<Table
					size='small'
					dataSource={items}
					columns={tableColumns}
					pagination={false}
				/>
			)}
			{displayMode === 'json' && (
				<CodeEditor
					height='200px'
					defaultLanguage='json'
					defaultValue={JSON.stringify(data, null, 2) || ''}
					options={{ readOnly: true, quickSuggestions: false, wordWrap: 'on' }}
				/>
			)}
		</Flex>
	);
};

export default Output;
