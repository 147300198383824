import { useContext, useEffect, useRef, useState } from 'react';
import { Flex, Input, Select, Tag, Checkbox, Collapse, InputNumber, ConfigProvider, Radio } from 'antd';
import { EditableTitle, Loading } from '../../../../../../../../../components/atoms';
import { BsStars } from 'react-icons/bs';
import CustomMetricContext from '../context/Context';
import GenflowOptimizeContext from '../../../../../../../../../context/genflowOptimize/Context';

const typeOptions = [
	{
		value: 'YES_NO',
		label: 'Yes / No'
	},
	{
		value: 'FIVE_GRADE',
		label: '5-Grade Evaluation'
	}
];

const formTitleStyle = {
	width: 35,
	fontWeight: 600
};

const refreshRequiredKeys = ['name', 'instructionText'];

const CustomMetricEditor = () => {
	const isInitialLoad = useRef(true);
	const debounceTimer = useRef();
	const { customMetricsRefresh, isBeforeEvaluationInvoked } = useContext(GenflowOptimizeContext);
	const { data, loading, operateCustomMetric } = useContext(CustomMetricContext);
	const [localType, setLocalType] = useState();

	useEffect(() => {
		if (isInitialLoad.current && data) {
			setLocalType(data?.type);
			isInitialLoad.current = false;
		}
	}, [data]);

	const debouncedOnChange = useRef((key, value, type) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			const payload = { key, value, ...(type ? { type } : {}) };
			operateCustomMetric(payload, 'PATCH', refreshRequiredKeys.includes(key));
		}, 750);
	}).current;

	const handleChange = (key, value, type) => {
		debouncedOnChange(key, value, type);
	};

	return (loading && isInitialLoad.current) ? (
		<Loading />
	) : (
		<Flex vertical gap='middle'>
			<Flex align='center' justify='space-between'>
				<EditableTitle
					labelOnNull='Untitled custom metric'
					label={data?.name}
					onChange={(v) => handleChange('name', v)}
					disabled={!isBeforeEvaluationInvoked}
				/>
				{data?.metadata?.hasAiGenerated && (
					<Tag bordered={false} color='blue' icon={<BsStars/>}>Drafted by AI</Tag>
				)}
			</Flex>
			<span>Fields to evaluate</span>
			<Checkbox.Group
				options={[
					{ value: 'input', label: 'Input' },
					{ value: 'knowledge', label: 'Knowledge', disabled: true },
					{ value: 'output', label: 'Output' }
				]}
				defaultValue={data?.required_fields}
				onChange={async (v) => {
					await handleChange('requiredFields', v, 'json');
					await customMetricsRefresh();
				}}
				disabled={!isBeforeEvaluationInvoked}
			/>
			<span>Evaluation Instruction</span>
			<TextArea
				placeholder='Tell AI what to evaluate'
				defaultValue={data?.instruction_text}
				onChange={(v) => handleChange('instructionText', v)}
				disabled={!isBeforeEvaluationInvoked}
			/>
			<span>Type</span>
			<Select
				size='large'
				variant='filled'
				placeholder='Select the stage of evaluation'
				options={typeOptions}
				value={localType}
				onChange={(v) => {
					setLocalType(v);
					handleChange('type', v);
				}}
				disabled={!isBeforeEvaluationInvoked}
			/>
			{localType && (
				<>
					<span>Describe the criteria of evaluation for each grade</span>
					{localType === 'YES_NO' && (
						<>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>Yes</span>
								<TextArea
									placeholder='Tell definition of Yes'
									defaultValue={data?.grade_definition_texts?.['yes']}
									onChange={(v) => handleChange('gradeDefinitionTexts.yes', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>No</span>
								<TextArea
									placeholder='Tell definition of No'
									defaultValue={data?.grade_definition_texts?.['no']}
									onChange={(v) => handleChange('gradeDefinitionTexts.no', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
						</>
					)}
					{localType === 'FIVE_GRADE' && (
						<>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>5</span>
								<TextArea
									placeholder='Tell definition of 5 (highest)'
									defaultValue={data?.grade_definition_texts?.['grade5']}
									onChange={(v) => handleChange('gradeDefinitionTexts.grade5', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>4</span>
								<TextArea
									placeholder='Tell definition of 4'
									defaultValue={data?.grade_definition_texts?.['grade4']}
									onChange={(v) => handleChange('gradeDefinitionTexts.grade4', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>3</span>
								<TextArea
									placeholder='Tell definition of 3'
									defaultValue={data?.grade_definition_texts?.['grade3']}
									onChange={(v) => handleChange('gradeDefinitionTexts.grade3', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>2</span>
								<TextArea
									placeholder='Tell definition of 2'
									defaultValue={data?.grade_definition_texts?.['grade2']}
									onChange={(v) => handleChange('gradeDefinitionTexts.grade2', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
							<Flex gap='small' align='center'>
								<span style={formTitleStyle}>1</span>
								<TextArea
									placeholder='Tell definition of 1 (lowest)'
									defaultValue={data?.grade_definition_texts?.['grade1']}
									onChange={(v) => handleChange('gradeDefinitionTexts.grade1', v)}
									disabled={!isBeforeEvaluationInvoked}
								/>
							</Flex>
						</>
					)}
					<ConfigProvider
						theme={{
							components: {
								Collapse: {
									headerPadding: 0,
									contentPadding: 0
								},
							},
						}}
					>
						<Collapse ghost items={[
							{
								key: 'item',
								label: 'Examples',
								children: (
									<Flex vertical gap='middle'>
										{data?.example_texts?.map((item, index) => (
											<Flex
												key={index}
												vertical
												gap='small'
												style={{
													border: '1px solid #DDDDDD',
													padding: '10px',
													borderRadius: '8px'
												}}
											>
												<Tag bordered={false} color='var(--primary-color)'>
													Example {index + 1}
												</Tag>
												{data?.required_fields?.includes('input') && (
													<ExampleItemContainer title='Input'>
														<TextArea
															placeholder='Input examples'
															defaultValue={item?.input}
															// onChange={handleExampleChange}
															disabled={!isBeforeEvaluationInvoked}
														/>
													</ExampleItemContainer>
												)}
												{data?.required_fields?.includes('output') && (
													<ExampleItemContainer title='Output'>
														<TextArea
															placeholder='Output examples'
															defaultValue={item?.output}
															// onChange={handleExampleChange}
															disabled={!isBeforeEvaluationInvoked}
														/>
													</ExampleItemContainer>
												)}
												{localType === 'YES_NO' && (
													<ExampleItemContainer title='Verdict'>
														<Radio.Group
															defaultValue={item?.verdict}
															disabled={!isBeforeEvaluationInvoked}
														>
															<Radio value={1}>Yes</Radio>
															<Radio value={0}>No</Radio>
														</Radio.Group>
													</ExampleItemContainer>
												)}
												{localType === 'FIVE_GRADE' && (
													<ExampleItemContainer title='Score'>
														<InputNumber
															size='large'
															variant='filled'
															defaultValue={item?.verdict}
															max={5}
															min={1}
															disabled={!isBeforeEvaluationInvoked}
														// onChange={handleExampleChange}
														/>
													</ExampleItemContainer>
												)}
												<ExampleItemContainer title='Reason'>
													<TextArea
														defaultValue={item?.reason}
														disabled={!isBeforeEvaluationInvoked}
														// onChange={handleExampleChange}
													/>
												</ExampleItemContainer>
											</Flex>
										))}
									</Flex>
								)
							}
						]}/>
					</ConfigProvider>
				</>
			)}
		</Flex>
	);
};

const TextArea = (props) => {
	const {
		placeholder, defaultValue, onChange = () => {}, disabled
	} = props;

	return (
		<Input.TextArea
			size='large'
			variant='filled'
			rows={3}
			placeholder={placeholder}
			defaultValue={defaultValue}
			onChange={(e) => onChange(e.target.value)}
			disabled={disabled}
		/>
	);
};

const ExampleItemContainer = ({ title, children }) => {
	return (
		<Flex gap='small' align='center'>
			<span style={{ ...formTitleStyle, width: 50 }}>{title}</span>
			{children}
		</Flex>
	);
};

export default CustomMetricEditor;