import Context from './Context';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthData, useAuthRequest } from '../../../../../../../../../hooks';
import GenflowOptimizeContext from '../../../../../../../../../context/genflowOptimize/Context';

const CustomMetricProvider = ({ outputKey, metricId, children }) => {
	const { genflowId, optimizationId } = useParams();
	const { customMetricsRefresh } = useContext(GenflowOptimizeContext);

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/metrics/${metricId}`;
	}, [genflowId, optimizationId, metricId]);

	const {
		data,
		loading,
		refresh
	} = useAuthData(baseEndpoint, !!genflowId && !!optimizationId && !!metricId);

	const { makeRequest } = useAuthRequest();
	const operateCustomMetric = async (payload, method, shouldRefreshList = false) => {
		const response = await makeRequest(baseEndpoint, method, payload);
		await refresh();
		if (shouldRefreshList) {
			await customMetricsRefresh();
		}
		return response;
	};

	const duplicateMetric = async () => {
		return false;
	};

	const deleteMetric = async () => {
		return await operateCustomMetric(null, 'DELETE', true);
	};

	const contextValues = useMemo(() => ({
		data: data?.data?.rows?.[0] || {},
		loading,
		refresh
	}), [data, loading]);

	return (
		<Context.Provider
			value={{
				...contextValues,
				operateCustomMetric,
				duplicateMetric,
				deleteMetric
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default CustomMetricProvider;
