import { useContext, useMemo, useState, useEffect } from 'react';
import { Button, Flex, Select, Table, Tag, Tooltip } from 'antd';
import { Empty, Loading, StorageUpload } from '../../../../../../../../components/atoms';
import GlobalAuthContext from '../../../../../../../../context/GlobalAuthContext';
import { useAuthData } from '../../../../../../../../hooks';
import Papa from 'papaparse';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';

const Upload = ({ setCurrent, onFinish }) => {
	const { csvList, storageLoading, pushMessage } = useContext(GlobalAuthContext);
	const objectOptions = csvList?.map((item) => ({ value: item.object_id, label: item.name }));
	const [localObjectId, setLocalObjectId] = useState();
	const [parsedData, setParsedData] = useState([]);
	const [parsedFields, setParsedFields] = useState([]);

	const { targetPlanData, operateDatasetManualAdd } = useContext(GenflowOptimizeContext);
	const inputSchemaKeys = targetPlanData?.metadata?.input?.map(schema => schema.key) || [];
	const hasValidKey = parsedFields.some(field => inputSchemaKeys.includes(field));

	const handleChange = (value) => {
		setLocalObjectId(value);
	};

	const handleUploadResult = (objectId) => {
		setLocalObjectId(objectId);
	};

	// Environment variable for the API endpoint
	const endpoint = `${process.env.REACT_APP_BASE_API}/storage/${localObjectId}/signedUrl`;
	const { data: objectData, loading: objectLoading } = useAuthData(endpoint, !!localObjectId);

	const signedUrl = useMemo(() => objectData?.url, [localObjectId, objectData?.url]);

	// Effect to clear parsed data when localObjectId changes
	useEffect(() => {
		setParsedData([]);
		setParsedFields([]);
	}, [localObjectId]);

	// Effect to fetch and parse CSV data
	useEffect(() => {
		if (!objectLoading && localObjectId && signedUrl) {
			fetch(signedUrl)
				.then(response => response.text())
				.then(csvData => {
					Papa.parse(csvData, {
						complete: (results) => {
							setParsedFields(results.meta?.fields);
							setParsedData(results.data);
						},
						header: true,
						skipEmptyLines: true,
						dynamicTyping: true,
					});
				});
		}
	}, [localObjectId, objectLoading, signedUrl]);

	const [submitLoading, setSubmitLoading] = useState(false);
	const handleSubmit = async () => {
		setSubmitLoading(true);

		if (!hasValidKey) {
			pushMessage('Cannot proceed as we could find no valid key.', 'error');
			setSubmitLoading(false);
		} else {
			const validParsedData = parsedData?.map(row => {
				const output = {};
				const keys = Object.keys(row);
				keys.forEach((key) => {
					if (inputSchemaKeys.includes(key)) {
						output[key] = row[key];
					}
				});
				return output;
			});

			await operateDatasetManualAdd(validParsedData, 'upload');
			onFinish();
		}
	};

	return (
		<Flex vertical gap="middle">
			<StorageUpload type="drag" onUpload={handleUploadResult} />
			<Select
				size='large'
				variant='filled'
				placeholder='Choose a csv file to upload'
				loading={storageLoading}
				options={objectOptions}
				allowClear
				value={localObjectId}
				onChange={handleChange}
				notFoundContent={<Empty type="waiting" description="Upload your files!" />}
			/>
			{(parsedData && parsedData.length > 0) ? (
				<Table
					columns={parsedFields?.map(field => ({
						key: field,
						dataIndex: field,
						title: (
							<Flex align='center' gap='small'>
								<span>{field}</span>
								<Tag
									bordered={false}
									color={inputSchemaKeys.includes(field) ? 'green' : 'red'}
								>
									<Flex align='center' gap='small'>
										{inputSchemaKeys.includes(field) ? 'VALID' : 'INVALID'}
										{!inputSchemaKeys.includes(field) && (
											<Tooltip
												title='This field name is not used in input schema. Please edit your csv file, otherwise ignored.'>
												<QuestionCircleOutlined/>
											</Tooltip>
										)}
									</Flex>
								</Tag>
							</Flex>
						)
					}))}
					dataSource={parsedData}
				/>
			) : (localObjectId && objectLoading) && <Loading />}
			<Button
				block
				type='primary'
				size='large'
				disabled={!localObjectId || !hasValidKey}
				loading={submitLoading}
				onClick={handleSubmit}
				icon={<UploadOutlined />}
			>
				Use this dataset
			</Button>
		</Flex>
	);
};

export default Upload;
