import { useContext, useEffect, useRef, useState } from 'react';
import { useAuthData, useAuthRequest } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../context/GlobalContext';
import { Button, Flex, Modal, Popconfirm, Tag, Tooltip, Typography } from 'antd';
import { DeleteOutlined, KeyOutlined } from '@ant-design/icons';
import { CopyableInput, RelativeTime } from '../../../../components/atoms';
import { ListPageTemplate } from '../../../../components/templates';
import AddApiKeyForm from './AddApiKeyForm';
import dayjs from 'dayjs';

const endpoint = 'https://backend.lang.teammate.as/api-keys';

const tableConfig = {
	rowKey: ({ _id }) => _id,
};

const ApiKeys = () => {
	const isInitialLoad = useRef(true);
	const { t } = useTranslation();
	const { data, loading, refresh } = useAuthData(endpoint);
	const { pushMessage } = useContext(GlobalContext);

	useEffect(() => {
		if (!loading && data) {
			isInitialLoad.current = false;
		}
	}, [data, loading]);

	const { makeRequest } = useAuthRequest();
	const makeApiKeyRequest = async (payload, method, apiKeyId) => {
		const response = await makeRequest(
			['PUT', 'DELETE'].includes(method) ? `${endpoint}/${apiKeyId}` : endpoint,
			method,
			payload
		);
		await refresh();

		return response;
	};

	const [showAddModal, setShowAddModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const handleRequest = async (values, method, _id) => {
		try {
			await makeApiKeyRequest(values, method, _id);
		} catch (err) {
			pushMessage(err, 'error');
		}
	};

	const ActionButtons = ({ _id, isActive }) => {
		return (
			<Flex gap="small">
				{/*{isActive ? (*/}
				{/*	<Typography.Link type="danger" onClick={() => handleRequest({ isActive: false }, 'PUT', _id, 'suspend')}>*/}
				{/*		{t('suspend')}*/}
				{/*	</Typography.Link>*/}
				{/*) : (*/}
				{/*	<Typography.Link type="primary" onClick={() => handleRequest({ isActive: true }, 'PUT', _id, 'resume')}>*/}
				{/*		{t('resume')}*/}
				{/*	</Typography.Link>*/}
				{/*)}*/}
				{/*<Typography.Link type="danger" onClick={() => handleRequest(null, 'DELETE', _id, 'delete')}>*/}
				{/*	{t('terminate')}*/}
				{/*</Typography.Link>*/}
				<DeleteButton
					onClick={() => handleRequest(null, 'DELETE', _id, 'delete')}
				/>
			</Flex>
		);
	};

	const columns = [
		{
			title: 'API Key Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'API Key (masked)',
			dataIndex: 'maskedApiKey',
			key: 'maskedApiKey',
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		}
	];

	const buttons = [
		{
			label: 'New API Key',
			type: 'primary',
			icon: <KeyOutlined />,
			onClick: () => setShowAddModal(true),
		}
	];

	const onSubmit = async (values) => {
		setShowLoading(true);
		const response = await makeApiKeyRequest(values, 'POST');
		if (response.success && response.apiKey) {
			setShowAddModal(false);
			setShowLoading(false);
			informGeneratedApiKey(response.apiKey);
		} else {
			setShowLoading(false);
		}
	};

	const apisDisplayData = data?.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt)).map((api) => {
		const { _id, isActive, createdAt, ...restModels } = api;
		return {
			...restModels,
			_id,
			createdAt: <RelativeTime datetime={createdAt} />,
			status: <Tag bordered={false} color={isActive ? 'success' : 'error'}>{isActive ? 'Active' : 'Suspended'}</Tag>,
			action: (
				<ActionButtons
					_id={_id}
					isActive={isActive}
				/>
			)
		};
	});

	const informGeneratedApiKey = (generatedApiKey) => {
		Modal.success({
			title: 'API Key is generated',
			content: (
				<Flex vertical style={{ paddingBottom: 10 }}>
					<Typography.Paragraph>
						Please save this secret key somewhere safe and accessible. For security reasons, you will not be able to view it again. If you lose this key, you will need to generate a new one.
					</Typography.Paragraph>
					<CopyableInput value={generatedApiKey} messageOnCopy='Your API Key copied' />
				</Flex>
			),
			width: 600,
			okButtonProps: {
				primary: true,
				size: 'large'
			}
		});
	};

	return (
		<>
			<ListPageTemplate
				title='API Keys'
				description='Manage your keys to access Teammately APIs'
				buttons={buttons}
				columns={columns}
				data={apisDisplayData}
				loading={loading}
				tableConfig={tableConfig}
			/>
			<AddApiKeyForm
				open={showAddModal}
				onSubmit={onSubmit}
				onCancel={() => {
					setShowAddModal(false);
				}}
				loading={showLoading}
			/>
		</>
	);
};

const DeleteButton = ({ disabled, onClick }) => {
	const [loading, setLoading] = useState(false);

	const handleDelete = async() => {
		setLoading(true);
		await onClick();
		setLoading(false);
	};

	return (
		<Popconfirm
			title='Are you sure to delete this API Key?'
			description='This process cannot be undone.'
			onConfirm={handleDelete}
			okText='Delete'
			cancelText='Cancel'
		>
			<Tooltip title='Delete' color='var(--tooltip-background)'>
				<Button
					type='text'
					shape='circle'
					icon={<DeleteOutlined />}
					danger
					loading={loading}
					disabled={disabled}
				/>
			</Tooltip>
		</Popconfirm>
	);
};

export default ApiKeys;
