import { useContext, useEffect, useRef, useState } from 'react';
import { MotionDiv } from '../../../../../../components/atoms';
import './index.css';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';

const DEFAULT_EDITING_STEP_VALUE = { type: null, id: null };

function useOutsideClick(ref, callback) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (event.shiftKey || event.target.classList.contains('ignore-bookcontainer-outside-clicks')) {
				return;
			}

			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);
}

const BookContainer = ({ type, id, animationIndex, children }) => {
	const { setCurrentEditingStep } = useContext(GenflowPlanContext);

	const [isActive, setIsActive] = useState(false);
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, () => {
		setIsActive(false);
	});

	const handleClick = (event) => {
		if (event.shiftKey) {
			setIsActive(!isActive);
		} else {
			setIsActive(true);
		}
	};

	useEffect(() => {
		if (isActive) {
			setCurrentEditingStep({ type, id });
		} else {
			setCurrentEditingStep(DEFAULT_EDITING_STEP_VALUE);
		}
	}, [isActive]);

	return (
		<MotionDiv animationIndex={animationIndex}>
			<div
				ref={wrapperRef}
				onClick={handleClick}
				className={`book-container${isActive ? ' book-container-active' : ''}`}
			>
				{children}
			</div>
		</MotionDiv>
	);
};

export default BookContainer;
