import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import GlobalContext from './GlobalContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import axios from 'axios';

const GlobalProvider = ({ children }) => {
	const [workspaceId, setWorkspaceId] = useState(null);
	const [displayLanguage, setDisplayLanguage] = useState('en');
	const [showBanner, setShowBanner] = useState(false);
	const isInitialMount = useRef(true);

	const [jwt, setJwt] = useState();
	const [tokenManager, setTokenManager] = useState();

	const [userId, setUserId] = useState();
	const [user, setUser] = useState({});
	const [userLoading, setUserLoading] = useState(false);

	const userEndpoint = 'https://backend.lang.teammate.as/users';
	const headers = useMemo(() => ({
		Authorization: `Bearer ${tokenManager?.accessToken || sessionStorage.getItem('accessToken') || ''}`,
		'User-ID': userId
	}), [tokenManager?.accessToken, sessionStorage.getItem('accessToken'), userId]);

	useEffect(() => {
		async function fetchData() {
			if (tokenManager?.accessToken || sessionStorage.getItem('accessToken')) {
				setUserLoading(true);
				try {
					const response = await axios.get(userEndpoint, { headers });
					if (response?.status === 200) {
						setUser(response.data);
						setUserId(response.data.uid);

						const initialWorkspace = response.data?.primaryWorkspace || response.data?.workspaces?.[0]?._id;
						setWorkspaceId(initialWorkspace);
					}
				} catch (error) {
					console.error('User Fetch Error:', error);
					redirectToAuth();
				}
				setUserLoading(false);
			} else if (!window.location.pathname.startsWith('/auth')) {
				redirectToAuth();
			}
		}
		fetchData();
	}, [userId, tokenManager?.accessToken, sessionStorage.getItem('accessToken')]);

	const fetchUserData = useCallback(async () => {
		if (tokenManager?.accessToken || sessionStorage.getItem('accessToken')) {
			setUserLoading(true);
			try {
				const response = await axios.get(userEndpoint, { headers });
				if (response?.status === 200) {
					setUser(response.data);
					setUserId(response.data.uid);

					const initialWorkspace = response.data?.primaryWorkspace || response.data?.workspaces?.[0]?._id;
					setWorkspaceId(initialWorkspace);
				}
			} catch (error) {
				console.error('User Fetch Error:', error);
			}
			setUserLoading(false);
		}
	}, [userId, tokenManager?.accessToken, sessionStorage.getItem('accessToken')]);

	const userRefresh = async () => {
		await fetchUserData();
	};

	const { i18n } = useTranslation();

	const refreshJwt = () => {};

	useEffect(()=> {
		if (!userLoading) { // && isInitialMount.current
			setDisplayLanguage(user?.language || 'en');
			i18n.changeLanguage(user?.language || 'en');

			const initialWorkspace = user?.primaryWorkspace || user?.workspaces?.[0]?._id;
			setWorkspaceId(initialWorkspace);
			isInitialMount.current = !initialWorkspace;
		}
	}, [userId]);

	const [workspaceData, setWorkspaceData] = useState();
	const [workspaceLoading, setWorkspaceLoading] = useState(true);
	const [workspaceError, setWorkspaceError] = useState();
	
	useEffect(() => {
		async function fetchWorkspaceData() {
			setWorkspaceLoading(true);
			setWorkspaceError(false);

			if (!(tokenManager?.accessToken || sessionStorage.getItem('accessToken')) || !workspaceId || !userId) {
				setWorkspaceLoading(false);
				return;
			}

			const headers = {
				Authorization: `Bearer ${tokenManager?.accessToken || sessionStorage.getItem('accessToken') || ''}`,
				'Workspace-ID': workspaceId,
				'User-ID': userId,
			};

			// eslint-disable-next-line no-undef
			const endpoint = `${process.env.REACT_APP_BASE_API}/workspaces`;
			axios.get(endpoint, { headers })
				.then(response => {
					setWorkspaceData(response?.data?.data);
				})
				.catch(e => {
					console.log(e);
					setWorkspaceError(true);
				})
				.finally(() => {
					setWorkspaceLoading(false);
				});
		}

		fetchWorkspaceData();
	}, [userId, workspaceId, tokenManager?.accessToken, sessionStorage.getItem('accessToken')]);

	const navigate = useNavigate();
	const redirectToAuth = () => {
		const AUTH_ENDPOINT = '/auth/login';
		const currentSafePath = !window.location.pathname.startsWith('/auth') ? window.location.pathname : '';
		const authUrl = `${AUTH_ENDPOINT}${!window.location.pathname.startsWith('/auth') ? `?callback=${encodeURIComponent(currentSafePath)}` : ''}`;
		navigate(authUrl);
	};

	const [messageApi, contextHolder] = message.useMessage();
	const messageQueue = useRef(new Map());

	const pushMessage = (message, type = 'info') => {
		const now = Date.now();
		const key = `${type}-${message}`;
		const lastShown = messageQueue.current.get(key);

		if (!lastShown || now - lastShown > 3000) {
			messageApi[type](message);
			messageQueue.current.set(key, now);
		}
	};

	const uniqueClientId = useMemo(() => {
		return userId && workspaceId ? `${workspaceId}:${userId}` : 'no-client';
	}, [userId, workspaceId]);

	const [collapsed, setCollapsed] = useState(false);

	const memoContextValues = useMemo(() => ({
		workspaceId,
		setWorkspaceId,
		workspace: workspaceData,
		workspaceLoading,
		// workspaceRefresh,
		userId,
		setUserId,
		user,
		userLoading,
		userRefresh,
		jwt,
		setJwt,
		refreshJwt,
		tokenManager,
		setTokenManager,
		displayLanguage,
		setDisplayLanguage,
		uniqueClientId,
	}), [workspaceId, workspaceData, user, userId, jwt, displayLanguage, uniqueClientId]);

	const unMemoContextValues = {
		showBanner,
		setShowBanner,
		collapsed,
		setCollapsed,
		redirectToAuth,
		pushMessage,
	};

	const contextValues = {
		...memoContextValues,
		...unMemoContextValues
	};

	return (
		<GlobalContext.Provider value={contextValues}>
			{contextHolder}
			{children}
		</GlobalContext.Provider>
	);
};

export default GlobalProvider;
