import axios from 'axios';

// todo this script should not be here, but in hooks
const makeRequestWithFreshJwt = async (endpoint, method, payload, token) => {
	const headers = {
		Authorization: `Bearer ${token}`
	};

	const response = await axios({
		method,
		url: endpoint,
		headers,
		data: payload
	});

	if (response.status !== 200 && response.status !== 201) {
		return { success: false, reason: response.message };
	} else {
		return response.data;
	}
};

export default makeRequestWithFreshJwt;
