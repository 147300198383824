import { useNavigate } from 'react-router-dom';
import { Button, Flex } from 'antd';
import { CardContainer } from '../../../../components';
import { ArrowRightOutlined, CheckOutlined, DoubleRightOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import AdminContext from '../../../../contexts/Context';
import './index.css';
import { MotionDiv } from '../../../../../../components/atoms';

const items = [
	{
		key: 'id1',
		title: 'Title',
		description: 'Description',
		refPath: '/genflows'
	},
	{
		key: 'id2',
		title: 'Title',
		description: 'Description',
		refPath: '/genflows'
	},
	{
		key: 'id3',
		title: 'Title',
		description: 'Description'
	}
];

const GetStartedCard = ({ animationIndex }) => {
	const { genflowList, genflowLoading, createGenflow } = useContext(AdminContext);
	const firstGenflowId = genflowList?.[0]?.genflow_id;
	const { getStartedTasksList, markGetStartedTaskDone } = useContext(AdminContext);

	const navigate = useNavigate();
	const [toggle, setToggle] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const allItems = [
		{
			id: 'create-genflow',
			title: 'Create a new generation flow',
			description: 'Start your AI project by creating a new generation flow. This is the foundation of your workflow, where you\'ll define input variables and set up the initial structure for your AI-powered process.',
			startText: 'Create new',
			onClickStart: createGenflow,
			docsLink: 'https://docs.teammately.ai/getting-started/develop#create-a-new-generation-flow'
		},
		{
			id: 'create-promptbook',
			title: 'Create a new prompt book',
			description: 'Craft and refine your AI prompts in the Prompt Book. Here, you can create multiple prompt sections, configure AI models, and fine-tune advanced settings to optimize your generation flow.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/develop`,
			docsLink: 'https://docs.teammately.ai/getting-started/develop#create-a-new-prompt-book'
		},
		{
			id: 'create-knowledgebook',
			title: 'Create a new knowledge book',
			description: 'Enhance your AI\'s knowledge with the Knowledge Book. Upload relevant files, configure chunking strategies, and set up retrieval methods to augment your AI\'s responses with external information.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/develop`,
			docsLink: 'https://docs.teammately.ai/getting-started/develop#create-a-new-knowledge-book'
		},
		{
			id: 'test-on-playground',
			title: 'Test on playground',
			description: 'Execute and refine your generation flow in real-time using the Playground. Input test values, observe the process timeline, and view outputs in various formats to ensure your AI performs as expected.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/develop`,
			docsLink: 'https://docs.teammately.ai/getting-started/develop#test-on-the-playground'
		},
		{
			id: 'generate-input-dataset',
			title: 'Let AI generate input dataset',
			description: 'Create a comprehensive dataset for evaluating your generation flow. AI can generate diverse inputs based on your plan to ensure a robust evaluation process.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#generate-input-dataset'
		},
		{
			id: 'generate-custom-metrics',
			title: 'Let AI generate custom metrics',
			description: 'Define evaluation metrics tailored to your specific needs. AI can suggest custom metrics, and you can also incorporate your own context to create metrics that assess output quality, relevance, and other crucial factors for your generation flow.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#generate-custom-metrics'
		},
		{
			id: 'evaluate-target-plan',
			title: 'Evaluate Target Plan',
			description: 'Run your initial plan through the evaluation process using the dataset and metrics you\'ve set up. Review the results in logs and score visualizations to understand your plan\'s performance.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#evaluate-target-plan'
		},
		{
			id: 'review-alt-plan',
			title: 'Review Alternative Plans',
			description: 'Examine AI-generated improvement suggestions based on your evaluation results. Select recommended plans or add existing ones for further comparison and optimization.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#review-alternative-plans'
		},
		{
			id: 'evaluate-alt-plan',
			title: 'Evaluate Alternative Plans',
			description: 'Compare the performance of your original plan with selected alternatives across all metrics. Analyze the results to identify strengths and weaknesses of each approach.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#evaluate-alternative-plans'
		},
		{
			id: 'choose-the-best',
			title: 'Choose the best plan',
			description: 'Make an informed decision on which plan to implement based on the comprehensive evaluation results. Consider overall performance, specific metric improvements, and potential trade-offs to select the optimal plan for your needs.',
			navigateTo: genflowLoading ? '/genflows' : `/genflows/${firstGenflowId}/optimize`,
			docsLink: 'https://docs.teammately.ai/getting-started/optimize#choose-the-best-plan-for-deployment'
		},
	];

	const [markLoadingTasks, setMarkLoadingTasks] = useState([]);
	const handleMarkComplete = async (taskId) => {
		setMarkLoadingTasks([...markLoadingTasks, taskId]);

		await markGetStartedTaskDone(taskId);

		setMarkLoadingTasks(markLoadingTasks?.filter(id => id !== taskId));
	};

	const displayTasks = allItems?.filter(item => getStartedTasksList?.every(task => task.task_id !== item.id))?.slice(0, 3) || [];

	return (
		<CardContainer
			title='Get started'
			description='Your AI journey starts here'
			type='gradiant'
			animationIndex={animationIndex}
		>
			<Flex gap='middle' justify='space-between'>
				<AnimatePresence>
					{displayTasks.map((item, index) => (
						<>
							<MotionDiv
								key={item.id}
								layout
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.3, ease: 'easeInOut' }}
								className='get-started-mini-card-container'
							>
								<Flex
									vertical
									style={{ height: '100%' }}
									gap='small'
									justify='space-between'
								>
									<Flex vertical gap='small'>
										<span style={{ fontWeight: 600, fontSize: 20 }}>{item.title}</span>
										<span>{item.description}</span>
									</Flex>
									<Flex gap='small' justify='flex-end'>
										<Button
											type='primary'
											icon={<CheckOutlined/>}
											loading={markLoadingTasks.includes(item.id)}
											onClick={() => handleMarkComplete(item.id)}
										>
										Check done
										</Button>
									</Flex>
								</Flex>
								{index !== (items.length - 1) && (
									<DoubleRightOutlined style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 24 }}/>
								)}
							</MotionDiv>
						</>
					))}
				</AnimatePresence>
			</Flex>
			{/*<AnimatePresence>*/}
			{/*	{!isClicked && (*/}
			{/*		<div onClick={() => setIsClicked(true)}>*/}
			{/*			<motion.div*/}
			{/*				animate={{ x: 0 }}*/}
			{/*				exit={{ x: toggle ? '100vw' : '-100vw' }}*/}
			{/*			>*/}
			{/*				<motion.div*/}
			{/*					initial={{ x: toggle ? 0 : 'calc(100vw - 350px)' }}*/}
			{/*					animate={{ x: toggle ? 'calc(100vw - 350px)' : 0 }}*/}
			{/*					transition={{*/}
			{/*						x: {*/}
			{/*							type: 'tween',*/}
			{/*							stiffness: 300,*/}
			{/*							duration: 5,*/}
			{/*						}*/}
			{/*					}}*/}
			{/*					onAnimationComplete={() => setToggle(!toggle)}*/}
			{/*				>*/}
			{/*					<img*/}
			{/*						src='/images/rocket.png'*/}
			{/*						alt='Generating'*/}
			{/*						style={{ ...(toggle ? { transform: 'scaleX(-1)' } : {}) }}*/}
			{/*						width={75}*/}
			{/*					/>*/}
			{/*				</motion.div>*/}
			{/*			</motion.div>*/}
			{/*		</div>*/}
			{/*	)}*/}
			{/*</AnimatePresence>*/}
		</CardContainer>
	);

};

export default GetStartedCard;
