import { useContext, useState } from 'react';
import { Button, Flex, Tag, Tooltip } from 'antd';
import { Empty, Loading, MotionDiv } from '../../../../../../components/atoms';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import GenflowDetailContext from '../../../../../../context/genflowDetail/Context';
import Icon, {
	CheckOutlined,
	FileSearchOutlined,
	LineChartOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FiTool } from 'react-icons/fi';
import { Bs1Square, Bs2Square, Bs3Square, Bs4Square, Bs5Square, Bs6Square, Bs7Square, BsStars } from 'react-icons/bs';
import './index.css';

const iconStyles = { fontSize: 20, color: 'var(--teammately-orange)' };
const numberedIconsMap = {
	1: Bs1Square,
	2: Bs2Square,
	3: Bs3Square,
	4: Bs4Square,
	5: Bs5Square,
	6: Bs6Square,
	7: Bs7Square,
};

const FinalReports = (props) => {
	const { reportData = {}, reportLoading, aggregatedResultsData = [], aggregatedResultsLoading } = props;
	const { analysis, rankings } = reportData;

	return (
		<>
			<Flex gap='large' vertical>
				<Card title='Final rankings judged by AI' animationIndex={1}>
					{(rankings && rankings?.length > 0) ? (
						<Flex gap='small' vertical>
							{rankings?.map((item, index) => (
								<MotionDiv key={item.plan_id} animationIndex={index}>
									<RankingContainer {...item} scores={aggregatedResultsData} scoresLoading={aggregatedResultsLoading} />
								</MotionDiv>
							))}
						</Flex>
					) : (
						<div style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{reportLoading ? <Loading/> : <Empty type='building' description='Waiting for final judge by AI'/>}
						</div>
					)}
				</Card>
				<Card title='AI Analysis' animationIndex={2}>
					{analysis ? (
						<span style={{ fontSize: 16, lineHeight: '24px' }} dangerouslySetInnerHTML={{
							__html: analysis
								.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Replace **text** with <b>text</b>
								.replace(/\n/g, '<br />')              // Replace newline characters with <br />
						}}></span>
					) : reportLoading
						? <Loading/>
						: <Empty type='waiting' description='Waiting for analysis by AI' />
					}
				</Card>
			</Flex>
		</>
	);
};

const Card = ({ title, animationIndex, children }) => {
	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex vertical gap='small' className='card-recommendations'>
				<span style={{ fontSize: 24, fontWeight: 600 }}>{title}</span>
				{children}
			</Flex>
		</MotionDiv>
	);
};

const RankingContainer = ({ rank, plan_id, reason_for_decision, scores, scoresLoading }) => {
	const { genflowId, plansList } = useContext(GenflowDetailContext);
	const { data, targetPlanId, customMetricsList, operateOptimize } = useContext(GenflowOptimizeContext);
	const thisPlanData = plansList?.find(item => item.genflow_plan_id === plan_id);
	const navigate = useNavigate();

	const displayScores = scores?.filter(item => item.agg_method === 'average')?.map((item) => {
		return {
			module_key: item.module_key,
			metric_key: item.metric_key,
			agg_method: item.agg_method,
			score: item.scores?.[plan_id]
		};
	});

	const currentBestPlanId = data?.metadata?.bestPlan;
	const [loading, setLoading] = useState(false);
	const handleMarkBest = async () => {
		setLoading(true);

		const payload = {
			key: 'metadata.bestPlan',
			value: plan_id
		};

		await operateOptimize(payload, 'PATCH');

		setLoading(false);
	};

	return (
		<div style={{ border: '1px solid #DDDDDD', padding: '10px', borderRadius: '10px' }}>
			<Flex gap='small' vertical>
				<Flex gap='small' align='flex-start' justify='space-between'>
					<Flex gap='small' align='flex-start'>
						<Icon component={numberedIconsMap[rank]} style={iconStyles}/>
						{targetPlanId === plan_id ? (
							<Tag bordered={false} color='magenta-inverse'>ORIGINAL</Tag>
						) : (
							<Tag bordered={false} color='cyan-inverse'>ALTERNATIVE</Tag>
						)}
						<span style={{ fontWeight: 600 }}>{thisPlanData?.name}</span>
					</Flex>
					<Flex gap='small' align='center'>
						<Tooltip title='Logs' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={FileSearchOutlined} />}
								onClick={() => navigate(`../experiments/${plan_id}/log`)}
							/>
						</Tooltip>
						<Tooltip title='Scores' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={LineChartOutlined} />}
								onClick={() => navigate(`../experiments/${plan_id}/scores`)}
							/>
						</Tooltip>
						<Tooltip title='Develop' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={FiTool} />}
								onClick={() => navigate(`/genflows/${genflowId}/develop/${plan_id}`)}
							/>
						</Tooltip>
						<Button
							type='primary'
							icon={<CheckOutlined />}
							loading={loading}
							disabled={currentBestPlanId === plan_id}
							onClick={handleMarkBest}
						>
							{currentBestPlanId === plan_id ? 'Marked as the best' : 'Mark as the best'}
						</Button>
					</Flex>
				</Flex>
				<Flex align='flex-start'>
					<Tag bordered={false}>Reason</Tag>
					<span>{reason_for_decision}</span>
				</Flex>
				<Flex align='flex-start'>
					<Tag bordered={false}>Avg. Scores</Tag>
					<Flex wrap gap='small'>
						{displayScores?.map(item => {
							const metricKey = item.metric_key;
							const isCustom = metricKey?.startsWith('custom/');
							const thisCustomMetricData = isCustom ? customMetricsList?.find(metric => metric.metric_id === metricKey?.replace('custom/', '')) : {};

							return (
								<Flex wrap={false} key={metricKey}>
									<Tag bordered={false} color='blue'>
										<Flex gap={3} align='center'>
											<span>{isCustom ? thisCustomMetricData?.name : metricKey}</span>
											{isCustom && (
												<Tooltip color='var(--tooltip-background)' title={thisCustomMetricData?.instruction_text}>
													<QuestionCircleOutlined />
												</Tooltip>
											)}
										</Flex>
									</Tag>
									<Flex wrap={false} gap={3}>
										<span>{item.score}</span>
									</Flex>
								</Flex>
							);
						})}
					</Flex>
				</Flex>
			</Flex>
		</div>
	);
};

export default FinalReports;
