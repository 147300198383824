import Presentation from './Presentation';
import { useAuthData } from '../../../../hooks';

const endpoint = `${process.env.REACT_APP_BASE_API}/storage`;

const Container = () => {
	const { data, loading, refresh } = useAuthData(endpoint);

	return <Presentation
		data={data?.data?.rows || []}
		loading={loading}
		refresh={refresh}
	/>;
};

export default Container;
