import { createContext } from 'react';

const GlobalAuthContext = createContext({
	storageList: [],
	storageLoading: true,
	storageRefresh: () => {},
	txtList: [],
	csvList: [],
	imageList: [],
	modelList: [],
	modelLoading: true,
	modelRefresh: () => {},
	chatModelList: [],
	chatModelLoading: true,
	chatModelRefresh: () => {},
	embeddingModelList: [],
	embeddingModelLoading: true,
	embeddingModelRefresh: () => {}
});

export default GlobalAuthContext;
