import { Flex } from 'antd';
import { WorkspaceSelect, DropdownHelp, DropdownWithAvatar } from './components';
import { useNavigate } from 'react-router-dom';

const LOGO_URL = '/favicon.png';

const bodyStyle = {
	position: 'fixed',
	top: 0,
	zIndex: 10,
	display: 'flex',
	alignItems: 'center',
	margin: 0,
	padding: '0 16px',
	height: '50px',
	width: '100%',
	backgroundColor: '#FFF',
	borderBottom: '1px solid rgb(232, 237, 235)',
	boxSizing: 'border-box',
};

const App = () => {
	const navigate = useNavigate();
	return (
		<div
			style={bodyStyle}
		>
			<Flex justify='space-between' align='flex-end' style={{ width: '100%', paddingLeft: 10 }}>
				<a onClick={() => navigate('/')}>
					<img
						src={LOGO_URL}
						alt='Teammately logo'
						style={{
							height: '40px',
							objectFit: 'contain',
							cursor: 'pointer',
						}}
					/>
				</a>
				<Flex
					gap='small'
					justify='flex-end'
					align='center'
				>
					<WorkspaceSelect />
					<DropdownHelp />
					<DropdownWithAvatar />
				</Flex>
			</Flex>
		</div>
	);
};

export default App;
