import { MetricCardContainer } from '../../index';
import { Button, Dropdown, Flex, Select, Tag, Tooltip } from 'antd';
import { Editors, MotionDiv } from '../../../../../../../../../components/atoms';
import { BsStars } from 'react-icons/bs';
import { useContext, useEffect, useRef, useState } from 'react';
import GenflowOptimizeContext from '../../../../../../../../../context/genflowOptimize/Context';
import CustomMetricContext from '../context/Context';
import {
	DeleteOutlined,
	EditOutlined,
	MoreOutlined,
	UnorderedListOutlined
} from '@ant-design/icons';
import { IoDuplicateOutline } from 'react-icons/io5';

const CustomMetricCard = (props) => {
	const { handleOpen = () => {}, outputKey } = props;
	const { data: optimizeData, loading: optimizeLoading, targetPlanData, operateOptimize } = useContext(GenflowOptimizeContext);
	const { data: metricData, loading: metricLoading, operateCustomMetric, duplicateMetric, deleteMetric } = useContext(CustomMetricContext);
	const targetModules = optimizeData?.metadata?.evaluationModules?.[outputKey];
	const [localSelected, setLocalSelected] = useState(false);
	const [localInputSchema, setLocalInputSchema] = useState();
	const isInitialLoad = useRef(true);

	useEffect(() => {
		if (isInitialLoad.current && !optimizeLoading && targetModules && Object.keys(targetModules)?.length > 0 && !metricLoading) {
			setLocalSelected(Object.keys(targetModules)?.includes(`custom/${metricData?.metric_id}`));
			isInitialLoad.current = false;
		}
	}, [optimizeLoading, metricLoading, targetModules]);

	useEffect(() => {
		if (optimizeData?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`]?.input) {
			setLocalInputSchema(optimizeData?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`]?.input);
		}
	}, [optimizeData?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`]?.input]);

	const handleSelect = (v) => {
		setLocalSelected(v);
		handleMetric(metricData?.metric_id, v);
	};

	const handleMetric = async (dimension, value) => {
		if (value) {
			await handleAddMetric(dimension, value);
		} else if (value === false) {
			await handleRemoveMetric(dimension, value);
		}
	};

	const handleAddMetric = async (key, value) => {
		const payload = {
			key: 'metadata.evaluationModules',
			value: {
				...optimizeData?.metadata?.evaluationModules,
				[outputKey]: {
					...optimizeData?.metadata?.evaluationModules?.[outputKey],
					[`custom/${key}`]: value
				}
			},
			type: 'json'
		};

		await operateOptimize(payload, 'PATCH');
	};

	const handleRemoveMetric = async (key) => {
		const newMetrics = {};
		const targetModules = optimizeData?.metadata?.evaluationModules?.[outputKey];
		if (targetModules && Object.keys(targetModules)?.length > 0) {
			Object.keys(targetModules)?.forEach(metricKey => {
				if (metricKey !== `custom/${metricData?.metric_id}`) {
					newMetrics[metricKey] = targetModules[metricKey];
				}
			});

			const payload = {
				key: 'metadata.evaluationModules',
				value: {
					...optimizeData?.metadata?.evaluationModules,
					[outputKey]: newMetrics
				},
				type: 'json'
			};

			await operateOptimize(payload, 'PATCH');
		}
	};

	const handleUpdateSchema = async (value) => {
		setLocalInputSchema(value);

		const payload = {
			key: 'metadata.evaluationModules',
			value: {
				...optimizeData?.metadata?.evaluationModules,
				[outputKey]: {
					...optimizeData?.metadata?.evaluationModules?.[outputKey],
					[`custom/${metricData?.metric_id}`]: {
						...optimizeData?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`],
						input: value
					}
				}
			},
			type: 'json'
		};

		await operateOptimize(payload, 'PATCH');
	};

	const inputKeyOptions = (targetPlanData?.metadata?.input && targetPlanData?.metadata?.input?.length > 0)
		? targetPlanData?.metadata?.input?.map((inputItem) => ({ value: inputItem.key, label: inputItem.key }))
		: [];

	// todo only initial loading
	return (metricData?.metric_id) ? (
		<MotionDiv
			key={metricData?.metric_id}
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
			style={{
				width: 'calc(33.33% - 10px)',
			}}
		>
			<MetricCardContainer
				title={metricData?.name || 'New custom metric'}
				description={metricData?.instruction_text || '(No instruction defined)'}
				selected={localSelected}
				onSelect={handleSelect}
				style={{
					width: '100%'
				}}
			>
				{(metricData?.required_fields && metricData?.required_fields?.length > 0) && (
					<Flex wrap>
						{metricData?.required_fields?.map(field => (
							<Tag key={field} bordered={false} color='magenta'>{field}</Tag>
						))}
					</Flex>
				)}
				<Flex align='center' justify='space-between'>
					<Flex align='center' gap='small'>
						<span>Editors</span>
						<Editors editors={metricData?.editors} />
					</Flex>
					{metricData?.metadata?.hasAiGenerated && (
						<div>
							<Tag bordered={false} color='blue' icon={<BsStars />}>Drafted by AI</Tag>
						</div>
					)}
				</Flex>
				{metricData?.required_fields?.includes('input') && (
					<Flex gap='small' align='center'>
						<span>Input</span>
						<Tooltip
							color='var(--tooltip-background)'
							title={!localSelected ? 'Enable this metric first' : null}
						>
							<Select
								variant='filled'
								style={{ width: '100%' }}
								placeholder='Input variable for eval'
								value={optimizeData?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`]?.input || localInputSchema}
								options={inputKeyOptions}
								disabled={!localSelected}
								status={(localSelected && !localInputSchema) ? 'error' : null}
								onChange={handleUpdateSchema}
							/>
						</Tooltip>
					</Flex>
				)}
				<Flex gap='small' align='center' justify='space-between'>
					<Button onClick={handleOpen} icon={<UnorderedListOutlined />} block>See instructions</Button>
					<DropdownMenu
						onClickEdit={handleOpen}
						onClickDuplicate={duplicateMetric}
						onClickDelete={deleteMetric}
					/>
				</Flex>
				{/*{required_fields?.includes('knowledge') && (*/}
				{/*	<Flex gap='small' align='center'>*/}
				{/*		<span>Input</span>*/}
				{/*		<Select*/}
				{/*			variant='filled'*/}
				{/*			style={{ width: '100%' }}*/}
				{/*			placeholder='Knowledgebook for eval'*/}
				{/*			value={data?.metadata?.evaluationModules?.[outputKey]?.[`custom/${metricData?.metric_id}`]?.knowledge}*/}
				{/*			options={inputKeyOptions}*/}
				{/*			disabled={!localSelected}*/}
				{/*			status={(localSelected && !metricData?.type) ? 'error' : null}*/}
				{/*			onChange={handleUpdateSchema}*/}
				{/*		/>*/}
				{/*	</Flex>*/}
				{/*)}*/}
			</MetricCardContainer>
		</MotionDiv>
	) : null;
};

const DropdownMenu = (props) => {
	const { onClickEdit, onClickDuplicate, onClickDelete } = props;
	const [loadingKeys, setLoadingKeys] = useState([]);

	const buttonItems = [
		{
			label: 'Edit',
			key: 'edit',
			icon: <EditOutlined />,
			onClick: onClickEdit,
		},
		{
			label: 'Duplicate',
			key: 'duplicate',
			icon: <IoDuplicateOutline />,
			loading: loadingKeys.includes('duplicate'),
			disabled: true,
			onClick: async () => {
				setLoadingKeys([...loadingKeys, 'duplicate']);
				await onClickDuplicate();
				setLoadingKeys(loadingKeys.filter((key) => key !== 'duplicate'));
			},
		},
		{
			label: 'Delete',
			key: 'delete',
			icon: <DeleteOutlined />,
			loading: loadingKeys.includes('delete'),
			danger: true,
			onClick: async () => {
				setLoadingKeys([...loadingKeys, 'delete']);
				await onClickDelete();
				setLoadingKeys(loadingKeys.filter((key) => key !== 'delete'));
			},
		}
	];

	return (
		<Dropdown
			menu={{
				items: buttonItems,
			}}
			trigger={['click']}
			placement='bottomRight'
		>
			<Button type='text' shape='circle' icon={<MoreOutlined />} />
		</Dropdown>
	);
};

export default CustomMetricCard;
