import { useState } from 'react';
import { Button, Form, Select, Input, InputNumber, Checkbox, Switch, Slider, Radio } from 'antd';
import { InputName, SliderWithInputNumber } from '../../atoms';
import { useAuthRequest } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';

// TODO: deepCompare
const FormWithRequest = (props) => {
	const { t } = useTranslation();
	const { formConfig, formItems = [], submitButtonText = t('button.save'), submitButtonConfig, requestEndpoint, requestMethod, onSuccess = () => {}, onFailed = () => {} } = props;
	const { makeRequest } = useAuthRequest();
	const [ requestLoading, setRequestLoading ] = useState(false);

	const makeEndpointRequest = async (payload) => {
		setRequestLoading(true);
		const response = await makeRequest(requestEndpoint, requestMethod, payload);
		setRequestLoading(false);

		return response;
	};

	const onFinish = async (values) => {
		const res = await makeEndpointRequest(values);

		if (res.success) {
			onSuccess({ ...res, formValues: values });
		} else {
			onFailed({ ...res, formValues: values });
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			style={{ width: '100%' }}
			{...formConfig}
		>
			{formItems?.map(renderFormItem)}
			<Form.Item
				wrapperCol={{
					span: 24,
				}}
			>
				<Button type='primary' size='large' htmlType='submit' icon={<SaveOutlined />} loading={requestLoading} {...submitButtonConfig} >
					{submitButtonText}
				</Button>
			</Form.Item>
		</Form>
	);
};

const renderInputComponent = ({ type, ...restProps }) => {
	switch (type) {
	case 'input':
		return <Input {...restProps} />;
	case 'input-password':
		return <Input.Password {...restProps} />;
	case 'input-textarea':
		return <Input.TextArea {...restProps} />;
	case 'input-number':
		return <InputNumber {...restProps} />;
	case 'input-name':
		return <InputName {...restProps} />;
	case 'select':
		return <Select {...restProps} />;
	case 'single-checkbox':
		return <Checkbox {...restProps} />;
	case 'multiple-checkbox':
		return <Checkbox.Group {...restProps} />;
	case 'radio':
		return <Radio {...restProps} />;
	case 'radio-group':
		return <Radio.Group {...restProps} />;
	case 'switch':
		return <Switch {...restProps} />;
	case 'slider':
		return <Slider {...restProps} />;
	case 'slider-with-input-number':
		return <SliderWithInputNumber {...restProps} />;
	default:
		return null;
	}
};

const renderFormItem = (formItem, index) => {
	const { label, name, rules, item, condition = true, formItemConfig } = formItem;
	if (!condition) return null;

	if (item?.type === 'hidden') {
		return <Form.Item key={name} name={name} hidden />;
	}

	return (
		<Form.Item
			key={name}
			label={label}
			name={name}
			rules={rules}
			{...formItemConfig}
		>
			{renderInputComponent(item)}
		</Form.Item>
	);
};

export default FormWithRequest;
