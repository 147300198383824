import { useContext, useEffect, useState } from 'react';
import { Spin, Form } from 'antd';
import { FormWithRequest } from '../../../../components/molecules';
import { useTranslation } from 'react-i18next';
import i18nCountries from 'i18n-iso-countries';
import countries from '../../../../models/static/countries';
import GlobalContext from '../../../../context/GlobalContext';
import AdminContext from '../../contexts/Context';
import { Loading } from '../../../../components/atoms';
import { CardContainer } from '../../components';

const universalFormItemProps = {
	size: 'large',
	variant: 'filled'
};

const UserSettings = (props) => {
	const { t, i18n } = useTranslation();
	const { pushMessage } = useContext(GlobalContext);

	const { submitButtonText = t('button.save'), isOnboard = false } = props;

	const { userData, userLoading, userRefresh } = useContext(AdminContext);

	const countriesOptions = countries?.map(({ value }) => {
		if (['HK', 'MO', 'TW'].includes(value)) {
			return { value, label: t(`country.china-${value.toLowerCase()}`) };
		}
		return { value, label: i18nCountries.getName(value, 'en') };
	}).sort((a, b) => a.label.localeCompare(b.label));

	const [formValues, setFormValues] = useState(userData);

	useEffect(() => {
		if (userData) {
			setFormValues(userData);
		}
	}, [userData]);

	const [form] = Form.useForm();
	const formConfig = {
		form,
		name: 'onboard',
		labelCol: {
			span: 6,
		},
		wrapperCol: {
			span: 18,
		},
		labelWrap: true,
		colon: false,
		layout: 'horizontal',
		preserve: false,
		initialValues: {
			...userData,
			name: userData?.name,
			avatarUrl: userData?.providerData?.[0].photoURL,
			acceptNewsletter: true,
			hasOnboarded: true,
		},
		onValuesChange: (changedFields, allFields) => {
			setFormValues(allFields);
		}
	};

	const formItems = [
		{
			name: 'avatarUrl',
			item: {
				type: 'hidden'
			}
		},
		{
			label: t('form.label.user-name'),
			name: 'name',
			rules: [
				{
					required: true,
					message: t('form.rules.user-name.required'),
				}
			],
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: t('form.placeholder.user-name'),
			}
		},
		{
			label: t('form.label.country-or-region'),
			name: 'countryOrRegion',
			rules: [
				{
					required: true,
					message: t('form.rules.country-or-region.required'),
				}
			],
			item: {
				...universalFormItemProps,
				type: 'select',
				placeholder: t('form.placeholder.country-or-region'),
				options: countriesOptions,
				showSearch: true,
				optionFilterProp: 'label',
			},
		},
		{
			label: t('form.label.org-type'),
			name: 'type',
			item: {
				...universalFormItemProps,
				type: 'radio-group',
				placeholder: t('form.placeholder.org-type'),
				options: [
					{
						label: t('form.options.org-type.individual'),
						value: 'individual'
					},
					{
						label: t('form.options.org-type.company'),
						value: 'company'
					}
				],
				optionType: 'button'
			}
		},
		{
			label: t('form.label.company-name'),
			name: 'companyName',
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: t('form.placeholder.company-name')
			},
			condition: formValues?.type === 'company'
		},
		{
			label: t('form.label.company-website'),
			name: 'companyWebsite',
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: 'https://...'
			},
			condition: formValues?.type === 'company',
		},
		{
			label: t('form.label.accept-newsletter'),
			name: 'acceptNewsletter',
			item: {
				...universalFormItemProps,
				type: 'switch',
				defaultChecked: true,
			},
		},
	];

	return userLoading ? (
		<Loading />
	) : (
		<CardContainer
			title='Personal settings'
		>
			<FormWithRequest
				formConfig={formConfig}
				formItems={formItems}
				submitButtonText={submitButtonText}
				submitButtonConfig={{
					type: 'primary',
					block: true
				}}
				requestEndpoint='https://backend.lang.teammate.as/users'
				requestMethod='PUT'
				onSuccess={async (res) => {
					pushMessage(t('message.success.profile-updated'), 'success');
				}}
			/>
		</CardContainer>
	);
};

export default UserSettings;
