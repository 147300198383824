import { Avatar, Button, Col, Empty, Flex, Input, Row, Spin } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import GlobalContext from '../../../../../context/GlobalContext';
import { MotionDiv, RelativeTime } from '../../../../../components/atoms';
import '../index.css';
import { useAuthRequest } from '../../../../../hooks';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';
import GenflowPlanContext from '../../../../../context/genflowPlan/Context';

// eslint-disable-next-line no-undef
const baseEndpoint = `${process.env.REACT_APP_BASE_API}/messages`;

const Comments = () => {
	const { genflowId } = useContext(GenflowDetailContext);
	const { planId, messagesData, messagesLoading, messagesRefresh } = useContext(GenflowPlanContext);
	const isInitialLoad = useRef(true);

	useEffect(() => {
		if (isInitialLoad.current && !messagesLoading && messagesData) {
			isInitialLoad.current = false;
		}
	}, [messagesData, messagesLoading]);

	const { makeRequest } = useAuthRequest();
	const handleRequest = async (type, data, messageId) => {
		let endpoint = baseEndpoint;
		if (type !== 'POST') {
			endpoint += `/${messageId}`;
		}

		let method, payload;
		switch (type) {
		case 'POST':
			method = 'POST';
			payload = {
				data,
				destination: {
					type: 'genflowPlans',
					genflowId,
					genflowPlanId: planId
				}
			};
			break;
		case 'EDIT':
			method = 'PATCH';
			payload = { data };
			break;
		case 'DELETE':
			method = 'DELETE';
			break;
		case 'LIKE':
			method = 'POST';
			endpoint += '/reaction';
			payload = {
				data: { reactionType: 'LIKE' }
			};
			break;
		case 'UNLIKE':
			method = 'POST';
			endpoint += '/reaction';
			payload = {
				data: { reactionType: 'UNLIKE' }
			};
			break;
		}

		const response = await makeRequest(endpoint, method, payload ?  JSON.stringify(payload) : null);
		if (response?.message === 'OK') {
			await messagesRefresh();
		} else {
			console.error('Error:', response);
		}

		return response;
	};

	return (
		<Flex vertical gap='middle' justify='space-between' style={{ height: 'calc(100vh - 165px)' }}>
			<Spin spinning={messagesLoading && isInitialLoad.current} style={{ height: 300 }}>
				{messagesData ? (
					<Flex vertical style={{ flex: 1, overflowY: 'auto' }}>
						{messagesData?.sort((a, b) => new Date(a['created_at']) - new Date(b['created_at']))
							.map((item, index) => {
								return (
									<Post
										key={item['message_id']}
										index={index}
										content={item.messages?.map(m => m.content).join('')}
										userId={item['created_by']}
										sentAt={item['created_at']}
										isEdited={!!item['edited_at']}
										onEdit={(newText) => {
											return handleRequest('EDIT', { messages: [{ type: 'text', content: newText }] }, item['message_id']);
										}}
										onDelete={() => handleRequest('DELETE', null, item['message_id'])}
									/>
								);
							})
						}
					</Flex>
				) : (
					<Empty />
				)}
			</Spin>
			<InputArea onSend={(text) => {
				return handleRequest('POST', { messages: [{ type: 'text', content: text }] });
			}} />
		</Flex>
	);
};

const Post = (props) => {
	const { index, userId, sentAt, isEdited, content, onEdit, onDelete } = props;
	const { workspace, pushMessage } = useContext(GlobalContext);
	const updatedByMetadata = workspace?.members?.find(m => m.user_id === userId);
	const [isEditing, setIsEditing] = useState(false);
	const [isDeleteClicked, setIsDeleteClicked] = useState(false);

	const handleEdit = (text) => {
		return onEdit(text).then((res) => {
			if (res?.message === 'OK') {
				setIsEditing(false);
			}
			return res;
		});
	};

	return (
		<MotionDiv animationIndex={index}>
			<Row gutter={10} className={`post${isDeleteClicked ? ' post-deleting' : ''}`}>
				<Col span={3}>
					<Avatar src={<img src={updatedByMetadata?.avatar_url} />} size='middle' />
				</Col>
				<Col span={19}>
					<Flex vertical gap='small'>
						<Flex gap='small'>
							<b>{updatedByMetadata?.user_name}</b>
							<span style={{ color: '#666666' }}><RelativeTime datetime={sentAt} /></span>
						</Flex>
						{isEditing ? (
							<InputArea isEdit defaultText={content} onSend={handleEdit} onCancel={() => setIsEditing(false)} />
						) : (
							<span>{content}{isEdited && <span style={{ color: '#AAAAAA' }}> (edited)</span>}</span>
						)}
						{!isEditing && (
							<Flex gap='small'>
								<Button type='text' size='small' onClick={() => setIsEditing(true)}>
							Edit
								</Button>
								<Button type='text' size='small' onClick={() => {
									setIsDeleteClicked(true);
									onDelete();
								}}>
							Delete
								</Button>
							</Flex>
						)}
					</Flex>
				</Col>
			</Row>
		</MotionDiv>
	);
};

const InputArea = ({ isEdit = false, defaultText = '', onSend, onCancel }) => {
	const { pushMessage } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const [text, setText] = useState(defaultText);

	const handleSend = () => {
		setLoading(true);
		onSend(text).then((res) => {
			if (res?.message === 'OK') {
				setText();
			} else {
				pushMessage('Error sending message', 'error');
			}
		});
		setLoading(false);
	};

	return (
		<Flex vertical gap='small'>
			<Input.TextArea
				placeholder='Leave your comment here'
				value={text}
				disabled={loading}
				style={{ resize: 'none' }}
				onChange={(e) => setText(e.target.value)}
			/>
			<Flex gap='small'>
				{isEdit ? (
					<>
						<Button loading={loading} type='primary' onClick={handleSend}>Save</Button>
						<Button loading={loading} onClick={onCancel}>Cancel</Button>
					</>
				) : (
					<Button loading={loading} type='primary' onClick={handleSend}>Send</Button>
				)}
			</Flex>
		</Flex>
	);
};

export default Comments;
