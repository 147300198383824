import { useContext, useRef, useState } from 'react';
import { Flex, Input } from 'antd';
import { Editors } from '../../../../../components/atoms';
import GlobalContext from '../../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';
import { CopyableInput } from '../../../../../components/atoms';
import '../index.css';

const Summary = (props) => {
	const { genflowData, operateGenflow } = useContext(GenflowDetailContext);
	const debounceTimer = useRef();

	const debouncedOnChange = useRef((key, value, type) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			try {
				operateGenflow({ key, value, ...(type ? { type } : {}) }, 'PATCH');
			} catch (error) {
				console.error('Error updating:', error);
			}
		}, 750);
	}).current;

	const handleChange = (key, value, type) => {
		debouncedOnChange(key, value, type);
	};

	return (
		<Flex vertical gap='small'>
			<ItemContainer title='Name' type='input' defaultValue={genflowData?.name} valueOnNull='Untitled generation flow' onChange={(v) => handleChange('name', v)} />
			<ItemContainer title='Description' type='input-area' defaultValue={genflowData?.metadata?.description} valueOnNull='Write descriptions' onChange={(v) => handleChange('metadata.description', v)} />
			<ItemContainer title='Generation Flow ID' type='copyable' value={genflowData?.genflow_id} />
		</Flex>
	);
};

const ItemContainer = ({ title, type, value, defaultValue, valueOnNull, onChange = () => {} }) => {
	return (
		<Flex vertical gap={3}>
			<span className='summary-title'>{title}</span>
			{type === 'input' && (
				<Input
					size='large'
					variant='filled'
					defaultValue={defaultValue}
					placeholder={valueOnNull}
					onChange={(e) => onChange(e.target.value)}
				/>
			)}
			{type === 'input-area' && (
				<Input.TextArea
					size='large'
					variant='filled'
					rows={3}
					defaultValue={defaultValue}
					placeholder={valueOnNull}
					onChange={(e) => onChange(e.target.value)}
				/>
			)}
			{type === 'copyable' && (
				<CopyableInput value={value} messageOnCopy='Generation Flow ID copied' />
			)}
			{type === 'editors' && (
				<Editors editors={value} />
			)}
		</Flex>
	);
};

export default Summary;
