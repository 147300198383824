import { Skeleton, Typography } from 'antd';
import { useState, useEffect, useRef } from 'react';
import './index.css';

const defaultStyle = {};

const EditableTitle = (props) => {
	const {
		loading,
		label,
		labelOnNull,
		level = 2,
		onChange = () => {},
		editing,
		onStartEditing = () => {},
		onEndEditing = () => {},
	} = props;
	const [localLabel, setLocalLabel] = useState(label);
	const [styles, setStyles] = useState(defaultStyle);
	const isInitialLoad = useRef(true);

	useEffect(() => {
		if (localLabel !== label && isInitialLoad.current) {
			setLocalLabel(label);
			isInitialLoad.current = false;
		}
	}, [label]);

	const handleChange = (v) => {
		if (v !== localLabel) {
			setLocalLabel(v);
			onChange(v);
		}
		setStyles(defaultStyle);
	};

	return loading ? (
		<Skeleton.Input active round size='large' style={{ width: 400 }} />
	) : (
		<div className='editable-title' style={styles}>
			<Typography.Title
				level={level}
				style={{ margin: 0, fontSize: level === 3 ? '20px' : '24px' }}
				editable={{
					...(editing ? { editing } : {}),
					onStart: () => {
						setStyles({ ...defaultStyle, backgroundColor: 'transparent' });
						onStartEditing();
					},
					onChange: (v) => {
						handleChange(v);
						onEndEditing();
					},
					text: localLabel || '',
					triggerType: 'text',
				}}
				ellipsis={{
					rows: 1,
					tooltip: localLabel,
				}}
			>
				{localLabel ? localLabel : (
					<span className='null-title-text'>{labelOnNull}</span>
				)}
			</Typography.Title>
		</div>
	);
};

export default EditableTitle;
