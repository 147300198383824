import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	sendPasswordResetEmail,
	confirmPasswordReset,
} from '@firebase/auth';
import { firebaseAuth } from '../../../config/firebase';
import { handleEmail, handleGoogleLogin, handleGithubLogin } from '../../../scripts/auth';
import { Flex, Typography, Form, Alert, Button, Divider } from 'antd';
import { GoogleOutlined, GithubOutlined } from '@ant-design/icons';
import { LoginForm, SignupForm, RequestPasswordResetForm, SubmitNewPasswordForm } from './components';
import { useTranslation } from 'react-i18next';
import { useNavigateWithParams } from '../../../hooks';
import GlobalContext from '../../../context/GlobalContext';

const AuthModal = ({ type = 'login' }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);

	const { setUserId, setTokenManager } = useContext(GlobalContext);

	const [searchParams] = useSearchParams();
	const callbackPath = searchParams.get('callback');
	const hasInvitationCode = searchParams.has('invitationCode');
	
	const navigate = useNavigateWithParams();

	useEffect(() => {
		setMessage(null);
		setErrorMessage(null);
		setContinueButtonDisabled(false);
	}, [type]);

	let assistanceTemplate = {
		'login': <Flex gap='small'>{'Don\'t have an account?'}<a onClick={() => navigate('/auth/signup')}>Sign up</a></Flex>,
		'signup': <Flex gap='small'>Already have an account?<a onClick={() => navigate('/auth/login')}>Login</a></Flex>,
		'reset-password': <Flex gap='small'><a onClick={() => navigate('/auth/login')}>Back to login</a></Flex>,
		'new-password': <Flex gap='small'><a onClick={() => navigate('/auth/login')}>Go to login</a></Flex>,
	};

	const handlePasswordResetRequest = async ({ username }) => {
		try {
			setErrorMessage(null);
			setLoading(true);
			await sendPasswordResetEmail(firebaseAuth, username)
				.then(() => {
					setErrorMessage(null);
					setContinueButtonDisabled(true);
					setMessage('Password reset email sent, please check your inbox');
				})
				.catch((error) => {
					const errorCode = error.code;
					if (errorCode === 'auth/invalid-email') {
						setErrorMessage('Invalid email address');
					} else if (errorCode === 'auth/user-not-found') {
						setErrorMessage('User not found');
					} else {
						setErrorMessage('Error in operation, check your email address');
					}
				});
		} catch (error) {
			setErrorMessage('Error in operation, check your email address');
		} finally {
			setLoading(false);
		}
	};

	const handleNewPasswordSubmit = async (payload) => {
		const { password, code } = payload;
		try {
			setErrorMessage(null);
			setLoading(true);
			await confirmPasswordReset(firebaseAuth, code, password)
				.then(() => {
					setErrorMessage(null);
					setContinueButtonDisabled(true);
					setMessage('Password reset successful, please go to login');
				})
				.catch((error) => {
					const errorCode = error.code;
					if ([ 'auth/expired-action-code', 'auth/invalid-action-code', 'auth/user-disabled', 'auth/user-not-found', 'auth/weak-password' ].includes(errorCode)) {
						setErrorMessage(t(`auth.message.error.code.${errorCode.replace('auth/', '')}`)); // TODO: translation many options
					} else {
						setErrorMessage('Error in operation, please try again');
					}
				});
		} catch (error) {
			setErrorMessage('Error in operation, please try again');
		} finally {
			setLoading(false);
		}
	};

	const handleEmailFormSubmit = async (payload) => {
		switch (type) {
		case 'login':
			await handleAuth('email', payload);
			break;
		case 'signup':
			await handleAuth('email', payload, 'signup');
			break;
		case 'reset-password':
			await handlePasswordResetRequest(payload);
			break;
		case 'new-password':
			await handleNewPasswordSubmit(payload);
			break;
		default:
			setErrorMessage('Unknown error: please refresh the page and try again');
		}
	};

	const simpleNavigate = useNavigate();
	const handleAuth = async (provider, options, type = 'login') => {
		setLoading(true);

		let response = {};
		if (provider === 'email') {
			response = await handleEmail(type, options);
		} else if (provider === 'google') {
			response = await handleGoogleLogin();
		} else if (provider === 'github') {
			response = await handleGithubLogin();
		}

		if (response.success) {
			setUserId(response.userId);
			setTokenManager(response.tokenManager);
			sessionStorage.setItem('accessToken', response.tokenManager?.accessToken);
			// Removing every params
			simpleNavigate(response.redirect || callbackPath || '/');
		} else {
			setErrorMessage(t(`auth.message.error.code.${response.code}`)); // I DON'T KNOW
		}

		setLoading(false);
	};

	const titleMap = {
		login: 'Login',
		signup: 'Signup',
		'reset-password': 'Reset Password',
		'new-password': 'New Password',
	};

	return (
		<Flex gap={10} vertical>
			<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Typography.Title level={1} style={{ fontSize: 30, margin: 0 }}>
					{titleMap?.[type]}
				</Typography.Title>
			</div>
			<Form
				onFinish={handleEmailFormSubmit}
				onFinishFailed={() => setErrorMessage(t(`auth.message.error.${type || 'unknown'}`))} // TODO: translation many options
				size='small'
			>
				{type === 'login' && <LoginForm /> }
				{type === 'signup' && <SignupForm /> }
				{type === 'reset-password' && <RequestPasswordResetForm />}
				{type === 'new-password' && <SubmitNewPasswordForm />}
				<Form.Item
					wrapperCol={{
						span: 24,
					}}
					noStyle
				>
					<Button type="primary" htmlType="submit" size="large" block loading={loading} disabled={continueButtonDisabled} >
						Continue
					</Button>
				</Form.Item>
			</Form>
			{message && <Alert message={message} type="success" />}
			{errorMessage && <Alert message={errorMessage} type="error" />}
			<Typography.Text style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 5, marginBottom: 5 }}>
				{assistanceTemplate[type]}
			</Typography.Text>
			{['login'].includes(type) && (
				<>
					<Divider style={{ margin: 0 }}>OR</Divider>
					<Button size="large" block icon={<GoogleOutlined />} onClick={() => handleAuth('google')} disabled={loading} >
						Login with Google
					</Button>
					<Button size="large" block icon={<GithubOutlined />} onClick={() => handleAuth('github')} disabled={loading} >
						Login with Github
					</Button>
				</>
			)}
		</Flex>
	);
};

const generateRedirectUrl = (path, callbackPath) => {
	return `${(callbackPath || path)}`;
};

export default AuthModal;
