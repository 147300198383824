import { Empty as AntEmpty } from 'antd';

const Apologies = ({ note }) => {
	return (
		<AntEmpty
			image='/images/sorry.png'
			description={note}
		/>
		// <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '30px' }}>
		// 	<img src='/images/sorry.png' alt='Sorry' style={{ maxWidth: '100%', maxHeight: '100%' }}/>
		// 	{note && (<span style={{ color: '#999999', marginTop: '10px' }}>{note}</span>)}
		// </div>
	);
};

export default Apologies;
