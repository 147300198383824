import { useContext, useEffect, useRef, useState } from 'react';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import { MetricCardContainer } from '../index';
import { Input, Select } from 'antd';
import { CodeEditor } from '../../../../../../../../components/atoms';

const MetricCardFormat = ({ outputKey = '' }) => {
	const outputFormatKeys = ['enum', 'json', 'regex', 'xml'];
	const { data, operateOptimize, isBeforeEvaluationInvoked } = useContext(GenflowOptimizeContext);
	const [localSelected, setLocalSelected] = useState(false);
	const [type, setType] = useState();
	const [localSchema, setLocalSchema] = useState();
	const targetModules = data?.metadata?.evaluationModules?.[outputKey] || {};
	const isInitialLoad = useRef(true);

	useEffect(() => {
		const selectedFormat = targetModules ? Object.keys(targetModules)?.find(metric => outputFormatKeys.includes(metric)) : null;
		if (selectedFormat) {
			setType(selectedFormat);
		}

		if (isInitialLoad.current) {
			setLocalSelected(!!selectedFormat);
			isInitialLoad.current = false;
		}
	}, [targetModules]);

	const handleSelect = (v) => {
		if (isBeforeEvaluationInvoked) {
			setLocalSelected(v);
			// handleSwitch(outputKey, metric_key, v);
			if (v === false) {
				setType();
				handleRemoveMetrics();
			}
		}
	};

	const handleTypeSelect = (v) => {
		if (isBeforeEvaluationInvoked) {
			setType(v);
			handleSwitchMetric(v);
		}
	};

	const handleSwitchMetric = async (newKey) => {
		if (isBeforeEvaluationInvoked) {
			setLocalSchema();
			const newMetrics = {};
			if (targetModules && Object.keys(targetModules)?.length > 0) {
				Object.keys(targetModules)?.forEach(metricKey => {
					if (!outputFormatKeys.includes(metricKey)) {
						newMetrics[metricKey] = targetModules[metricKey];
					}
				});
			}
			newMetrics[newKey] = { schema: null };

			const payload = {
				key: 'metadata.evaluationModules',
				value: {
					...data?.metadata?.evaluationModules,
					[outputKey]: newMetrics
				},
				type: 'json'
			};

			await operateOptimize(payload, 'PATCH');
		}
	};

	const handleRemoveMetrics = async () => {
		if (isBeforeEvaluationInvoked) {
			const newMetrics = {};
			if (targetModules && Object.keys(targetModules)?.length > 0) {
				Object.keys(targetModules)?.forEach(metricKey => {
					if (!outputFormatKeys.includes(metricKey)) {
						newMetrics[metricKey] = targetModules[metricKey];
					}
				});

				const payload = {
					key: 'metadata.evaluationModules',
					value: {
						...data?.metadata?.evaluationModules,
						[outputKey]: newMetrics
					},
					type: 'json'
				};

				await operateOptimize(payload, 'PATCH');
			}
		}
	};

	const debounceTimer = useRef();

	const debouncedOnChange = useRef((editingType, value) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			try {
				const newMetrics = {};
				if (targetModules && Object.keys(targetModules)?.length > 0) {
					Object.keys(targetModules)?.forEach(metricKey => {
						if (!outputFormatKeys.includes(metricKey)) {
							newMetrics[metricKey] = targetModules[metricKey];
						}
					});
				}
				newMetrics[editingType] = { schema: value };

				const payload = {
					key: 'metadata.evaluationModules',
					value: {
						...data?.metadata?.evaluationModules,
						[outputKey]: newMetrics
					},
					type: 'json'
				};

				operateOptimize(payload, 'PATCH');
			} catch (error) {
				console.error('Error updating schema:', error);
			}
		}, 750);
	}).current;

	const handleSchemaChange = (editingType, value) => {
		if (isBeforeEvaluationInvoked) {
			setLocalSchema(value);
			debouncedOnChange(editingType, value);
		}
	};

	return (
		<MetricCardContainer
			title='Output Format Validation'
			description='Validate output format strictly with Enum, JSON schema, Regex, or XML schema.'
			selected={localSelected}
			onSelect={handleSelect}
			isFree
			metricsList={[
				{ label: 'Validity', type: 'boolean' },
			]}
		>
			<Select
				variant='filled'
				placeholder='Select format type'
				value={type}
				options={[
					{
						value: 'json',
						label: 'JSON'
					},
					{
						value: 'regex',
						label: 'Regex'
					},
					{
						value: 'enum',
						label: 'Enum'
					},
					{
						value: 'xml',
						label: 'XML'
					},
				]}
				disabled={!localSelected || !isBeforeEvaluationInvoked}
				allowClear
				status={(localSelected && !type) ? 'error' : null}
				onChange={handleTypeSelect}
			/>
			{(localSelected && type === 'enum') && (
			// TODO: For some reasons, it does not return error when value is Erased after initial input
				<Select
					mode='tags'
					size='large'
					variant='filled'
					style={{
						width: '100%',
					}}
					placeholder='Input enum (press "Enter" to save)'
					disabled={!localSelected || !isBeforeEvaluationInvoked}
					open={false}
					suffixIcon={null}
					status={(targetModules?.[type]?.schema || localSchema) ? null : 'error'}
					onChange={(v) => handleSchemaChange('enum', v)}
				/>
			)}
			{(localSelected && type === 'json') && (
				<CodeEditor
					height='200px'
					defaultLanguage='json'
					status={(targetModules?.[type]?.schema || localSchema) ? null : 'error'}
					onChange={(v) => handleSchemaChange('json', v)}
				/>
			)}
			{(localSelected && type === 'regex') && (
				<Input
					size='large'
					variant='filled'
					placeholder='Input regex'
					disabled={!localSelected || !isBeforeEvaluationInvoked}
					status={(targetModules?.[type]?.schema || localSchema) ? null : 'error'}
					onChange={(e) => handleSchemaChange('regex', e.target.value)}
				/>
			)}
			{(localSelected && type === 'xml') && (
				<CodeEditor
					height='200px'
					defaultLanguage='xml'
					status={(targetModules?.[type]?.schema || localSchema) ? null : 'error'}
					onChange={(v) => handleSchemaChange('xml', v)}
				/>
			)}
		</MetricCardContainer>
	);
};

export default MetricCardFormat;
