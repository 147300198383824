import { Flex } from 'antd';
import './index.css';
import MotionDiv from '../MotionDiv';

const titleStyle = {
	fontSize: '20px',
	fontWeight: 600,
};

const TipsCard = ({ title, animationIndex, children }) => {
	return (
		<MotionDiv animationIndex={animationIndex} className='card-tips on-hover-shadow'>
			<Flex vertical gap='middle'>
				{title && (<span style={titleStyle}>{title}</span>)}
				{children}
			</Flex>
			<img src='/images/guiding.png' className='tip-image' alt='Tip'/>
		</MotionDiv>
	);
};

export default TipsCard;
