import { useState } from 'react';
import { Form, Input, Select, Radio } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import i18nCountries from 'i18n-iso-countries';
import countries from '../../../../models/static/countries';
import departments from '../../../../models/static/department.json';

const SignupForm = () => {
	const [hasName, setHasName] = useState(false);
	const [type, setType] = useState('individual');

	const countriesOptions = countries?.map(({ value }) => {
		if (value === 'HK') {
			return { value, label: 'China (Hong Kong SAR)' };
		}
		if (value === 'MO') {
			return { value, label: 'China (Macao SAR)' };
		}
		if (value === 'TW') {
			return { value, label: 'China (Taiwan Province)' };
		}
		return {
			value,
			label: i18nCountries.getName(value, 'en')
		};
	}).sort((a, b) => a.label?.localeCompare(b.label));

	return (
		<>
			<Form.Item
				name='username'
				rules={[
					{
						required: true,
						message: 'Please enter email address',
					},
				]}
			>
				<Input
					size='large'
					placeholder='Email address'
					prefix={<MailOutlined className={'prefixIcon'} />}
				/>
			</Form.Item>
			<Form.Item
				name='password'
				rules={[
					{
						required: true,
						message: 'Please enter password',
					},
				]}
				validateTrigger="onBlur"
			>
				<Input.Password
					size='large'
					placeholder='Password'
					prefix={<LockOutlined className={'prefixIcon'} />}
				/>
			</Form.Item>
			<Form.Item
				name='name'
				rules={[
					{
						required: true,
						message: 'Please enter your name'
					},
				]}
			>
				<Input
					size='large'
					placeholder='Input name'
					prefix={<UserOutlined className={'prefixIcon'} />}
					onChange={() => setHasName(true)}
				/>
			</Form.Item>
			{hasName && (
				<>
					<Form.Item
						name='countryOrRegion'
						rules={[
							{
								required: true,
								message: 'Please select your country/region of residence',
							},
						]}
					>
						<Select
							size='large'
							placeholder='Select country/region of residence'
							options={countriesOptions}
							showSearch
							allowClear
							optionFilterProp='label'
						/>
					</Form.Item>
					<Form.Item name='type'>
						<Radio.Group
							size='large'
							defaultValue='individual'
							onChange={(e) => setType(e.target.value)}
						>
							<Radio.Button value='individual'>Individual</Radio.Button>
							<Radio.Button value='company'>Company</Radio.Button>
						</Radio.Group>
					</Form.Item>
					{type === 'company' && (
						<>
							<Form.Item
								name='companyName'
							>
								<Input
									size='large'
									placeholder='Company name'
								/>
							</Form.Item>
							{/*<Form.Item*/}
							{/*	name='industry'*/}
							{/*>*/}
							{/*	<Select*/}
							{/*		size='large'*/}
							{/*		placeholder={t('form.placeholder.industry')}*/}
							{/*		options={industries?.map(industry => ({*/}
							{/*			...industry,*/}
							{/*			label: t('form.options.industry.' + industry.value),*/}
							{/*		}))}*/}
							{/*		prefix={<UserOutlined className={'prefixIcon'} />}*/}
							{/*	/>*/}
							{/*</Form.Item>*/}
							<Form.Item
								name='department'
							>
								<Select
									size='large'
									placeholder='Tell us your expert'
									options={departments}
									allowClear
								/>
							</Form.Item>
						</>
					)}
				</>
			)}
			<Form.Item
				name='language'
				hidden
				value='en'
			/>
		</>
	);
};

export default SignupForm;
