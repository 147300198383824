import { Avatar, Button, Col, Flex, Input, Row } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import GlobalContext from '../../../../../context/GlobalContext';
import { AbsoluteTime, Ellipsis, Loading, RelativeTime } from '../../../../../components/atoms';
import { useAuthData } from '../../../../../hooks';
import GenflowPlanContext from '../../../../../context/genflowPlan/Context';
import '../index.css';
import { UserOutlined } from '@ant-design/icons';

const operationMap = {
	CREATE: 'Created',
	UPDATE: 'Updated',
	DELETE: 'Delete',
};

const typeMap = {
	GENFLOW_PLAN: 'generation flow plan',
	PROMPTBOOK: 'promptbook',
	KNOWLEDGEBOOK: 'knowledgebook',
};

const keyMap = {
	promptText: 'prompt text',
	'metadata.input': 'input',
	'metadata.steps': 'steps',
	'metadata.output': 'output',
};

// TODO update descriptions
// TODO max number of record read

const History = () => {
	const { genflowId, planId } = useContext(GenflowPlanContext);
	const historyEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/histories/genflow-plan?genflowId=${genflowId}&planId=${planId}`;
	}, [genflowId, planId]);

	const { data, loading, refresh } = useAuthData(historyEndpoint, !!genflowId && !!planId);
	const [historyList, setHistoryList] = useState([]);
	useEffect(() => {
		if (data?.data?.rows?.length > 0) {
			setHistoryList(data?.data?.rows);
		}
	}, [genflowId, planId, data?.data?.rows, loading]);

	return loading ? (
		<Loading />
	) : (
		<Flex vertical style={{ flex: 1, overflowY: 'auto' }}>
			{historyList?.map((item) => (
				<Record
					key={item.request_id}
					content={[
						operationMap[item.operation_type] || '',
						typeMap[item.type] || item.type,
						keyMap[item.key] || item.key,
						'to "',
						item.value,
						'"'
					].join(' ')}
					userId={item.user_id}
					createdAt={item.timestamp}
				/>
			))}
		</Flex>
	);
};

const Record = (props) => {
	const { userId, createdAt, content } = props;
	const { workspace } = useContext(GlobalContext);
	const updatedByMetadata = workspace?.members?.find(m => m.user_id === userId);

	return (
		<Flex vertical gap='small' className='post'>
			<Flex gap='small' align='center'>
				<Avatar
					src={updatedByMetadata?.avatar_url}
					icon={<UserOutlined/>}
				/>
				<b>{updatedByMetadata?.user_name}</b>
				<span style={{ color: '#666666' }}><AbsoluteTime datetime={createdAt} /></span>
			</Flex>
			<Ellipsis threshold={200} name={content} />
		</Flex>
	);
};

export default History;
