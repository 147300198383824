import { createContext } from 'react';

const Context = createContext({
	genflowId: null,
	planId: null,
	promptbookId: null,
	data: {},
	loading: true,
	refresh: () => {},
	editPromptbook: () => {},
	copyPromptbook: () => {},
	deletePromptbook: () => {},
	isOpenGenerationAssistant: false,
	setOpenGenerationAssistant: () => {}
});

export default Context;
