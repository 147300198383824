import { AssistantModule, Knowledgebook, Promptbook } from '../../../../components/molecules';
import { Flex, Skeleton } from 'antd';
import {
	CommentOutlined,
	HistoryOutlined,
	PlayCircleOutlined,
	QuestionCircleOutlined
} from '@ant-design/icons';
import { IconLogoSilhouette } from '../../../../components/atoms';
import { useContext, useEffect, useRef, useState } from 'react';
import { ChunkPlayground, Comments, History, Playground, Tips, Documentation } from './sidebarScreens';
import { AddStep, BookContainer, Header, StartEndStep } from './mainComponents';
import { IoMdCut } from 'react-icons/io';
import GlobalContext from '../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import GenflowPlanContext from '../../../../context/genflowPlan/Context';

const headerHeight = 60;

const whitelistedStepTypes = [
	'promptbook',
	'knowledgebook'
];

const Develop = () => {
	const { collapsed } = useContext(GlobalContext);
	const { genflowId, plansRefresh } = useContext(GenflowDetailContext);
	const { data, loading, stepNameMap, operatePlan, addPromptbook, addKnowledgebook, currentEditingStep } = useContext(GenflowPlanContext);
	const [ localData, setLocalData ] = useState(data);
	const isInitialLoad = useRef(true);
	const [currentKey, setCurrentKey] = useState('playground');
	const [previousType, setPreviousType] = useState();
	const isAssistantVisible = Boolean(currentKey);

	const bodyStyle = {
		paddingTop: headerHeight + 24,
		width: `calc(100% - ${isAssistantVisible ? '460' : '60'}px)`,
		transition: 'width 0.3s'
	};

	useEffect(() => {
		if (localData !== data) {
			setLocalData(data);
		}

		if (isInitialLoad.current && !loading && data) {
			isInitialLoad.current = false;
		}
	}, [data, loading]);

	const assistantItems = [
		...(currentEditingStep?.type === 'knowledgebook'
			? [
				{
					key: 'chunk-playground',
					title: 'Chunk Playground',
					icon: IoMdCut,
					content: <ChunkPlayground />
				}
			]
			: []
		),
		{
			key: 'playground',
			title: 'Playground',
			icon: PlayCircleOutlined,
			content: <Playground />
		},
		{
			key: 'tips',
			title: 'Tips',
			icon: IconLogoSilhouette,
			content: <Tips />
		},
		{
			key: 'comments',
			title: 'Comments',
			icon: CommentOutlined,
			content: <Comments />
		},
		{
			key: 'history',
			title: 'Edit History',
			icon: HistoryOutlined,
			content: <History />
		},
		{
			key: 'documentation',
			title: 'Documentation',
			icon: QuestionCircleOutlined,
			content: <Documentation />
		},
	];

	useEffect(() => {
		if (currentEditingStep?.type === 'knowledgebook' && previousType !== currentEditingStep?.type) {
			setCurrentKey('chunk-playground');
		} else if (currentEditingStep?.type !== 'knowledgebook' && previousType === 'knowledgebook' && currentKey === 'chunk-playground') {
			setCurrentKey('playground');
		}
		setPreviousType(currentEditingStep?.type);
	}, [currentEditingStep]);

	const handleChange = async (key, value, type) => {
		let shouldUpdate = false, shouldRefreshList = false;

		if (key.startsWith('metadata.')) {
			const metadataKey = key.replace('metadata.', '');
			if (localData.metadata?.[metadataKey] !== value) {
				setLocalData({ ...localData, metadata: { ...localData?.metadata, [metadataKey]: value } });
				shouldUpdate = true;
			}
		} else if (localData?.[key] !== value) {
			setLocalData({ ...localData, [key]: value });
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			await operatePlan({ key, value, type }, 'PATCH');
		}

		if (key === 'name') {
			shouldRefreshList = true;
		}

		if (shouldRefreshList) {
			await plansRefresh();
		}
	};

	const handleAddStep = async (position, type) => {
		let response = {}, newId;
		if (type === 'PROMPTBOOK') {
			response = await addPromptbook(position);
			if (response?.message === 'OK') {
				newId = response?.result?.['promptbook_id'];
			}
		} else if (type ==='KNOWLEDGEBOOK') {
			response = await addKnowledgebook(position);
			if (response?.message === 'OK') {
				newId = response?.result?.['knowledgebook_id'];
			}
		}

		if (newId) {
			const newItem = { type: type?.toLowerCase(), id: newId };
			let newSteps = [...(localData?.metadata?.steps || [])];
			newSteps.splice(position, 0, newItem);
			await handleChange('metadata.steps', newSteps,'json');
		}

		return response;
	};

	return (
		<>
			<Header
				loading={isInitialLoad.current}
				label={data?.name}
				labelOnNull='Untitled plan'
				onChange={(v) => handleChange('name', v)}
				backDestination={`/genflows/${genflowId}/develop`}
				style={{
					width: `calc(100vw - ${collapsed ? '50' : '200'}px - 275px + 10px)`,
					transition: 'width 0.25s',
					marginLeft: '-16px'
				}}
			/>
			<AssistantModule
				items={assistantItems}
				currentKey={currentKey}
				setCurrentKey={setCurrentKey}
			/>
			<div style={bodyStyle}>
				{loading ? <Skeleton active /> : (
					<Flex vertical gap='middle'>
						<StartEndStep
							type='input'
							onChange={(v) => handleChange('metadata.input', v, 'json')}
							animationIndex={0}
						/>
						<AddStep position={0} onAdd={handleAddStep} animationIndex={1} />
						{data?.metadata?.steps?.length > 0 && data?.metadata?.steps?.map((step, index) => {
							const { id, type } = step;

							return whitelistedStepTypes.includes(type) && (
								<Flex vertical gap='middle' key={id}>
									{type === 'promptbook' && (
										<BookContainer type={type} id={id} animationIndex={(index * 2) + 2}>
											<Promptbook id={id} stepNameMap={stepNameMap} />
										</BookContainer>
									)}
									{type === 'knowledgebook' && (
										<BookContainer type={type} id={id} animationIndex={(index * 2) + 2}>
											<Knowledgebook id={id} stepNameMap={stepNameMap} />
										</BookContainer>
									)}
									<AddStep position={index + 1} onAdd={handleAddStep} animationIndex={(index * 2) + 3}/>
								</Flex>
							);
						})}
						<StartEndStep
							type='output'
							onChange={(v) => handleChange('metadata.output', v, 'json')}
							animationIndex={data?.metadata?.steps?.length * 2 + 2}
						/>
					</Flex>
				)}
			</div>
		</>
	);
};

export default Develop;
