import { useEffect, useRef } from 'react';
import { Flex } from 'antd';
import { FileSearchOutlined, LineChartOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from './components';
import { Logs, Scores } from './screens';
import { motion, AnimatePresence } from 'framer-motion';
import './index.css';

const allowedTabKeys = ['logs', 'scores'];

const ExperimentDetail = () => {
	const isInitialLoad = useRef(true);
	const { tabKey } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!tabKey || !allowedTabKeys.includes(tabKey)) {
			navigate('./comparison/logs');
		}
	}, [tabKey]);

	useEffect(() => {
		isInitialLoad.current = false;
	}, []);

	const direction = allowedTabKeys.indexOf(tabKey);

	return (
		<Flex vertical gap='middle'>
			<Flex vertical>
				<Tabs
					activeKey={tabKey}
					onSelect={(v) => navigate(`./comparison/${v}`)}
					items={[
						{
							key: 'logs',
							component: (
								<TabItemWrapper
									title='Logs'
									icon={<FileSearchOutlined style={{ fontSize: 20 }} />}
								/>
							)
						},
						{
							key: 'scores',
							component: (
								<TabItemWrapper
									title='Scores'
									icon={<LineChartOutlined style={{ fontSize: 20 }} />}
								/>
							)
						},
					]}
				/>
				<div className='experiment-detail-container'>
					<AnimatePresence initial={false} custom={direction}>
						{tabKey === 'logs' && (
							<TabContentWrapper tabKey='logs' direction={direction} isInitialLoad={isInitialLoad}>
								<Logs />
							</TabContentWrapper>
						)}
						{tabKey === 'scores' && (
							<TabContentWrapper tabKey='scores' direction={direction} isInitialLoad={isInitialLoad}>
								<Scores />
							</TabContentWrapper>
						)}
					</AnimatePresence>
				</div>
			</Flex>
		</Flex>
	);
};

const TabItemWrapper = ({ title, icon }) => {
	return (
		<Flex gap='small' style={{ height: 50 }} align='center'>
			{icon && icon}
			<span style={{ fontSize: 20 }}>{title}</span>
		</Flex>
	);
};

const TabContentWrapper = ({ tabKey, direction, isInitialLoad, children }) => {
	const tabVariants = {
		...(isInitialLoad.current ? {} : {
			initial: (direction) => ({
				x: direction > 0 ? 1000 : -1000,
				opacity: 0
			}),
			animate: {
				x: 0,
				opacity: 1,
				transition: { type: 'spring', stiffness: 300, damping: 30 }
			}
		}),
		exit: (direction) => ({
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
			transition: { duration: 0.2 }
		})
	};

	return (
		<motion.div
			key={tabKey}
			variants={tabVariants}
			initial='initial'
			animate='animate'
			exit='exit'
			custom={direction}
			style={{ padding: '0 10px' }}
		>
			{children}
		</motion.div>
	);
};

export default ExperimentDetail;
