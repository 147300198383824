import { Button, Flex, Popconfirm, Tooltip } from 'antd';
import { BookItemContainer, Loading } from '../../../../atoms';
import TypeSelect from './TypeSelect';
import ObjectSelect from './ObjectSelect';
import Chunk from './Chunk';
import ModelSelect from './ModelSelect';
import QueryTextEditor from './QueryTextEditor';
import OutputFormatSelect from './OutputFormatSelect';
import { useContext, useEffect, useState } from 'react';
import KnowledgebookContext from '../../contexts/Context';
import { UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import GenflowPlanContext from '../../../../../context/genflowPlan/Context';

const selectProps = {
	size: 'large',
	variant: 'filled',
	style: { width: '100%' },
};

const mainItems = [
	{
		key: 'type',
		title: 'Type',
		component: <TypeSelect />,
	},
	{
		key: 'objects',
		title: 'Files for search',
		component: <ObjectSelect />,
	},
	{
		key: 'chunk',
		title: 'Chunking strategies',
		component: <Chunk />,
	},
	{
		key: 'model',
		title: 'Model',
		component: <ModelSelect />,
	},
	{
		key: 'query-text',
		title: (
			<Flex align='center' gap='small'>
				<span>Query Text</span>
				<Tooltip
					title={(
						<Flex vertical gap='small'>
							<span>This will be used to search through the knowledge base.</span>
							<span>Ensure your query is clear and specific for better results.</span>
							<span>Unlike Prompt Book, it is better to use keywords or short phrases in Knowledge Book queries, similar to search queries. This improves matching accuracy.</span>
						</Flex>
					)}
					color='var(--tooltip-background)'
				>
					<QuestionCircleOutlined />
				</Tooltip>
			</Flex>
		),
		component: (
			<QueryTextEditor />
		),
	},
	// {
	// 	key: 'output-format',
	// 	title: 'Output Format',
	// 	component: <OutputFormatSelect {...selectProps} />,
	// }
];

const Editor = () => {
	const { indexKnowledgebook, isIndexed, data: knowledgebookData, loading: knowledgebookLoading } = useContext(KnowledgebookContext);
	const [loading, setLoading] = useState(false);
	const [processCheckErrorMessages, setProcessCheckErrorMessages] = useState([]);

	const handleInitiateIndexing = async () => {
		setLoading(true);
		await indexKnowledgebook();
		setLoading(false);
	};

	const isAllowedToProceed = (
		!!knowledgebookData?.type
		&& (knowledgebookData?.type === 'EMBEDDINGS' ? !!knowledgebookData?.metadata?.model?.metaModelId : true)
		&& (knowledgebookData?.type === 'EMBEDDINGS' ? !!knowledgebookData?.metadata?.chunk?.splitter_type : true)
		&& (knowledgebookData?.type === 'EMBEDDINGS' ? !!knowledgebookData?.metadata?.chunk?.chunk_size : true)
		&& knowledgebookData?.metadata?.objects?.length > 0
	);

	useEffect(() => {
		const messages = [];
		if (!knowledgebookData?.type) {
			messages.push('Type must be selected.');
		}
		if (knowledgebookData?.type === 'EMBEDDINGS') {
			if (!knowledgebookData?.metadata?.model?.metaModelId) {
				messages.push('Model must be selected.');
			}
			if (!knowledgebookData?.metadata?.chunk?.splitter_type) {
				messages.push('Splitter type must be selected.');
			}
			if (!knowledgebookData?.metadata?.chunk?.chunk_size) {
				messages.push('Chunk size must be selected.');
			}
		}
		if (!knowledgebookData?.metadata?.objects || knowledgebookData?.metadata?.objects?.length === 0) {
			messages.push('At least one file must be selected.');
		}

		setProcessCheckErrorMessages(messages);
	}, [knowledgebookData]);

	return (
		<Flex vertical gap='middle'>
			{isIndexed && (
				<IndexedMessage />
			)}
			{knowledgebookLoading ? <Loading /> : mainItems.map((item) => (
				<BookItemContainer key={item.key} title={item.title}>
					{item.component}
				</BookItemContainer>
			))}
			{!isIndexed && (
				<Tooltip
					color='var(--tooltip-background)'
					title={processCheckErrorMessages?.join('\n')}
				>
					<Button
						size='large'
						type='primary'
						disabled={!isAllowedToProceed}
						loading={loading}
						onClick={handleInitiateIndexing}
					>
					Start indexing
					</Button>
				</Tooltip>
			)}
		</Flex>
	);
};

const IndexedMessage = () => {
	const { compiledPlanRefresh } = useContext(GenflowPlanContext);
	const { indexKnowledgebook, data } = useContext(KnowledgebookContext);
	const [loading, setLoading] = useState(false);

	const handleUnlock = async () => {
		setLoading(true);
		const response = await indexKnowledgebook('DELETE');
		if (response === 'OK') {
			await compiledPlanRefresh();
		}

		setLoading(false);
	};

	return (
		<Flex className='indexed-message-container' justify='space-between' align='center'>
			<Flex vertical>
				<span style={{ fontWeight: 600 }}>
					This knowledgebook is already indexed.
				</span>
				<span>
					In order to modify, you have to drop the indexed corpus first.
				</span>
			</Flex>
			<Popconfirm
				title='Are you sure to drop index?'
				description={(
					<Flex vertical>
						<span>As the search index is already made and optimized for the fixed configs,</span>
						<span>unlocking for edit means the already index corpus would be dropped.</span>
					</Flex>
				)}
				okText='DROP INDEX'
				okButtonProps={{ danger: true }}
				icon={
					<WarningOutlined
						style={{
							color: 'red',
						}}
					/>
				}
				onConfirm={handleUnlock}
			>
				<Button
					type='primary'
					icon={<UnlockOutlined />}
					loading={loading}
				>
					UNLOCK
				</Button>
			</Popconfirm>
		</Flex>
	);
};

export default Editor;
