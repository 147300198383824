import { useContext, useState } from 'react';
import AdminContext from '../../../../contexts/Context';
import { Button, Flex } from 'antd';
import { CardContainer } from '../../../../components';
import { Loading } from '../../../../../../components/atoms';
import GenerationFlowMiniCard from './GenerationFlowMiniCard';
import { useNavigate } from 'react-router-dom';

const GenerationFlows = ({ animationIndex, receiveAttention = false }) => {
	const { genflowList, genflowLoading, createGenflow } = useContext(AdminContext);
	const [creatingGenflow, setCreatingGenflow] = useState(false);
	const navigate = useNavigate();

	const handleCreateGenflow = async () => {
		setCreatingGenflow(true);
		await createGenflow();
		setCreatingGenflow(false);
	};
    
	return (
		<CardContainer
			title='Generation flows'
			animationIndex={animationIndex}
			extra={(
				<Button size='large' type='text' onClick={() => navigate('./genflows')}>
					Show all
				</Button>
			)}
		>
			<Flex vertical gap='large'>
				{receiveAttention && (
					<div
						style={{
							minHeight: 500,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Flex vertical gap={30}>
							<Flex vertical align='center' style={{ padding: '100px 0' }}>
								<span className='top-description'>\\ START HERE //</span>
								<img src='/images/smiling.gif' alt='Click me please' width={102} height={85}/>
								<Button
									type='primary'
									size='large'
									onClick={handleCreateGenflow}
									loading={creatingGenflow}
								>
									Create my first generation flow
								</Button>
							</Flex>
						</Flex>
					</div>
				)}
				{genflowLoading && (
					<Loading />
				)}
				<Flex wrap gap={8}>
					{genflowList?.slice(0, 10)?.map((item, index) => <GenerationFlowMiniCard key={item.genflow_id} animationIndex={index} {...item} />)}
				</Flex>
			</Flex>
		</CardContainer>
	);
};

export default GenerationFlows;
