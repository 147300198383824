import { ModelCascader } from '../../../../../atoms';
import { useContext, useEffect, useState } from 'react';
import KnowledgebookContext from '../../../contexts/Context';
import PromptbookContext from '../../../../Promptbook/contexts/Context';
import deepEquivalence from '../../../../../../utils/deepEquivalence';

const selectProps = {
	size: 'large',
	variant: 'filled',
	style: { width: '100%' },
};

const ModelSelect = () => {
	const { data, loading, editKnowledgebook, isIndexed } = useContext(KnowledgebookContext);
	const [localModelValue, setLocalModelValue] = useState();

	useEffect(() => {
		if (!loading && data?.metadata?.model && !deepEquivalence(data?.metadata?.model, localModelValue)) {
			setLocalModelValue(data?.metadata?.model);
		}
	}, [data?.metadata?.model]);

	const handleChange = (value) => {
		setLocalModelValue(value);
		editKnowledgebook('metadata.model', value, 'json');
	};

	return (
		<ModelCascader
			{...selectProps}
			types={['embedding']}
			value={localModelValue}
			loading={loading}
			onChange={handleChange}
			disabled={isIndexed}
		/>
	);
};

export default ModelSelect;
