import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useData = (url, allowLoad = true) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const fetchData = useCallback(async () => {
		setLoading(true);
		setError(false);
		try {
			const { data } = await axios.get(url);
			setData(data);
			setLoading(false);
		} catch (e) {
			console.error('Error fetching data: ', url, e.message);
			setLoading(false);
			setError(true);
		}
	}, [url]);

	useEffect(() => {
		if (allowLoad) fetchData();
	}, [fetchData, url, allowLoad]);

	return { data, loading, error, refresh: fetchData };
};

export default useData;
