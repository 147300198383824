import { signInWithPopup, fetchSignInMethodsForEmail, GithubAuthProvider } from '@firebase/auth';
import { firebaseAuth } from '../../config/firebase';
import storeToken from './storeToken';
import createNewUser from './createNewUser';

const githubProvider = new GithubAuthProvider();
const handleGithubLogin = async () => {
	try {
		const userCredential = await signInWithPopup(firebaseAuth, githubProvider);

		const { user } = userCredential;
		const { stsTokenManager, accessToken = '', metadata = {}, uid } = user;
		if (!accessToken) {
			throw new Error('Access token not found');
		}

		// storeToken(accessToken);

		const { createdAt, lastLoginAt } = metadata;
		let redirectPath;

		if ((lastLoginAt - createdAt) < 1000) {
			const response = await createNewUser(accessToken, { email: user.email, name: user?.name || user.displayName || '' });
			if (!response) {
				throw new Error('Error creating new user');
			}

			redirectPath = '/';
		}

		return {
			success: true,
			redirect: redirectPath,
			userId: uid,
			token: accessToken,
			tokenManager: stsTokenManager
		};
	} catch (error) {
		let message = 'Error logging in with Github';
		if (error.code === 'auth/account-exists-with-different-credential') {
			const email = error.customData.email;
			const methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
			const displayMethods = {
				'google.com': 'Google',
				'github.com': 'Github',
				'password': 'Email',
			};
			message = `You already have an account with ${displayMethods[methods?.[0]]}. Please login with ${displayMethods[methods?.[0]]} to link your accounts.`;
		}

		return {
			success: false,
			message,
			code: error.code
		};
	}
};

export default handleGithubLogin;
