import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Flex, Timeline, Tag, Typography, ConfigProvider } from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	FieldTimeOutlined
} from '@ant-design/icons';
import { NodeLog } from '../../../../../../components/molecules';
import modelRequiredNodes from '../../../../../../models/static/modelRequiredNodes.json';
import './index.css';

const slideInVariants = {
	hidden: {
		x: 50,
		opacity: 0,
		scale: 0.95
	},
	visible: {
		x: 0,
		opacity: 1,
		scale: 1,
		transition: {
			type: 'spring',
			stiffness: 260,
			damping: 20
		}
	}
};

const TestTimelineLog = ({ data, scrollContainerRef, allowAnimate = true }) => {
	const { t } = useTranslation();
	const isEmpty = !data?.nodeRequestLogs?.length;
	let items = [
		{
			children: <Typography.Text strong>
				Process started
			</Typography.Text>
		}
	];

	items = items.concat(data?.nodeRequestLogs?.map((nodeRequestLog) => {
		let descriptions = [];
		const { model, type, result: nodeRequestResult } = nodeRequestLog;
		const nodeMeta = {};

		if (modelRequiredNodes.map(name => name.replace('node-', '')).includes(nodeRequestLog.type)) {
			descriptions.push({
				title: t('title.model'),
				content: `${model?.name} (${t(`provider.${model?.metaProviderId}`)})`
			});
		}

		if (type === 'teammate-aug') {
			descriptions = descriptions.concat([
				{
					title: t('title.inputs'),
					content: nodeRequestResult?.log?.input,
					type: ['image-to-text-generation'].includes(nodeRequestLog.type) ? 'imageAndText'
						: ['image-to-video-generation'].includes(nodeRequestLog.type) ? 'image' : null
				},
				{
					title: t('title.output-summary'),
					content: nodeRequestResult?.log?.outputSummary || '-',
				},
				{
					title: t('title.output-documents'),
					content: (
						<Flex vertical gap={5}>
							{nodeRequestResult?.log?.outputDocuments?.map((document, index) => (
								<Typography.Text key={index}>[{index + 1}] {document?.text?.target} ({t('label.source')}: {document?.object?.name})</Typography.Text>
							))}
						</Flex>
					),
				}
			]);
		} else {
			descriptions = descriptions.concat([
				{
					title: t('title.inputs'),
					content: nodeRequestResult?.log?.input,
					type: ['image-to-text-generation'].includes(nodeRequestLog.type) ? 'imageAndText'
						: ['image-to-video-generation'].includes(nodeRequestLog.type) ? 'image' : null
				},
				{
					title: t('title.outputs'),
					content: nodeRequestResult?.log?.output,
					type: ['text-to-image-generation'].includes(nodeRequestLog.type) ? 'image'
						: ['text-to-speech-generation'].includes(nodeRequestLog.type) ? 'audio'
							: ['image-to-video-generation'].includes(nodeRequestLog.type) ? 'video' : null
				}
			]);
		}

		return {
			color: nodeRequestLog.success ? 'green' : 'red',
			children: <NodeLog
				title={nodeRequestLog.name}
				icon={nodeMeta.icon}
				backgroundColor={nodeMeta.backgroundColor}
				success={nodeRequestLog.success}
				duration={nodeRequestLog.duration}
				descriptions={descriptions}
			/>
		};
	}));

	if (data?.requestLogs?.length > 0) {
		const requestLog = data?.requestLogs[0];
		items.push({
			children: (
				<Flex gap='small'>
					<Typography.Text strong>
						Process Completed
					</Typography.Text>
					<>
						{
							requestLog?.success
								? (
									<Tag color='success' icon={<CheckCircleOutlined />}>{t('tag.success')}</Tag>
								) : (
									<Tag color='error' icon={<CloseCircleOutlined />}>{t('tag.error')}</Tag>
								)
						}
						<Tag icon={<FieldTimeOutlined />}>
							{Number(((requestLog?.completed - requestLog?.requested) * 1000).toFixed(0)).toLocaleString()} ms
						</Tag>
					</>
				</Flex>
			)
		});
	}

	useEffect(() => {
		if (scrollContainerRef?.current) {
			scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
		}
	}, [items]);

	return (
		<Flex vertical gap='middle' style={{ marginTop: '2rem' }}>
			<span className='playground-component-title'>Process Timeline</span>
			<ConfigProvider
				theme={{
					components: {
						Timeline: {
							itemPaddingBottom: 15
						},
					},
				}}
			>
				<Timeline
					pending={data?.requestLogs?.length === 0 ? t('desc.running') : false}
					items={items?.map(item => {
						const { children, ...itemConfig } = item;
						if (children) {
							return {
								...itemConfig,
								children: allowAnimate ? (
									<motion.div
										initial='hidden'
										animate='visible'
										variants={slideInVariants}
									>
										{children}
									</motion.div>
								) : children
							};
						} else {
							return null;
						}
					})}
				/>
			</ConfigProvider>
		</Flex>
	);
};

export default TestTimelineLog;
