import Context from './Context';
import GenflowPlanContext from '../../../../context/genflowPlan/Context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthData, useAuthRequest } from '../../../../hooks';
import { useParams } from 'react-router-dom';

const Provider = ({ id, children }) => {
	const { genflowId, planId } = useParams();
	const { data: planData, compiledPlanRefresh, operatePlan } = useContext(GenflowPlanContext);
	const steps = planData?.metadata?.steps;

	// eslint-disable-next-line no-undef
	const baseEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${planId}/promptbooks/${id}`;
	const { data, loading, refresh } = useAuthData(baseEndpoint);

	const promptbookContextValues = useMemo(() => ({
		data: data?.data?.rows?.[0] || {},
		loading,
		refresh,
	}), [data]);

	const { makeRequest } = useAuthRequest();
	const editPromptbook = async (key, value, type) => {
		const payload = { key, value, ...(type ? { type } : {}) };
		const response = await makeRequest(baseEndpoint, 'PATCH', payload);
		await Promise.all([refresh(), compiledPlanRefresh()]);
		return response;
	};

	const copyPromptbook = async (position) => {
		const response = await makeRequest(baseEndpoint + '/copy', 'POST');

		if (response?.message === 'OK') {
			const newPromptbookId = response?.result?.['promptbook_id'];
			const newItem = { type: 'promptbook', id: newPromptbookId };
			let newSteps = [...(steps || [])];
			newSteps.splice(position, 0, newItem);
			operatePlan({ key: 'metadata.steps', value: newSteps, type: 'json' }, 'PATCH');
		}

		return response;
	};

	const deletePromptbook = async () => {
		const response = await makeRequest(baseEndpoint, 'DELETE');
		if (response?.message === 'OK') {
			let newSteps = steps.filter((item) => item.id !== id);
			operatePlan({ key: 'metadata.steps', value: newSteps, type: 'json' }, 'PATCH');
		}
		return response;
	};

	const [isOpenGenerationAssistant, setOpenGenerationAssistant] = useState(false);

	const contextValues = {
		genflowId,
		planId,
		promptbookId: id,
		...promptbookContextValues,
		editPromptbook,
		copyPromptbook,
		deletePromptbook,
		isOpenGenerationAssistant,
		setOpenGenerationAssistant
	};

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default Provider;
