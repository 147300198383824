import { LoadingOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

const Generating = () => {
	return (
		<Flex gap='small' style={{ color: '#999999' }}>
			<LoadingOutlined />
			<div>
				<span>Generating...</span>
			</div>
		</Flex>
	);
};

export default Generating;
