import { motion } from 'framer-motion';
import ImageContainer from '../ImageContainer';

const Loading = ({ note = 'Loading hard...' }) => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<motion.div
				initial={{ x: 20 }}
				animate={{ x: 0 }}
				transition={{ stiffness: 300 }}
			>
				<ImageContainer
					src='/images/bike.gif'
					alt='Loading'
					width={120}
					style={{ marginBottom: '10px' }}
				/>
			</motion.div>
			<span style={{ color: '#999999' }}>{note}</span>
		</div>
	);
};

export default Loading;
