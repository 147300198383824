import { createContext } from 'react';

const Context = createContext({
	genflowId: null,
	planId: null,
	knowledgebookId: null,
	data: {},
	loading: true,
	refresh: () => {},
	editKnowledgebook: () => {},
	copyKnowledgebook: () => {},
	deleteKnowledgebook: () => {},
	indexKnowledgebook: () => {},
	isIndexed: false
});

export default Context;
