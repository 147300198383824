import { Typography, Table, Flex, Button, Spin } from 'antd';
import { Loading, MotionDiv } from '../../atoms';
import './index.css';

const ListPageTemplate = (props) => {
	const {
		title,
		description,
		buttons = [],
		loadingButtons = [],
		buttonsLoading = false,
		rightButtons = [],
		columns = [],
		data = [],
		loading = false,
		tableConfig = {},
		componentOnEmpty,
	} = props;

	const headerStyle = {
		margin: 0,
		marginTop: 0,
		padding: 0,
		paddingBottom: '0.5rem',
		fontSize: '2rem',
		color: 'rgba(0, 0, 0, 0.85)',
		width: '100%'
	};

	const descriptionStyle = {
		margin: 0,
		padding: 0,
		color: 'rgba(0, 0, 0, 0.6)',
		width: '100%'
	};

	return (
		<Flex vertical gap={10}>
			{title && (
				<Typography.Title level={1} style={headerStyle}>
					{title}
				</Typography.Title>
			)}
			{description && (
				<Typography.Paragraph style={descriptionStyle}>
					{description}
				</Typography.Paragraph>
			)}
			<MotionDiv>
				<Flex vertical gap={10} className='list-table-wrapper'>
					<Flex gap='small' align='center' justify='space-between' className='list-table-button-area'>
						<Flex gap='small'>
							{buttons && buttons.map(({ id, isCustom, component, label, ...restButtonProps }, index) =>
								isCustom
									? component
									: (
										<Button key={index} size='large' loading={buttonsLoading || loadingButtons?.includes(id)} {...restButtonProps}>
											{label}
										</Button>
									)
							)}
						</Flex>
						<Flex gap='small'>
							{rightButtons && rightButtons.map(({ label, ...restButtonProps }, index) => (
								<Button key={index} size='large' {...restButtonProps}>
									{label}
								</Button>
							))}
						</Flex>
					</Flex>
					<div style={{ padding: '0 12px' }}>
						{loading ? (
							<div style={{ height: '70vh' }}>
								<Loading />
							</div>
						) : data?.length === 0 && componentOnEmpty ? (
							<Flex vertical align='center' justify='center' style={{ height: 'calc(100vh - 300px)' }}>
								{componentOnEmpty}
							</Flex>
						) : (
							<MotionDiv>
								<Table
									columns={columns}
									dataSource={data}
									{...tableConfig}
								/>
							</MotionDiv>
						)}
					</div>
				</Flex>
			</MotionDiv>
		</Flex>
	);
};

export default ListPageTemplate;
