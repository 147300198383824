import { useState } from 'react';
import { Dropdown as AntDropdown, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const Dropdown = ({ name, items, type = 'button-text', ...otherDropdownConfig }) => {
	const [opening, setOpening] = useState(false);

	return (
		<AntDropdown
			menu={{
				items,
			}}
			placement="bottomRight"
			trigger="hover"
			onOpenChange={(open) => setOpening(open)}
			{...otherDropdownConfig}
		>
			<Button type='text' onClick={(e) => e.preventDefault()}>
				<Space>
					{name}
					<DownOutlined style={{
						transform: opening ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'transform 0.2s ease-in-out',
					}} />
				</Space>
			</Button>
		</AntDropdown>
	);
};

export default Dropdown;
