import { useState } from 'react';
import { Flex, Segmented } from 'antd';
import AggregatedResults from './AggregatedResults';
import Charts from './Charts';
import { BarChartOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import './index.css';

const Scores = () => {
	const [displayType, setDisplayType] = useState('chart');

	return (
		<Flex vertical gap='small'>
			<Flex justify='flex-end'>
				<Segmented
					options={[
						{
							label: 'Chart',
							value: 'chart',
							icon: <BarChartOutlined />,
						},
						{
							label: 'Spreadsheet',
							value: 'spreadsheet',
							icon: <BorderlessTableOutlined />,
						},
					]}
					value={displayType}
					onChange={setDisplayType}
					size='large'
				/>
			</Flex>
			{displayType === 'chart' && (
				<Charts />
			)}
			{displayType === 'spreadsheet' && (
				<AggregatedResults />
			)}
		</Flex>
	);
};

export default Scores;
