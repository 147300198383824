import { useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const AddModal = (props) => {
	const { t } = useTranslation();
	const { children, open, onSubmit, onCancel, okText = t('button.submit'), cancelText = t('cancel'), title, initialValues } = props;
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleCancel = () => {
		onCancel();
	};

	const handleOk = () => {
		setLoading(true);
		form
			.validateFields()
			.then(async (values) => {
				const response = await onSubmit(values);
				if (response?.success) {
					form.resetFields();
				}
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal
			open={open}
			title={title}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="back" disabled={loading} onClick={handleCancel}>
					{cancelText}
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={handleOk}>
					{okText}
				</Button>
			]}
			destroyOnClose
		>
			<Form
				form={form}
				name="basic"
				style={{
					maxWidth: 600,
				}}
				autoComplete="off"
				layout="vertical"
				preserve={false}
				initialValues={initialValues}
			>
				{children}
			</Form>
		</Modal>
	);
};

export default AddModal;