import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Flex, theme, Select, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import GenflowListContext from '../../../../context/genflowList/Context';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import { TbLayoutDashboard, TbSettingsAutomation } from 'react-icons/tb';
import { LuArrowLeft, LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu';
import { FiTool } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { FaMagnifyingGlassChart } from 'react-icons/fa6';
import { IoSettingsSharp } from 'react-icons/io5';

const defaultHeight = 'calc(100vh - 50px)';
const iconStyle = {
	fontSize: 20,
	color: '#555555',
};

const defaultOpenKeys = [];

const SideMenu = ({ collapsed, setCollapsed, collapsedWidth }) => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const { data, loading } = useContext(GenflowListContext);
	const { genflowId, localPathArr } = useContext(GenflowDetailContext);
	const currentKey = localPathArr[0] || 'root';
	const navigate = useNavigate();

	function getItem({ key, icon, customIcon, children, type, disabled, label, style }) {
		return {
			key,
			icon: customIcon || <Icon style={{
				...iconStyle,
				color: currentKey === key ? 'var(--primary-color)' : 'var(--teammately-icon-gray)'
			}} component={icon} />,
			children,
			label,
			type,
			disabled,
			style: !collapsed
				? { paddingLeft: 12, paddingRight: 12, ...style }
				: { padding: '3px 11px', ...style },
		};
	}

	const items = [
		getItem({
			key: 'root',
			customIcon: <Icon component={TbLayoutDashboard} style={{
				...iconStyle,
				color: currentKey === 'root' ? 'var(--primary-color)' : 'var(--teammately-icon-gray)',
				fontSize: 24,
				marginLeft: -4,
				marginRight: 0 }}
			/>,
			label: 'Overview'
		}),
		getItem({
			key: 'develop',
			customIcon: <Icon component={FiTool} style={{
				...iconStyle,
				color: currentKey === 'develop' ? 'var(--primary-color)' : 'var(--teammately-icon-gray)',
				fontSize: 22,
				marginLeft: -4,
				marginRight: 2
			}} />,
			label: 'Develop'
		}),
		getItem({
			key: 'optimize',
			customIcon: <Icon component={IoSettingsSharp} style={{
				...iconStyle,
				color: currentKey === 'optimize' ? 'var(--primary-color)' : 'var(--teammately-icon-gray)',
				fontSize: 24,
				marginLeft: -4,
				marginRight: 0
			}} />,
			label: 'Optimize'
		}),
		getItem({
			key: 'observe',
			customIcon: <Icon component={FaMagnifyingGlassChart} style={{
				...iconStyle,
				color: currentKey === 'observe' ? 'var(--primary-color)' : 'var(--teammately-icon-gray)',
				fontSize: 22,
				marginLeft: -1,
				marginRight: 0
			}} />,
			label: 'Observe'
		}),
	];

	const handleGenflowIdChange = (v) => {
		if (v) {
			navigate('/genflows/' + v);
		}
	};

	return (
		<Layout.Sider
			collapsible
			collapsed={collapsed}
			collapsedWidth={collapsedWidth}
			onCollapse={(value) => setCollapsed(value)}
			trigger={null}
			style={{
				background: colorBgContainer,
				overflow: 'scroll',
				height: defaultHeight,
				position: 'fixed',
				boxShadow: '6px 0 10px -5px rgba(0, 0, 0, 0.05)',
				zIndex: 20
			}}
		>
			<Flex vertical justify='space-between' style={{ height: defaultHeight }}>
				<Flex vertical>
					<AnimatePresence>
						{!collapsed && (
							<motion.div
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.2 }}
							>
								<Flex vertical gap={5} style={{ margin: 10, width: 180 }}>
									<span style={{ fontSize: 12, fontWeight: 600, color: '#999999' }}>Generation Flow</span>
									{loading ? (
										<Skeleton.Input active round size='middle' style={{ width: 180 }}/>
									) : (
										<Select
											showSearch
											size='middle'
											variant='filled'
											placeholder='Select generation flow'
											options={data?.map(item => ({
												value: item.genflow_id,
												label: item.name || 'Untitled'
											}))}
											optionFilterProp='label'
											value={genflowId}
											onChange={handleGenflowIdChange}
											style={{ width: '100%' }}
										/>
									)}
								</Flex>
							</motion.div>
						)}
					</AnimatePresence>
					{collapsed && <div style={{ height: 71 }} />}
					<motion.div
						animate={{ y: collapsed ? -71 : 0 }}
						transition={{ duration: 0.2 }}
					>
						<Menu
							theme="light"
							mode="inline"
							items={[
								getItem({
									customIcon: <LuArrowLeft style={{
										...iconStyle,
										color: '#555555',
										fontSize: 24,
										marginLeft: -5,
										marginRight: -2
									}}/>,
									label: 'Back to List'
								})
							]}
							selectedKeys={null}
							onClick={() => {
								navigate('/genflows');
							}}
						/>
						<Menu
							style={{ fontWeight: 500 }}
							theme='light'
							mode='inline'
							items={items}
							selectedKeys={[localPathArr[0] || 'root']}
							defaultOpenKeys={defaultOpenKeys}
							onClick={(v) => navigate(v.key === 'root' ? '' : v.key)}
						/>
					</motion.div>
				</Flex>
				<Menu
					theme="light"
					mode="inline"
					items={[
						getItem({
							customIcon: collapsed ? (
								<LuArrowRightToLine style={{
									...iconStyle,
									color: '#555555',
									fontSize: 24,
									marginLeft: -5,
								}} />
							) : (
								<LuArrowLeftToLine style={{
									...iconStyle,
									color: '#555555',
									fontSize: 24,
									marginLeft: -2,
									marginRight: -2
								}} />
							),
							label: collapsed ? 'Expand' : 'Collapse'
						})
					]}
					selectedKeys={null}
					onClick={() => {
						setCollapsed(!collapsed);
					}}
				/>
			</Flex>
		</Layout.Sider>
	);
};

export default SideMenu;
