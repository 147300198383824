import { useContext, useEffect, useRef, useState } from 'react';
import PromptbookContext from '../../../contexts/Context';
import { CodeEditor } from '../../../../../atoms';
import { Skeleton } from 'antd';

const defaultSystemPrompt = `<!-- Initializing the expected role of this app -->
You are a helpful assistant.`;

const defaultValue = `## Context
- 
- 

<!-- Instruction text -->
## Instruction


## User Query
{{textInput}}
`;

const PromptTextEditor = ({ type, stepNameMap }) => {
	const { data, loading, editPromptbook, isOpenGenerationAssistant } = useContext(PromptbookContext);
	const debounceTimer = useRef();
	const [editorKey, setEditorKey] = useState(Date.now());
	const [renderAllowed, setRenderAllowed] = useState(true);
	const [isFocused, setIsFocused] = useState(false);
	const delayTimer = useRef(null);

	useEffect(() => {
		if (!isFocused) {
			setEditorKey(Date.now());
			setRenderAllowed(!isOpenGenerationAssistant);
		}
	}, [isOpenGenerationAssistant, data]);

	useEffect(() => {
		if (!isOpenGenerationAssistant) {
			if (delayTimer.current) {
				clearTimeout(delayTimer.current);
			}
			delayTimer.current = setTimeout(() => {
				if (data['prompt_text']) {
					setRenderAllowed(true);
				}
			}, 500); // Set this to the number of milliseconds you want to wait
		}
		return () => {
			if (delayTimer.current) {
				clearTimeout(delayTimer.current);
			}
		};
	}, [data['prompt_text'], isOpenGenerationAssistant]);

	const debouncedOnChange = useRef((text) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			if (type === 'system') {
				editPromptbook('metadata.systemPrompt', text);
			} else if (type === 'prompt') {
				editPromptbook('promptText', text);
			}
		}, 750);
	}).current;

	const handleChange = (text) => {
		debouncedOnChange(text);
	};

	return (loading || isOpenGenerationAssistant || !renderAllowed) ? (
		<Skeleton active={loading} />
	) : (
		<div key={editorKey}>
			<CodeEditor
				height={type === 'system' ? '50px' : '300px'}
				language='markdown'
				defaultValue={data['prompt_text'] || defaultValue}
				onChange={handleChange}
				onStartEditing={() => setIsFocused(true)}
				onFinishEditing={() => setIsFocused(false)}
				options={{
					selectOnLineNumbers: true,
					wordWrap: 'on',
					wrappingIndent: 'indent'
				}}
				stepNameMap={stepNameMap}
			/>
		</div>
	);
};

export default PromptTextEditor;
