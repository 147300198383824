import Presentation from './Presentation';
import { useAuthData } from '../../../../../../hooks';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import { useContext } from 'react';

const Container = (props) => {
	const { genflowId, optimizationId, data } = useContext(GenflowOptimizeContext);
	const targetPlanId = data?.metadata?.targetPlanId;

	// eslint-disable-next-line no-undef
	const recommendationEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/plans/${targetPlanId}/recommendations`;
	const { data: recommendationsData } = useAuthData(recommendationEndpoint, !!targetPlanId);

	// eslint-disable-next-line no-undef
	const recommendationsAnalysisEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/plans/${targetPlanId}/recommendations/analysis`;
	const { data: recommendationAnalysisData } = useAuthData(recommendationsAnalysisEndpoint, !!targetPlanId);

	return <Presentation
		{...props}
		recommendations={recommendationsData?.data?.rows || []}
		analysis={recommendationAnalysisData?.data?.rows?.[0]?.analysis || ''}
	/>;
};

export default Container;
