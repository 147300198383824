import { useContext, useEffect, useRef, useState } from 'react';
import KnowledgebookContext from '../../../contexts/Context';
import { Flex, Radio, Select, Switch, Tooltip } from 'antd';
import { SliderWithInputNumber } from '../../../../../atoms';
import { motion, AnimatePresence } from 'framer-motion';
import { QuestionCircleOutlined } from '@ant-design/icons';

const DEFAULT_VALUE = {
	splitter_type: 'recursive_character',
	chunk_size: 250,
	chunk_overlap: 50,
	separators: ['\\n', '.'],
	keep_separator: 'end'
};

const Chunk = () => {
	const { data, loading, editKnowledgebook, isIndexed } = useContext(KnowledgebookContext);
	const [localValue, setLocalValue] = useState({});
	const isUsingDefaultValue = useRef(false);
	const isInitialLoad = useRef(true);
	
	useEffect(() => {
		if (isInitialLoad.current && !loading && data?.metadata?.chunk) {
			setLocalValue(data?.metadata?.chunk);
			isInitialLoad.current = false;
		}
	}, [data]);

	const handleChange = (key, value) => {
		if (key === 'chunk_size' && value < localValue['chunk_overlap']) {
			setLocalValue({ ...localValue, [key]: value, 'chunk_overlap': value });
		} else {
			setLocalValue({ ...localValue, [key]: value });
		}
	};

	useEffect(() => {
		editKnowledgebook('metadata.chunk', localValue, 'json');
	}, [localValue]);

	const items = [
		{
			key: 'splitter_type',
			title: 'Splitter Type',
			component: (
				<Radio.Group
					buttonStyle='solid'
					value={localValue['splitter_type'] || DEFAULT_VALUE['splitter_type']}
					onChange={(e) => handleChange('splitter_type', e.target.value)}
					disabled={isIndexed}
				>
					<Radio.Button value='recursive_character'>Recursive Character</Radio.Button>
					<Radio.Button value='token' disabled>Token</Radio.Button>
				</Radio.Group>
			)
		},
		{
			key: 'chunk_size',
			title: 'Chunk Size',
			component: (
				<SliderWithInputNumber
					min={1}
					max={500}
					value={localValue['chunk_size'] || DEFAULT_VALUE['chunk_size']}
					variant='filled'
					onChange={(v) => handleChange('chunk_size', v)}
					disabled={isIndexed}
				/>
			)
		},
		{
			key: 'chunk_overlap',
			title: 'Chunk Overlap',
			component: (
				<SliderWithInputNumber
					min={0}
					max={localValue['chunk_size']}
					value={localValue['chunk_overlap'] || DEFAULT_VALUE['chunk_overlap']}
					variant='filled'
					onChange={(v) => handleChange('chunk_overlap', v)}
					disabled={isIndexed}
				/>
			)
		},
		{
			key: 'separators',
			title: (
				<Flex align='center' gap='small'>
					<span>Separators</span>
					<Tooltip
						title={(
							<Flex vertical gap='small'>
								<span>Input separator and press Enter</span>
							</Flex>
						)}
						color='var(--tooltip-background)'
					>
						<QuestionCircleOutlined />
					</Tooltip>
				</Flex>
			),
			component: (
				<Select
					mode='tags'
					variant='filled'
					size='large'
					allowClear
					style={{
						width: 'calc(100% - 125px)',
					}}
					value={localValue['separators'] || DEFAULT_VALUE['separators']}
					placeholder='Input separator and press Enter'
					open={false}
					suffixIcon={null}
					onChange={(v) => handleChange('separators', v)}
					disabled={isIndexed}
				/>
			)
		},
		{
			key: 'keep_separator',
			title: 'Keep Separator',
			component: (
				<Flex gap='small' align='center' style={{ height: 30 }}>
					<Switch
						size='large'
						onChange={(v) => handleChange('keep_separator', v === true ? 'end' : false)}
						defaultValue={localValue['keep_separator']}
						disabled={isIndexed}
					/>
					<AnimatePresence>
						{localValue['keep_separator'] && (
							<motion.div
								initial={{ opacity: 0, x: -10 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: -10 }}
								transition={{ duration: 0.2 }}
							>
								<Radio.Group
									size='small'
									buttonStyle='solid'
									value={localValue['keep_separator'] || DEFAULT_VALUE['keep_separator']}
									onChange={(e) => handleChange('keep_separator', e.target.value)}
									disabled={isIndexed}
								>
									<Radio.Button value='start'>Start</Radio.Button>
									<Radio.Button value='end'>End</Radio.Button>
								</Radio.Group>
							</motion.div>
						)}
					</AnimatePresence>
				</Flex>
			)
		},
	];

	return (
		<Flex vertical gap='small'>
			{items.map((item) => (
				<ChunkItem key={item.key} title={item.title}>
					{item.component}
				</ChunkItem>
			))}
		</Flex>
	);
};

const ChunkItem = ({ title, children }) => {
	return (
		<Flex gap='small' align='center'>
			<span style={{ fontWeight: 600, width: 125 }}>{title}</span>
			{children}
		</Flex>
	);
};

export default Chunk;
