import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Row, Col, Card, Avatar, Typography, Tag } from 'antd';
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './index.css';

const NodeLog = (props) => {
	const { title, icon, backgroundColor, success, duration = 0, descriptions = [] } = props;
	const { t } = useTranslation();

	const displayDescriptions = [
		{
			title: t('title.result'),
			content: success
				? (
					<Tag color='success' icon={<CheckCircleOutlined />}>{t('tag.success')}</Tag>
				) : (
					<Tag color='error' icon={<CloseCircleOutlined />}>{t('tag.error')}</Tag>
				),
			type: 'default',
		},
		{
			title: t('title.duration'),
			content: (
				<Tag icon={<FieldTimeOutlined />}>{duration.toLocaleString()} ms</Tag>
			),
			type: 'default',
		},
		...descriptions
	];

	return (
		<div className='playground-card'>
			<Flex vertical gap='small'>
				{title && (<Title name={title} icon={icon} backgroundColor={backgroundColor} />)}
				{displayDescriptions.map(({ title, content, type }, index) => {
					let imageUrl;
					if (type === 'imageAndText') {
						const jsonMatch = content.match(/<user>(\[.*\])/);
						if (jsonMatch) {
							const jsonString = jsonMatch[1];
							const json = JSON.parse(jsonString);

							const imageUrlObject = json.find(item => item.type === 'image_url');

							if (imageUrlObject && imageUrlObject.image_url && imageUrlObject.image_url.url) {
								imageUrl = imageUrlObject.image_url.url;
							}
						}
					}

					return (
						<Row key={index} gutter={8}>
							<Col span={5}>
								<Typography.Text strong>{title}</Typography.Text>
							</Col>
							<Col span={19}>
								{type === 'default'
									? content
									: type === 'image' ? (
										<ImageWithCdnFallback style={{ width: '100%' }} src={content} />
									) : type === 'imageAndText' ? (
										<>
											<Typography.Paragraph
												ellipsis={{
													rows: 1,
													expandable: true,
													symbol: 'more',
												}}
												style={{ margin: 0 }}
											>
												{content}
											</Typography.Paragraph>
											<ImageWithCdnFallback style={{ width: '100%' }} src={imageUrl}/>
										</>
									) : type === 'video' ? (
										<VideoWithCdnFallback style={{ width: '100%' }} src={content} controls autoPlay muted loop>
											Your browser does not support the video tag.
										</VideoWithCdnFallback>
									) : type === 'documents' ? (
										{ content }
									) : (
										<Typography.Paragraph
											ellipsis={{
												rows: 1,
												expandable: true,
												symbol: 'more',
											}}
											style={{ margin: 0 }}
										>
											{content}
										</Typography.Paragraph>
									)
								}
							</Col>
						</Row>
					);
				})}
			</Flex>
		</div>
	);
};

const Title = ({ icon, backgroundColor, name }) => {
	return (
		<Flex gap='small' align='center'>
			<Avatar
				shape="square"
				size="small"
				icon={icon}
				style={{ display: 'block', backgroundColor: backgroundColor || '#FFFFFF' }}
			/>
			<Typography.Text
				strong
				ellipsis
			>
				{name}
			</Typography.Text>
		</Flex>
	);
};

function ImageWithCdnFallback({ src, alt, ...rest }) {
	const [imgSrc, setImgSrc] = useState(src);

	const handleError = () => {
		const url = new URL(src);
		const filePath = url.pathname.split('/').pop();
		setImgSrc(`https://storage.googleapis.com/rx-0003-cdn/public/demo/${filePath}`);
	};

	return <img {...rest} src={imgSrc} alt={alt} onError={handleError} />;
}

function VideoWithCdnFallback({ src, ...rest }) {
	const [videoSrc, setVideoSrc] = useState(src);

	const handleError = () => {
		const url = new URL(src);
		const filePath = url.pathname.split('/').pop();
		setVideoSrc(`https://storage.googleapis.com/rx-0003-cdn/public/demo/${filePath}`);
	};

	return (
		<video {...rest} src={videoSrc} onError={handleError}>
		Your browser does not support the video tag.
		</video>
	);
}

export default NodeLog;
