import './index.css';
import { forwardRef, useEffect, useRef, useState } from 'react';

const Tabs = ({ items = [], activeKey, onSelect = () => {} }) => {
	const [indicatorStyle, setIndicatorStyle] = useState({});
	const tabRefs = useRef([]);

	useEffect(() => {
		const activeTab = tabRefs.current[items.findIndex(item => item.key === activeKey)];
		if (activeTab) {
			setIndicatorStyle({
				left: activeTab.offsetLeft + 'px',
				width: activeTab.offsetWidth + 'px'
			});
		}
	}, [activeKey, items]);

	return (
		<>
			<ul className="tab-list">
				{items.map((item, index) => (
					<TabItem
						ref={el => tabRefs.current[index] = el}
						key={item.key}
						id={item.key}
						{...item}
						onSelect={onSelect}
						isActive={activeKey === item.key}
					/>
				))}
				<div className="tab-indicator" style={indicatorStyle} />
			</ul>
		</>
	);
};

const TabItem = forwardRef(({ onSelect, id, isActive, component }, ref) => {
	return (
		<li ref={ref} className={`tab-item${isActive ? ' tab-item-active' : ''}`} onClick={() => onSelect(id)}>
			{component}
		</li>
	);
});

TabItem.displayName = 'TabItem';

export default Tabs;
