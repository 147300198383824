import { useState, useEffect } from 'react';
import { collection, getFirestore, onSnapshot, query, where, orderBy, limit } from '@firebase/firestore';

const useFirestoreRealtimeData = (queryParams) => {
	const { collectionName, conditions, orderKey, limitCount } = queryParams;
	const [data, setData] = useState([]);

	useEffect(() => {
		const db = getFirestore();
		let q = query(collection(db, collectionName));

		const areConditionsValid = conditions && conditions.length === 3 && conditions.every(c => c !== null);

		if (!areConditionsValid) {
			conditions.forEach(condition => {
				q = query(q, where(condition[0], condition[1], condition[2]));
			});
		} else {
			q = query(q, where('_id', '==', ''));
		}

		if (orderKey) {
			q = query(q, orderBy(orderKey));
		}
		if (limitCount) {
			q = query(q, limit(limitCount));
		}

		const unsubscribe = onSnapshot(q,(querySnapshot) => {
			const results = [];
			querySnapshot.forEach((doc) => {
				results.push({ ...doc.data(), id: doc.id });
			});
			setData(results);
		});
		return () => unsubscribe();
	}, [collectionName, conditions, orderKey, limitCount]);

	return data;
};

export default useFirestoreRealtimeData;
