import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Presentation from './Presentation';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import GlobalContext from '../../../../context/GlobalContext';

const Container = (props) => {
	const navigate = useNavigate();
	const { pushMessage } = useContext(GlobalContext);
	const { genflowId, operatePlan } = useContext(GenflowDetailContext);

	const handleCreate = async () => {
		const response = await operatePlan(null, 'POST');
		if (response.message === 'OK' && response.result?.['genflow_plan_id']) {
			const genflowPlanId = response.result?.['genflow_plan_id'];
			navigate(`/genflows/${genflowId}/develop/${genflowPlanId}`);

			return response;
		} else {
			pushMessage('Creation failed', 'error');
			
			return response;
		}
	};

	return (
		<Presentation
			handleCreate={handleCreate}
		/>
	);
};

export default Container;
