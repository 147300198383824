import { useContext } from 'react';
import { Alert, Button, Flex, Tabs } from 'antd';
import { CopyBlock, dracula } from 'react-code-blocks';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';
import {
	CheckCircleFilled,
	CloseCircleFilled,
	KeyOutlined,
	RightSquareOutlined
} from '@ant-design/icons';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const DISPLAY_ENDPOINT_DOMAIN = 'https://tmmt.ly/v0';
const DEFAULT_INPUT_PAYLOAD = {
	input: {
		textInput: 'Hello World!'
	}
};

function formatJSONString(json, initialIndent = '') {
	const jsonString = JSON.stringify(json, null, 4);
	return jsonString.split('\n').map(line => initialIndent + line).join('\n');
}

const Endpoint = () => {
	const { genflowId, genflowData, productionPlanData } = useContext(GenflowDetailContext);
	const navigate = useNavigate();

	const inputSchemaArr = productionPlanData?.metadata?.input;
	const inputSchemaForPayload = {};
	inputSchemaArr?.length > 0 && inputSchemaArr?.forEach((item) => {
		inputSchemaForPayload[item.key] = 'YOUR_TEXT';
	});

	const inputPayload = inputSchemaArr?.length > 0 ? { input: inputSchemaForPayload } : DEFAULT_INPUT_PAYLOAD;

	const codeModules = [
		{
			key: 'bash',
			language: 'bash',
			label: 'curl',
			code: `curl -X POST ${DISPLAY_ENDPOINT_DOMAIN}/${genflowId} \\
-H "Authorization: Bearer $TEAMMATELY_API_KEY" \\
-H "Content-Type: application/json" \\
-d '${JSON.stringify(inputPayload)}'`,
		},
		{
			key: 'javascript',
			language: 'javascript',
			label: 'Node.js',
			code: `import axios from 'axios';

async function main() {
    const endpointUrl = '${DISPLAY_ENDPOINT_DOMAIN}/${genflowId}';
    const apiKey = 'TEAMMATELY_API_KEY'; // Replace with your actual API key
    
    const data = ${formatJSONString(inputPayload, '    ')};
    
	const response = await axios.post(endpointUrl, data, {
		headers: {
			'Authorization': \`Bearer \${apiKey}\`,
			'Content-Type': 'application/json'
		}
	});

	console.log('Response:', response.data);
}

main();`
		},
		{
			key: 'python',
			language: 'python',
			label: 'Python',
			code: `import requests

endpoint_url = '${DISPLAY_ENDPOINT_DOMAIN}/${genflowId}'
api_key = 'TEAMMATELY_API_KEY'  # Replace with your actual API key

headers = {
    'Authorization': f'Bearer {api_key}',
    'Content-Type': 'application/json'
}

data = ${JSON.stringify(inputPayload, null, 4)}

response = requests.post(endpoint_url, json=data, headers=headers)`,
		}
	];

	return (
		<Flex vertical gap='large'>
			<div className='production-plan-container'>
				<Flex vertical gap='small'>
					<span style={{ fontWeight: 600 }}>CURRENT PRODUCTION PLAN</span>
					{genflowData?.metadata?.productionPlanId ? (
						<Flex gap='small' align='center'>
							<CheckCircleFilled style={{ color: 'var(--primary-color)', fontSize: 18 }} />
							<a
								className='production-plan-link'
								onClick={() => navigate(`./develop/${productionPlanData?.genflow_plan_id}`)}
							>
								{productionPlanData?.name || 'Untitled plan'}
							</a>
						</Flex>
					): (
						<Flex gap='small' align='center'>
							<CloseCircleFilled style={{ color: '#333333', fontSize: 18 }} />
							<span>You have no production plan yet</span>
							<Button size='small' type='link' onClick={() => navigate('./develop')}>Choose plan</Button>
						</Flex>
					)}
				</Flex>
			</div>
			<Flex vertical>
				<Flex gap='small' align='center' justify='space-between'>
					<Flex gap='small' align='center' justify='flex-start'>
						<RightSquareOutlined style={{ fontSize: 20, fontWeight: 600 }} />
						<span style={{ fontSize: 20, fontWeight: 600 }}>
							Your API Endpoint
						</span>
					</Flex>
					<Flex gap='small' align='center' justify='flex-end'>
						<Button type='primary' icon={<KeyOutlined />} onClick={() => navigate('/api-keys')}>Get API Key</Button>
						<Button icon={<HiOutlineBookOpen />} href={`${process.env.REACT_APP_DOCS_DOMAIN}/api/reference`} target='_blank'>Read docs</Button>
					</Flex>
				</Flex>
				<Tabs
					defaultActiveKey='bash'
					items={codeModules?.map(codeModule => {
						return {
							key: codeModule.key,
							label: codeModule.label,
							children: (
								<Flex vertical gap='middle'>
									{!genflowData?.metadata?.productionPlanId && (
										<Alert
											message='The following code is for your future reference. You cannot access this endpoint unless you promote one plan to production.'
											type='warning'
											banner
										/>
									)}
									<CopyBlock
										text={codeModule.code}
										language={codeModule.language}
										showLineNumbers
										theme={dracula}
										codeBlock
									/>
								</Flex>
							),
						};
					})}
				/>
			</Flex>
		</Flex>
	);
};

export default Endpoint;
