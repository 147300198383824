import { Button, Divider, Flex, Modal, Table, Tag, Tooltip } from 'antd';
import {
	CodeEditor,
	Editors,
	Empty,
	Loading,
	MotionDiv,
	RelativeTime,
	TipsCard,
	UserTableCell
} from '../../../../../../components/atoms';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAuthData } from '../../../../../../hooks';
import Icon, {
	DownOutlined,
	ImportOutlined,
	MinusCircleOutlined,
	PlayCircleOutlined, PlusOutlined,
	RightOutlined
} from '@ant-design/icons';
import GenflowDetailContext from '../../../../../../context/genflowDetail/Context';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../../../../../context/GlobalContext';
import { FloatingControlPanel } from './components';
import { FiTool } from 'react-icons/fi';
import './index.css';

const MAX_ALT_PLANS_COUNT = 3;

const Recommendations = (props) => {
	const isInitialLoad = useRef(true);
	const { analysis, recommendations } = props;
	const recommendationIds = recommendations?.map((item) => item.recommendation_id);
	const { pushMessage } = useContext(GlobalContext);
	const { genflowData, plansList } = useContext(GenflowDetailContext);
	const { hasReachedMaxModuleCount, isBeforeAltEvaluationInvoked } = useContext(GenflowOptimizeContext);
	const currentProductionPlanId = genflowData?.metadata?.productionPlanId;
	const { genflowId, data, plansLoading, plansRefresh, invokeEvaluation, updateStatus, operateOptimize } = useContext(GenflowOptimizeContext);
	const targetPlanId = data?.metadata?.targetPlanId;
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();

	// eslint-disable-next-line no-undef
	const genflowPlanEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${targetPlanId}`;
	const { data: genflowPlanData } = useAuthData(genflowPlanEndpoint, !!targetPlanId);
	const targetPlanInitialPromptbookId = genflowPlanData?.data?.rows?.[0]?.metadata?.steps?.[0]?.id || '';

	// eslint-disable-next-line no-undef
	const promptbookEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${targetPlanId}/promptbooks/${targetPlanInitialPromptbookId}`;
	const { data: promptbookList, loading: promptbookLoading } = useAuthData(promptbookEndpoint, !!targetPlanInitialPromptbookId);
	const promptbookData = promptbookList?.data?.rows?.[0];

	useEffect(() => {
		if (isInitialLoad.current) {
			plansRefresh();
			isInitialLoad.current = false;
		}
	}, []);

	const handleInvocation = async () => {
		if (data?.metadata?.comparisonPlans && data?.metadata?.comparisonPlans?.length <= MAX_ALT_PLANS_COUNT) {
			setLoading(true);

			if (!data?.metadata?.comparisonPlans?.length > 0) {
				pushMessage('Choose at least one plan for comparison', 'error');
			} else {
				const responses = await Promise.all(data?.metadata?.comparisonPlans.map((planId) => {
					return invokeEvaluation(planId);
				}));

				if (responses?.every(response => response)) {
					await updateStatus('ALT_PLANS_EVALUATIONS');
					navigate('../experiments');
				}
			}
			setLoading(false);
		}
	};

	const floatingHeight = 60;
	const bodyStyle = {
		paddingTop: floatingHeight + 10
	};

	const nominatedPlansColumns = [
		{
			title: 'Plan name',
			dataIndex: 'label',
			key: 'label',
			width: '50%',
		},
		{
			title: 'Created by',
			dataIndex: 'createdBy',
			key: 'createdBy',
		},
		{
			title: 'Editors',
			dataIndex: 'editors',
			key: 'editors',
		},
		{
			title: 'Updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		}
	];

	const handleNominatedPlanRemoval = async (planId) => {
		setRemovingPlans([...removingPlans, planId]);
		const newComparisonPlans = data?.metadata?.comparisonPlans?.filter(id => id !== planId);
		const payload = {
			key: 'metadata.comparisonPlans',
			value: newComparisonPlans,
			type: 'json'
		};
		await operateOptimize(payload, 'PATCH');
		setRemovingPlans(removingPlans.filter(id => id !== planId));
	};
	const [removingPlans, setRemovingPlans] = useState([]);

	const nominatedPlansDisplayData = data?.metadata?.comparisonPlans?.map(planId => {
		const targetPlan = plansList?.find(plan => plan.genflow_plan_id === planId);

		return {
			planId,
			label: (
				<Flex vertical>
					<div>
						{recommendationIds?.includes(planId)
							? <Tag bordered={false} color='magenta-inverse'>JUST RECOMMENDED</Tag>
							: <Tag bordered={false} color='magenta'>EXISTING PLANS</Tag>
						}
						{planId === currentProductionPlanId && (
							<Tag bordered={false} color='var(--teammately-orange)' style={{ fontWeight: 600 }}>PROD</Tag>
						)}
					</div>
					{targetPlan?.name || 'Untitled plan'}
				</Flex>
			),
			createdBy: <UserTableCell userId={targetPlan?.created_by}/>,
			editors: <Editors editors={targetPlan?.editors} />,
			updatedAt: <RelativeTime datetime={targetPlan?.updated_at} />,
			action: (
				<Flex gap='small' align='center'>
					<Tooltip title='Develop' color='var(--tooltip-background)'>
						<Button
							type='text'
							shape='circle'
							icon={<Icon component={FiTool} />}
							onClick={() => navigate(`/genflows/${genflowId}/develop/${planId}`)}
						/>
					</Tooltip>
					<Tooltip title={isBeforeAltEvaluationInvoked ? 'Remove from evaluation' : null} color='var(--tooltip-background)'>
						<Button
							type='text'
							shape='circle'
							icon={<MinusCircleOutlined />}
							loading={removingPlans?.includes(planId)}
							disabled={!isBeforeAltEvaluationInvoked}
							onClick={() => handleNominatedPlanRemoval(planId)}
						/>
					</Tooltip>
				</Flex>
			)
		};
	});

	return (
		<>
			<div style={bodyStyle}>
				<FloatingControlPanel height={floatingHeight}>
					<Flex justify='space-between' align='center' style={{ width: '100%' }}>
						<Flex gap='small' align='center' justify='flex-start'>
							<span style={{ fontSize: 20, paddingLeft: 10 }}>
								{recommendations?.length === 0 ? 'Waiting for AI recommendations' : [
									(!data?.metadata?.comparisonPlans || data?.metadata?.comparisonPlans?.length === 0) ? 'No' : data?.metadata?.comparisonPlans?.length,
									'plan' + (data?.metadata?.comparisonPlans?.length > 1 ? 's' : ''),
									'nominated'
								].join(' ')}
							</span>
							{data?.metadata?.comparisonPlans?.length > MAX_ALT_PLANS_COUNT && (
								<Tag bordered={false} color='red-inverse'>
									Too many plans, select up to {MAX_ALT_PLANS_COUNT} plans
								</Tag>
							)}
						</Flex>
						<Flex gap='small' align='center' justify='flex-end'>
							<Tooltip
								title={recommendations?.length === 0 ? 'Please run evaluations first, or wait for AI recommendations' : null}
								color='var(--tooltip-background)'
							>
								<Button
									size='large'
									icon={<DownOutlined />}
									onClick={() => {
										setModalOpen(true);
									}}
									disabled={modalOpen || recommendations?.length === 0 || !isBeforeAltEvaluationInvoked}
									iconPosition='end'
								>
								Add from existing plans
								</Button>
							</Tooltip>
							<Tooltip
								title={data?.metadata?.comparisonPlans?.length > MAX_ALT_PLANS_COUNT ? `You cannot add more than ${MAX_ALT_PLANS_COUNT} plans now.`
									: recommendations?.length === 0 ? 'Please run evaluations first, or wait for AI recommendations' : null}
								color='var(--tooltip-background)'
							>
								<Button
									type='primary'
									size='large'
									icon={<PlayCircleOutlined />}
									loading={loading}
									disabled={!data?.metadata?.comparisonPlans?.length > 0 || data?.metadata?.comparisonPlans?.length > MAX_ALT_PLANS_COUNT || !isBeforeAltEvaluationInvoked}
									onClick={handleInvocation}
								>
									Start Alternative Plan Evaluations
								</Button>
							</Tooltip>
						</Flex>
					</Flex>
				</FloatingControlPanel>
				{/*<TipsCard title='What to do here?'>*/}
				{/*	<span>*/}
				{/*		Let&apos;s do followings in step by step:*/}
				{/*		<ul>*/}
				{/*			<li><strong>See &quot;AI Analysis&quot;:</strong> Review the AI&apos;s analysis of the evaluation results.</li>*/}
				{/*			<li><strong>Choose &quot;AI recommended plans&quot;:</strong> Based on the AI analysis, alternative plans are suggested. If you want to use these in the next step of evaluation, press the &quot;Evaluate and Compare&quot; button.</li>*/}
				{/*			<li><strong>See &quot;Nominated plans list for evaluations&quot;:</strong> The plans you selected by pressing the &quot;Evaluate and Compare&quot; button will be listed here. These plans will be evaluated in the next step.</li>*/}
				{/*			<li><strong>Click &quot;Start Alternative Plan Evaluations&quot;:</strong> After pressing the &quot;Start Alternative Plan Evaluations&quot; button, you will proceed to the next step where the alternative plans will be evaluated.</li>*/}
				{/*		</ul>*/}
				{/*		On the top right of the screen, there is an &quot;Add from existing plans&quot; option. This allows you to select a plan that is not part of the current optimization. The selected plan will also be listed in the Nominated plans list for evaluations and will be subject to evaluation.*/}
				{/*	</span>*/}
				{/*</TipsCard>*/}
				<Flex gap='large' vertical>
					<Card title='AI Analysis' animationIndex={1}>
						{analysis ? (
							<span style={{ fontSize: 16, lineHeight: '24px' }} dangerouslySetInnerHTML={{
								__html: analysis
									.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Replace **text** with <b>text</b>
									.replace(/\n/g, '<br />')              // Replace newline characters with <br />
							}}></span>
						) : <Empty type='waiting' description='Waiting for the evaluation to be completed' />}
					</Card>
					<Card title='AI recommended plans' animationIndex={2}>
						{recommendations?.length > 0 ? (
							<Flex gap='middle' wrap justify='flex-start'>
								{promptbookLoading ? <Loading /> : (
									<>
										<PlanCard
											color='red'
											animationIndex={0}
											recommendation_id={promptbookData?.genflow_plan_id}
											isOriginal
											data={{
												steps: [{
													model: { name: promptbookData?.model?.metaModelId?.split('/')?.[1] },
													prompt_text: promptbookData?.prompt_text,
													config: {
														max_tokens: promptbookData?.metadata?.maxTokens,
														stop: [],
														temperature: promptbookData?.metadata?.temperature,
														top_p: promptbookData?.metadata?.topP,
													},
													type: 'promptbook'
												}]
											}}
										/>
										{recommendations?.map((recommendation, index) => (
											<PlanCard
												key={recommendation.recommendation_id}
												color='green'
												animationIndex={index + 1}
												index={index}
												{...recommendation}
											/>
										))}
									</>
								)}
							</Flex>
						) : (
							<Empty type='building' description='Waiting for the evaluation to be completed' />
						)}
					</Card>
					<Card title='Nominated plans list for evaluations' animationIndex={3}>
						{data?.metadata?.comparisonPlans?.length > MAX_ALT_PLANS_COUNT && (
							<Tag bordered={false} color='red-inverse'>
								Too many plans, select up to {MAX_ALT_PLANS_COUNT} plans
							</Tag>
						)}
						{(nominatedPlansDisplayData && nominatedPlansDisplayData?.length > 0) ? (
							<Table
								columns={nominatedPlansColumns}
								dataSource={nominatedPlansDisplayData}
							/>
						) : (
							<div style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{loading ? <Loading/> : <Empty type='building' description='No plan is nominated. Choose plans for comparison!'/>}
							</div>
						)}
					</Card>
				</Flex>
			</div>
			<ExistingPlanSelectionModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
};

const Card = ({ title, animationIndex, children }) => {
	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex vertical gap='small' className='card-recommendations'>
				<span style={{ fontSize: 24, fontWeight: 600 }}>{title}</span>
				{children}
			</Flex>
		</MotionDiv>
	);
};

const PlanCard = (props) => {
	const { animationIndex, recommendation_id, description, data, isOriginal = false, index } = props;
	const [isAdded, setIsAdded] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const { genflowId, plansList, plansLoading, plansRefresh } = useContext(GenflowDetailContext);

	useEffect(() => {
		if (
			!plansLoading
			&& plansList?.length > 0
			&& plansList?.some(plan => plan.genflow_plan_id === recommendation_id)
		) {
			setIsAdded(true);
		}
	}, [plansList, plansLoading]);

	const steps = data?.steps;
	const color = isOriginal ? 'red' : 'green';

	const { data: optimizeData, operateOptimize, convertRecommendationToPlan, isBeforeAltEvaluationInvoked } = useContext(GenflowOptimizeContext);

	const handleConversion = async () => {
		setLoading(true);

		const response = await convertRecommendationToPlan(recommendation_id);
		if (response?.message === 'OK') {
			if (isBeforeAltEvaluationInvoked) {
				const payload = {
					key: 'metadata.comparisonPlans',
					value: [...optimizeData?.metadata?.comparisonPlans || [], recommendation_id],
					type: 'json'
				};
				await operateOptimize(payload, 'PATCH');
			}
			await plansRefresh();

			setIsAdded(true);
		}
		setLoading(false);
	};

	return (
		<MotionDiv animationIndex={animationIndex} className='card-recommendation-plans'>
			<Flex vertical gap='small'>
				<span style={{ fontWeight: 600 }}>{isOriginal ? 'Original Plan' : `Plan ${index + 1}`}</span>
				<span style={{ lineHeight: '24px', height: '96px' }}>{description}</span>
				<Divider style={{ margin: 10 }} />
				{steps?.map((step, index) => (
					<Flex key={index} vertical gap='small' className='card-recommendation-plans' style={{ width: '100%' }}>
						<Flex align='center'>
							<span style={{ fontWeight: 600, fontSize: 18 }}>{step?.name}</span>
							<Tag bordered={false}>{step.type || ''}</Tag>
						</Flex>
						<Tag color={color} bordered={false}>Model</Tag>
						{step?.model?.name || 'Unknown model'}
						<Tag color={color} bordered={false}>Prompt Text</Tag>
						<CodeEditor
							height='200px'
							defaultValue={step?.prompt_text || ''}
							options={{
								selectOnLineNumbers: true,
								wordWrap: 'on',
								wrappingIndent: 'indent'
							}}
						/>
						<Tag color={color} bordered={false}>Configs</Tag>
						{step?.config && Object.keys(step?.config)?.filter(configKey => !!step?.config[configKey])?.map(configKey => (
							<span key={configKey}><b>{configKey}</b>: {step?.config[configKey]}</span>
						))}
					</Flex>
				))}
				{!isOriginal && (
					<Button
						type={!isAdded && 'primary'}
						size='large'
						icon={!isAdded && <ImportOutlined />}
						onClick={handleConversion}
						loading={loading}
						disabled={isAdded}
					>
						{isAdded && optimizeData?.metadata?.comparisonPlans?.includes(recommendation_id) ? 'Added for eval queue'
							: isAdded ? 'Added in plan list'
								: isBeforeAltEvaluationInvoked ? 'Evaluate and Compare'
									: 'Import to plan'}
					</Button>
				)}
				{(isAdded || isOriginal) && (
					<Button
						size='large'
						icon={<RightOutlined />}
						onClick={() => navigate(`/genflows/${genflowId}/develop/${recommendation_id}`)}
					>
						View in Develop
					</Button>
				)}
			</Flex>
		</MotionDiv>
	);
};

const ExistingPlanSelectionModal = ({ open, onClose }) => {
	const isInitialLoad = useRef(true);
	const { pushMessage } = useContext(GlobalContext);
	const { genflowData, plansList, plansLoading, plansRefresh } = useContext(GenflowDetailContext);
	const { data, operateOptimize } = useContext(GenflowOptimizeContext);
	const currentProductionPlanId = genflowData?.metadata?.productionPlanId;
	const [localComparisonPlans, setLocalComparisonPlans] = useState([]);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (isInitialLoad.current && data?.metadata?.comparisonPlans) {
			setLocalComparisonPlans(data?.metadata?.comparisonPlans);
			isInitialLoad.current = false;
		}
	}, [data?.metadata?.comparisonPlans]);

	const displayData = plansList?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.map((row) => {
			const { genflow_id, genflow_plan_id, name, created_by, updated_at, editors, metadata } = row;

			return {
				key: genflow_plan_id,
				updatedAt: <RelativeTime datetime={updated_at} />,
				createdBy: <UserTableCell userId={created_by} />,
				editors: <Editors editors={editors} loading={plansLoading} />,
				label: (
					<Flex align='center'>
						{currentProductionPlanId === genflow_plan_id && (
							<Tag bordered={false} color='var(--teammately-orange)' style={{ fontWeight: 600 }}>PROD</Tag>
						)}
						<span>{name || 'No title'}</span>
					</Flex>
				),
			};
		});

	const columns = [
		{
			title: 'Plan name',
			dataIndex: 'label',
			key: 'label',
			width: '60%',
		},
		{
			title: 'Editors',
			dataIndex: 'editors',
			key: 'editors',
		},
		{
			title: 'Updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
		},
	];

	const handleSave = async () => {
		setSaving(true);
		const payload = {
			key: 'metadata.comparisonPlans',
			value: localComparisonPlans,
			type: 'json'
		};
		const response = await operateOptimize(payload, 'PATCH');
		if (response.message === 'OK') {
			pushMessage('Comparison plan updated', 'success');
			onClose();
		} else {
			pushMessage('Unknown error updating comparison plans', 'error');
		}
		setSaving(false);
	};

	return (
		<Modal
			title='Add from existing plans'
			width='80vw'
			style={{
				top: 20,
			}}
			open={open}
			onCancel={onClose}
			footer={[
				<Button key='cancel' size='large' onClick={onClose} disabled={saving}>Cancel</Button>,
				<Button key='save' size='large' type='primary' onClick={handleSave} loading={saving}>Save</Button>
			]}
		>
			<Table
				columns={columns}
				dataSource={displayData}
				loading={plansLoading && !plansList}
				rowSelection={{
					columnTitle: 'Compare',
					columnWidth: 50,
					type: 'checkbox',
					selectedRowKeys: localComparisonPlans,
					onChange: (keys) => {
						setLocalComparisonPlans(keys);
					}
				}}
			/>
		</Modal>
	);
};

export default Recommendations;
