import { MotionDiv } from '../../../../../../../components/atoms';
import { useContext, useState } from 'react';
import GlobalContext from '../../../../../../../context/GlobalContext';
import GenflowOptimizeContext from '../../../../../../../context/genflowOptimize/Context';
import { Flex, Tag, Input, Button } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const CardEditing = ({ onFinish }) => {
	const { pushMessage } = useContext(GlobalContext);
	const { targetPlanData, operateDatasetManualAdd } = useContext(GenflowOptimizeContext);
	const inputSchemaKeys = targetPlanData?.metadata?.input?.map(schema => schema.key) || [];
	const [submitLoading, setSubmitLoading] = useState(false);
	const [value, setValue] = useState({});

	const handleSubmit = async () => {
		setSubmitLoading(true);
		const response = await operateDatasetManualAdd([value], 'manual');

		if (response.message === 'OK') {
			onFinish();
		} else {
			pushMessage('Error updating database', 'error');
		}
	};

	return (
		<MotionDiv
			key='editer'
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
		>
			<div className='user-input-card-editor'>
				<Flex gap='middle'>
					<Flex gap='middle' vertical style={{ width: '100%' }}>
						{inputSchemaKeys.map((key) => (
							<Flex key={key} gap='small' align='flex-start'>
								<div>
									<Tag style={{ fontWeight: 600 }}>{key}</Tag>
								</div>
								<Input.TextArea
									variant='filled'
									rows={3}
									placeholder={`Write an input text for "${key}"`}
									value={value[key]}
									onChange={(e) => {
										setValue({ ...value, [key]: e.target.value });
									}}
								/>
							</Flex>
						))}
					</Flex>
					<Flex vertical gap='small'>
						<Button type='primary' icon={<SaveOutlined />} onClick={handleSubmit} loading={submitLoading}>
							Save
						</Button>
						<Button icon={<CloseOutlined />} onClick={onFinish} disabled={submitLoading}>
							Discard
						</Button>
					</Flex>
				</Flex>
			</div>
		</MotionDiv>
	);
};

export default CardEditing;
