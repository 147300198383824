import { Avatar, Flex, Typography } from 'antd';
import { useContext } from 'react';
import GlobalContext from '../../../context/GlobalContext';

const DEFAULT_ICON_SRC = '/images/avatar-circle-color-orange.png';

const UserTableCell = ({ userId }) => {
	const isAi = userId === 'ai';
	const { workspace } = useContext(GlobalContext);
	const updatedByMetadata = workspace?.members?.find(m => m.user_id === userId);

	return (
		<Flex align='center' gap='small'>
			<div style={{ width: '30px' }}>
				<Avatar
					size={30}
					src={isAi ? '/images/ai.png' : (updatedByMetadata?.avatar_url || DEFAULT_ICON_SRC)}
					alt={isAi ? 'AI Generated' : updatedByMetadata?.user_name}
				/>
			</div>
			<Typography.Text ellipsis>{isAi ? 'AI Generated' : updatedByMetadata?.user_name}</Typography.Text>
		</Flex>
	);
};

export default UserTableCell;
