import { Button, Flex, Tooltip } from 'antd';
import { BackButton, EditableTitle } from '../../../../../../components/atoms';
import { useContext, useState } from 'react';
import GlobalContext from '../../../../../../context/GlobalContext';
import { PlayCircleOutlined } from '@ant-design/icons';
import GenflowPlanContext from '../../../../../../context/genflowPlan/Context';
import GenflowDetailContext from '../../../../../../context/genflowDetail/Context';
import dayjs from 'dayjs';

const headerStyle = {
	backgroundColor: '#FFFFFF',
	padding: '10px',
	position: 'fixed',
	zIndex: 10,
	top: 50,
	borderBottom: '1px solid #e8e8e8',
	marginLeft: -16
};

const Header = (props) => {
	const { collapsed, user } = useContext(GlobalContext);
	const { optimizationsData, createOptimizeWithInitialization } = useContext(GenflowDetailContext);
	const { data, loading, planId } = useContext(GenflowPlanContext);
	const { backDestination, ...restProps } = props;
	const [loadingOptimizeButton, setLoadingOptimizeButton] = useState(false);

	const isOptimizeForbidden = (
		loading
		|| (!data?.metadata?.input || data?.metadata?.input?.length === 0 || !data?.metadata?.input?.every(i => i.key))
		|| data?.metadata?.steps?.length === 0
		|| (!data?.metadata?.output || data?.metadata?.output?.length === 0 || !data?.metadata?.output?.every(i => i.key) || !data?.metadata?.output?.some(s => s.variable))
	);
	const shouldPulse = !loading && optimizationsData?.length === 0 && !isOptimizeForbidden;

	const handleCreateOptimize = async () => {
		setLoadingOptimizeButton(true);
		await createOptimizeWithInitialization({
			targetPlanId: planId,
			...(data?.name ? { name: `${data?.name} - Optimize by ${user.name} on ${dayjs().format('YYYY-MM-DD HH:mm:ss')}` } : {}),
		});
		setLoadingOptimizeButton(false);
	};

	return (
		<div
			style={{
				...headerStyle,
				width: `calc(100vw - ${collapsed ? '50' : '200'}px)`,
				transition: 'width 0.25s'
			}}
		>
			<Flex align='center' justify='space-between'>
				<Flex align='center' justify='flex-start' gap='small'>
					<BackButton destination={backDestination} />
					<EditableTitle
						{...restProps }
					/>
				</Flex>
				<Flex align='center' justify='flex-end' gap='small'>
					<Tooltip
						color='var(--tooltip-background)'
						title={isOptimizeForbidden
							? `You need to have at least ${data?.metadata?.steps?.length === 0 ? 'one step and ' : ''} one output schema to run optimization.`
							: null
						}
					>
						<Button
							className={shouldPulse ? 'pulse' : null}
							type='primary'
							size='large'
							icon={<PlayCircleOutlined />}
							loading={loadingOptimizeButton}
							disabled={loading || isOptimizeForbidden}
							onClick={handleCreateOptimize}
						>
							Start optimizing this plan
						</Button>
					</Tooltip>
				</Flex>
			</Flex>
		</div>
	);
};

export default Header;
