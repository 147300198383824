import { Empty as AntEmpty } from 'antd';

const typeImageMap = {
	building: '/images/building.png',
	waiting: '/images/observing.png',
};

const Empty = (props) => {
	const { type, ...restProps } = props;

	const config = {
		...restProps,
		...(type ? { image: typeImageMap[type] } : {})
	};

	return (
		<AntEmpty
			{...config}
		/>
	);
};

export default Empty;
