import { Button, Flex } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import ExperimentDetailContext from '../../contexts/Context';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import { CheckCircleFilled, CloseCircleOutlined, DoubleRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { AbsoluteTime } from '../../../../../../../../components/atoms';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './index.css';

const hasAltPlansStatus = [
	'ALT_PLANS_GENERATED',
	'ALT_PLANS_EVALUATIONS',
	'ALT_PLANS_EVALUATIONS_COMPLETED',
	'COMPLETED',
	'FINAL_REPORT_GENERATED'
];

const Working = () => {
	const { genflowId, optimizationId, refresh, status, data } = useContext(GenflowOptimizeContext);
	const { isWorking, messages } = useContext(ExperimentDetailContext);
	const hasAltPlans = useMemo(() => {
		return hasAltPlansStatus.includes(status);
	}, [status, isWorking, messages]);
	const [animate, setAnimate] = useState(false);
	const controls = useAnimation();
	const navigate = useNavigate();

	useEffect(() => {
		if (hasAltPlans) {
			// Start the animation for scaling and shaking
			controls.start({
				scale: [1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1],
				rotate: [0, 10, -10, 10, -10, 10, -10, 0],
				transition: {
					duration: 2,
					times: [0, 0.15, 0.3, 0.4, 0.5, 0.6, 0.7, 1],
					ease: 'easeInOut'
				}
			});

			// Set and clear the animation state
			setAnimate(true);
			const timer = setTimeout(() => {
				setAnimate(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [hasAltPlans, controls]);

	const cardStyle = {
		padding: 20,
		borderRadius: 12,
		animation: animate ? 'fadeToWhite 3s forwards' : ''
	};

	const [progressList, setProgressList] = useState([]);
	useEffect(() => {
		const newProgressList = [];

		if (messages?.some(message => message.data?.event === 'SIMULATION')) {
			const targetMessage = messages?.find(message => message.data?.event === 'SIMULATION');
			newProgressList.push({
				id: 'simulation',
				event: 'SIMULATION',
				status: targetMessage?.data?.type,
				timestamp: targetMessage?.timestamp || targetMessage?.data?.timestamp,
			});
		} else if (isWorking) {
			newProgressList.push({
				id: 'simulation-progress',
				event: 'SIMULATION',
				status: 'PROGRESS',
			});
		}

		const hasOverallEvaluationCompleted = messages?.some(message => message.data?.event === 'EVALUATIONS_OVERALL');

		if (messages?.some(message => message.data?.event?.startsWith('EVALUATION:'))) {
			const evaluationMessages = messages?.filter(message => message.data?.event?.startsWith('EVALUATION:'));

			const failedMessages = evaluationMessages?.filter(message => message.data?.type === 'ERROR') || [];
			if (failedMessages.length > 0) {
				const lastFailedEvaluationMessage = failedMessages.length > 0 ? failedMessages[failedMessages.length - 1] : {};

				newProgressList.push({
					id: 'evaluation-error',
					event: 'EVALUATION',
					status: 'ERROR',
					timestamp: lastFailedEvaluationMessage?.timestamp || lastFailedEvaluationMessage?.data?.timestamp,
					progress: failedMessages?.length || 0
				});
			}

			const successfulMessages = evaluationMessages?.filter(message => message.data?.type === 'SUCCESS') || [];
			if (successfulMessages.length > 0) {
				const lastSuccessfulEvaluationMessage = successfulMessages.length > 0 ? successfulMessages[successfulMessages.length - 1] : {};

				newProgressList.push({
					id: 'evaluation' + !hasOverallEvaluationCompleted ? '-progress' : '',
					event: 'EVALUATION',
					status: hasOverallEvaluationCompleted ? 'SUCCESS' : 'PROGRESS',
					timestamp: lastSuccessfulEvaluationMessage?.timestamp || lastSuccessfulEvaluationMessage?.data?.timestamp,
					progress: successfulMessages?.length || 0
				});
			}
		}

		if (messages?.some(message => message.data?.event === 'RECOMMENDATION_GEN')) {
			const targetMessage = messages?.find(message => message.data?.event === 'RECOMMENDATION_GEN');
			newProgressList.push({
				id: 'recommendation-gen' + isWorking ? '-progress' : '',
				event: 'RECOMMENDATION_GEN',
				status: targetMessage?.data?.type === 'ERROR' ? targetMessage?.data?.type : isWorking ? 'PROGRESS' : 'SUCCESS',
				timestamp: targetMessage?.timestamp || targetMessage?.data?.timestamp,
			});
		}

		setProgressList(newProgressList);
	}, [messages]);

	useEffect(() => {
		refresh();
	}, [isWorking]);
	
	return (
		<div
			className={`card-generating ${isWorking ? 'card-generating-background' : hasAltPlans ? 'card-completed-background' : ''}`}
			style={cardStyle}
		>
			<Flex gap='large'>
				{isWorking ? (
					<img src='/images/evaluating.gif' height={200} alt='Evaluating'/>
				) : hasAltPlans && (
					<motion.img
						src='/images/congrats.png'
						height={200}
						alt='Alt plans generated'
						animate={controls}
					/>
				)}
				<Flex vertical gap='middle' style={{ paddingTop: 20, width: '100%' }}>
					<span style={{ fontSize: 20, fontWeight: 600 }}>
						{isWorking ? 'Hang tight, I\'m experimenting hard!' : hasAltPlans ? 'Congratulations! AI suggestions are now ready!' : ''}
					</span>
					{progressList?.map((progress, index) => (
						<Message
							key={index}
							id={progress.id}
							status={progress.status}
							event={progress.event}
							timestamp={progress.timestamp}
							progress={progress.progress}
						/>
					))}
				</Flex>
				{hasAltPlans && (
					<Flex style={{ width: '100%' }} justify='flex-end' align='flex-end'>
						<Button
							type='primary'
							size='large'
							icon={<DoubleRightOutlined />}
							onClick={() => navigate(`/genflows/${genflowId}/optimize/${optimizationId}/recommendations`)}
						>
							See AI suggestions now
						</Button>
					</Flex>
				)}
			</Flex>
		</div>
	);
};

const Message = ({ id, status, event, timestamp, progress }) => {
	const iconMap = {
		SUCCESS: <CheckCircleFilled style={{ color: 'var(--primary-color)' }} />,
		PROGRESS: <LoadingOutlined style={{ color: 'var(--primary-color)' }} />,
		ERROR: <CloseCircleOutlined style={{ color: 'red' }} />,
	};

	const messageMap = {
		INITIALIZATION: {
			SUCCESS: 'Started evaluation',
		},
		SIMULATION: {
			SUCCESS: 'Simulation completed',
			PROGRESS: 'Now simulating the plan',
			ERROR: 'Simulation failed',
		},
		EVALUATION: {
			SUCCESS: `Evaluation completed in ${progress} metrics`,
			PROGRESS: `Evaluation completed in ${progress} metrics`,
			ERROR: `Evaluation failed in ${progress} metrics`,
		},
		RECOMMENDATION_GEN: {
			SUCCESS: 'AI suggestions generated',
			PROGRESS: 'AI suggestions generated, now preparing the report',
			ERROR: 'AI suggestions generation failed',
		}
	};

	return (
		<motion.div
			key={id}
			initial={{ opacity: 0, y: 15 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.2 }}
		>
			<Flex gap='small'>
				{iconMap?.[status]}
				<span>{messageMap?.[event]?.[status]}</span>
				{timestamp && (<span style={{ color: '#999999' }}><AbsoluteTime datetime={timestamp} /></span>)}
			</Flex>
		</motion.div>
	);
};

export default Working;
