import { ScrollRestoration, useOutlet } from 'react-router-dom';
import { ConfigProvider, Flex } from 'antd';
import './index.css';

const AuthLayout = () => {
	const outlet = useOutlet();

	const bodyStyle = {
		position: 'relative', width: '100vw', height: '100vh'
	};

	const backgroundStyle = {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: 'auto',
		height: '100vh',
		backgroundImage: 'url(/images/auth-background.png)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundSize: 'auto 100%',
		zIndex: -5,
	};

	return (
		<div style={bodyStyle}>
			<div
				style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
				className='first-view-animated-background'
			/>
			<div style={backgroundStyle} />
			<div
				style={{
					position: 'relative',
					zIndex: 1,
					overflow: 'hidden',
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<ConfigProvider
					theme={{
						token: {
							colorPrimaryBg: '#EAEDFA',
							colorPrimary: '#3857D3',
							colorInfo: '#3857D3',
							colorLink: '#3857D3',
							colorInfoText: '#3857D3',
							fontSizeHeading2: '24px',
							lineHeightHeading2: '30px'
						},
						components: {
							Table: {
								rowHoverBg: '#EAEDFA',
							}
						}
					}}
				>
					<Flex
						gap='middle'
						vertical
						style={{
							padding: '20px',
							borderRadius: 10,
							backgroundColor: 'rgba(256, 256, 256, 0.9)',
							width: 400,
							maxWidth: '90vw',
							zIndex: 10
						}}
					>
						<div style={{ padding: '0 25px' }}>
							<img src='/logo.svg' alt='Teammately logo' />
						</div>
						{outlet}
					</Flex>
				</ConfigProvider>
			</div>
			<ScrollRestoration/>
		</div>
	);
};

export default AuthLayout;
