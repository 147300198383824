import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Button, InputNumber, Skeleton, Tooltip } from 'antd';
import Icon, { CheckOutlined, CloseCircleOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, CardGenerating, CreationModal, FloatingControlPanel, Progress } from './components';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import { BiSortAlt2 } from 'react-icons/bi';
import { IoMdCreate } from 'react-icons/io';
import { Empty } from '../../../../../../components/atoms';
import CardEditing from './components/CardEditing';

const floatingHeight = 60;
const bodyStyle = {
	paddingTop: floatingHeight + 10
};

const maxInputCount = 100;

const InputDataset = () => {
	const [initialModalOpen, setInitialModalOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [userInputCount, setUserInputCount] = useState();
	const {
		data,
		targetPlanData,
		userInputSetList,
		userInputSetLoading,
		userInputSetRefresh,
		setUserInputSetSort,
		setUserInputSetFilter,
		userInputSetGenerationsStatusList,
		userInputSetGenerationsStatusRefresh,
		operateOptimize,
		operateDatasetApproval,
		isInitializationCompleted,
		isBeforeEvaluationInvoked
	} = useContext(GenflowOptimizeContext);

	const isInitialLoad = useRef(true);
	useEffect(() => {
		if (data?.metadata?.targetUserInputCount && isInitialLoad.current) {
			setUserInputCount(data?.metadata?.targetUserInputCount);
			isInitialLoad.current = false;
		}
	}, [data]);

	const debounceTimer = useRef();
	const debouncedOnChange = useRef((value) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}

		const payload = {
			key: 'metadata.targetUserInputCount',
			value,
			type: 'integer'
		};
		debounceTimer.current = setTimeout(() => {
			operateOptimize(payload, 'PATCH');
		}, 250);
	}).current;

	const handleInput = (value) => {
		if (isBeforeEvaluationInvoked) {
			setUserInputCount(value);
			if (value >= 1 && value <= maxInputCount) {
				debouncedOnChange(value);
			}
		}
	};

	const currentApprovedUserInputCount = userInputSetList?.filter(item => item.is_approved === true)?.length;
	const generatingTasks = userInputSetGenerationsStatusList?.filter(item => item?.status === 'GENERATING' && new Date(item.invoked_at) >= new Date(new Date() - 5 * 60000))
        || [];

	const hasActiveTasks = useMemo(() => {
		return generatingTasks?.length > 0;
	}, [generatingTasks]);

	useEffect(() => {
		if (hasActiveTasks) {
			const interval = setInterval(() => {
				userInputSetRefresh();
				userInputSetGenerationsStatusRefresh();
			}, 3000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [hasActiveTasks, userInputSetRefresh, userInputSetGenerationsStatusRefresh]);

	const handleClose = () => {
		userInputSetGenerationsStatusRefresh();
		setDialogOpen(false);

		setTimeout(() => {
			userInputSetGenerationsStatusRefresh();
		}, 1000);
	};

	const [isManuallyEditing, setManuallyEditing] = useState(false);

	const displayItemComponents = useMemo(() => {
		let components = [];
		components = [
			...(isManuallyEditing ? [
				<CardEditing key='editor' onFinish={() => setManuallyEditing(false)} disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted} />
			] : []),
			...(generatingTasks && generatingTasks.length > 0
				? generatingTasks.map((task, index) => (
					<CardGenerating
						key={task.invocation_id}
						invocation_id={task.invocation_id}
						invoked_at={task.invoked_at}
						metadata={task.metadata}
						shouldAnimateProgress={new Date(task.invoked_at) >= new Date(new Date() - 5000)}
						isCompleted={!generatingTasks?.map(item => item.invocation_id).includes(task.invocation_id)}
					/>
				))
				: []
			),
			...(userInputSetList && userInputSetList.length > 0
				? userInputSetList.map((item, index) => (
					<Card
						key={item?.optimization_dataset_record_id}
						animationIndex={index + generatingTasks.length}
						disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted}
						{...item}
					/>
				))
				: []
			)
		];

		return components;
	}, [userInputSetList, userInputSetGenerationsStatusList, generatingTasks]);

	const [sortKey, setSortKey] = useState('created_at:desc');
	const [filterKey, setFilterKey] = useState('all');

	const sortOptions = [
		{ value: 'created_at:asc', label: 'Created (Asc)' },
		{ value: 'created_at:desc', label: 'Created (Desc)' },
		{ value: 'source_key:asc', label: 'Type' },
		...((targetPlanData?.metadata?.input && targetPlanData?.metadata?.input?.length > 0)
			? targetPlanData.metadata.input
				.map(input => ([
					{ value: `input->>'${input.key}':asc`, label: `${input.key} (Asc)` },
					{ value: `input->>'${input.key}':desc`, label: `${input.key} (Desc)` }
				])).flat()
                || []
			: []
		)
	];

	const filterOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'generations', label: 'AI Generations' },
		{ value: 'upload', label: 'Upload' },
		{ value: 'logs', label: 'Production Logs' },
		{ value: 'manual', label: 'Manual Input' },
	];

	const handleQueryChange = (method, value) => {
		switch (method) {
		case 'sort': {
			setSortKey(value);
			const [key, direction] = value?.split(':') ?? [null, null];
			setUserInputSetSort({ key, direction });
			break;
		}

		case 'filter': {
			setFilterKey(value);
			setUserInputSetFilter(value);
			break;
		}

		default:
			console.warn(`Unhandled method: ${method}`);
			break;
		}
	};

	const commonSelectProps = {
		variant: 'filled',
		popupMatchSelectWidth: false,
		size: 'large',
	};

	const [approveAllButtonLoading, setApproveAllButtonLoading] = useState(false);
	const handleApproveAll = async () => {
		setApproveAllButtonLoading(true);
		await userInputSetRefresh();
		const uncheckedItems = userInputSetList?.filter(item => item.is_approved === null)?.map(item => item.optimization_dataset_record_id);

		await operateDatasetApproval(uncheckedItems, true);
		await userInputSetRefresh();
		setApproveAllButtonLoading(false);
	};

	const shouldSuggestToApproveAll = userInputSetList?.length > 0
		&& userInputSetList?.filter(item => !item.is_approved)?.length > 0
		&& currentApprovedUserInputCount < userInputCount;

	return (
		<>
			<div style={bodyStyle}>
				<FloatingControlPanel height={floatingHeight}>
					<Flex justify='space-between' align='center' style={{ width: '100%' }}>
						<Flex gap='small' align='center'>
							<AddInputDatasetButton
								onClickAdd={() => setDialogOpen(true)}
								onClickManual={() => setManuallyEditing(true)}
								disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted}
							/>
							<Tooltip
								color='var(--tooltip-background)'
								title={userInputSetList?.filter(item => !item.is_approved)?.length === 0 ? 'Nothing to approve' : (
									<span>
										Approve every unchecked pattern. Those checked disapproved ( <CloseCircleOutlined /> ) will not be impacted.
									</span>
								)}
							>
								<Button
									className={shouldSuggestToApproveAll ? 'pulse' : null}
									size='large'
									icon={<CheckOutlined />}
									onClick={handleApproveAll}
									loading={approveAllButtonLoading}
									disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted || userInputSetList?.filter(item => !item.is_approved)?.length === 0}
								>
                                    Approve All
								</Button>
							</Tooltip>
							<Flex gap='small' align='center'>
								<div style={{ paddingTop: 8 }}>
									<Progress
										percent={(currentApprovedUserInputCount / userInputCount) * 100}
										size={{ width: 200, height: 20 }}
									/>
								</div>
								<span
									style={{
										display: 'inline-block',
										whiteSpace: 'nowrap',
										fontSize: 16,
										color: currentApprovedUserInputCount > userInputCount ? 'red' :
											currentApprovedUserInputCount === userInputCount ? 'green'
												: 'black'
									}}
								>
									{currentApprovedUserInputCount} /
								</span>
								<InputNumber
									placeholder='Number'
									size='large'
									variant='filled'
									value={userInputCount}
									onChange={handleInput}
									min={1}
									max={maxInputCount}
									style={{ width: 75 }}
									disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted}
								/>
							</Flex>
						</Flex>
						<Flex align='center' justify='flex-end' style={{ width: '100%' }}>
							{/*<Select*/}
							{/*	value={sortKey}*/}
							{/*	options={sortOptions}*/}
							{/*	onChange={(v) => handleQueryChange('sort', v)}*/}
							{/*	labelRender={({ label }) => <Flex gap='small' align='center'><BiSortAlt2 />{label}</Flex>}*/}
							{/*	{...commonSelectProps}*/}
							{/*/>*/}
							{/*<Select*/}
							{/*	value={filterKey}*/}
							{/*	options={filterOptions}*/}
							{/*	onChange={(v) => handleQueryChange('filter', v)}*/}
							{/*	labelRender={({ label }) => <Flex gap='small' align='center'><FilterOutlined />{label}</Flex>}*/}
							{/*	{...commonSelectProps}*/}
							{/*/>*/}
							<Tooltip title='Coming soon' color='var(--tooltip-background)'>
								<Button size='large' shape='circle' type='text' icon={<Icon component={BiSortAlt2} />} disabled />
							</Tooltip>
							<Tooltip title='Coming soon' color='var(--tooltip-background)'>
								<Button size='large' shape='circle' type='text' icon={<FilterOutlined />} disabled />
							</Tooltip>
						</Flex>
					</Flex>
				</FloatingControlPanel>
				<Flex vertical gap='small'>
					{displayItemComponents?.length > 0
						? displayItemComponents : userInputSetLoading
							? <Skeleton active />
							: (
								<Flex vertical align='center' justify='center' style={{ height: '50vw' }}>
									<Empty description='No dataset - You need to add some!' type='building'>
										<AddInputDatasetButton
											vertical
											onClickAdd={() => setDialogOpen(true)}
											disabled={!isBeforeEvaluationInvoked || !isInitializationCompleted}
										/>
									</Empty>
								</Flex>
							)
					}
				</Flex>
			</div>
			<CreationModal open={dialogOpen} onClose={handleClose} />
		</>
	);
};

const AddInputDatasetButton = ({ vertical = false, onClickAdd, onClickManual, disabled }) => {
	const { isInitializationCompleted, isBeforeEvaluationInvoked } = useContext(GenflowOptimizeContext);
	return (
		<Flex gap='small' vertical={vertical}>
			<Tooltip
				color='var(--tooltip-background)'
				title={
					!isInitializationCompleted ? 'Please complete the Initialization step first'
						: !isBeforeEvaluationInvoked ? 'You cannot modify after you started evaluation.'
							: null
				}
			>
				<Button size='large' type='primary' icon={<PlusOutlined />} onClick={onClickAdd} disabled={disabled}>
					Add Input Dataset
				</Button>
			</Tooltip>
			<Tooltip
				color='var(--tooltip-background)'
				title={
					!isInitializationCompleted ? 'Please complete the Initialization step first'
						: !isBeforeEvaluationInvoked ? 'You cannot modify after you started evaluation.'
							: null
				}
			>
				<Button size='large' type='primary' icon={<Icon component={IoMdCreate} />} onClick={onClickManual} disabled={disabled}>
                Add Manually
				</Button>
			</Tooltip>
		</Flex>
	);
};

export default InputDataset;
