import { useContext, useEffect } from 'react';
import { Button, Flex, Table, Tag, Tooltip } from 'antd';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import Icon, {
	CheckCircleOutlined,
	FileSearchOutlined,
	LineChartOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FiTool } from 'react-icons/fi';
import { MotionDiv } from '../../../../../../components/atoms';
import './index.css';

const ReportList = () => {
	const navigate = useNavigate();
	const { genflowId, plansList, plansLoading, plansRefresh, data, metricsData, status } = useContext(GenflowOptimizeContext);
	const targetSimulationCount = data?.metadata?.targetUserInputCount;
	const targetEvaluationCount = (metricsData && data?.metadata?.evaluationModules) ?
		Object.keys(data?.metadata?.evaluationModules).map(outputKey => {
			const targetModules = Object.keys(data?.metadata?.evaluationModules[outputKey]) || [];
			const targetMetrics = metricsData?.filter(metric => targetModules.includes(metric.module_key)) || [];
			return targetMetrics.length;
		}).reduce((acc, targetMetricsCount) => acc + targetMetricsCount, 0) : 0;

	useEffect(() => {
		const anyProcessing = plansList.some(plan => {
			const { simulations_completion_count, evaluations_completion_count } = plan;
			return simulations_completion_count < targetSimulationCount || evaluations_completion_count < (targetSimulationCount * targetEvaluationCount);
		});

		let intervalId;
		if (anyProcessing) {
			intervalId = setInterval(() => {
				plansRefresh();
			}, 2000);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [plansList, plansRefresh, targetSimulationCount, targetEvaluationCount]);

	const columns = [
		{
			key: 'type',
			title: 'Type',
			dataIndex: 'plan_type',
			width: 100,
			render: (text) => (
				<Tag bordered={false} color={text === 'ORIGINAL' ? 'red' : 'green'}>{text}</Tag>
			)
		},
		{
			key: 'name',
			title: 'Plan Name',
			dataIndex: 'name',
			width: 600
		},
		{
			key: 'progress',
			title: 'Progress',
			dataIndex: 'progress',
			render: (_, record) => {
				const { simulations_completion_count, evaluations_completion_count } = record;
				if (simulations_completion_count >= targetSimulationCount && evaluations_completion_count >= (targetSimulationCount * targetEvaluationCount)) {
					return <Tag bordered={false} color='green' icon={<CheckCircleOutlined />}>Completed</Tag>;
				} else if (status === 'ALT_PLANS_EVALUATIONS' && (simulations_completion_count < targetSimulationCount || evaluations_completion_count < (targetSimulationCount * targetEvaluationCount))) {
					return <Tag bordered={false} color='blue' icon={<LoadingOutlined />}>Processing</Tag>;
				} else {
					return <Tag bordered={false}>Not started</Tag>;
				}
			}
		},
		{
			key: 'simulations',
			title: '# Simulation Comp',
			dataIndex: 'simulations_completion_count',
			render: (text) => (
				<span>{text}/{targetSimulationCount} {(status === 'ALT_PLANS_EVALUATIONS' && text < targetSimulationCount) && <LoadingOutlined />}</span>
			)
		},
		{
			key: 'evaluations',
			title: '# Evaluation Comp',
			dataIndex: 'evaluations_completion_count',
			render: (text, record) => (
				<span>{text}/{targetSimulationCount * targetEvaluationCount} {(status === 'ALT_PLANS_EVALUATIONS' && record.simulations_completion_count >= targetSimulationCount && text < (targetSimulationCount * targetEvaluationCount)) && <LoadingOutlined />}</span>
			)
		},
		{
			key: 'action',
			title: 'Action',
			dataIndex: 'action'
		}
	];

	const dataSource = plansList?.map((item) => {
		return {
			...item,
			action: (
				<Flex gap='small'>
					<Tooltip title='Logs' color='var(--tooltip-background)'>
						<Button
							type='text'
							shape='circle'
							icon={<Icon component={FileSearchOutlined} />}
							onClick={() => navigate(`./${item.genflow_plan_id}/log`)}
						/>
					</Tooltip>
					<Tooltip title='Scores' color='var(--tooltip-background)'>
						<Button
							type='text'
							shape='circle'
							icon={<Icon component={LineChartOutlined} />}
							onClick={() => navigate(`./${item.genflow_plan_id}/scores`)}
						/>
					</Tooltip>
					<Tooltip title='Develop' color='var(--tooltip-background)'>
						<Button
							type='text'
							shape='circle'
							icon={<Icon component={FiTool} />}
							onClick={() => navigate(`/genflows/${genflowId}/develop/${item.genflow_plan_id}`)}
						/>
					</Tooltip>
				</Flex>
			)
		};
	});

	return (
		<Flex vertical gap='large'>
			<Card title='All plans in evaluation'>
				<Flex gap='large' vertical>
					<Table
						columns={columns}
						dataSource={dataSource}
						loading={plansLoading}
						pagination={false}
					/>
				</Flex>
			</Card>
			{/*<Card title='Useful resources'>*/}
			{/*	<Flex gap='middle'>*/}
			{/*		<TipsCard title='For next improvement' animationIndex={0}>*/}
			{/*			Developing generation-flow is iterative process. While waiting for the evaluation results, reading blog posts might be helpful.*/}
			{/*			<Button type='primary' onClick={() => window.open('https://google.com', '_blank')}>See blog posts</Button>*/}
			{/*		</TipsCard>*/}
			{/*	</Flex>*/}
			{/*</Card>*/}
		</Flex>
	);
};

const Card = ({ title, animationIndex, children }) => {
	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex vertical gap='small' className='card-recommendations'>
				{title && <span style={{ fontSize: 24, fontWeight: 600 }}>{title}</span>}
				{children}
			</Flex>
		</MotionDiv>
	);
};

export default ReportList;
