import { useContext } from 'react';
import { Flex } from 'antd';
import GlobalContext from '../../../../../../../context/GlobalContext';

const FloatingControlPanel = ({ height, children }) => {
	const { collapsed } = useContext(GlobalContext);

	return (
		<div
			className='floating-control-panel'
			style={{
				width: `calc(100vw - ${collapsed ? '50' : '200'}px - 275px - 10px)`,
				transition: 'width 0.25s'
			}}
		>
			<Flex align='center' style={{ height }}>
				{children}
			</Flex>
		</div>
	);
};

export default FloatingControlPanel;
