import yaml from 'js-yaml';

const convertGenflowJsonToYaml = (jsonData) => {
	if (!jsonData) {
		return null;
	}

	const appConfig = {
		name: jsonData.name || '',
		genflow_id: jsonData.genflow_id || '',
		genflow_plan_id: jsonData.genflow_plan_id || '',
		input_schema: [],
		steps: [],
		output_schema: []
	};

	// Input schema processing
	jsonData.input_schema?.forEach(inputItem => {
		const inputSchemaEntry = {
			key: inputItem.key,
			type: inputItem.type,
			description: inputItem.description,
			default: inputItem.default,
			optional: !!inputItem.optional,
			validation: inputItem.validation
		};
		appConfig.input_schema.push(inputSchemaEntry);
	});

	// Steps processing
	jsonData.steps?.forEach(step => {
		const stepEntry = {
			name: step.name,
			id: step.id,
			type: step.type,
			model: step.model,
			config: step.config,
			prompt_text: step.prompt_text,
			output: step.output
		};
		appConfig.steps.push(stepEntry);
	});

	// Output schema processing
	jsonData.output_schema?.forEach(outputItem => {
		const outputSchemaEntry = {
			key: outputItem.key,
			variable: outputItem.variable
		};
		appConfig.output_schema.push(outputSchemaEntry);
	});

	// Convert the app configuration object to YAML
	return yaml.dump(appConfig, { allowUnicode: true, sortKeys: false, noArrayIndent: true });
};

export default convertGenflowJsonToYaml;
