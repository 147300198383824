import makeRequestWithFreshJwt from './makeRequestWithFreshJwt';

const HOSTNAME = 'https://backend.lang.teammate.as';
// const HOSTNAME = 'http://localhost:8080';

const createNewUser = async (accessToken, payload) => {
	try {
		await makeRequestWithFreshJwt(
			`${HOSTNAME}/users`,
			'POST',
			payload,
			accessToken
		);

		return true;
	} catch (error) {
		return false;
	}
};

export default createNewUser;
