import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Empty, Flex, Input, Skeleton, Tag, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { MdOutlineRefresh } from 'react-icons/md';
import { useAuthRequest } from '../../../../../../../../hooks';
import convertGenflowJsonToYaml from '../../../../../../../../utils/convertGenflowJsonToYaml';
import jsonExtractor from '../../../../../../../../utils/jsonExtractor';
import GlobalContext from '../../../../../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../../../../../context/genflowDetail/Context';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import CandidateCard from './CandidateCard';
import { AnimatePresence, motion } from 'framer-motion';
import { BsStars } from 'react-icons/bs';
import { Loading } from '../../../../../../../../components/atoms';

const GenerationModal = ({ onFinish = () => {} }) => {
	const isInitialLoad = useRef(true);
	const { pushMessage } = useContext(GlobalContext);
	const { genflowId } = useContext(GenflowDetailContext);
	const {
		optimizationId,
		targetPlanId,
		targetCompiledPlanData,
		metricGenerationWatchingList,
		setMetricGenerationWatchingList,
		isBeforeEvaluationInvoked
	} = useContext(GenflowOptimizeContext);
	const genflowYaml = convertGenflowJsonToYaml(targetCompiledPlanData?.json);

	const [additionalDirections, setAdditionalDirections] = useState('');
	const [submitLoading, setSubmitLoading] = useState(false);
	const [candidatesLoading, setCandidatesLoading] = useState(true);
	const [candidatesInDraft, setCandidatesInDraft] = useState([]);
	const [candidatesForGeneration, setCandidatesForGeneration] = useState([]);

	const { makeRequest } = useAuthRequest();
	// eslint-disable-next-line no-undef
	const generationEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/metrics-generate`;

	const handleSubmit = async () => {
		setSubmitLoading(true);

		if (!targetPlanId) {
			pushMessage('Target Plan must be selected first', 'error');
			setSubmitLoading(false);
			return;
		} else if (candidatesForGeneration?.length === 0) {
			pushMessage('You have to select at least one candidates', 'error');
			setSubmitLoading(false);
			return;
		}

		const payload = {
			candidates: candidatesForGeneration
		};

		const response = await makeRequest(generationEndpoint, 'POST', payload);
		if (response?.message === 'OK') {
			const invocationId = response.result?.invocation_id;
			if (invocationId) {
				const payload = {
					invocationId,
					requestTimestamp: response.result?.timestamp,
					requestCount: candidatesForGeneration?.length
				};
				setMetricGenerationWatchingList([ ...metricGenerationWatchingList, payload ]);
			}

			onFinish();
		} else {
			pushMessage('Failed to start generation. Please try again later.', 'error');
		}
		setSubmitLoading(false);
	};

	// eslint-disable-next-line no-undef
	const testGenflowEndpoint = `${process.env.REACT_APP_LANG_API}/run/9PeUJYi_QSC2c9H6C7cHQg/hjTxnUyMQYK1F4feYCnPfw`;
	const refreshCandidates = async () => {
		setCandidatesLoading(true);

		const payload = {
			input: {
				genflowYaml: genflowYaml,
				customInstructions: additionalDirections,
			}
		};

		const response = await makeRequest(testGenflowEndpoint, 'POST', payload);
		if (response?.success) {
			const textResults = response?.data?.output?.results;
			const results = jsonExtractor(textResults);
			if (results.metrics?.length > 0) {
				setCandidatesInDraft(results.metrics);
			}
		}

		setCandidatesLoading(false);
	};

	useEffect(() => {
		if (isInitialLoad.current && genflowYaml) {
			refreshCandidates();
			isInitialLoad.current = false;
		}
	}, []);

	const handleAddToGeneration = (shouldAdd, targetCandidate) => {
		if (shouldAdd === true) {
			setCandidatesForGeneration([...candidatesForGeneration, targetCandidate]);
		} else if (shouldAdd === false) {
			const newCandidatesForGeneration = candidatesForGeneration?.filter((candidate) => {
				return candidate.title !== targetCandidate.title && candidate.instructionText !== targetCandidate.instructionText;
			});
			setCandidatesForGeneration(newCandidatesForGeneration);
		}
	};

	return (
		<Flex vertical gap='middle'>
            This is what AI generates for suggestion based on your target plan. Choose some so our AI can elaborate on.
			{candidatesLoading ? <Loading note='Hang tight! AI is generating suggestions...' /> : (candidatesInDraft && candidatesInDraft?.length > 0) ? (
				<Flex wrap gap='small'>
					{candidatesInDraft?.map((candidate, i) => {
						return (
							<CandidateCard
								key={i}
								animationIndex={i}
								selected={candidatesForGeneration?.some(item => item.title === candidate.title && item.instructionText === candidate.instructionText)}
								onSelect={(v) => handleAddToGeneration(v, candidate)}
								{...candidate}
							/>
						);
					})}
				</Flex>
			) : <Empty />}
            Write any additional contexts or directions for the evaluation if any, so the generated metrics would have more focus.
			<Flex gap='middle' align='center'>
				<Input.TextArea
					variant='filled'
					placeholder='e.g. "Be specific at the aspect of XXX."'
					value={additionalDirections}
					onChange={(v) => setAdditionalDirections(v.target.value)}
				/>
				<Button
					size='large'
					onClick={() => refreshCandidates()}
					icon={<Icon component={MdOutlineRefresh} />}
					disabled={isInitialLoad.current}
					loading={!isInitialLoad.current && candidatesLoading}
				>
                    Refresh candidates
				</Button>
			</Flex>
			{candidatesForGeneration?.length > 0 && (
				<Flex gap='small' wrap>
					<AnimatePresence>
						{candidatesForGeneration?.map((candidate, i) => (
							<motion.div
								key={`${candidate.title}/${candidate.instructionText}`}
								layout
								initial={{ opacity: 0, y: 10 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: 10 }}
								transition={{ duration: 0.2 }}
							>
								<Tooltip
									title={candidate.instructionText}
									color='var(--tooltip-background)'
								>
									<Tag
										color='blue'
										bordered={false}
										style={{ margin: 0 }}
										closable
										onClose={() => handleAddToGeneration(false, candidate)}
									>
										{candidate.title}
									</Tag>
								</Tooltip>
							</motion.div>
						))}
					</AnimatePresence>
				</Flex>
			)}
			<Button
				block
				type='primary'
				size='large'
				loading={submitLoading}
				disabled={candidatesForGeneration?.length === 0 || !isBeforeEvaluationInvoked}
				onClick={handleSubmit}
				icon={<Icon component={BsStars} />}
			>
                Start generating {candidatesForGeneration?.length} custom metrics
			</Button>
		</Flex>
	);
};

export default GenerationModal;
