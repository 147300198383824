import { useContext, useState } from 'react';
import { Flex, Select, Typography, Divider, Button, Form, Input } from 'antd';
import Icon, { UpOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuthRequest } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import AddModal from '../AddModal';
import GlobalContext from '../../../../../../context/GlobalContext';
import { MdOutlineWorkspacePremium } from 'react-icons/md';
import './index.css';

const WorkspaceSelect = () => {
	const { t } = useTranslation();
	const { user, userRefresh } = useContext(GlobalContext);
	const { workspaces = [], primaryWorkspace = '' } = user;

	const [selectOpen, setSelectOpen] = useState(false);
	const [open, setOpen] = useState(false);

	const { makeRequest } = useAuthRequest();
	const handleWorkspaceChange = async (value) => {
		userRefresh();
		await makeRequest('https://backend.lang.teammate.as/users', 'PUT', { primaryWorkspace: value }).then(() => {
			window.location.href = '/';
		});
	};

	const createNewWorkspace = async (data) => {
		const endpoint = 'https://backend.lang.teammate.as/workspaces';
		const response = await makeRequest(endpoint, 'POST', data);
		if (response?.success) {
			await handleWorkspaceChange(response._id);
			setOpen(false);
		}
	};

	return (
		<>
			<Select
				className='workspace-select'
				size='large'
				variant='filled'
				options={workspaces?.map(({ _id, name }) => ({ label: <Flex gap={4} align='center'><Icon style={{ fontSize: 20 }} component={MdOutlineWorkspacePremium} />{name}</Flex>, textLabel: name, value: _id }))}
				optionRender={(option) => option.data.textLabel}
				onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
				onSelect={handleWorkspaceChange}
				value={primaryWorkspace}
				placeholder={t('select-workspace')}
				suffixIcon={selectOpen ? <UpOutlined /> : <DownOutlined />}
				popupMatchSelectWidth={false}
				placement='topLeft'
				dropdownRender={(menu) => (
					<>
						{menu}
						<Divider
							style={{
								margin: '8px 0',
							}}
						/>
						<Button type="text" icon={<PlusOutlined />} block onClick={() => setOpen(true)}>
							{t('button.create-new-workspace')}
						</Button>
					</>
				)}
			/>
			<AddModal
				open={open}
				title={t('button.create-new-workspace')}
				onSubmit={createNewWorkspace}
				onCancel={() => setOpen(false)}
				okText={t('button.create')}
			>
				<Flex vertical gap='small'>
					<Typography.Text type='secondary'>
						{t('create-new-workspace-modal-description')}
					</Typography.Text>
					<Form.Item
						label={t('form.new-workspace-name')}
						name="name"
						rules={[
							{
								required: true,
								message: t('form.rule.required-workspace-name'),
							},
							{
								max: 30,
								message: t('form.rule.max-workspace-name')
							}
						]}
					>
						<Input
							allowClear
							style={{
								width: '100%',
							}}
							placeholder={t('workspace-name')}
						/>
					</Form.Item>
				</Flex>
			</AddModal>
		</>
	);
};

export default WorkspaceSelect;
