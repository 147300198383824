import { useState, useEffect } from 'react';
import { Spin, Flex, Form, Input } from 'antd';
import { verifyPasswordResetCode } from '@firebase/auth';
import { firebaseAuth } from '../../../../config/firebase';
import { LockOutlined } from '@ant-design/icons';

const RequestPasswordResetForm = () => {
	const [code, setCode] = useState('');
	const [username, setUsername] = useState(null);
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const oobCode = queryParams.get('oobCode') || '';
		setCode(oobCode);

		if (!oobCode) return;

		verifyPasswordResetCode(firebaseAuth, oobCode).then((email) => {
			setUsername(email);
		});
	}, []);

	return (
		<Flex gap='middle' vertical>
			{username ? (
				<>
					<>Reset password for {username}</>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: 'Please enter password',
							},
						]}
					>
						<Input.Password
							size='large'
							placeholder='Password'
							prefix={<LockOutlined className={'prefixIcon'} />}
						/>
					</Form.Item>
					<Form.Item
						hidden
						name="code"
						initialValue={code}
					/>
				</>
			) : (
				<Spin spinning={true} />
			)}
		</Flex>
	);
};

export default RequestPasswordResetForm;
