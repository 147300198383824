import { useContext, useEffect, useState } from 'react';
import { Spin, Form, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormWithRequest } from '../../molecules';
import { useAuthRequest } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import countries from '../../../models/static/countries';
import companySize from '../../../models/static/companySize.json';
import department from '../../../models/static/department.json';
import sourceTypes from '../../../models/static/sourceTypes.json';
import features from '../../../models/static/features.json';
import i18nCountries from 'i18n-iso-countries';
import GlobalContext from '../../../context/GlobalContext';

const locales = ['en', 'ja', 'zh'];
locales.forEach(async locale => {
	const module = await import(`i18n-iso-countries/langs/${locale}.json`);
	i18nCountries.registerLocale(module.default);
});

const universalFormItemProps = {
	size: 'large',
	variant: 'filled'
};

const UserProfileForm = (props) => {
	const { t, i18n } = useTranslation();
	const { setWorkspaceId, isDemo, user: userData, userLoading, userRefresh } = useContext(GlobalContext);

	const { submitButtonText = t('button.save'), isOnboard = false } = props;
	
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();

	const language = i18n.language || navigator.language || navigator.userLanguage;
	const expectCountryOrRegion = (lng) => {
		switch (lng) {
		case 'ja':
			return 'JP';
		case 'zh':
			return 'CN';
		case 'ko':
			return 'KR';
		case 'en':
			return 'US';
		default:
			return 'US';
		}
	};

	const countriesOptions = countries?.map(({ value }) => {
		if (['HK', 'MO', 'TW'].includes(value)) {
			return { value, label: t(`country.china-${value.toLowerCase()}`) };
		}
		return { value, label: i18nCountries.getName(value, i18n.language) };
	}).sort((a, b) => a.label.localeCompare(b.label));
	
	const [formValues, setFormValues] = useState(userData);

	useEffect(() => {
		if (userData) {
			setFormValues(userData);
		}
	}, [userData]);

	const [form] = Form.useForm();
	const formConfig = {
		form,
		name: 'onboard',
		labelCol: {
			span: 6,
		},
		wrapperCol: {
			span: 18,
		},
		labelWrap: true,
		colon: false,
		layout: 'horizontal',
		preserve: false,
		initialValues: {
			...userData,
			name: userData?.name,
			avatarUrl: userData?.providerData?.[0].photoURL,
			countryOrRegion: expectCountryOrRegion(language),
			acceptNewsletter: true,
			hasOnboarded: true,
		},
		onValuesChange: (changedFields, allFields) => {
			setFormValues(allFields);
		}
	};

	const formItems = [
		{
			name: 'avatarUrl',
			item: {
				type: 'hidden'
			}
		},
		{
			label: t('form.label.user-name'),
			name: 'name',
			rules: [
				{
					required: true,
					message: t('form.rules.user-name.required'),
				}
			],
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: t('form.placeholder.user-name'),
			}
		},
		{
			label: t('form.label.country-or-region'),
			name: 'countryOrRegion',
			rules: [
				{
					required: true,
					message: t('form.rules.country-or-region.required'),
				}
			],
			item: {
				...universalFormItemProps,
				type: 'select',
				placeholder: t('form.placeholder.country-or-region'),
				options: countriesOptions,
				showSearch: true,
				optionFilterProp: 'label',
			},
		},
		{
			label: t('form.label.org-type'),
			name: 'type',
			rules: [
				{
					required: true,
					message: t('form.rules.org-type.required'),
				}
			],
			item: {
				...universalFormItemProps,
				type: 'radio-group',
				placeholder: t('form.placeholder.org-type'),
				options: [
					{
						label: t('form.options.org-type.individual'),
						value: 'individual'
					},
					{
						label: t('form.options.org-type.company'),
						value: 'company'
					}
				],
				optionType: 'button'
			}
		},
		{
			label: t('form.label.company-name'),
			name: 'companyName',
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: t('form.placeholder.company-name')
			},
			rules: [
				{
					required: formValues?.type === 'company',
					message: t('form.rules.company-name.required'),
				}
			],
			condition: formValues?.type === 'company'
		},
		{
			label: t('form.label.company-size'),
			name: 'companySize',
			item: {
				...universalFormItemProps,
				type: 'select',
				placeholder: t('form.placeholder.company-size'),
				options: companySize?.map(size => ({
					...size,
					label: size.label?.replace('{{employees}}', t('form.options.replacement.employees')),
				})),
			},
			rules: [
				{
					required: formValues?.type === 'company',
					message: t('form.rules.company-size.required'),
				}
			],
			condition: formValues?.type === 'company'
		},
		{
			label: t('form.label.company-website'),
			name: 'companyWebsite',
			item: {
				...universalFormItemProps,
				type: 'input',
				placeholder: 'https://...'
			},
			condition: formValues?.type === 'company',
		},
		{
			label: t('form.label.accept-newsletter'),
			name: 'acceptNewsletter',
			item: {
				...universalFormItemProps,
				type: 'switch',
				defaultChecked: true,
			},
		},
		...(isOnboard ? [
			{
				label: t('form.label.department'),
				name: 'department',
				item: {
					...universalFormItemProps,
					type: 'select',
					placeholder: t('form.placeholder.department'),
					options: department?.map(dep => ({
						...dep,
						label: t('form.options.department.' + dep.value),
					})),
					popupMatchSelectWidth: false,
					listHeight: 512
				}
			},
			{
				label: t('form.label.source-type'),
				name: 'sourceType',
				item: {
					...universalFormItemProps,
					type: 'multiple-checkbox',
					placeholder: t('form.placeholder.source-type'),
					options: sourceTypes?.map(source => ({
						...source,
						label: t('form.options.source-type.' + source.value),
					})),
				}
			},
			{
				label: t('form.label.features-in-interest'),
				name: 'featuresInInterest',
				item: {
					...universalFormItemProps,
					type: 'select',
					placeholder: t('form.placeholder.features-in-interest'),
					options: features?.map(feature => ({
						...feature,
						label: t('form.options.features-in-interest.' + feature.value),
					})),
					mode: 'multiple',
					popupMatchSelectWidth: false,
					listHeight: 512
				},
			},
			{
				label: t('form.label.engaging-project'),
				name: 'engagingProject',
				item: {
					...universalFormItemProps,
					type: 'input-textarea',
					placeholder: t('form.placeholder.engaging-project')
				},
			},
			{
				name: 'hasOnboarded',
				item: {
					type: 'hidden'
				}
			},
		] : []),
	];

	const { makeRequest } = useAuthRequest();
	const createNewWorkspace = async (data) => {
		const endpoint = 'https://backend.lang.teammate.as/workspaces';
		return await makeRequest(endpoint, 'POST', data);
	};

	return (
		<Spin spinning={userLoading}>
			{contextHolder}
			{(!userLoading && userData) && (
				<FormWithRequest
					formConfig={formConfig}
					formItems={formItems}
					submitButtonText={submitButtonText}
					submitButtonConfig={{
						type: 'primary',
						block: true,
						disabled: isDemo
					}}
					requestEndpoint='https://backend.lang.teammate.as/users'
					requestMethod='PUT'
					onSuccess={async (res) => {
						if (isOnboard && searchParams.has('invitationCode')) {
							messageApi.open({ type: 'success', content: t('message.success.profile-first-record') });
							navigate(`/auth/join-workspace?invitationCode=${searchParams.get('invitationCode')}`);
						} else if (isOnboard) {
							const { formValues } = res;
							const response = await createNewWorkspace({
								name: `${formValues?.name || ''}${t('static.workspace-name')}`
							});
							if (response?.success) {
								setWorkspaceId(response?._id);
								messageApi.open({ type: 'success', content: t('message.success.profile-first-record') });
								navigate('/');
							}
						} else {
							messageApi.open({ type: 'success', content: t('message.success.profile-updated') });
						}
					}}
				/>
			)}
		</Spin>
	);
};

export default UserProfileForm;
