import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import GlobalContext from '../../../context/GlobalContext';
import * as Ably from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react';
import { Loading } from '../../atoms';

const ProtectedRoute = ({ children }) => {
	const isClientLoaded = useRef(false);
	const { userLoading, workspaceLoading, uniqueClientId } = useContext(GlobalContext);

	const [ablyClient, setAblyClient] = useState(null);
	useEffect(() => {
		if (uniqueClientId) {
			const client = new Ably.Realtime({
				// eslint-disable-next-line no-undef
				key: 'J11xTw.B3Ynvw:_2RQ_D_YGyPGnN5NUi-mfp-XcQNyHaPisC_Fq9cYbxE',
				clientId: uniqueClientId,
			});
			setAblyClient(client);
			isClientLoaded.current = true;

			return () => client?.close();  // Cleanup Ably client on component unmount
		}
		if (isClientLoaded.current && !uniqueClientId) {
			ablyClient?.close();
		}
	}, [uniqueClientId]);

	if (userLoading || workspaceLoading) {
		return <LoadingComponent />;
	}

	return ablyClient ? (
		<AblyProvider client={ablyClient}>
			<ChannelProvider channelName={uniqueClientId}>
				{children}
			</ChannelProvider>
		</AblyProvider>
	) : children;
};

const LoadingComponent = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100vw',
				height: '100vh'
			}}
		>
			<Loading />
		</div>
	);
};

export default ProtectedRoute;
