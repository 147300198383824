import { Form, Input } from 'antd';
import { AddModal } from '../../../../../components/molecules';

const AddApiKeyForm = ({ open, onSubmit, onCancel, loading }) => {
	const handleOk = async (values) => {
		await onSubmit(values);
	};

	const handleCancel = () => {
		onCancel();
	};

	return (
		<AddModal
			open={open}
			title='Create a new API Key for Teammately Endpoints'
			onSubmit={handleOk}
			onCancel={handleCancel}
			okText='Create API Key'
			loading={loading}
		>
			<Form.Item
				label='Key name (optional)'
				name="name"
			>
				<Input variant='filled' size='large' placeholder='My Test Key' />
			</Form.Item>
		</AddModal>
	);
};

export default AddApiKeyForm;
