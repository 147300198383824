import { createContext } from 'react';

const Context = createContext({
	genflowList: [],
	genflowLoading: true,
	genflowRefresh: () => {},
	createGenflow: () => {},
	workspaceData: {},
	workspaceLoading: true,
	workspaceRefresh: () => {},
	handleWorkspaceRequest: () => {},
	handleWorkspaceMemberRequest: () => {},
	handleWorkspaceInvitationRequest: () => {},
	getStartedTasksList: [],
	getStartedTasksLoading: true,
	getStartedTasksRefresh: () => {},
	markGetStartedTaskDone: () => {}
});

export default Context;
