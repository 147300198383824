import Presentation from './Presentation';
import { useAuthData, useAuthRequest } from '../../../../hooks';
import { useContext, useState } from 'react';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';

const Container = (props) => {
	const [requestLoading, setRequestLoading] = useState(false);
	const { genflowId } = useContext(GenflowDetailContext);
	const endpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations?limit=100`;
	const { data, loading, refresh } = useAuthData(endpoint);

	const { makeRequest } = useAuthRequest();
	const createGenflow = async (payload) => {
		setRequestLoading(true);
		const response = await makeRequest(endpoint, 'POST', payload);

		await refresh();
		setRequestLoading(false);

		return response;
	};

	return <Presentation
		{...props}
		data={{
			genflows: data?.data
		}}
		loading={{
			genflows: loading,
			requests: requestLoading,
		}}
		refresh={{
			genflows: refresh
		}}
		create={{
			genflow: createGenflow
		}}
	/>;
};

export default Container;
