import { Button, Flex } from 'antd';
import { Apologies } from '../../components/atoms';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 'calc(100vh - 50px)',
			textAlign: 'center'
		}}>
			<Flex vertical gap='large'>
				<div style={{ width: 200, margin: 'auto' }}>
					<Apologies />
				</div>
				<Flex vertical gap='middle'>
					<span style={{ fontSize: 30 }}>404</span>
					<span style={{ fontSize: 16, color: '#999999' }}>Sorry, the page you visited does not exist.</span>
				</Flex>
				<div>
					<Button type='primary' size='large' icon={<HomeOutlined />} onClick={() => navigate('/')}>Back Home</Button>
				</div>
			</Flex>
		</div>
	);
};

export default NotFound;
