import { Pie as AntPie } from '@ant-design/plots';
import { Apologies } from '../../../atoms';

const adjustDataOrder = (map, arr) => {
	const keys = Object.keys(map)?.map(Number)?.sort((a, b) => b - a);
	const sortedMap = Object.fromEntries(keys.map(k => [k, map[k]]));
	const sortedData = [...arr]?.sort((a, b) => b.dimension - a.dimension);
	return { sortedMap, sortedData };
};

const colors = [
	'#81D6D8', '#FFA093', '#FFC93D', '#C0DEA2', '#B5ACFF',
	'#FF914D', '#94BDFF', '#FF94BD', '#98C683', '#D09AE8'
];

const Pie = ({
	data = [],
	keyMap,
	metric,
	height,
	showDonutContent = false,
	donutTitle,
	donutContent = '',
}) => {
	// if data is reversed, adjust the order in a way that the highest value is at the top
	const { sortedMap, sortedData } = adjustDataOrder(keyMap, data);
	keyMap = sortedMap;
	data = sortedData;

	// get the max key of keyMap
	const maxKey = Math.max(...Object.keys(sortedMap).map(Number));

	// assign colors according to max key
	const assignColors = (dimension) => {
		if (dimension == null || isNaN(dimension) || dimension > maxKey || dimension < 0) {
			return colors[colors.length - 1];
		}
		const index = maxKey - dimension;
		return colors[index] || colors[colors.length - 1];
	};

	const config = {
		data: sortedData,
		angleField: 'value',
		colorField: 'dimension',
		height,
		radius: 1.0,
		innerRadius: showDonutContent ? 0.3 : 0,
		label: {
			type: showDonutContent ? 'inner' : 'inner',
			offset: showDonutContent ? '-50%' : '-40%',
			content: ({ percent, dimension, value }) => {
				return `${keyMap[dimension] || 'Unknown'}\n${(percent * 100).toFixed(0)}%\n(${value.toLocaleString()})`;
			},
			style: {
				textAlign: 'center',
				fontSize: 14,
				fontWeight: 'bold',
			},
			autoRotate: false,
		},
		interactions: [
			{
				type: 'element-selected',
			},
		],
		legend: false,
		color: ({ dimension }) => assignColors(dimension),
		statistic: showDonutContent ? {
			title: donutTitle ? {
				style: {
					whiteSpace: 'pre-wrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: 12,
					paddingBottom: '5px',
				},
				content: donutTitle,
			} : false,
			content: {
				style: {
					whiteSpace: 'pre-wrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: 20,
				},
				content: donutContent,
			},
		} : false, // if showDonutContent is false, disable statistic
		tooltip: {
			formatter: (datum) => {
				return { name: keyMap[datum.dimension] || 'Unknown', value: datum.value.toLocaleString() };
			},
			color: ({ dimension }) => assignColors(dimension),
		},
	};

	return data.length > 0 ? (
		<AntPie {...config} />
	) : (
		<div style={{
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
			<Apologies note='No data was found...'/>
		</div>
	);
};


// // // test data
// // binary case
// let keyMap = {
// 	1: 'YES',
// 	0: 'NO',
// };

// let data = [
// 	{ dimension: 1, value: 212 },
// 	{ dimension: 0, value: 800 },
// ];

// // reversed case
// let keyMap = {
// 	0: 'NO',
// 	1: 'YES',
// };

// let data = [
// 	{ dimension: 0, value: 800 },
// 	{ dimension: 1, value: 212 },
// ];

// // five-point scale case
// let keyMap = {
// 	5: '5',
// 	4: '4',
// 	3: '3',
// 	2: '2',
// 	1: '1',
// };

// let data = [
// 	{ dimension: 5, value: 500 },
// 	{ dimension: 4, value: 400 },
// 	{ dimension: 3, value: 300 },
// 	{ dimension: 2, value: 212 },
// 	{ dimension: 1, value: 800 },
// ];

// reversed case
// let keyMap = {
// 	1: '1',
// 	2: '2',
// 	3: '3',
// 	4: '4',
// 	5: '5',
// };

// let data = [
// 	{ dimension: 1, value: 800 },
// 	{ dimension: 2, value: 212 },
// 	{ dimension: 3, value: 300 },
// 	{ dimension: 4, value: 400 },
// 	{ dimension: 5, value: 500 },
// ];

export default Pie;
