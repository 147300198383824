import { useContext, useEffect, useRef, useState } from 'react';
import { Skeleton, Flex, Select, Button, Input, Tag } from 'antd';
import Box from './components/Box';
import GenflowDetailContext from '../../../../../../context/genflowDetail/Context';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import './index.css';

const Initialize = () => {
	return (
		<Flex vertical gap='large'>
			{/*<TipsCard title='Optimize Overview'>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		The Optimize phase is crucial for refining and improving your generation flow engineering workflow. It allows you to evaluate your initial generation flow across multiple datasets, considering factors such as accuracy, speed, and cost.*/}
			{/*	</Typography.Paragraph>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		While the Optimize process consists of six detailed steps, you can think of it in three main phases:*/}
			{/*	</Typography.Paragraph>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		1. <strong>Setup</strong>: Select a plan and create the necessary datasets and evaluation metrics.<br />*/}
			{/*		2. <strong>Evaluation</strong>: Evaluate your selected plan, review AI-generated suggestions and re-run AI-suggested alternative plans.<br />*/}
			{/*		3. <strong>Comparison</strong>: Compare your original plan with alternative plans to find the best one.*/}
			{/*	</Typography.Paragraph>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		Here are the actual six steps in the Optimize process:*/}
			{/*	</Typography.Paragraph>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		1. <strong>Initialize</strong>: Select a single Plan that you created in the Develop phase. This selected Plan will be the target for optimization. Confirm your selection to proceed with the optimization process.<br />*/}
			{/*		2. <strong>Create Input Dataset</strong>: Generate a dataset for the Plan you specified in the Initialize phase. This dataset will be used to evaluate and optimize your generation flow.<br />*/}
			{/*		3. <strong>Create Eval Metrics</strong>: Define the metrics used to evaluate and optimize your generation flow. These metrics will help you assess the quality and effectiveness of your AI-generated outputs.<br />*/}
			{/*		4. <strong>Evaluate Target Plan</strong>: Run the evaluation on your target plan using the metrics you set up and review the results.<br />*/}
			{/*		5. <strong>Review Alternative Plans</strong>: Based on the evaluation results from step 4, AI will suggest alternative plans. Select these plans for further evaluation.<br />*/}
			{/*		6. <strong>Evaluate Alternative Plans</strong>: Run the evaluation on the alternative plans suggested by AI. Compare the performance of your original Plan with the alternative Plans you selected.*/}
			{/*	</Typography.Paragraph>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		Optimizing process is iterative, and it&apos;s okay to revisit and refine your approach as needed.*/}
			{/*	</Typography.Paragraph>*/}
			{/*</TipsCard>*/}
			{/*<TipsCard title='Important Notes in This Step'>*/}
			{/*	<Typography.Paragraph>*/}
			{/*		<ul>*/}
			{/*			<li>After selecting a Plan, you must click the &quot;Confirm&quot; button to proceed.</li>*/}
			{/*			<li><strong>Once confirmed, the selection becomes fixed and cannot be undone.</strong></li>*/}
			{/*			<li>If you accidentally select the wrong plan, you&apos;ll need to return to the Optimize List and create a new optimization process.</li>*/}
			{/*		</ul>*/}
			{/*	</Typography.Paragraph>*/}
			{/*</TipsCard>*/}
			<Box animationIndex={0}>
				<Summary />
			</Box>
			<Box animationIndex={1}>
				<TargetPlan />
			</Box>
		</Flex>
	);
};

const Summary = (props) => {
	const { data, operateOptimize } = useContext(GenflowOptimizeContext);
	const debounceTimer = useRef();

	const debouncedOnChange = useRef((key, value, type) => {
		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}
		debounceTimer.current = setTimeout(() => {
			try {
				operateOptimize({ key, value, ...(type ? { type } : {}) }, 'PATCH');
			} catch (error) {
				console.error('Error updating:', error);
			}
		}, 750);
	}).current;

	const handleChange = (key, value, type) => {
		debouncedOnChange(key, value, type);
	};

	return (
		<Flex vertical gap='small'>
			<Flex gap='small' align='flex-end'>
				<span style={{ fontWeight: 600, fontSize: 20 }}>Summary</span>
				<span style={{ color: '#666666' }}>Label this optimize title and optionally write description for your reference</span>
			</Flex>
			<ItemContainer title='Name' type='input' defaultValue={data?.name} valueOnNull='Untitled optimization' onChange={(v) => handleChange('name', v)} />
			<ItemContainer title='Description' type='input-area' defaultValue={data?.metadata?.description} valueOnNull='Write descriptions' onChange={(v) => handleChange('metadata.description', v)} />
		</Flex>
	);
};

const ItemContainer = ({ title, type, defaultValue, valueOnNull, onChange = () => {} }) => {
	const isInitialLoad = useRef(true);
	const [localValue, setLocalValue] = useState();

	useEffect(() => {
		if (isInitialLoad.current && defaultValue && !localValue) {
			setLocalValue(defaultValue);
			isInitialLoad.current = false;
		}
	}, [defaultValue]);

	const handleEdit = (v) => {
		setLocalValue(v);
		onChange(v);
	};

	return (
		<Flex vertical gap={3}>
			<span className='summary-title'>{title}</span>
			{type === 'input' && (
				<Input
					size='large'
					variant='filled'
					value={localValue}
					placeholder={valueOnNull}
					onChange={(e) => handleEdit(e.target.value)}
				/>
			)}
			{type === 'input-area' && (
				<Input.TextArea
					size='large'
					variant='filled'
					rows={3}
					value={localValue}
					placeholder={valueOnNull}
					onChange={(e) => handleEdit(e.target.value)}
				/>
			)}
		</Flex>
	);
};

const TargetPlan = () => {
	const { plansList, plansLoading } = useContext(GenflowDetailContext);
	const { data, operateOptimize, updateStatus } = useContext(GenflowOptimizeContext);

	const [loading, setLoading] = useState(false);
	const [hasFixedId, setHasFixedId] = useState(false);

	const options = plansList?.map((item) => ({
		label: item.name || 'No title' + (item.genflow_plan_id && ` (${item.genflow_plan_id})`),
		value: item.genflow_plan_id
	}));

	const [selectedPlanId, setSelectedPlanId] = useState();
	useEffect(() => {
		if (data?.metadata?.targetPlanId) {
			setHasFixedId(true);
			setSelectedPlanId(data?.metadata?.targetPlanId);
		}
	}, [data]);

	const handleConfirm = async () => {
		setLoading(true);

		const payload = {
			key: 'metadata.targetPlanId',
			value: selectedPlanId,
		};
		await operateOptimize(payload, 'PATCH');

		await updateStatus('CONFIGURATION');

		setLoading(false);
	};

	return (
		<Flex vertical gap='small'>
			<Flex gap='small' align='flex-end'>
				{!selectedPlanId && (
					<Tag bordered={false} color='magenta-inverse'>ACTION REQUIRED</Tag>
				)}
				<span style={{ fontWeight: 600, fontSize: 20 }}>Select a plan</span>
				<span style={{ color: '#666666' }}>The selected plan here would be the base for optimization!</span>
			</Flex>
			<Flex vertical gap='small'>
				{plansLoading
					? (
						<Skeleton.Input
							active
							size='large'
						/>
					) : (
						<Select
							variant='filled'
							size='large'
							style={{ width: '100%' }}
							placeholder='Choose a plan for optimization'
							options={options}
							value={selectedPlanId}
							onChange={(v) => setSelectedPlanId(v)}
							disabled={hasFixedId}
						/>
					)}
				<Button
					type='primary'
					size='large'
					onClick={handleConfirm}
					loading={loading}
					disabled={!selectedPlanId || data?.metadata?.targetPlanId}
				>
					{hasFixedId ? 'Fixed' : 'Confirm to get started'}
				</Button>
			</Flex>
		</Flex>
	);
};

export default Initialize;
