import { useEffect, useRef, useState } from 'react';
import { MotionDiv } from '../../../../../../../../components/atoms';
import { Flex, Tag } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import './index.css';

const CandidateCard = (props) => {
	const { animationIndex, title, instructionText, selected, onSelect = () => {}, requiredField } = props;
	const [isSelected, setIsSelected] = useState();
	const requiredFieldMap = {
		'output': ['Output'],
		'input-output': ['Input', 'Output'],
		'input-knowledge-output': ['Input', 'Knowledge', 'Output']
	};

	const handleSelect = (v) => {
		onSelect(v);
		setIsSelected(v);
	};

	useEffect(() => {
		setIsSelected(selected);
	}, [selected]);

	return (
		<MotionDiv animationIndex={animationIndex}>
			<Flex
				className={`candidate-card ${isSelected ? 'candidate-card-selected' : ''}`}
				gap='middle'
				onClick={() => handleSelect(!isSelected)}
			>
				<CheckCircleFilled style={{ color: isSelected ? 'var(--primary-color)' : '#666666', fontSize: 24 }} />
				<Flex vertical gap='small'>
					<span className='candidate-card-title'>{title}</span>
					{(requiredFieldMap[requiredField] && requiredFieldMap[requiredField]?.length > 0) && (
						<Flex wrap>
							{requiredFieldMap[requiredField]?.map(field => (
								<Tag key={field} bordered={false} color='magenta'>{field}</Tag>
							))}
						</Flex>
					)}
					<span className='metric-card-description'>{instructionText}</span>
				</Flex>
			</Flex>
		</MotionDiv>
	);
};

export default CandidateCard;
