import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton = ({ destination }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Button
			size='large'
			type='link'
			icon={<LeftOutlined />}
			onClick={() => navigate(destination)}
		>
			{t('button.back')}
		</Button>
	);
};

export default BackButton;
