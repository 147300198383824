import Presentation from './Presentation';
import { useAuthData, useAuthRequest } from '../../../../hooks';

const PLATFORM_ENDPOINT = 'https://backend.lang.teammate.as/platforms';

const Container = (props) => {
	const { data: platformsData, loading: platformsLoading, refresh: platformsRefresh } = useAuthData(PLATFORM_ENDPOINT);

	const { makeRequest } = useAuthRequest();
	const editActiveStatus = async (_id, isCurrentlyActive) => {
		const payload = {
			isActive: !isCurrentlyActive
		};
		const endpoint = `${PLATFORM_ENDPOINT}/${_id}`;
		const response = await makeRequest(endpoint, 'PUT', payload);
		await platformsRefresh();

		return response;
	};

	const handleDelete = async (id) => {
		const endpoint = `${PLATFORM_ENDPOINT}/${id}`;
		const response = await makeRequest(endpoint, 'DELETE');
		await platformsRefresh();

		return response;
	};

	return <Presentation
		{...props}
		data={{
			platforms: platformsData
		}}
		loading={{
			platforms: platformsLoading
		}}
		refresh={{
			platforms: platformsRefresh
		}}
		editActiveStatus={editActiveStatus}
		handleDelete={handleDelete}
	/>;
};

export default Container;
