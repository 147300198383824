import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyAmzpULv5cUz5aMHigiP_nw2PW4m7q4CNQ',
	authDomain: 'tm-lang.firebaseapp.com',
	projectId: 'tm-lang',
	storageBucket: 'tm-lang.appspot.com',
	messagingSenderId: '180786113968',
	appId: '1:180786113968:web:51c3cd7baf79b47fe0fa75'
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);

export {
	app,
	firebaseAuth
};
