import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ProtectedRoute, DefaultLayout, AuthLayout, GenflowLayout } from './components/layouts';
import GlobalProvider from './context/GlobalProvider';
import GlobalAuthProvider from './context/GlobalAuthProvider';
import GenflowPlanProvider from './context/genflowPlan/Provider';
import GenflowOptimizeProvider from './context/genflowOptimize/Provider';
import * as Screen from './screens';
import './scripts/i18n';
import './App.css';

const App = () => {
	const routes = [
		{
			path: 'auth',
			element: (
				<GlobalProvider>
					<AuthLayout />
				</GlobalProvider>
			),
			children: [
				{
					index: true,
					element: <Screen.Auth />
				},
				{
					path: ':id',
					element: <Screen.Auth />
				},
			]
		},
		{
			path: '*',
			element: (
				<GlobalProvider>
					<ProtectedRoute>
						<GlobalAuthProvider>
							<DefaultLayout />
						</GlobalAuthProvider>
					</ProtectedRoute>
				</GlobalProvider>
			),
			children: [
				{
					index: true,
					element: <Screen.Admin />
				},
				{
					path: 'story',
					element: <Screen.Story />,
				},
				{
					path: 'get-started',
					element: <Screen.Admin />
				},
				{
					path: 'storage',
					element: <Screen.Admin />,
				},
				{
					path: 'platform-connections',
					element: <Screen.Admin />,
				},
				{
					path: 'settings',
					children: [
						{
							path: 'user',
							element: <Screen.Admin />,
						},
						{
							path: 'workspace',
							children: [
								{
									index: true,
									element: <Screen.Admin />,
								},
								{
									path: ':tabKey',
									element: <Screen.Admin />,
								},
							]
						},
					]
				},
				{
					path: 'api-keys',
					element: <Screen.Admin />,
				},
				{
					path: 'genflows',
					children: [
						{
							index: true,
							element: <Screen.Admin />
						},
						{
							path: ':genflowId',
							element: <GenflowLayout />,
							children: [
								{
									index: true,
									element: <Screen.GenflowDetail />
								},
								{
									path: 'develop',
									children: [
										{
											index: true,
											element: <Screen.GenflowDetail />
										},
										{
											path: ':planId',
											element: (
												<GenflowPlanProvider>
													<Screen.GenflowDetail />
												</GenflowPlanProvider>
											)
										}
									]
								},
								{
									path: 'optimize',
									children: [
										{
											index: true,
											element: <Screen.GenflowDetail />
										},
										{
											path: ':optimizationId',
											children: [
												{
													index: true,
													element: (
														<GenflowOptimizeProvider>
															<Screen.GenflowDetail />
														</GenflowOptimizeProvider>
													)
												},
												{
													path: ':pageKey',
													element: (
														<GenflowOptimizeProvider>
															<Screen.GenflowDetail/>
														</GenflowOptimizeProvider>
													),
													children: [
														{
															path: ':planId',
															children: [
																{ path: ':tabKey' }
															]
														},
														{
															path: ':comparisonPlanAId',
														},
														{
															path: ':comparisonPlanAId/:comparisonPlanBId',
														}
													]
												}
											]
										}
									]
								},
								{
									path: 'observe',
									element: <Screen.GenflowDetail />
								},
							]
						},
					]
				},
				{
					path: '*',
					element: <Screen.NotFound />
				}
			]
		}
	];

	const router = createBrowserRouter(routes);

	return (
		<RouterProvider
			router={router}
			fallbackElement={<DefaultLayout />}
		/>
	);
};

export default App;
