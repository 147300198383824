import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Flex, Avatar, Tooltip, Button, Spin } from 'antd';
import { Ellipsis, Loading, ReceiveAttentionButton, RelativeTime } from '../../../../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import GlobalContext from '../../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../../context/genflowDetail/Context';

const statusTagMap = {
	INITIALIZATION: {
		text: 'Just created',
		color: 'magenta',
		icon: <EditOutlined />
	},
	CONFIGURATION: {
		text: 'Configuring',
		color: 'magenta',
		icon: <EditOutlined />
	},
	TARGET_PLAN_EVALUATION: {
		text: 'Evaluating target plan',
		color: 'blue',
		icon: <LoadingOutlined />
	},
	ALT_PLANS_GENERATED: {
		text: 'Alt plans generated',
		color: 'magenta',
		icon: <EditOutlined />
	},
	ALT_PLANS_EVALUATIONS: {
		text: 'Evaluating alt plans',
		color: 'blue',
		icon: <LoadingOutlined />
	},
	ALT_PLANS_EVALUATIONS_COMPLETED: {
		text: 'All evaluations completed',
		color: 'magenta',
		icon: <EditOutlined />
	},
	COMPLETED: {
		text: 'Completed',
		color: 'green',
		icon: <CheckCircleOutlined />
	},
	FINAL_REPORT_GENERATED: {
		text: 'Final report generated',
		color: 'green',
		icon: <CheckCircleOutlined />
	},
	UNKNOWN: {
		text: '(Unknown)'
	},
};

const Optimization = (props) => {
	const { genflowId, genflowData, plansList, plansLoading, optimizationsData, optimizationsLoading, createOptimize } = useContext(GenflowDetailContext);
	const [ localData, setLocalData ] = useState(genflowData);
	const navigate = useNavigate();
	const { workspace } = useContext(GlobalContext);

	const shouldReceiveAttention = (!plansLoading && !optimizationsLoading) && plansList?.length > 0 && (!optimizationsData || optimizationsData?.length === 0);

	useEffect(() => {
		if (genflowData && localData !== genflowData) {
			setLocalData(genflowData);
		}
	}, [genflowData]);

	const columns = [
		{
			title: 'Optimization Name',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
			width: '65%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
	];

	const displayData = optimizationsData?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.slice(0, 10)
		.map((row) => {
			const { optimization_id, name, status, created_at, updated_at, updated_by, metadata } = row;
			const updatedByMetadata = workspace?.data?.members?.find(m => m.user_id === updated_by);

			return {
				key: optimization_id,
				status: (
					<Tag
						bordered={false}
						color={statusTagMap?.[status]?.color || null}
						icon={statusTagMap?.[status]?.icon}
					>
						{statusTagMap?.[status]?.text}
					</Tag>
				),
				label: (
					<Tooltip placement="topLeft" title={name || 'Untitled Optimization'}>
						<a onClick={() => navigate(`/genflows/${genflowId}/optimize/${optimization_id}`)}>
							<Ellipsis name={name || 'Untitled Optimization'} threshold={60} />
						</a>
					</Tooltip>
				),
			};
		});

	const [createLoading, setCreateLoading] = useState(false);

	const onCreateClick = async () => {
		setCreateLoading(true);
		await createOptimize();
		setCreateLoading(false);
	};

	return (
		<Flex vertical gap='small'>
			<Flex gap='small' align='center' justify='space-between' style={{ width: '100%' }}>
				<Button loading={createLoading} size='large' type='primary' icon={<PlusOutlined />} onClick={onCreateClick}>
                    Create new optimize
				</Button>
				<Button size='large' type='text' onClick={() => navigate('./optimize')}>
					Show all
				</Button>
			</Flex>
			{(plansLoading || optimizationsLoading) ? (
				<Loading />
			) : shouldReceiveAttention ? (
				<ReceiveAttentionButton
					onClick={onCreateClick}
					loading={createLoading}
					shoulderText='YOUR NEXT STEP'
					buttonText='Create new optimize'
				/>
			) : (
				<Table
					pagination={false}
					columns={columns}
					dataSource={displayData}
				/>
			)}
		</Flex>
	);
};

export default Optimization;
