import Presentation from './Presentation';
import { useAuthData } from '../../../../../../hooks';
import GenflowOptimizeContext from '../../../../../../context/genflowOptimize/Context';
import { useContext, useMemo } from 'react';

const Container = (props) => {
	const { genflowId, optimizationId } = useContext(GenflowOptimizeContext);

	const finalReportsEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/recommendations/final-reports`;
	}, [optimizationId]);
	const { data, loading } = useAuthData(finalReportsEndpoint);

	const aggregatedResultsEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/evaluations/results/aggregated`;
	}, [optimizationId]);
	const { data: aggregatedResultsData, loading: aggregatedResultsLoading } = useAuthData(aggregatedResultsEndpoint);

	return <Presentation
		{...props}
		reportData={data?.data?.rows?.[0] || {}}
		reportLoading={loading}
		aggregatedResultsData={aggregatedResultsData?.data?.rows}
		aggregatedResultsLoading={aggregatedResultsLoading}
	/>;
};

export default Container;
