import { useState } from 'react';
import Icon, {
	ArrowRightOutlined,
	DeleteOutlined,
	EditOutlined,
	FileAddOutlined,
	FileSearchOutlined
} from '@ant-design/icons';
import { AddPlatformForm } from '../../../../components/organisms';
import { ListPageTemplate } from '../../../../components/templates';
import { Flex, Tag, Table, Avatar, Typography, Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { RelativeTime } from '../../../../components/atoms';

const ModelListPage = (props) => {
	const { t } = useTranslation();
	const { data, loading, refresh, handleDelete } = props;
	const someLoading = Object.keys(loading).some(key => loading[key]);

	const queryParams = new URLSearchParams(window.location.search);
	
	const [showModal, setShowModal] = useState(queryParams.get('openModal') === 'true');
	const [targetPlatformId, setTargetPlatformId] = useState(null);

	const buttons = [
		{
			label: t('button.create-new-platform-connection'),
			type: 'primary',
			icon: <FileAddOutlined />,
			onClick: () => setShowModal(true),
		}
	];

	const rightButtons = [
		{
			label: t('button.view-docs'),
			type: 'primary',
			ghost: true,
			icon: <ArrowRightOutlined />,
			onClick: () => window.open('https://link.teammate.as/docs-model'),
		}
	];

	const ActionButtons = ({ id }) => {
		return (
			<Flex gap="middle">
				<Button
					type='text'
					shape='circle'
					icon={<EditOutlined />}
					onClick={() => {
						setTargetPlatformId(id);
						setShowModal(true);
					}}
				/>
				<Popconfirm
					title={t('popconfirm.title.delete-platform')}
					okText={t('popconfirm.ok.delete-platform')}
					okButtonProps={{ danger: true }}
					onConfirm={() => {
						handleDelete(id);
					}}
					cancelText={t('cancel')}
				>
					<Button
						type='text'
						shape='circle'
						danger
						icon={<DeleteOutlined />}
					/>
				</Popconfirm>
			</Flex>
		);
	};

	const platformColumns = [
		{
			title: t('table.header.platform'),
			dataIndex: 'platformName',
			key: 'platformName',
		},
		{
			title: t('table.header.connection-name'),
			dataIndex: 'platformConnectionName',
			key: 'platformConnectionName',
		},
		{
			title: t('api-key-masked'),
			dataIndex: 'maskedApiKey',
			key: 'maskedApiKey',
		},
		{
			title: t('table.header.created-at'),
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: t('table.header.model-count'),
			dataIndex: 'modelCount',
			key: 'modelCount',
		},
		{
			title: t('action'),
			dataIndex: 'action',
			key: 'action',
		}
	];

	const providerColumns = [
		{
			title: t('table.header.provider'),
			dataIndex: 'providerName',
			key: 'providerName',
		},
		{
			title: t('table.header.model-count'),
			dataIndex: 'modelCount',
			key: 'modelCount',
		}
	];

	const modelColumns = [
		{
			title: t('table.header.model'),
			dataIndex: 'modelName',
			key: 'modelName',
		},
		{
			title: t('table.header.model-type'),
			dataIndex: 'modelType',
			key: 'modelType',
		}
	];

	const platformDisplayData = data.platforms?.map((platform, index) => {
		const { _id, metaPlatformId, metaPlatformIsActive, createdBy, createdAt, updatedBy, ...restModels } = platform;
		return {
			...restModels,
			key: index,
			platformName: t(`platform.${metaPlatformId}`),
			createdBy: (
				<Flex gap='small' align='center'>
					<Avatar size="small" src={createdBy?.avatarUrl} alt={createdBy?.name} />
					{createdBy?.name}
				</Flex>
			),
			createdAt: <RelativeTime datetime={createdAt} />,
			modelCount: platform?.metaProviders?.reduce((acc, provider) => acc + provider?.metaModels?.length, 0),
			status: <Tag color={metaPlatformIsActive ? 'success' : 'error'}>{metaPlatformIsActive ? t('active') : t('suspended')}</Tag>,
			action: metaPlatformId !== 'teammate-infer' && <ActionButtons id={_id} />
		};
	});

	const generateProviderDisplayData = (metaProviders) => metaProviders?.map((provider, index) => {
		const { _id, metaModels } = provider;
		return {
			key: index,
			metaModels,
			modelCount: metaModels?.length,
			providerName: _id ? t(`provider.${_id?.split('/')[1]}`) : ''
		};
	});

	const generateModelDisplayData = (metaModels) => metaModels?.map((model, index) => {
		const { _id, name, isActive, type, displayOrder, validUntil } = model;
		return {
			key: index,
			modelName: name,
			modelType: t(`model-type.${type}`),
			status: <Tag color={isActive ? 'success' : 'error'}>{isActive ? t('active') : t('suspended')}</Tag>,
		};
	});


	const tableConfig = {
		expandable: {
			expandedRowRender: (platformRecord) => (
				<Table
					columns={providerColumns}
					dataSource={generateProviderDisplayData(platformRecord.metaProviders)}
					pagination={false}
					expandable={{
						expandedRowRender: (providerRecord) => (
							<Table
								columns={modelColumns}
								dataSource={generateModelDisplayData(providerRecord.metaModels)}
								pagination={false}
							/>
						),
						rowExpandable: () => true,
						expandRowByClick: true,
					}}
				/>
			),
			rowExpandable: () => true,
			expandRowByClick: true,
		},
	};

	return (
		<>
			<ListPageTemplate
				title={t('title.page.model')}
				description={t('desc.page.model')}
				buttons={buttons}
				rightButtons={rightButtons}
				columns={platformColumns}
				data={platformDisplayData}
				loading={someLoading}
				tableConfig={tableConfig}
			/>
			<AddPlatformForm
				open={showModal}
				onSubmit={() => {
					refresh.platforms();
					setShowModal(false);
					setTargetPlatformId(null);
				}}
				onCancel={() => {
					setShowModal(false);
					setTargetPlatformId(null);
				}}
				loading={someLoading}
				platformId={targetPlatformId}
				initialValues={data.platforms?.find(({ _id }) => _id === targetPlatformId) || null}
			/>
		</>
	);
};

export default ModelListPage;
