import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Spin, Tag, Tooltip } from 'antd';
import Icon, {
	CheckCircleOutlined,
	EditOutlined, FileDoneOutlined,
	FileSearchOutlined, HomeOutlined, LineChartOutlined,
	LoadingOutlined,
	PlusOutlined, RightOutlined
} from '@ant-design/icons';
import { ListPageTemplate } from '../../../../components/templates';
import { Ellipsis, Empty, RelativeTime, UserTableCell } from '../../../../components/atoms';
import GlobalContext from '../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';
import { BsStars } from 'react-icons/bs';

const statusTagMap = {
	INITIALIZATION: {
		text: 'Just created',
		color: 'magenta',
		icon: <EditOutlined />
	},
	CONFIGURATION: {
		text: 'Configuring',
		color: 'magenta',
		icon: <EditOutlined />
	},
	TARGET_PLAN_EVALUATION: {
		text: 'Evaluating target plan',
		color: 'blue',
		icon: <LoadingOutlined />
	},
	ALT_PLANS_GENERATED: {
		text: 'Alt plans generated',
		color: 'magenta',
		icon: <EditOutlined />
	},
	ALT_PLANS_EVALUATIONS: {
		text: 'Evaluating alt plans',
		color: 'blue',
		icon: <LoadingOutlined />
	},
	ALT_PLANS_EVALUATIONS_COMPLETED: {
		text: 'All evaluations completed',
		color: 'magenta',
		icon: <EditOutlined />
	},
	COMPLETED: {
		text: 'Completed',
		color: 'green',
		icon: <CheckCircleOutlined />
	},
	FINAL_REPORT_GENERATED: {
		text: 'Final report generated',
		color: 'green',
		icon: <CheckCircleOutlined />
	},
	UNKNOWN: {
		text: '(Unknown)'
	},
};

const OptimizeListPage = ({ data, loading, refresh, create }) => {
	const navigate = useNavigate();
	const [buttonsLoading, setButtonsLoading] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const { pushMessage, workspace } = useContext(GlobalContext);
	const { genflowId, plansList } = useContext(GenflowDetailContext);

	const columns = [
		{
			title: 'Title',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
			width: 300,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Best plan',
			dataIndex: 'bestPlan',
			key: 'bestPlan',
		},
		{
			title: 'Updated at',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		},
	];

	const displayData = data.genflows?.rows?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
		.map((row) => {
			const { genflow_id, optimization_id, name, status = 'UNKNOWN', updated_at, metadata } = row;
			const bestPlanData = metadata?.bestPlan ? plansList?.find(item => item.genflow_plan_id === metadata?.bestPlan) : {};

			return {
				key: optimization_id,
				label: (
					<Tooltip placement="topLeft" title={name || 'No title'}>
						<a onClick={() => navigate(`/genflows/${genflow_id}/optimize/${optimization_id}`)}>
							{name || 'No title'}
						</a>
					</Tooltip>
				),
				status: (
					<Tag
						bordered={false}
						color={statusTagMap?.[status]?.color || null}
						icon={statusTagMap?.[status]?.icon}
					>
						{statusTagMap?.[status]?.text}
					</Tag>
				),
				bestPlan: metadata?.bestPlan ? (
					<Tooltip placement="topLeft" title={bestPlanData?.name || 'No title'} color='var(--tooltip-background)'>
						<a onClick={() => navigate(`/genflows/${genflow_id}/develop/${metadata?.bestPlan}`)}>
							<Ellipsis name={bestPlanData?.name || 'No title'} threshold={30}/>
						</a>
					</Tooltip>
				) : (
					<Tooltip
						placement="topLeft"
						title='You need to run comparable experiments and mark the best plan.'
						color='var(--tooltip-background)'
					>
						<span style={{ color: '#999999' }}>Not identified yet</span>
					</Tooltip>
				),
				updatedAt: (<RelativeTime datetime={updated_at} />),
				action: (
					<Flex gap='small'>
						<Tooltip title='Overview' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<HomeOutlined />}
								onClick={() => navigate(`./${optimization_id}/experiments`)}
							/>
						</Tooltip>
						<Tooltip title='Recommended Plans' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={BsStars} />}
								onClick={() => navigate(`./${optimization_id}/recommendations`)}
							/>
						</Tooltip>
						<Tooltip title='Logs' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={FileSearchOutlined} />}
								onClick={() => navigate(`./${optimization_id}/experiments/comparison/logs`)}
							/>
						</Tooltip>
						<Tooltip title='Scores' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<Icon component={LineChartOutlined} />}
								onClick={() => navigate(`./${optimization_id}/experiments/comparison/scores`)}
							/>
						</Tooltip>
						<Tooltip title='Final report' color='var(--tooltip-background)'>
							<Button
								type='text'
								shape='circle'
								icon={<FileDoneOutlined />}
								onClick={() => navigate(`./${optimization_id}/conclusions`)}
							/>
						</Tooltip>
					</Flex>
				)
			};
		});

	const onCreateClick = async () => {
		setButtonsLoading(true);

		const response = await create.genflow();

		if (response.message === 'OK' && response.result?.['optimization_id']) {
			const optimizationId = response.result?.['optimization_id'];
			setButtonsLoading(false);
			navigate(`/genflows/${genflowId}/optimize/${optimizationId}`);

			return response;
		} else {
			pushMessage('Creation failed', 'error');

			setButtonsLoading(false);
			return response;
		}
	};

	const buttons = [
		{
			isCustom: true,
			component: <ButtonCreate onClick={onCreateClick} loading={buttonsLoading} />
		},
	];

	const ComponentOnEmpty = () => (
		<Flex vertical gap='small'>
			<Empty type='building' description='Start optimization!' />
			<ButtonCreate onClick={onCreateClick} loading={buttonsLoading} />
		</Flex>
	);
	
	return (
		<div style={{ paddingTop: 12 }}>
			<ListPageTemplate
				title='Optimize List'
				description='The Optimize phase refines and improves your generation flow by evaluating it across multiple datasets backed by AI support.'
				buttons={buttons}
				buttonsLoading={buttonsLoading}
				columns={columns}
				data={displayData}
				loading={loading.genflows}
				componentOnEmpty={<ComponentOnEmpty />}
			/>
		</div>
	);
};

const ButtonCreate = ({ loading, onClick }) => {
	const { plansList, plansLoading } = useContext(GenflowDetailContext);
	const navigate = useNavigate();

	return (
		<Tooltip
			title={(!plansLoading && plansList?.length === 0) ? (
				<Flex vertical gap='small' style={{ paddingBottom: 5 }}>
					<span>You need to start from creating a plan first!</span>
					<Button
						size='small'
						icon={<RightOutlined />}
						onClick={() => navigate('../develop')}
					>
						Go to plan page
					</Button>
				</Flex>
			) : null}
			color='var(--tooltip-background)'
			placement='bottom'
		>
			<Button
				type='primary'
				size='large'
				icon={<PlusOutlined />}
				loading={loading || plansLoading}
				disabled={!plansLoading && plansList?.length === 0}
				onClick={onClick}
			>
				Create new optimize
			</Button>
		</Tooltip>
	);
};

export default OptimizeListPage;
