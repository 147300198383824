import { Button, Flex } from 'antd';
import Icon from '@ant-design/icons';
import { FiTool } from 'react-icons/fi';
import { TbLayoutDashboard } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { IoSettingsSharp } from 'react-icons/io5';
import './index.css';

const GenerationFlowMiniCard = (props) => {
	const { name, genflow_id } = props;
	const navigate = useNavigate();
	const bodyStyle = {
		width: 'calc(50% - 4px)'
	};
	const basePath = `/genflows/${genflow_id}`;

	return (
		<Flex vertical gap='middle' className='genflow-mini-card-container' style={bodyStyle}>
			<span className='genflow-mini-card-title'>{name || '(Untitled)'}</span>
			<Flex gap='small' wrap>
				<Button type='primary' icon={<Icon component={TbLayoutDashboard} />} onClick={() => navigate(basePath)}>Overview</Button>
				<Button type='primary' ghost icon={<Icon component={FiTool} />} onClick={() => navigate(basePath + '/develop')}>Develop</Button>
				<Button type='primary' ghost icon={<Icon component={IoSettingsSharp} />} onClick={() => navigate(basePath + '/optimize')}>Optimize</Button>
			</Flex>
		</Flex>
	);
};

export default GenerationFlowMiniCard;
