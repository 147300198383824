import { EditableTitle } from '../../../../atoms';
import { Button, Dropdown, Flex, Modal } from 'antd';
import {
	CopyOutlined,
	DeleteOutlined,
	DownOutlined,
	EditOutlined,
	EllipsisOutlined, ExclamationCircleFilled,
	UpOutlined
} from '@ant-design/icons';
import { useContext, useState } from 'react';
import KnowledgebookContext from '../../contexts/Context';
import GenflowPlanContext from '../../../../../context/genflowPlan/Context';
import { adjustArrayOrder } from '../../../../../scripts';
import GlobalContext from '../../../../../context/GlobalContext';


const buttonSharedProps = {
	shape:'circle',
	size: 'middle',
	type: 'text'
};

const { confirm } = Modal;

const Header = () => {
	const { pushMessage } = useContext(GlobalContext);
	const { data: planData, operatePlan } = useContext(GenflowPlanContext);
	const { knowledgebookId, data, loading, editKnowledgebook, copyKnowledgebook, deleteKnowledgebook } = useContext(KnowledgebookContext);
	const steps = planData?.metadata?.steps || [];
	const bookIndex = steps?.findIndex(item => item.id === knowledgebookId);
	const [isTitleEditing, setIsTitleEditing] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const handleMove = (direction) => {
		const newSteps = adjustArrayOrder(steps, bookIndex, direction);
		operatePlan({ key: 'metadata.steps', value: newSteps, type: 'json' }, 'PATCH');
	};

	const handleDelete = () => {
		showDeleteConfirm(data?.name || 'Untitled knowledgebook');
	};

	const showDeleteConfirm = (name) => {
		confirm({
			title: 'Are you sure delete this task?',
			icon: <ExclamationCircleFilled />,
			content: 'Knowledgebook: ' + name,
			okText: 'Delete',
			okType: 'danger',
			cancelText: 'Cancel',
			confirmLoading: deleting,
			async onOk() {
				setDeleting(true);
				await deleteKnowledgebook();
				setDeleting(false);
			},
			onCancel() {
			},
		});
	};

	const buttonItems = [
		{
			label: 'Copy ID',
			key: 'copy',
			icon: <CopyOutlined />,
			onClick: async () => {
				await navigator.clipboard.writeText(knowledgebookId);
				pushMessage('Knowledgebook ID copied', 'success');
			}
		},
		{
			label: 'Rename',
			key: 'rename',
			icon: <EditOutlined />,
			onClick: () => setIsTitleEditing(true)
		},
		{
			label: 'Duplicate',
			key: 'duplicate',
			icon: <CopyOutlined />,
			onClick: () => copyKnowledgebook(bookIndex + 1),
			disabled: true
		},
		{
			label: 'Delete',
			key: 'delete',
			danger: true,
			icon: <DeleteOutlined />,
			onClick: () => handleDelete()
		}
	];

	return (
		<>
			<Flex align='center' justify='space-between'>
				<EditableTitle
					editing={isTitleEditing}
					onStartEditing={() => setIsTitleEditing(true)}
					onEndEditing={() => setIsTitleEditing(false)}
					level={3}
					label={data?.name}
					labelOnNull='Untitled knowledgebook'
					loading={loading}
					onChange={(text) => editKnowledgebook('name', text)}
				/>
				<Flex
					gap='small'
					align='center'
					justify='flex-end'
					onClick={(e) => e.stopPropagation()}
				>
					<Button
						{...buttonSharedProps}
						disabled={bookIndex === 0}
						icon={<UpOutlined />}
						onClick={() => handleMove('up')}
					/>
					<Button
						{...buttonSharedProps}
						disabled={bookIndex === steps?.length - 1}
						icon={<DownOutlined />}
						onClick={() => handleMove('down')}
					/>
					<Dropdown
						menu={{
							items: buttonItems,
						}}
						trigger={['click']}
						placement='bottomRight'
					>
						<Button
							{...buttonSharedProps}
							icon={<EllipsisOutlined />}
						/>
					</Dropdown>
				</Flex>
			</Flex>
		</>
	);
};

export default Header;
