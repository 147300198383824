import { useState, useEffect, useRef } from 'react';
import { Col, InputNumber, Row, Slider } from 'antd';

const SliderWithInputNumber = (props) => {
	const isInitialLoad = useRef(true);
	const { defaultValue, value, disabled, size, onChange = () => {}, min = 0, max = 100, step = 1, valueFormatter = (value) => value } = props;
	const [inputValue, setInputValue] = useState(value || defaultValue || 0);

	useEffect(() => {
		if (value !== undefined && isInitialLoad.current) {
			setInputValue(value);
			isInitialLoad.current = false;
		}
	}, [value]);

	const triggerChange = (changedValue, shouldCallOnChange = false) => {
		setInputValue(changedValue);

		if (onChange && shouldCallOnChange) {
			onChange(changedValue);
		}
	};

	const onSliderChange = (newValue) => {
		if (!('value' in props)) {
			setInputValue(newValue);
		}
		triggerChange(newValue, false);
	};

	const onSliderChangeComplete = (newValue) => {
		if (!('value' in props)) {
			setInputValue(newValue);
		}
		triggerChange(newValue, true);
	};

	const onInputNumberChange = (newValue) => {
		if (!('value' in props)) {
			setInputValue(newValue);
		}
		triggerChange(newValue, true);
	};

	return (
		<Row gutter={16} style={{ width: '100%', margin: 0 }}>
			<Col flex="auto">
				<Slider
					min={min}
					max={max}
					onChange={onSliderChange}
					onChangeComplete={onSliderChangeComplete}
					defaultValue={defaultValue}
					value={typeof inputValue === 'number' ? inputValue : 0}
					step={step}
					tooltip={{
						formatter: valueFormatter,
					}}
					disabled={disabled}
				/>
			</Col>
			<Col flex="100px" style={{ padding: 0 }}>
				<InputNumber
					min={min}
					max={max}
					step={step}
					defaultValue={defaultValue}
					value={value}
					onChange={onInputNumberChange}
					style={{ width: '100%' }}
					formatter={valueFormatter}
					parser={valueFormatter}
					variant='filled'
					size={size}
					disabled={disabled}
				/>
			</Col>
		</Row>
	);
};

export default SliderWithInputNumber;