import { useContext, useEffect, useState } from 'react';
import KnowledgebookContext from '../../../contexts/Context';
import { Radio, Tooltip } from 'antd';

const TypeSelect = () => {
	const { data, loading, editKnowledgebook, isIndexed } = useContext(KnowledgebookContext);
	const [localValue, setLocalValue] = useState();

	useEffect(() => {
		if (data?.type !== localValue) {
			setLocalValue(data?.type);
		}
	}, [data]);

	const handleChange = (value) => {
		setLocalValue(value);
		editKnowledgebook('type', value);
	};
	
	return (
		<Radio.Group value={localValue} onChange={(e) => handleChange(e.target.value)} disabled={isIndexed}>
			<Radio value='EMBEDDINGS'>Embeddings</Radio>
			<Tooltip color='var(--tooltip-background)' title='Coming soon' placement='topLeft'>
				<Radio disabled value='GRAPH-RAG'>Graph RAG</Radio>
			</Tooltip>
		</Radio.Group>
	);
};

export default TypeSelect;
