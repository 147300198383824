import { useContext, useState } from 'react';
import GlobalContext from '../../../../../../../../context/GlobalContext';
import GenflowDetailContext from '../../../../../../../../context/genflowDetail/Context';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import { useAuthRequest } from '../../../../../../../../hooks';
import { Button, Flex, Input, Select } from 'antd';
import { SliderWithInputNumber } from '../../../../../../../../components/atoms';
import Icon from '@ant-design/icons';
import { BsStars } from 'react-icons/bs';
import ISO6391 from 'iso-639-1';

const MAX_GENERATION_COUNT = 100;

const AIGenerate = ({ setCurrent, onFinish }) => {
	const { pushMessage } = useContext(GlobalContext);
	const { genflowId, plansList, plansLoading } = useContext(GenflowDetailContext);
	const { optimizationId, userInputSetList, targetPlanId } = useContext(GenflowOptimizeContext);
	const [generationCount, setGenerationCount] = useState(50);
	const [languageCode, setLanguageCode] = useState('en');
	const [additionalDirections, setAdditionalDirections] = useState('');
	const [submitLoading, setSubmitLoading] = useState(false);

	const { makeRequest } = useAuthRequest();
	// eslint-disable-next-line no-undef
	const generationEndpoint = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/optimizations/${optimizationId}/datasets/generations-invoke/${targetPlanId}`;

	const handleSubmit = async () => {
		setSubmitLoading(true);

		if (!targetPlanId) {
			pushMessage('Target Plan must be selected first', 'error');
			setSubmitLoading(false);
			return;
		}

		const payload = {
			generationCount,
			metadata: {
				languageCode,
				additionalDirections,
			}
		};

		const response = await makeRequest(generationEndpoint, 'POST', payload);
		if (response?.message === 'OK') {
			onFinish();
		} else {
			pushMessage('Failed to start generation. Please try again later.', 'error');
		}
		setSubmitLoading(false);
	};

	return (
		<Flex vertical gap='middle'>
            How many user input to create?
			<SliderWithInputNumber
				size='large'
				variant='filled'
				value={generationCount}
				onChange={(v) => setGenerationCount(v)}
				min={1}
				max={MAX_GENERATION_COUNT}
			/>
			{/*(Optional) Which language to create?*/}
			{/*<LanguageSelect*/}
			{/*	value={languageCode}*/}
			{/*	onChange={(value) => setLanguageCode(value)}*/}
			{/*/>*/}
            (Optional) Write any additional contexts or directions for the input patterns if any, so the generated patterns would have more focus.
			<Input.TextArea
				variant='filled'
				placeholder='e.g. "Be specific at XXX topic."'
				value={additionalDirections}
				onChange={(v) => setAdditionalDirections(v.target.value)}
			/>
			<Button
				block
				type='primary'
				size='large'
				loading={submitLoading}
				onClick={handleSubmit}
				icon={<Icon component={BsStars} />}
			>
                Start generating Input Dataset
			</Button>
		</Flex>
	);
};

const LanguageSelect = ({ value, onChange }) => {
	const topLanguages = ['en', 'zh', 'es'];
	const otherMajorLanguages = ['de', 'fr', 'ja', 'ko', 'it', 'ru', 'ar'];

	const languages = ISO6391.getAllCodes().map(code => ({
		label: ISO6391.getName(code),
		value: code
	}));

	languages.sort((a, b) => {
		const indexA = topLanguages.indexOf(a.value);
		const indexB = topLanguages.indexOf(b.value);
		const majorIndexA = otherMajorLanguages.indexOf(a.value);
		const majorIndexB = otherMajorLanguages.indexOf(b.value);

		if (indexA !== -1 || indexB !== -1) {
			if (indexA !== -1 && indexB !== -1) {
				return indexA - indexB;
			}
			return indexA !== -1 ? -1 : 1;
		}

		if (majorIndexA !== -1 || majorIndexB !== -1) {
			if (majorIndexA !== -1 && majorIndexB !== -1) {
				return 0;
			}
			return majorIndexA !== -1 ? -1 : 1;
		}

		return a.label.localeCompare(b.label); // Alphabetical order for the rest
	});

	return (
		<Select
			value={value}
			options={languages}
			size='large'
			variant='filled'
			onChange={onChange}
		/>
	);
};

export default AIGenerate;
