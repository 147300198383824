import { useContext, useEffect, useRef, useState } from 'react';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';
import { MetricCardContainer } from '../index';
import GlobalContext from '../../../../../../../../context/GlobalContext';

const MetricCardGeneral = (props) => {
	const { title, description, isFree, metricsList, outputKey, moduleKey, comingSoon = false } = props;
	const { pushMessage } = useContext(GlobalContext);
	const { data, loading, hasReachedMaxModuleCount, operateOptimize } = useContext(GenflowOptimizeContext);
	const targetModules = data?.metadata?.evaluationModules?.[outputKey];
	const [localSelected, setLocalSelected] = useState(false);
	const isInitialLoad = useRef(true);

	useEffect(() => {
		if (isInitialLoad.current && !loading && targetModules && Object.keys(targetModules)?.length > 0) {
			setLocalSelected(Object.keys(targetModules).includes(moduleKey));
			isInitialLoad.current = false;
		}
	}, [loading, targetModules]);

	const handleSelect = (v) => {
		if (v === false || !hasReachedMaxModuleCount) {
			setLocalSelected(v);
			handleMetric(moduleKey, v);
		} else if (v === true && hasReachedMaxModuleCount) {
			pushMessage('You cannot add more than 3 metrics.', 'warning');
		}
	};

	const handleMetric = async (dimension, value) => {
		if (value === true) {
			await handleAddMetric(dimension, value);
		} else if (value === false) {
			await handleRemoveMetric(dimension, value);
		}
	};

	const handleAddMetric = async (key, value) => {
		const payload = {
			key: 'metadata.evaluationModules',
			value: {
				...data?.metadata?.evaluationModules,
				[outputKey]: {
					...data?.metadata?.evaluationModules?.[outputKey],
					[key]: value
				}
			},
			type: 'json'
		};

		await operateOptimize(payload, 'PATCH');
	};

	const handleRemoveMetric = async (key) => {
		const newMetrics = {};
		const targetModules = data?.metadata?.evaluationModules?.[outputKey];
		if (Object.keys(targetModules)?.length > 0) {
			Object.keys(targetModules)?.forEach(metricKey => {
				if (metricKey !== key) {
					newMetrics[metricKey] = targetModules[metricKey];
				}
			});

			const payload = {
				key: 'metadata.evaluationModules',
				value: {
					...data?.metadata?.evaluationModules,
					[outputKey]: newMetrics
				},
				type: 'json'
			};

			await operateOptimize(payload, 'PATCH');
		}
	};

	return (
		<MetricCardContainer
			title={title}
			description={description}
			selected={localSelected}
			onSelect={handleSelect}
			isFree={isFree}
			metricsList={metricsList}
			comingSoon={comingSoon}
		/>
	);
};

export default MetricCardGeneral;
