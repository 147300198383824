import { Flex, Switch, Tag, Tooltip } from 'antd';
import { useContext } from 'react';
import GenflowOptimizeContext from '../../../../../../../../context/genflowOptimize/Context';

const MetricCardContainer = ({ children, title, description, selected = false, onSelect = () => {}, isFree = false, disabled, comingSoon, metricsList = [], style }) => {
	const {
		hasReachedMaxModuleCount,
		isBeforeEvaluationInvoked
	} = useContext(GenflowOptimizeContext);

	return (
		<Tooltip color='var(--tooltip-background)' title={comingSoon ? 'Coming soon' : null}>
			<Flex
				vertical
				gap='middle'
				style={{
					width: 'calc(33.33% - 10px)',
					...(comingSoon ? { color: '#999999' } : {}),
					...style,
				}}
				className={`metric-card${selected ? ' metric-card-selected' : ''}`}
			>
				<Flex vertical gap='small'>
					<Flex align='flex-start' gap='small' justify='space-between'>
						<Flex align='flex-start' gap='small'>
							<span className='metric-card-title'>{title}</span>
							{/*{isFree && (*/}
							{/*	<Tag color='green' bordered={false}>Free</Tag>*/}
							{/*)}*/}
						</Flex>
						<Tooltip
							title={!isBeforeEvaluationInvoked ? '[LOCKED] You cannot modify after evaluation.' : (hasReachedMaxModuleCount && !selected) ? 'You cannot add more than 3 metrics now.' : null}
							color='var(--tooltip-background)'
						>
							<Switch
								checked={selected}
								onChange={onSelect}
								disabled={!isBeforeEvaluationInvoked || (hasReachedMaxModuleCount && !selected) || disabled || comingSoon}
							/>
						</Tooltip>
					</Flex>
					{description && (
						<span
							className='metric-card-description'
							style={{
								...(comingSoon ? { color: '#999999' } : {}),
							}}
						>
							{description}
						</span>
					)}
					{metricsList.length > 0 && (
						<Flex wrap>
							{metricsList.map((metric, i) => (
								<Tag
									key={i}
									color={selected ? 'blue' : null}
									bordered={false}
									style={{
										margin: 3,
										...(comingSoon ? { color: '#999999' } : {}),
									}}
								>
									{metric.label} {'<'}{metric.type?.toUpperCase()}{'>'}
								</Tag>
							))}
						</Flex>
					)}
				</Flex>
				{children}
			</Flex>
		</Tooltip>
	);
};

export default MetricCardContainer;
