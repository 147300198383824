import Context from './Context';
import { useAuthData, useAuthRequest } from '../../hooks';
import { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GlobalContext from '../GlobalContext';

const GenflowDetailProvider = ({ children }) => {
	const { genflowId } = useParams();

	const location = useLocation();
	const localPathArr = location.pathname.split('/')?.slice(3);

	const { pushMessage } = useContext(GlobalContext);
	const navigate = useNavigate();

	const baseEndpoint = useMemo(() => {
		// eslint-disable-next-line no-undef
		return `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}`;
	}, [genflowId]);

	const { data, loading, refresh } = useAuthData(baseEndpoint, !!genflowId);
	const { data: plansData, loading: plansLoading, refresh: plansRefresh } = useAuthData(baseEndpoint + '/plans?limit=100');
	const { data: optimizationsData, loading: optimizationsLoading, refresh: optimizationsRefresh } = useAuthData(baseEndpoint + '/optimizations?limit=100', !!genflowId);

	// Production plan data
	const productionPlanId = data?.data?.rows?.[0]?.metadata?.productionPlanId;
	const productionPlanUrl = `${process.env.REACT_APP_BASE_API}/genflows/${genflowId}/plans/${productionPlanId}`;
	const { data: productionPlanData, loading: productionPlanLoading, refresh: productionPlanRefresh } = useAuthData(productionPlanUrl, !!productionPlanId && !loading);

	const { makeRequest } = useAuthRequest();
	const [requestLoading, setRequestLoading] = useState(false);

	const operateGenflow = async (payload, method, path = '') => {
		setRequestLoading(true);
		const response = await makeRequest(baseEndpoint + path, method, payload);
		await refresh(); // Trigger refresh to update data after operation
		setRequestLoading(false);
		return response;
	};

	const createPlan = async () => {
		const response = await makeRequest(baseEndpoint + '/plans', 'POST');
		if (response.message === 'OK' && response.result?.['genflow_plan_id']) {
			const genflowPlanId = response.result?.['genflow_plan_id'];
			navigate(`/genflows/${genflowId}/develop/${genflowPlanId}`);

			await plansRefresh();

			return response;
		} else {
			pushMessage('Creation failed', 'error');

			return response;
		}
	};

	const createOptimize = async () => {
		const response = await makeRequest(baseEndpoint + '/optimizations', 'POST');
		if (response.message === 'OK' && response.result?.['optimization_id']) {
			const optimizationId = response.result?.['optimization_id'];
			navigate(`/genflows/${genflowId}/optimize/${optimizationId}`);

			await optimizationsRefresh();

			return response;
		} else {
			pushMessage('Creation failed', 'error');

			return response;
		}
	};

	const createOptimizeWithInitialization = async ({ targetPlanId, name }) => {
		const createResponse = await makeRequest(baseEndpoint + '/optimizations', 'POST');
		if (createResponse.message === 'OK' && createResponse.result?.['optimization_id']) {
			const optimizationId = createResponse.result?.['optimization_id'];

			// Initialization
			if (targetPlanId) {
				await makeRequest(baseEndpoint + '/optimizations/' + optimizationId, 'PATCH', {
					key: 'metadata.targetPlanId',
					value: targetPlanId
				});
			}

			if (name) {
				await makeRequest(baseEndpoint + '/optimizations/' + optimizationId, 'PATCH', {
					key: 'name',
					value: name
				});
			}

			await makeRequest(baseEndpoint + '/optimizations/' + optimizationId, 'PATCH', {
				key: 'status',
				value: 'CONFIGURATION'
			});

			navigate(`/genflows/${genflowId}/optimize/${optimizationId}`);

			await optimizationsRefresh();

			return createResponse;
		} else {
			pushMessage('Creation failed', 'error');

			return createResponse;
		}
	};

	const operatePlan = async (payload, method, path = '') => {
		const response = await makeRequest(baseEndpoint + '/plans' + path, method, payload);
		await plansRefresh();
		return response;
	};

	const contextValues = useMemo(() => ({
		genflowId,
		localPathArr,
		genflowData: data?.data?.rows?.[0] || {},
		genflowLoading: loading,
		genflowRefresh: refresh,
		productionPlanData: productionPlanData?.data?.rows?.[0] || {},
		productionPlanLoading,
		productionPlanRefresh,
		operateGenflow,
		plansList: plansData?.data?.rows,
		plansLoading,
		plansRefresh,
		operatePlan,
		optimizationsData: optimizationsData?.data?.rows,
		optimizationsLoading,
		optimizationsRefresh,
		createPlan,
		createOptimize,
		createOptimizeWithInitialization,
	}), [genflowId, localPathArr, data, productionPlanData, plansData, optimizationsData]);

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default GenflowDetailProvider;