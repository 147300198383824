import { Button, Flex } from 'antd';
import { ImageContainer, MotionDiv } from '../../../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import GenflowDetailContext from '../../../../context/genflowDetail/Context';

const Observe = () => {
	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 'calc(100vh - 50px)',
		width: '100%'
	};
	const { genflowId } = useContext(GenflowDetailContext);
	const navigate = useNavigate();

	return (
		<div style={style}>
			<Flex vertical gap={30} align='center'>
				<MotionDiv animationIndex={0}>
					<ImageContainer
						width={150}
						src='/images/observing.png'
						alt='Observe'
					/>
				</MotionDiv>
				<MotionDiv animationIndex={1}>
					<span style={{ fontSize: 18 }}>
                    Observability is in private preview, <a href='mailto:hello@teammate.ltd'>contact us</a> to get access!
					</span>
				</MotionDiv>
				<MotionDiv animationIndex={2}>
					<Button type='primary' size='large' onClick={() => navigate(`/genflows/${genflowId}`)}>
					Go back to Overview
					</Button>
				</MotionDiv>
			</Flex>
		</div>
	);
};

export default Observe;
