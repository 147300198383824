import { Flex } from 'antd';
import Provider from './contexts/Provider';
import { Header, Editor } from './components';
import './index.css';

const Promptbook = ({ id, stepNameMap }) => {
	return (
		<Provider id={id}>
			<Flex vertical gap='middle'>
				<Header />
				<Editor stepNameMap={stepNameMap} />
			</Flex>
		</Provider>
	);
};

export default Promptbook;
