import Context from './Context';
import { useAuthData } from '../../hooks';

const GenflowListProvider = ({ children }) => {
	// eslint-disable-next-line no-undef
	const baseEndpoint = `${process.env.REACT_APP_BASE_API}/genflows`;

	const { data, loading, refresh } = useAuthData(baseEndpoint + '?limit=100');

	const contextValues = {
		data: data?.data?.rows || [],
		loading,
		refresh
	};

	return (
		<Context.Provider value={contextValues}>
			{children}
		</Context.Provider>
	);
};

export default GenflowListProvider;
